import { h } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import { useCaptcha, reloadCaptcha } from 'resources/captcha'

import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import './index.sass'

export default function Captcha({
  className,
  value,
  onChange,
  submitting,
  ...props
}){
  const inputRef = useRef()
  const captcha = useCaptcha()
  const loading = !captcha || captcha.loading
  useEffect(
    () => {
      if (!submitting && (!captcha || captcha.consumed)){
        reloadCaptcha()
        onChange('')
        setTimeout(() => {
          TextInput.focus(inputRef)
        }, 100)
      }
    },
    [submitting]
  )

  const reload = () => {
    reloadCaptcha()
  }

  return <div
    {...props}
    className={classNames('Captcha', { className })}
  >
    <ErrorMessage error={captcha.error}/>
    <div className="Captcha-stage">
      {loading
        ? <Loading size="sm" type="block"/>
        : <CaptchaImage svg={captcha.svg}/>
      }
      <Button
        tabIndex={-1}
        type="none"
        onClick={reload}
        disabled={submitting || loading}
      >reload</Button>
    </div>
    <TextInput {...{
      ref: inputRef,
      placeholder: 'captcha',
      disabled: (submitting || loading || captcha.consumed),
      value,
      onChange,
    }}/>
  </div>
}

Captcha.propTypes = {
  className: PropTypes.string,
  captcha: PropTypes.shape({
    loading: PropTypes.bool,
    consumed: PropTypes.bool,
    error: ErrorMessage.propTypes.error,
    encValue: PropTypes.string,
    svg: PropTypes.string,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
  submitting: PropTypes.bool,
}


function CaptchaImage({ svg }){
  return <div {...{
    className: 'Captcha-Image',
    style: {
      backgroundImage: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}")`,
    }
  }}/>
}
