import { h } from 'preact'

import classNames from 'lib/classNames'
import PageError from 'components/PageError'
import JlinxLooking from 'components/Jlinx/looking.svg'

export default function LoggedOutFeedPostsMessage({
  className,
  message = 'You must be logged in to see posts',
  destinationOrganizationApikey,
}){
  return <PageError {...{
    className: classNames('LoggedOutFeedPostsMessage', { className }),
    header: message,
    img: JlinxLooking,
    destinationOrganizationApikey,
  }} />
}
