import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import TextInput from 'components/TextInput'
import Switch from 'components/Switch'
import Tooltip from 'components/Tooltip'
import DatePicker from 'components/DatePicker'
import './index.sass'

export default class TextInputWithSwitch extends Component {

  static propTypes = {
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    textInputDisabled: PropTypes.bool.isRequired,
    textInputUnsaved: PropTypes.bool.isRequired,
    switchDisabled: PropTypes.bool.isRequired,
    switchUnsaved: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    noDatePickerIcon: PropTypes.bool,
    onTextChange: PropTypes.func,
    onTextInput: PropTypes.func,
    onSwitchChange: PropTypes.func,
    onTextInputClick: PropTypes.func,
    className: PropTypes.string,
    textInputClassName: PropTypes.string,
    switchTooltip: PropTypes.bool.isRequired,
    tooltipText: PropTypes.string,
    autocomplete: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
    textInputDisabled: false,
    textInputUnsaved: false,
    switchDisabled: false,
    switchUnsaved: false,
    switchTooltip: false,
  }

  focus = () => {
    const input = this.base.querySelector('input')
    if (input) input.focus()
  }

  render(){
    const {
      disabled,
      textInputDisabled,
      switchDisabled,
      textInputUnsaved,
      switchUnsaved,
      value,
      placeholder,
      checked,
      onTextChange,
      onTextInput,
      onSwitchChange,
      onTextInputClick,
      textInputClassName,
      name,
      type,
      switchTooltip,
      tooltipText,
      noDatePickerIcon,
      autocomplete,
      ...props
    } = this.props

    let className = `TextInputWithSwitch ${this.props.className || ''} `
    let switchElement = <Switch
      disabled={switchDisabled || disabled}
      unsaved={switchUnsaved}
      checked={!!checked}
      onChange={onSwitchChange}
    />
    if (switchTooltip) {
      className += `TextInputWithSwitch-switchTooltip`
      switchElement = <Tooltip onClick={this.focus} text={tooltipText}>
        {switchElement}
      </Tooltip>
    }

    const inputProps = {
      name,
      value,
      placeholder,
      type,
      autocomplete,
      className: textInputClassName,
      disabled: textInputDisabled || disabled,
      unsaved: textInputUnsaved,
      onChange: onTextChange,
      onInput: onTextInput,
      onClick: onTextInputClick,
    }

    return <div className={className} {...props}>
      { type === 'date'
        ? <DatePicker noIcon={noDatePickerIcon} {...inputProps} />
        : <TextInput {...inputProps} />
      }
      {switchElement}
    </div>
  }
}
