if (process.env.NODE_ENV === 'development'){

  global.debug = (...args) => {
    if (args.length === 0) return
    const upstack = (new Error()).stack.split('\n')[2].trim()
    const name = `%c DEBUG (${upstack})`
    const style = 'color: red; font-size: 120%'
    console.group(name, style)
    for (const arg of args) console.log(arg)
    console.groupEnd(name)
  }

} else {
  const noop = () => {}
  global.debug = noop
}
