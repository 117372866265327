import Header from 'components/Header'
import { h } from 'preact'

export default function NotAMemberMessage() {
  return <Header size="md" className="NotAMember" style={{
    textAlign: 'center',
    padding: '1rem',
  }}>
    You are no longer a member of this chat.
  </Header>
}
