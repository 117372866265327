import { h, Fragment } from 'preact'
import { useEffect } from 'preact/hooks'
import { usePublicProfiles } from 'lib/membershipAppStateHooks'
import EndUserDMAvatar from 'components/EndUserDMAvatar'
import { useAcceptedMembershipsForOrganization } from 'lib/membershipAppStateHooks'
import Header from 'components/Header'
import './index.sass'


export function DMChatChannelHeader({
  organization,
  chatname,
  onAdminStatusChange,
  myPublicProfile,
  memberUserDids,
  chatImage }) {
  const { acceptedOrganizationMemberships } =
  useAcceptedMembershipsForOrganization(organization.apikey, 'OrganizationChatPage')
  const otherMemberDids = new Set(memberUserDids)
  otherMemberDids.delete(myPublicProfile.did)


  const otherMemberDid = otherMemberDids.values().next().value

  useEffect(() => {
    if (otherMemberDids.size === 1) {
      const otherMemberDid = otherMemberDids.values().next().value
      const isOtherMemberAdmin = acceptedOrganizationMemberships.some(membership =>
        membership.memberUserDid === otherMemberDid && membership.admin)
      onAdminStatusChange(isOtherMemberAdmin)
    } else {

      onAdminStatusChange(false)
    }
  }, [acceptedOrganizationMemberships, otherMemberDid, onAdminStatusChange])

  const { publicProfiles } = usePublicProfiles(
    [...otherMemberDids],
    'OrganizationChatPage'
  )

  return <Fragment>
    <EndUserDMAvatar {...{ size: 'sm', chatImage, publicProfiles }}  />

    <Header size="lg">
      <p style={{padding:'0.5rem', fontWeight:'bold'}}>{chatname}</p>
    </Header>
  </Fragment>
}
