import oldAppState, { useAppState } from './oldAppState'

export async function verifyExternalIdentity({ type, value, verificationCode }){
  await oldAppState.takeAction('externalIdentities.verify', {
    type, value, verificationCode
  })
}

export function useVerifyExternalIdentity(){
  const {
    verifyingExternalIdentity,
    errorVerifyingExternalIdentity: verifyingExternalIdentityError,
  } = useAppState(
    [
      'verifyingExternalIdentity',
      'verifiedExternalIdentity',
      'errorVerifyingExternalIdentity',
    ],
    'resources/externalIdentities', // TMP BACK PATCH
  )
  return {
    verifyExternalIdentity,
    verifyingExternalIdentity,
    verifyingExternalIdentityError,
  }
}
