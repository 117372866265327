import { useAppState } from 'lib/appState'
import { useEffect, useCallback, useMemo } from 'preact/hooks'
import { entityKeys } from 'lib/actionHelpers'

export default function useLoadedEntity({
  entityKey,
  entityName = entityKey,
  loadAction,
  loadEntity: _loadEntity = takeAction => takeAction(loadAction),
  updateAction,
  updateEntity: _updateEntity = takeAction => takeAction(updateAction),
  componentName,
}){
  const {
    entityLoadingKey,
    entityLoadingErrorKey,
    entityNotFoundKey,
    entityUpdatingKey,
    entityUpdatingErrorKey,
  } = entityKeys(entityKey)

  const {
    takeAction,
    entity,
    entityLoading,
    entityLoadingError,
    entityNotFound,
    entityUpdating,
    entityUpdatingError,
  } = useAppState(
    {
      [entityKey]: 'entity',
      [entityLoadingKey]: 'entityLoading',
      [entityLoadingErrorKey]: 'entityLoadingError',
      [entityNotFoundKey]: 'entityNotFound',
      [entityUpdatingKey]: 'entityUpdating',
      [entityUpdatingErrorKey]: 'entityUpdatingError',
    },
    componentName,
  )

  const loadEntity = useCallback(
    () => { if (entityNotFound !== true) _loadEntity(takeAction) },
    [_loadEntity]
  )

  const updateEntity = useMemo(
    () => (
      _updateEntity
        ? changes => { _updateEntity(takeAction, changes) }
        : undefined
    ),
    [_updateEntity]
  )

  useEffect(
    () => { if (!entity) return loadEntity() },
    [entityKey, entity, entityNotFound]
  )

  const capEntityName = capitalize(entityName)
  return {
    [`load${capEntityName}`]: loadEntity,
    [`update${capEntityName}`]: updateEntity,
    [`${entityName}Loading`]: entityLoading,
    [`${entityName}LoadingError`]: entityLoadingError,
    [`${entityName}`]: entity,
    [`${entityName}NotFound`]: entityNotFound,
    [`${entityName}Updating`]: entityUpdating,
    [`${entityName}UpdatingError`]: entityUpdatingError,
  }
}

function capitalize(type){
  return type.substr(0, 1).toUpperCase() + type.substr(1)
}
