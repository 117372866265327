import api from '../api'
import { batchAction } from 'lib/actionHelpers'

export function addNotificationsToAppState(notifications){
  const newState = {}
  const notificationUids = []
  notifications.forEach(notification => {
    notificationUids.push(notification.uid)
    newState[`notification:${notification.uid}`] = notification
  })
  this.addToSet('notifications:uids', notificationUids)
  this.setState(newState)
}

export async function load() {
  const loadingKey = `notifications:loading`
  const errorKey = `${loadingKey}:error`
  if (
    this.getState()[loadingKey] ||
    this.getState()['notifications:lastPage']
  ) return

  this.setState({
    [loadingKey]: true,
    [errorKey]: undefined,
  })

  const oldest = this.getState()['notifications:oldest']
  const before = oldest && oldest.toISOString()
  try {
    const {
      notifications,
      lastPage,
      numberOfUnreadNotifications,
    } = await api.getNotifications({ before })
    const oldest = findOldestCreatedAt(notifications)
    this.takeAction('notifications.addNotificationsToAppState', notifications)
    this.setState({
      'notifications:oldest': oldest,
      'notifications:lastPage': lastPage,
      'notifications:unread': numberOfUnreadNotifications,
    })

    const organizationApikeys = new Set()
    const publicProfileDids = new Set()
    const addIfExists = (set, value) => { if (value) set.add(value) }

    notifications.forEach(notification => {
      addIfExists(organizationApikeys, notification.organizationApikey)
      addIfExists(organizationApikeys, notification.networkOrganizationApikey)
      addIfExists(organizationApikeys, notification.memberOrganizationApikey)
      addIfExists(publicProfileDids, notification.requesterPublicProfileDid)
      addIfExists(publicProfileDids, notification.inviteePublicProfileDid)
    })
    this.takeAction('organizations.load', [...organizationApikeys])
    this.takeAction('publicProfiles.get', { dids: [...publicProfileDids] })
  } catch(error) {
    this.setState({
      [errorKey]: error,
    })
  } finally {
    this.setState({ [loadingKey]: undefined })
  }
}

const findOldestCreatedAt = array =>
  array.map(m => new Date(m.createdAt)).sort((a, b) => b - a)[0]

export const markAsRead = batchAction({
  setKey: 'notificationToMarkAsRead',
  addToSet(uid) { return uid },
  async handleBatch(uids){
    const {
      notifications,
      numberOfUnreadNotifications,
    } = await api.markNotificationsAsRead({ uids: [...uids] })
    this.setState({
      'notifications:unread': numberOfUnreadNotifications,
    })
    this.takeAction('notifications.addNotificationsToAppState', notifications)
  },
})
