import { h } from 'preact'

import { BANNER_SIZE } from 'lib/imageHelpers'

import CropImageAssetInput from 'components/CropImageAssetInput'
import OrganizationBanner from 'components/OrganizationBanner'

//TMP
const DEFAULT = OrganizationBanner.DEFAULT

export default function BannerImageInput({
  className = '',
  defaultValue = DEFAULT,
  ...props
}){
  return <CropImageAssetInput
    {...props}
    className={`BannerImageInput ${className}`}
    defaultValue={defaultValue}
    height={BANNER_SIZE.height}
    width={BANNER_SIZE.width}
    backgroundFillStyle="#ffffff"
  />
}

BannerImageInput.width = BANNER_SIZE.width
BannerImageInput.height = BANNER_SIZE.height
OrganizationBanner.DEFAULT = DEFAULT
