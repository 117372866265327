import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import BannerImage from 'components/BannerImage'
import DEFAULT from 'components/OrganizationBanner/default.jpg'

export default function EndUserBanner({
  className,
  publicProfile,
  ...props
}){
  return <BannerImage {...{
    ...props,
    className: classNames('EndUserBanner', { className }),
    defaultSrc: DEFAULT,
    src: publicProfile.banner,
  }}/>
}

EndUserBanner.propTypes = {
  className: PropTypes.string,
  publicProfile: PropTypes.shape({
    banner: PropTypes.string,
  }),
}
