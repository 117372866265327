import { useState, useEffect } from 'preact/hooks'

const mediaQuery = window.matchMedia('(display-mode: standalone)')
import { onPWAChange } from 'lib/PWAHelpers'

export default function useIsPWA(){
  const [isPWA, setIsPWA] = useState(!!mediaQuery.matches)
  useEffect(() => onPWAChange(isPWA => setIsPWA(isPWA)), [])
  return isPWA
}
