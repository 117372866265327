import { useState, useEffect } from 'preact/hooks'
import { set as setLocation } from 'lib/location'
import { useLocalStorageState, getLocalStorageState } from 'lib/storageHooks'

import TruTSVG from '../components/TruT/truT.svg'

const getPermission = global.Notification
  ? () => global.Notification.permission
  : () => 'denied'

const getHasPermission = () => getPermission() === 'granted'

const requestPermission = global.Notification
  ? async () => (
    getHasPermission() ||
    (await global.Notification.requestPermission()) === 'granted'
  )
  : async () => 'denied'

export { requestPermission }

// https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
const DEFAULT_OPTIONS = {
  icon: `${location.origin}${TruTSVG}`,
}

const NOTIFICATIONS = new Set()

const SENT_KEY = 'browserNotification:uuids'
const getSentUUIDs = () => {
  try{ return JSON.parse(localStorage[SENT_KEY] || '[]') }catch(e){ return [] }
}
const isDuplicate = uuid => getSentUUIDs().includes(uuid)
const recordSent = uuid => {
  localStorage[SENT_KEY] = JSON.stringify([...getSentUUIDs(), uuid].slice(-100))
}

export function createBrowserNotification({
  uuid, // try to prevent duplicates
  title,
  destinationPath,
  ...options
}){
  if (uuid && isDuplicate(uuid)) return
  if (uuid) options.tag = uuid
  const notification = new Notification(title, {
    ...DEFAULT_OPTIONS,
    ...options,
  })
  if (uuid) recordSent(uuid)
  NOTIFICATIONS.add(notification)
  notification.onclick = function(){
    setLocation(destinationPath)
    window.focus()
    notification.close()
  }
  return notification
}

export function getBrowserNotificationSettings(){
  return getLocalStorageState('notifications') || {}
}

export function useBrowserNotificationPermission(){
  const [permission, setPermission] = useState(getPermission())
  useEffect(() => {
    const update = () => { setPermission(getPermission()) }
    const timeout = setInterval(update, 200)
    return () => { clearTimeout(timeout) }
  }, [])
  return [permission, requestPermission]
}

export function useBrowserNotificationSettings(){
  return useLocalStorageState('notifications')
}

document.addEventListener('visibilitychange', function() {
  if (document.visibilityState !== 'visible') return
  NOTIFICATIONS.forEach(notification => {
    NOTIFICATIONS.delete(notification)
    notification.close()
  })
})


DEBUG.getBrowserNotificationSettings = getBrowserNotificationSettings
