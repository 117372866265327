import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

export default {
  homePage: {
    title: `Your Home Feed`,
    content: <div>
      <p>
        Your Home Feed is a combination of the curated posts published by all
        the {PNFO.plural} that you join or follow.
      </p>
    </div>,
  },
  shareFeedPost: {
    title: `Share`,
    content: <div>
      <p>Share gives you a link to post on other websites.</p>
    </div>,
  },
  goToForumPost: {
    title: `Go To Forum Post`,
    content: <div>
      <p>
        This takes you to the {PNFO.singular} forum where this was posted. If
        you're a member, you can elevate it, comment on it and let everyone know
        what you think about it there. Click to join if you are not already
        a member.
      </p>
    </div>,
  },
  feedPostTruLine: {
    title: `${APP_NAME}Line`,
    content: <div>
      <p>
        The {APP_NAME}Line shows where content came from, where it was posted and
        republished, and by whom.
      </p>
    </div>,
  },
  repostFeedPost: {
    title: `Repost`,
    content: <div>
      <p>
        Build a reputation as a trusted source by reposting relevant content to {PNFO.singular} forums.
      </p>
    </div>,
  },
  allHubsPage: {
    title: `Lists of ${PNFO.plural}`,
    content: <div>
      <p>
        Publicly listed {PNFO.plural} are shown on the right. {PNFO.plural} you
        join are listed under the "Member" tab.
      </p>
    </div>,
  },
  createAHub: {
    title: `Create a ${PNFO.singular}`,
    content: <div>
      <p>
        {APP_NAME} is a network of {PNFO.plural}. You can create a {PNFO.singular} for any group, as a
        private social space for your friends or family, for people to comment on your personal blog,
        or to publicly list your business or non-profit.
      </p>
    </div>,
  },
  publicProfilePage: {
    title: 'Your Profile Page',
    content: <div>
      <p>
        Show off your unique style with a profile photo and banner. Tell us about yourself in your
        bio. You can post content here and re-post it to your {PNFO.plural}. Curators can also invite
        people to join their {PNFO.plural} from their profile page.
      </p>
    </div>,
  },
  hubAboutPage: {
    title: `${PNFO.singular} Main Page`,
    content: <div>
      <p>
        This brings you to the {PNFO.singular}'s main page where you can learn
        more about what they stand for. {PNFO.singular} admins can customize this
        page and make it stand out with simple inline tools to format images and text.
      </p>
    </div>,
  },
  hubPublishedPage: {
    title: `Published Posts From ${PNFO.singular}`,
    content: <div>
      <p>
        This is where you can find the interesting posts {PNFO.singular} Curators choose to
        publish. This contributes to your home feed.
      </p>
    </div>,
  },
  hubPeoplePage: {
    title: `Members of this ${PNFO.singular}`,
    content: <div>
      <p>
        {PNFO.singular} members are listed here. {PNFO.singular} curators are in
        charge of their {PNFO.singular}'s reputation. They manage subscriptions
        and publishing and can also invite members from here.
      </p>
    </div>,
  },
  hubForumPage: {
    title: `Forum for Members of this ${PNFO.singular}`,
    content: <div>
      <p>
        The Forum is the members space for each {PNFO.singular}. Elevate, comment, and discuss
        content posted here by other members, or fed from
        other {PNFO.plural} via {PNFO.singular}-To-{PNFO.singular} subscription.
      </p>
    </div>,
  },
  hubNetworkPage: {
    title: `Network this ${PNFO.singular} maintains`,
    content: <div>
      <p>
        This shows the network of {PNFO.plural} that are affiliated with this {PNFO.singular}
      </p>
    </div>,
  },
  postToTheForum: {
    spotlightOptions: { circular: false },
    title: `Post to the Forum!`,
    content: <div>
      <p>
        The Forum is the heart of {APP_NAME}. Jump into the conversation by posting as a member,
        connect with others and help shape what the curators publish by elevating and commenting.
      </p>
    </div>,
  },
  joinToBrowseTheForum: {
    spotlightOptions: { circular: true },
    title: `Join to Post to the Forum!`,
    content: <div>
      <p>
        The Forum is the heart of {APP_NAME}. Join this {PNFO.singular} to jump into the
        conversation by posting as a member, connect with others and help shape what the
        curators publish by elevating and commenting.
      </p>
    </div>,
  },
  chat: {
    title: `Chat`,
    content: <div>
      <p>
        Form deeper connections with others, either privately by DM, with any
        small group, or the entire {PNFO.singular}.
      </p>
    </div>,
  },
  helpButton: {
    title: `More Help!`,
    content: <div>
      <p>
        Want to take it from the top? Maybe this walkthrough was so much fun you want
        to do it again. Either way, here you can replay this tutorial or access FAQ's
        and other documents that will help expand your experience on Tru.
      </p>
    </div>,
  },
  hubAdminCog: {
    title: 'Admin Settings',
    content: <div>
      <p>
        This is where {PNFO.singular} admins can find a variety of options to change
        their {PNFO.plural} settings such as branding, visibility, and membership details.
      </p>
    </div>,
  },
  upvoteForumFeedPost: {
    title: 'Elevate Forum Feed Post',
    content: <div>
      <p>Help elevate this post to the top of the forum</p>
    </div>
  },
  viewFeedPostComments: {
    title: 'View Feed Post Comments',
    content: <div>
      <p>Click here to view the the feed post and any comments it might have</p>
    </div>
  },
  viewTruLine: {
    title: 'Tru Line',
    content: <div>
      <p>Click here to view the Tru Line for this feed post</p>
    </div>
  },
  publishForumFeedPost: {
    title: 'Publish a Forum Feed Post',
    content: <div>
      <p>Admins and curators can publish forum feed posts</p>
    </div>
  },
  editForumFeedPost: {
    title: 'Edit your Forum Feed Post',
    content: <div>
      <p>You can edit any forum feed post you have created.</p>
    </div>
  },
  gotoForumFeedPost: {
    title: 'Go to Forum Post',
    content: <div>
      <p>Every published post on {APP_NAME} came from a forum on a {PNFO.singular}.</p>
      <p>Click this buton to view the {PNFO.singular} forum post that this post came from.</p>
      <p>See the {APP_NAME}Line for more info</p>
    </div>
  }
}
