
export function findDMChatChannel(synopsis, did) {
  return synopsis.find(s => s.type === 'dm' &&
    s.memberUserDids.length === 2 &&
    (
      s.memberUserDids[0] === did ||
      s.memberUserDids[1] === did
    )
  )
}
