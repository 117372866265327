import { useLocalStorage } from 'lib/storageHooks'

export default function useInvitedToOrganization(organizationApikey){
  const [
    invitedToOrganization,
    setInvitedToOrganization,
  ] = useLocalStorage(`invitedToOrganization:${organizationApikey}`)

  return {
    invitedToOrganization,
    setInvitedToOrganization,
  }
}

