import { h, Fragment } from 'preact'
import { useState, useMemo, useRef, useCallback, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import { useOnLocationChange } from 'resources/location'

import { useLocalStorage } from 'lib/storageHooks'
import classNames from 'lib/classNames'
import useToggle from 'lib/useToggleHook'
import { refToDOMNode } from 'lib/preactHelpers'

import IconButton from 'components/IconButton'
import StyleishModal from 'components/StyleishModal'
import './index.sass'

export function useInterfaceHelpVisibility(){
  const [value, setValue, getValue] = useLocalStorage('showHelpInterface')
  const visible = value === 1
  const show = useCallback(() => { setValue(1) }, [])
  const hide = useCallback(() => { setValue(undefined) }, [])
  const toggle = useCallback(() => { getValue() === 1 ? hide() : show() }, [])
  return [visible, show, hide, toggle]
}

export default function InterfaceHelp({
  className,
  children,
  inside,
  p = 'tr',
  offset = inside ? 2 : 0,
  title = 'Interface Help',
  content,
  Component = 'span',
}){
  const baseRef = useRef()
  const iconRef = useRef()
  const [helpInterfaceVisible] = useInterfaceHelpVisibility()
  const [hasTarget, setHasTarget] = useState(false)
  const [isOpen, open, close] = useToggle()
  const visible = !!(helpInterfaceVisible && content && hasTarget)

  useOnLocationChange(close)

  useEffect(
    () => {
      const base = refToDOMNode(baseRef)
      const lookForTarget = () => {
        const icon = refToDOMNode(iconRef)
        const children = [...base.childNodes].filter(n => n !== icon)
        setHasTarget(children.length > 0)
      }
      lookForTarget()
      const observer = new global.MutationObserver(lookForTarget)
      observer.observe(
        base,
        { attributes: true, childList: true, subtree: true },
      )
      return () => {
        observer.disconnect()
      }
    },
    []
  )

  useEffect(
    () => {
      if (!visible) return
      const base = refToDOMNode(baseRef)
      const icon = refToDOMNode(iconRef)
      const set = hovered =>
        base.classList[hovered ? 'add' : 'remove']('InterfaceHelp-iconHovered')
      const mouseEnter = () => set(true)
      const mouseLeave = () => set(false)
      icon.addEventListener('mouseenter', mouseEnter)
      icon.addEventListener('mouseleave', mouseLeave)
      return () => {
        icon.removeEventListener('mouseenter', mouseEnter)
        icon.removeEventListener('mouseleave', mouseLeave)
      }
    },
    [visible]
  )

  const animationDelay = useMemo(
    () => `${Math.round(Math.random() * 200)}ms`,
    [],
  )
  const inline = p === 'inline'

  return <Component {...{
    ref: baseRef,
    className: classNames('InterfaceHelp', { className, visible, inline }),
  }}>
    {children}
    {visible && <Fragment>
      <div {...{
        ref: iconRef,
        className: 'InterfaceHelp-button',
        style: {
          '--animation-delay': animationDelay,
          '--position-offset': `${offset}`,
        },
        'data-v': inline ? undefined : p[0],
        'data-h': inline ? undefined : p[1],
      }}>
        <IconButton {...{
          type: 'help-circled',
          size: 'sm',
          blue: true,
          className: 'InterfaceHelp-Icon',
          onClick: open,
        }}/>
      </div>
      <StyleishModal {...{
        modalClassName: 'InterfaceHelp-modal',
        showCloseXButton: true,
        title,
        scrollInternally: true,
        open: isOpen,
        onClose: close,
      }}>
        <HelperContent {...{ content }}/>
      </StyleishModal>
    </Fragment>}
  </Component>
}

InterfaceHelp.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  p: PropTypes.string,
  offset: PropTypes.number,
  title: PropTypes.node,
  Component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  content: PropTypes.node,
  fixed: PropTypes.bool,
  inside: PropTypes.bool,
}

function HelperContent({ content }){
  return <div>
    {typeof content === 'string' ? <p>{content}</p> : content}
  </div>
}

