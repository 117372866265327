import { h, Fragment } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import {
  useUserOrganizationMemberships,
  useOrganizations,
  useRemoveOrganizationMembership,
  usePublicProfile,
} from 'lib/membershipAppStateHooks'
import { useMyPublicProfileDid } from 'resources/auth'
import OrganizationListDraggable from 'components/OrganizationListDraggable'
import ChatButton from 'components/ChatButton'
import IconButton from 'components/IconButton'


export default function UserMembershipsList({ publicProfileDid, className = '', isEditClickedDone}){

  const {
    loadingUserMemberships,
    errorLoadingUserMemberships,
    userMemberships,
  } = useUserOrganizationMemberships(publicProfileDid, 'UserMembershipsList')

  const organizationApikeys = new Set(
    userMemberships
      .filter(membership => membership.organizationApikey)
      .map(membership => membership.organizationApikey),
  )

  const myPublicProfileDid = useMyPublicProfileDid()

  const {userMemberships: adminMemberships} = useUserOrganizationMemberships(myPublicProfileDid, 'UserMembershipsList')

  const HubAdminsArray = adminMemberships.filter(m => m.admin).map(m => m.organizationApikey)

  const {
    publicProfile,
  } = usePublicProfile(publicProfileDid, 'UserMembershipsList')

  let {
    organizations,
    organizationsLoading,
    organizationsLoadingError,
  } = useOrganizations(organizationApikeys, 'UserMembershipsList')

  const {
    removingMembership,
    removeMembership,
  } = useRemoveOrganizationMembership('OrganizationMembersList')

  const disabled = !!removingMembership

  organizations = (
    loadingUserMemberships ||
    organizationsLoading
  )
    ? undefined
    : organizations.map((organization) => {
      const membership = userMemberships.find(m => m.organizationApikey === organization.apikey)
      const myMembership = membership.memberUserDid === myPublicProfileDid
      return {
        ...organization,
        membership,
        buttons: <Fragment>
          {
            HubAdminsArray.includes(organization.apikey) && (
              <IconButton {...{
                className: 'OrganizationMembersList-removeMembershipButton',
                key: 'remove',
                disabled: myMembership || disabled,
                type: 'cancel-circled',
                onClick: () => {
                  if (!confirm(`Are you sure you want to remove ${publicProfile.username} from ${organization.name}?`)) return // eslint-disable-line
                  removeMembership(membership)
                  window.location.reload()
                },
              }}/>
            )
          }
          <ChatButton
            organizationApikey={membership.organizationApikey}
            publicProfileDid={membership.memberUserDid}
          />
        </Fragment>
      }
    })

  return <OrganizationListDraggable {...{
    className: `UserMembershipsLists ${className}`,
    error: errorLoadingUserMemberships || organizationsLoadingError,
    organizations,
    filter: o => o.membership,
    onEmpty: `This user is not a member of any ${PNFO.plural} yet.`,
    isEditClickedDone,
  }}/>
}
