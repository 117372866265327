import {
  checkForNewServiceWorker,
  reloadForNewServiceWorker,
} from 'lib/serviceworker'

export async function detect(){
  if (this.getState()['newAppVersionDetected']) return
  checkForNewServiceWorker()
}

export async function promptToReload(){
  this.setState({ ['newVersion.promptingToReload']: true })
}

export async function reload(){
  if (this.getState()['updatingAppVersion']) return
  this.setState({['updatingAppVersion']: true})
  reloadForNewServiceWorker()
}
