import { h } from 'preact'

import HeaderedContentBox from 'components/HeaderedContentBox'
import PublicProfileInfo from 'components/PublicProfileInfo'

export default function UserProfileAboutPage({publicProfile}){
  return <div className="UserProfileAboutPage">
    <HeaderedContentBox padded header="About">
      <PublicProfileInfo {...{publicProfile}}/>
    </HeaderedContentBox>
  </div>
}


UserProfileAboutPage.pageTitle = 'About'
