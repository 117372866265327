import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Header from 'components/Header'
import Subtext from 'components/Subtext'

import './index.sass'

export default class RadioInputGroup extends Component {

  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.node.isRequired,
        PropTypes.shape({
          label: PropTypes.node.isRequired,
          disabled: PropTypes.bool,
          subtext: PropTypes.string,
        }).isRequired,
      ]).isRequired,
    ).isRequired,
    value: PropTypes.number,
    disabled: PropTypes.bool,
    large: PropTypes.bool,
  }

  onChange = event => {
    this.props.onChange(Number(event.target.value))
  }

  uuid() {
    if (!this._uuid) this._uuid = `${Date.now()}${Math.random()}`
    return this._uuid
  }

  render() {
    const { options, name, value, disabled, large } = this.props
    const radioGroupName = name || this.uuid()

    const optionRadioButtons = options.map((option, index) => {
      const optionLabel = typeof option === 'object' ? option.label : option

      const optionClassName = classNames('RadioInputGroup-radio', { disabled: option.disabled, large })

      return <label className={optionClassName}>
        <input
          onChange={this.onChange}
          disabled={disabled || option.disabled}
          type="radio"
          value={index}
          name={radioGroupName}
          checked={+value === index}
        />
        <div className="RadioInputGroup-radio-labelColumn">
          <Header size="lg">{optionLabel}</Header>
          {option.subtext && <Subtext>{option.subtext}</Subtext>}
        </div>
      </label>
    })

    const className = classNames('RadioInputGroup', { className: this.props.className })
    return <div className={`RadioInputGroup ${className}`}>
      {optionRadioButtons}
    </div>
  }
}
