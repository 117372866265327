import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'
import{useState} from 'preact/hooks'

import classNames from 'lib/classNames'
import useToggle from 'lib/useToggleHook'
import { useMyPublicProfileDid } from 'resources/auth'
import { useOrganizationsIAdmin } from 'lib/membershipAppStateHooks'

import HeaderedContentBox from 'components/HeaderedContentBox'
import UserMembershipsList from 'components/UserMembershipsList'
import InviteUserToOrganizationsModal from 'components/InviteUserToOrganizationsModal'
import MyOrganizationsList from 'components/MyOrganizationsList'
import Button from 'components/Button'
import EditButton from 'components/EditButton'

export default function PublicProfileMembershipsList({ className, publicProfile }) {
  const [isOpen, open, close] = useToggle(false)
  const { organizationsIAdmin } = useOrganizationsIAdmin('PublicProfileMembershipsList')
  const myPublicProfileDid = useMyPublicProfileDid()
  const [isEditClickedDone, setIsEditClicked] = useState(false)
  const isProfileOwner = myPublicProfileDid === publicProfile.did
  const handleButtonClick = () => {
    setIsEditClicked(!isEditClickedDone)
  }
  const inviteUserToOrganizationsButton = organizationsIAdmin.length > 0 && myPublicProfileDid !== publicProfile.did &&
    <Button {...{
      type: 'primary',
      onClick: open,
      value: `Invite to ${PNFO.plural}`,
    }}/>

  const editButton = isProfileOwner && (
    <EditButton onClick={handleButtonClick} />
  )

  return <HeaderedContentBox {... {
    className: classNames('PublicProfileMembershipsList', { className }),
    icon: 'people',
    header: isDataYogi ? 'Data Sharing Relationships' : 'Memberships',
    buttons: inviteUserToOrganizationsButton,
    edit: editButton
  }}>
    <InviteUserToOrganizationsModal {...{
      open: isOpen,
      onClose: close,
      publicProfile,
      organizationsIAdmin,
    }}/>
    {isDataYogi
      ? <MyOrganizationsList {...{ onEmpty: `You have no SISAs` }}/>
      : <UserMembershipsList {...{ publicProfileDid: publicProfile.did, isEditClickedDone}}/>
    }
  </HeaderedContentBox>
}
