import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import PropTypes from 'prop-types'

import { scrollIntoView } from 'lib/DOMHelpers'

import ContentBox from 'components/ContentBox'
import Link from 'components/Link'
import ErrorMessage from 'components/ErrorMessage'
import DropdownMenu from 'components/DropdownMenu'
import IconButton from 'components/IconButton'
import './index.sass'

export default function FeedContentBox({
  disabled,
  error,
  modals,
  alert,
  icon,
  title,
  actions,
  truncateBody,
  body,
  bottom,
  ...props
}){

  let className = 'FeedContentBox'
  if (props.className) className += ` ${props.className}`

  const ref = useRef(null)
  const scrollTo = () => {
    setTimeout(() => {
      scrollIntoView(ref.current.base, 100)
    })
  }

  return <ContentBox {...{...props, ref, className}} >
    {modals}
    {alert}
    <ErrorMessage error={error} />
    <div className="FeedContentBox-top">
      {icon && <div className="FeedContentBox-icon">{icon}</div>}
      {title && <div className="FeedContentBox-title">{title}</div>}
      {actions && actions.length > 0 &&
        <DropdownMenu
          className="FeedContentBox-actions"
          disabled={disabled}
          options={actions}
          rightAligned
        >
          <IconButton type="dot-3"/>
        </DropdownMenu>
      }
    </div>
    {truncateBody ? <BodyTruncated {...{scrollTo, body}}/> : <Body {...{body}}/>}
    {bottom && <div className="FeedContentBox-bottom">{bottom}</div>}
  </ContentBox>
}

FeedContentBox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: ErrorMessage.propTypes.error,
  modals: PropTypes.node,
  alert: PropTypes.node,
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  actions: DropdownMenu.propTypes.options,
  truncateBody: PropTypes.bool,
  body: PropTypes.node.isRequired,
  bottom: PropTypes.node,
}


function Body({ className = '', body, children }){
  return <div className={`FeedContentBox-Body ${className}`}>
    <div className="FeedContentBox-Body-content">{body}</div>
    {children}
  </div>
}

function BodyTruncated({ body, scrollTo }){
  const ref = useRef(null)
  const [contentOverflows, setContentOverflows] = useState(false)
  const [showingMore, setShowingMore] = useState(false)
  const [hasMedia, setHasMedia] = useState(false)
  const toggleShowingMore = () => {
    setShowingMore(!showingMore)
    if (showingMore) scrollTo()
  }

  useEffect(
    () => {
      if (!ref.current || !ref.current.base) return
      const domNode = ref.current.base.querySelector('.FeedContentBox-Body-content')
      setContentOverflows(domNode.scrollHeight > domNode.clientHeight)
      const media = domNode.querySelector('img,video,iframe')
      setHasMedia(!!media)
      if (media) {
        media.addEventListener('load', () => {
          setContentOverflows(domNode.scrollHeight > domNode.clientHeight)
        })
      }
    },
    [ref.current && ref.current.base],
  )

  let className = 'FeedContentBox-Body-truncated'
  if (!hasMedia) className += ' FeedContentBox-Body-noMedia'
  if (showingMore) className += ' FeedContentBox-Body-showingMore'
  else if (contentOverflows) className += ' FeedContentBox-Body-overflows'

  return <Body {...{ ref, className, body }}>
    {contentOverflows &&
      <Link
        type="text"
        onClick={toggleShowingMore}
        className="FeedContentBox-Body-toggle"
      >
        Show {showingMore ? 'less' : 'more'}
      </Link>
    }
  </Body>
}
