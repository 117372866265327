import {h} from "preact"
import PropTypes from "prop-types"
import "./index.sass"
import {usePublicProfiles} from "lib/membershipAppStateHooks"
import EndUserDMAvatar from "components/EndUserDMAvatar"
import Icon from "components/Icon"

const ChatChip = ({className = "", chats, filter, onEmpty, onDelete}) => {
  const allMemberUserDids = chats?.flatMap((chat) => chat.memberUserDids || [])
  const uniqueMemberUserDids = Array.from(new Set(allMemberUserDids))

  const {publicProfiles} = usePublicProfiles(
    uniqueMemberUserDids,
    "HubChipList"
  )

  let filteredChats = chats
  if (filter) {
    const filterLowerCase = filter.toLowerCase()
    filteredChats = chats.filter(
      (chat) =>
        chat.name.toLowerCase().includes(filterLowerCase) ||
        chat.preview.toLowerCase().includes(filterLowerCase)
    )
  }
  // Handle empty state
  if (!filteredChats.length) {
    return <div className={`${className} chip-list-empty`}>{onEmpty}</div>
  }

  return (
    <div className={`${className} chip-list`}>
      {filteredChats.map((chat) => {
        if (chat.memberUserDids) {
          const chatPublicProfiles = publicProfiles.filter((profile) =>
            chat.memberUserDids.includes(profile.did)
          )

          if (chatPublicProfiles.length > 0) {
            return (
              <div key={chat.uid} className="chip">
                <EndUserDMAvatar
                  chatImage={chat.image_url}
                  publicProfiles={chatPublicProfiles}
                  size="sm"
                />
                <p style={{fontSize: "12px"}}>
                  {chat.name.length > 4 ? `${chat.name.substring(0, 4)}...` : chat.name}
                </p>
                {onDelete && (
                  <button
                    className="chip-delete"
                    onClick={() => onDelete(chat.uid)}
                  >
                    x
                  </button>
                )}
              </div>
            )
          }
        }
        return (
          <div key={chat.uid} className="chip">
            {chat.image_url &&
              (chat.image_url.startsWith("#") ? (
                <div
                  className="avatarImg"
                  style={{backgroundColor: chat.image_url}}
                >
                  <Icon type="chaty" />
                </div>
              ) : (
                <img
                  src={chat.image_url}
                  alt="Chat Image"
                  className="avatarImg"
                  style={{padding: 0}}
                />
              ))}
            <p style={{fontSize: "12px"}}>
              {chat.name.length > 4 ? `${chat.name.substring(0, 4)}...` : chat.name}
            </p>
            {onDelete && (
              <button
                className="chip-delete"
                onClick={() => onDelete(chat.uid)}
              >
                x
              </button>
            )}
          </div>
        )
      })}
    </div>
  )
}

ChatChip.propTypes = {
  className: PropTypes.string,
  chats: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      preview: PropTypes.string,
      chatImage: PropTypes.string, // Assuming this is a URL or path to an image
      // Add other props as needed
    })
  ).isRequired,
  filter: PropTypes.string,
  onEmpty: PropTypes.node,
  onDelete: PropTypes.func,
}

export default ChatChip
