import { h } from 'preact'

import { usePreferences } from 'lib/membershipAppStateHooks'
import { setPageTitle } from 'lib/Page'

import Button from 'components/Button'
import Header from 'components/Header'
import FullPageCard from 'components/FullPageCard'
import Card from 'components/Card'
import Link from 'components/Link'
import ButtonRow from 'components/ButtonRow'
import ErrorMessage from 'components/ErrorMessage'
import './index.sass'

export default function DailyDigestAnnouncementPage({  }){
  const { updatingPreferences, updatingPreferencesError, updatePreferences } = usePreferences('WelcomePage')
  const title = `New Daily Digest!`
  setPageTitle(title)
  const disabled = !!updatingPreferences
  const close = () => {
    updatePreferences({ has_seen_daily_digest_email_announcement: true })
  }
  return <FullPageCard className="DailyDigestAnnouncementPage">
    <ErrorMessage error={updatingPreferencesError}/>
    <Card.Body>
      <Header size="xl" centered>{title}</Header>
      <p>We've just launched our new Daily Digest feature!</p>
      <p>
        If you would like to receive an email once a day, with all your {APP_NAME} activity,
        then please <Link type="link" href="/settings" newWindow>go to your settings page</Link> and:
        <ol>
          <li>add add an email addess to your account</li>
          <li>enable the daily digest email notification setting</li>
        </ol>
      </p>
      <ButtonRow>
        <Button
          disabled={disabled}
          autoFocus
          type="normal"
          onClick={close}
          value="later"
        />
        <Button
          disabled={disabled}
          autoFocus
          type="primary"
          href="/settings"
          newWindow
          onClick={close}
          value="Lets do it!"
        />
      </ButtonRow>
    </Card.Body>
  </FullPageCard>
}
