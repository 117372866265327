import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { useLoggedIn } from 'resources/auth'
import { useLocation } from 'resources/location'

import Header from 'components/Header'
import Button from 'components/Button'
import './index.sass'

export default function PageError({
  className,
  showImage,
  header,
  img,
  subtext,
  recommendation,
  destinationOrganizationApikey,
  ...props
}){
  return <div
    {...props}
    className={classNames('PageError', { className })}
  >

    <Header size="lg" centered>{header}</Header>
    {showImage &&  <img className="PageError-img" src={img} />}
    <Header size="sm" centered>{subtext}</Header>
    <div>{recommendation || <Recommendation {...{destinationOrganizationApikey}}/>}</div>
  </div>
}

PageError.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  img: PropTypes.string,
  showImage:PropTypes.bool,
  subtext: PropTypes.node,
  recommendation: PropTypes.node,
  destinationOrganizationApikey: PropTypes.string,
}

function Recommendation({ destinationOrganizationApikey }){
  const location = useLocation()
  const loggedIn = useLoggedIn()

  let loginLocation = location.toLogin()
  let signupLocation = location.toSignup()
  if (destinationOrganizationApikey)
    signupLocation = signupLocation.withQuery({ o: destinationOrganizationApikey })

  return loggedIn
    ? <Fragment>
      <Button type="normal" href="/" value="Go To Your Feed"/>
    </Fragment>
    : <Fragment>
      <Button type="normal" href={loginLocation} value="Login"/>
      <span> or </span>
      <Button type="normal" href={signupLocation} value="Signup"/>
    </Fragment>
}
