import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { useCreateChatChannel } from 'lib/chatHooks'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import './index.sass'


export function NewDMChatChannel({ organization, myPublicProfile, memberUserDids }) {
  memberUserDids = memberUserDids.filter(did => did !== myPublicProfile.did)

  const organizationApikey = organization.apikey
  const createChatChannel = useCreateChatChannel('OrganizationChatPage')
  const [error, setError] = useState()

  useEffect(
    () => {
      createChatChannel({
        type: 'dm',
        organizationApikey,
        memberUserDids,
      }).catch(setError)
    },
    []
  )

  return <div className="OrganizationChatPage-NewDMChatChannel">
    {error ? <ErrorMessage {...{ error, dismissable: false }} /> : <Loading type="fullPage" />}
  </div>
}
