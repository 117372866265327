import api from '../api'

export async function load() {
  if (this.getState().gettingRecoveryCodes) return

  this.setState({
    gettingRecoveryCodes: true,
    gettingRecoveryCodesError: undefined,
    updatingRecoveryCodesError: undefined,
    deletingRecoveryCodesError: undefined,
  })

  try {
    const { recoveryCodes } = await api.getRecoveryCodes()

    this.setState({ recoveryCodes })
  } catch (gettingRecoveryCodesError) {
    this.setState({ gettingRecoveryCodesError })
  } finally {
    this.setState({ gettingRecoveryCodes: undefined })
  }
}

export async function generate() {
  if (this.getState().generatingRecoveryCodes) return

  this.setState({
    generatingRecoveryCodes: true,
    gettingRecoveryCodesError: undefined,
    generatingRecoveryCodesError: undefined,
    deletingRecoveryCodesError: undefined,
  })

  try {
    const { recoveryCodes } = await api.generateRecoveryCodes()
    this.setState({recoveryCodes })

  } catch (generatingRecoveryCodesError) {
    this.setState({ generatingRecoveryCodesError })
  } finally {
    this.setState({ generatingRecoveryCodes: undefined })
  }
}

async function _delete() {
  if (this.getState().deletingRecoveryCodes) return

  this.setState({
    deletingRecoveryCodes: true,
    gettingRecoveryCodesError: undefined,
    generatingRecoveryCodesError: undefined,
    deletingRecoveryCodesError: undefined,
  })

  try {
    await api.deleteRecoveryCodes()
    this.setState({ recoveryCodes: [] })
  } catch (deletingRecoveryCodesError) {
    this.setState({ deletingRecoveryCodesError })
  } finally {
    this.setState({ deletingRecoveryCodes: undefined })
  }
}

export { _delete as delete }
