import { useCallback, useEffect } from 'preact/hooks'

export default function useOnScroll(ref, onScroll, args = []){
  const _onScroll = useCallback(onScroll, args)
  useEffect(
    () => {
      const scollHandler = event => {
        const scrollingElement = event.target && event.target.scrollingElement || event.target
        if (
          event.target === window.document ||
          scrollingElement.contains(ref.current.base)
        ){
          return _onScroll(event)
        }
      }
      window.document.addEventListener('scroll', scollHandler, true)
      window.addEventListener('resize', onScroll)
      return () => {
        window.document.removeEventListener('scroll', scollHandler, true)
        window.removeEventListener('resize', onScroll)
      }
    },
    [_onScroll]
  )
}
