import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import Form from 'components/Form'
import HugeChoiceInput from 'components/HugeChoiceInput'
import HelpButton from 'components/HelpButton'

export default function OrganizationPublicPrivateFormSection({ form, disabled }) {
  const isClosed = form.getValue('is_closed')
  return <Form.Row>
    <Form.Item>
      <Form.Label>
        PUBLIC OR PRIVATE&nbsp;
        <HelpButton>
          A Public {PNFO.singular} allows all membership types, is publicly listed and forum is visible to non-members
          by default. A Private {PNFO.singular} does not allow open membership, is not publicly listed and only shows
          forum to members by default.
        </HelpButton>
      </Form.Label>
      <HugeChoiceInput
        className="MembershipSettingsStep-PublicPrivateButtons"
        fat
        block
        defaultValue={false}
        disabled={disabled}
        value={
          typeof isClosed !== 'boolean' ? undefined :
          isClosed === true ? 1 :
          0
        }
        onChange={value => {
          if (value === 0){
            form.change({
              is_private: false,
              is_closed: false,
              closed_memberships: false,
              users_can_request_membership: false,
              publicly_listed: true,
            })
          }else if (value === 1){
            form.change({
              is_private: true,
              is_closed: true,
              closed_memberships: true,
              users_can_request_membership: false,
              publicly_listed: false,
            })
          }
        }}
        options={[
          {
            value: 0,
            buttonValue: 'Public',
          },
          {
            value: 1,
            buttonValue: 'Private',
          },
        ]}
      />
    </Form.Item>
  </Form.Row>
}

