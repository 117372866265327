import { h, Fragment } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import {
  useUpdateOrganizationMembership,
  useMyPublicProfile,
  useMuteUser,
  useUnmuteUser,
  useMutedUsers
} from 'lib/membershipAppStateHooks'
import Dropdown from 'components/Dropdown'
import TextInput from 'components/TextInput'
import Form from 'components/Form'
import FormBuilder from 'components/FormBuilder'
import SwitchListMember from 'components/SwitchListMember'


export default function OrganizationMembershipEditForm({
  className = '',
  organizationMembership,
  publicProfile,
  organization,
  onMutedUsersChange,
  ...props
}){
  const [value, onChange] = useState({})
  const { myPublicProfile } = useMyPublicProfile('OrganizationMembershipEditForm')
  const { muteUser} = useMuteUser('OrganizationMembersList')
  const { unmuteUser } = useUnmuteUser('OrganizationMembersList')
  const { listmuted, mutedUsers } = useMutedUsers('OrganizationMembersList')
  const [isAdmin, setIsAdmin] = useState(organizationMembership.admin)
  const [isCurator, setIsCurator] = useState(organizationMembership.curator)
  const [durationSelection, setDurationSelection] = useState('')

  const [muteDetails, setMuteDetails] = useState({ mute_type: '', expires_at: '', reason: '', comments:
   '', ismuted: true, organizationapikey: organization.apikey })
  const isMuted = mutedUsers?.some(mu => mu.user_did === publicProfile.did)
  const [initialMuteStatus, setInitialMuteStatus] = useState(isMuted)


  useEffect(() => {
    setInitialMuteStatus(isMuted)
    setMuteToggle(isMuted)
  }, [isMuted, mutedUsers, publicProfile.did])

  const [muteToggle, setMuteToggle] = useState(isMuted)
  const muteToggleKey = `mute-toggle-${muteToggle}`

  useEffect(() => {
    listmuted({ organizationDid: organization.did })
  }, [listmuted])

  const {
    updateOrganizationMembership,
    updatingOrganizationMembership,
    errorUpdatingOrganizationMembership,
  } = useUpdateOrganizationMembership(
    organizationMembership,
    'OrganizationMembershipEditForm',
  )

  const confirmAdminRemoval = () => {
    const target = publicProfile.did === myPublicProfile.did ? 'yourself' : 'this user'
    const confirmation = `Are you sure you want to take away admin privileges from ${target}?`
    // eslint-disable-next-line no-alert
    return confirm(confirmation)
  }
  const handleAdminChange = (isChecked) => {
    setIsAdmin(isChecked)
  }

  const handleCuratorChange = (isChecked) => {
    setIsCurator(isChecked)
  }
  const handleMuteToggleChange = (isChecked) => {

    setMuteToggle(isChecked)
    if (!isChecked) {
      setMuteDetails({ mute_type: '', expires_at: '', reason: '', comments: '' })
    }
  }

  const handleMuteDetailsChange = (key, valueOrEvent) => {
    let value
    if (valueOrEvent && valueOrEvent.target && typeof valueOrEvent.target.value !== 'undefined') {
      value = valueOrEvent.target.value
    } else if (typeof valueOrEvent === 'string') {
      value = valueOrEvent
    } else {
      console.error('Invalid input:', valueOrEvent)
      return
    }

    if (key === 'expires_at') {

      setDurationSelection(value)

      if (value === '1 Day') {
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        value = tomorrow.toISOString()
      } else if (value === '7 Days') {
        const weekLater = new Date()
        weekLater.setDate(weekLater.getDate() + 7)
        value = weekLater.toISOString()
      } else if (value === 'Indefinitely') {
        value = null
      }
    }
    setMuteDetails(prev => ({ ...prev, [key]: value }))
  }


  const handleSubmit = async (changes) => {

    if (changes.admin === false && !confirmAdminRemoval()) {
      return
    }
    await updateOrganizationMembership(changes)

    if (initialMuteStatus && !muteToggle) {
      // eslint-disable-next-line no-alert
      const confirmUnmute = confirm("Are you sure you want to unpause this user?")

      if (!confirmUnmute) {
        return
      }

      try {
        await unmuteUser({
          organizationDid: organization.did,
          userDid: publicProfile.did
        })

        onMutedUsersChange()
      } catch (error) {

        console.error('Error in unmute operation:', error)
      }
    } else if (!initialMuteStatus && muteToggle) {

      try {
        await muteUser({
          organizationDid: organization.did,
          userDid: publicProfile.did,
          muteDetails
        })
        onMutedUsersChange()
      } catch (error) {
        console.error('Error in mute operation:', error)
      }
    }
  }

  return (
    <FormBuilder {...{
      ...props,
      className: `OrganizationMembershipEditForm ${className}`,
      persistedValues: organizationMembership,
      value,
      onChange,
      onSubmit: handleSubmit,
      submitting: updatingOrganizationMembership,
      error: errorUpdatingOrganizationMembership,
      render: form => (
        <Fragment>
          <Form.Row>
            {form.item({
              valueProp: 'curator',
              input: <SwitchListMember
                checked={isCurator}
                onChange={handleCuratorChange}
                label="curator"
                description="Manages feed, forum and memberships"
              />
            })}
          </Form.Row>
          <Form.Row>
            {form.item({
              valueProp: 'admin',
              input: <SwitchListMember
                checked={isAdmin}
                onChange={handleAdminChange}
                label="admin"
                description="Full administrative privileges"
              />
            })}
          </Form.Row>
          <Form.Row>
            {form.item({

              valueProp: 'muteToggle',
              input: <SwitchListMember
                key={muteToggleKey}
                label={isMuted ? "Unpause  User" : "Pause User"}
                description={isMuted ? "User is currently paused. Toggle to unpause." : "Toggle to pause the user."}
                checked={muteToggle}
                onChange={handleMuteToggleChange}
              />
            })}
          </Form.Row>

          {!isMuted && muteToggle && (
            <Fragment>
              <div className="modal-content">
                <Form.Row>
                  <label>Mute From</label>
                  <Dropdown
                    placeholder="Select Mute Type"
                    value={muteDetails.mute_type}
                    onChange={e => handleMuteDetailsChange('mute_type', e)}
                    options={['ALL', , 'post', 'hubchat', 'comments', 'DM']}
                  />
                </Form.Row>
                <Form.Row>
                  <label>Duration</label>
                  <Dropdown
                    placeholder="Select Duration"
                    value={durationSelection}
                    onChange={e => handleMuteDetailsChange('expires_at', e)}
                    options={['1 Day', '7 Days', 'Indefinitely']}
                  />
                </Form.Row>
              </div>
              <Form.Row>
                <label>Reason</label>
                <Dropdown
                  style={{ width: '100%', padding: '0.5rem' }}
                  placeholder="Reason "
                  value={muteDetails.reason}
                  onChange={e => handleMuteDetailsChange('reason', e)}
                  options={['Offensive or hostile', 'Spam or off-topic', "Disrespect for administrators", "Others"]}
                />
              </Form.Row>
              <Form.Row>
                <TextInput
                  type="text"
                  placeholder="Additional Comments"
                  value={muteDetails.comments}
                  onChange={e => handleMuteDetailsChange('comments', e)}
                />
              </Form.Row>
            </Fragment>
          )}
          {form.buttonRow('Update', 'Updating…')}
        </Fragment>
      ),
    }} />
  )
}

OrganizationMembershipEditForm.propTypes = {
  className: PropTypes.string,
  organizationMembership: PropTypes.shape({
    admin: PropTypes.bool,
    curator: PropTypes.bool,
  }).isRequired,
  publicProfile: PropTypes.object,
  organization: PropTypes.shape({
    apikey: PropTypes.string,
    did: PropTypes.string,
  }),
  onMutedUsersChange: PropTypes.func,
}
