import api from '../api'
import {
  tx,
  loadCollection,
  addToCollection,
  replaceCollection,
} from 'lib/actionHelpers'
import {
  getCollectionKey,
  memberToId,
  memberIdToKey,
  memberToKey,
  getMemberKeys,
} from 'lib/organizationNetworkMembershipHooks'

export function load(organizationApikey){
  if (!organizationApikey) throw new Error(`organizationApikey undefined`)
  return loadCollection.call(this, {
    reload: true,
    entityKey: getCollectionKey(organizationApikey),
    memberToId,
    memberKey: memberIdToKey,
    request: () =>
      api.getOrganizationNetworkMemberships(organizationApikey)
  })
}

export async function create({ organizationApikey, memberOrganizationApikey }){
  const {
    entityCreatingKey,
    entityCreatingErrorKey,
  } = getMemberKeys({organizationApikey, memberOrganizationApikey})
  return tx.call(this, entityCreatingKey, entityCreatingErrorKey, async () => {
    const organizationNetworkMembership =
      await api.createOrganizationNetworkMembership(
        organizationApikey, memberOrganizationApikey
      )
    addToCollection.call(this, {
      entityKey: getCollectionKey(organizationApikey),
      memberToId,
      memberKey: memberIdToKey,
      collection: [organizationNetworkMembership],
    })
  })
}

export async function update({ organizationApikey, memberOrganizationApikey, changes }){
  const { entityUpdatingKey, entityUpdatingErrorKey }
    = getMemberKeys({organizationApikey, memberOrganizationApikey})
  return tx.call(this, entityUpdatingKey, entityUpdatingErrorKey, async () => {
    const organizationNetworkMembership = await api.updateOrganizationNetworkMembership(
      organizationApikey, memberOrganizationApikey, changes
    )
    const appStateKey = memberToKey(organizationNetworkMembership)
    this.setState({ [appStateKey]: organizationNetworkMembership })
  })
}

export async function remove({ organizationApikey, memberOrganizationApikey }){
  const { entityUpdatingKey, entityUpdatingErrorKey }
    = getMemberKeys({organizationApikey, memberOrganizationApikey})
  return tx.call(this, entityUpdatingKey, entityUpdatingErrorKey, async () => {
    const organizationNetworkMembership = await api.removeOrganizationNetworkMembership(
      organizationApikey, memberOrganizationApikey
    )
    const appStateKey = memberToKey(organizationNetworkMembership)
    const deletedId = memberToId(organizationNetworkMembership)
    const getAndRemove = key => {
      const without = (this.getState()[key] || []).filter(id => id !== deletedId)
      return without.length > 0 ? without : undefined
    }
    const networkCollectionKey = getCollectionKey(organizationApikey)
    const memberCollectionKey = getCollectionKey(memberOrganizationApikey)
    this.setState({
      [appStateKey]: undefined,
      [networkCollectionKey]: getAndRemove(networkCollectionKey),
      [memberCollectionKey]: getAndRemove(memberCollectionKey),
    })
  })
}

export async function sort(organizationApikey, sortOrder){
  const collectionKey = getCollectionKey(organizationApikey)
  const sortingKey = `${collectionKey}:sorting`
  const sortingErrorKey = `${collectionKey}:sorting:error`
  return tx.call(this, sortingKey, sortingErrorKey, async () => {
    const organizationNetworkMemberships =
      await api.sortOrganizationNetworkMemberships(organizationApikey, sortOrder)
    replaceCollection.call(this, {
      entityKey: getCollectionKey(organizationApikey),
      memberToId,
      memberKey: memberIdToKey,
      collection: organizationNetworkMemberships,
    })
  })
}
