import { h } from 'preact'

import classNames from 'lib/classNames'

import Button from 'components/Button'

export default function OrganizationSISAButton({ className, organizationApikey, sisa }){
  if (isDataYogi && sisa) return
  return <Button {...{
    className: classNames('OrganizationSISAButton', { className }),
    href: sisa ? `/${organizationApikey}/my-data` : `/${organizationApikey}/sisa`,
    type: 'normal',
    value: sisa ? 'My Data' : isDataYogi ? 'Sign Standard Information Sharing Agreement' : 'Co-manage My Data',
    fat: !!isDataYogi,
  }}/>
}
