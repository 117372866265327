/*

  Usage:

  pick({a: 1, b: 2, c: 3}, ['a','c']) => {a: 1, c: 3}
  pick(state, ['loading','loadingError']) => { loading, loadingError }
*/
export default function pickProps(object, props){
  const newObject = {}
  for (const prop of Array.from(props)) newObject[prop] = object[prop]
  return newObject
}

export function propsPicker(props){
  return object => pickProps(object, props)
}
