import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Button from 'components/Button'
import Spinner from 'components/Spinner'
import Form from 'components/Form'

export default function FatSubmitButton({
  className,
  submitting,
  value: [value, submittingValue],
  ...props
}){
  return <Form.Row
    {...props}
    className={classNames('FatSubmitButton', { className })}
  >
    <Form.Item>
      <Button submit fat block {...{
        type: 'primary',
        value: (
          submitting
            ? <span><Spinner />&nbsp;{submittingValue}…</span>
            : value
        ),
        ...props,
        disabled: props.disabled || submitting,
      }}/>
    </Form.Item>
  </Form.Row>
}

FatSubmitButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  value: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
}

