import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import SwitchList from 'components/SwitchList'

import {
  COMMUNICATION_CHANNEL_TITLES,
  REQUESTED_COMMUNICATION_CHANNELS_PROP_TYPE,
  COMMUNICATION_CHANNELS_PROP_TYPE,
  DECORATED_COMMUNICATION_CHANNEL_TITLES,
} from 'lib/accountDataSpec'

export default class AliceOrganizationCommunicationChannelsForm extends Component {

  static propTypes = {
    disabled:                           PropTypes.bool,
    className:                          PropTypes.string,
    requestedCommunicationChannels:     REQUESTED_COMMUNICATION_CHANNELS_PROP_TYPE.isRequired,
    communicationChannels:              COMMUNICATION_CHANNELS_PROP_TYPE.isRequired,
    communicationChannelsStagedChanges: COMMUNICATION_CHANNELS_PROP_TYPE,
    onChange:                           PropTypes.func.isRequired,
  }

  render({
    disabled,
    className = '',
    requestedCommunicationChannels,
    communicationChannels,
    communicationChannelsStagedChanges = {},
  }){

    const requestedCommunicationChannelKeys = Object.keys(COMMUNICATION_CHANNEL_TITLES)
      .filter(communicationChannel => communicationChannel in requestedCommunicationChannels)

    if (requestedCommunicationChannelKeys.length === 0) return <div
      className={`AliceOrganizationCommunicationChannelsForm ${className}`}
    >
      <Header size="xs">
        This organization does not currently have any communication channels.
      </Header>
    </div>

    const content = requestedCommunicationChannelKeys
      .map(key => {
        const title = DECORATED_COMMUNICATION_CHANNEL_TITLES[key]
        const unsaved = (
          key in communicationChannelsStagedChanges &&
          'enabled' in communicationChannelsStagedChanges[key]
        )
        const checked = unsaved
          ? !!communicationChannelsStagedChanges[key].enabled
          : !!(
            communicationChannels[key] &&
            communicationChannels[key].enabled
          )
        return { key, title, checked, unsaved }
      })

    return <SwitchList
      className={`AliceOrganizationCommunicationChannelsForm ${className}`}
      disabled={disabled}
      content={content}
      onChange={(index, checked) => {
        this.props.onChange({[content[index].key]: {enabled: checked}})
      }}
    />
  }
}
