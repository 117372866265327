import { h } from 'preact'

import CropImageAssetInput from 'components/CropImageAssetInput'
import { AVATAR_SIZE } from 'lib/imageHelpers'

import DEFAULT from './default.png'

export default function AvatarInput({
  className = '',
  defaultValue = DEFAULT,
  ...props
}){
  return <CropImageAssetInput
    {...props}
    className={`AvatarInput ${className}`}
    defaultValue={defaultValue}
    height={AVATAR_SIZE.height}
    width={AVATAR_SIZE.width}
    circle
  />
}
