import { h, Fragment } from 'preact'

import { useRef, useCallback, useMemo } from 'preact/hooks'

import classNames from 'lib/classNames'

import FormBuilder from 'components/FormBuilder'
import RichMediaOrWYSIWYGInput from 'components/RichMediaOrWYSIWYGInput'
import Form from 'components/Form'
import Button from 'components/Button'
import SlideInAnimation from 'components/SlideInAnimation'

import './index.sass'

export default function FeedPostAndCommentForm({
  className,
  value,
  onChange,
  onCancel,
  submitting,
  disabled,
  persistedValues,
  alwaysShowControls,
  children,
  ...props
}){
  const reset = () => { onChange(undefined) }

  const bodyValue = (value && value.body) || (persistedValues && persistedValues.body)
  const {
    uploading,
    failedUploads,
    loadingPreview,
    isEmpty: bodyIsEmpty,
  } = useMemo(
    () => RichMediaOrWYSIWYGInput.analyzeValue(bodyValue),
    [bodyValue],
  )
  const submittable = !uploading && !failedUploads && !loadingPreview && !bodyIsEmpty

  const rootRef = useRef()
  const focusInput = useCallback(
    () => {
      const input = rootRef.current.base.querySelector('textarea, *[contenteditable]')
      if (input) input.focus()
    },
    []
  )
  const scheduleFocusInput = () => { setTimeout(focusInput, 10) }

  return <FormBuilder {...{
    ...props,
    ref: rootRef,
    className: classNames('FeedPostAndCommentForm', { className }),
    persistedValues,
    disabled,
    value,
    onChange,
    onReset: reset,
    submittable,
    submitting,
    onSuccess(...args){
      reset()
      if (props.onSuccess) props.onSuccess(...args)
    },
    render: form => <Fragment>
      {children({
        form,
        scheduleFocusInput,
      })}
      <SlideInAnimation>
        {(alwaysShowControls || (!bodyIsEmpty || onCancel)) &&
          <FormControls {...{
            form,
            onCancel,
            uploading,
            failedUploads,
            loadingPreview,
            submittable,
            scheduleFocusInput,
          }} />
        }
      </SlideInAnimation>
    </Fragment>
  }}/>
}

function FormControls({
  form,
  uploading,
  failedUploads,
  loadingPreview,
  submittable,
  onCancel,
  focusInput,
  createText = 'Create',
  creatingText = 'Creating…',
  scheduleFocusInput,
}){
  const disabled = form.disabled
  const unsubmittableBecause = (
    (failedUploads && 'Some Uploads Failed') ||
    (uploading && 'Uploading…') ||
    (loadingPreview && 'Loading URL Preview…')
  )
  return <Form.ButtonRow reverse className="FeedPostAndCommentForm-Controls">
    {unsubmittableBecause
      ? <Button type="primary" disabled>{unsubmittableBecause}</Button>
      : form.submitButton({
        type: 'primary',
        value: form.persistedValues ? 'Update' : createText,
        submittingValue: form.persistedValues ? 'Updating…' : creatingText,
        disabled: disabled || !submittable,
      })
    }
    {form.resetButton({
      disabled: disabled,
      onClick: focusInput,
      confirm: (
        'Are you sure you want to reset this form?\n\n' +
        'All contents and file attachments will be lost.'
      ),
    })}
    {onCancel &&
      <Button {...{
        type: 'normal',
        onClick: onCancel,
        value: 'cancel',
        disabled,
      }}/>
    }
    <span onClick={scheduleFocusInput} />
  </Form.ButtonRow>
}
