import { h } from 'preact'
import PropTypes from 'prop-types'

import ImageWithLoader from 'components/ImageWithLoader'
import './index.sass'

export default function OrganizationFeedPostMedia({
  className = '',
  mediaMimeType,
  mediaUrl,
  useImageLoader = false,
}){
  if (!mediaUrl || !mediaMimeType) return null
  let media = null
  if (mediaMimeType === 'video/vimeoUrl') {
    media = <VimeoVideo src={mediaUrl} />
  }else if (mediaMimeType.includes('image')) {
    media = useImageLoader
      ? <ImageWithLoader src={mediaUrl} />
      : <img src={mediaUrl} loading="lazy" />
  }else if (mediaMimeType.includes('video')) {
    media = <video
      controls
      src={mediaUrl}
      type={mediaMimeType}
      loading="lazy"
    />
  }else{
    media = <BrokenMedia />
  }

  return <div className={`OrganizationFeedPostMedia ${className}`}>
    {media}
  </div>
}

OrganizationFeedPostMedia.propTypes = {
  mediaMimeType: PropTypes.string,
  mediaUrl: PropTypes.string,
  className: PropTypes.string,
  useImageLoader: PropTypes.bool,
}

const VimeoVideo = ({ src }) =>
  <iframe
    className="OrganizationFeedPostMedia-vimeo"
    src={src}
    frameBorder="0"
    allowFullScreen
    loading="lazy"
  />

const BrokenMedia = () =>
  <img src="data: " className="OrganizationFeedPostMedia-broken" />
