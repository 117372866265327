import { h } from 'preact'
import PropTypes from 'prop-types'

import './index.sass'

export default function Subtext({
  className = '',
  children,
  ...props
}){
  return <span
    {...props}
    className={`Subtext ${className}`}
  >{children}</span>
}

Subtext.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
