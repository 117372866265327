import { h, Fragment } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { useMyPublicProfileDid } from 'resources/auth'
import {
  useMyOrganizationMembership,
  useAcceptedMembershipsForOrganization,
  usePublicProfiles,
  useRemoveOrganizationMembership,
  useMutedUsers,
  useAllMembershipsForOrganization,
  useFormerMembershipsForOrganization
} from 'lib/membershipAppStateHooks'
import useToggle from 'lib/useToggleHook'
import {
  publicProfileToDisplayName,
  publicProfileToPathname,
} from 'lib/publicProfiles'
import classNames from 'lib/classNames'

import IconButton from 'components/IconButton'
import OrganizationMembershipEditForm from 'components/OrganizationMembershipEditForm'
import OrganizationMembershipPills from 'components/OrganizationMembershipPills'
import IconRowAccordion from 'components/IconRowAccordion'
import PeopleListMember from 'components/PeopleListMember'
import EndUserBanner from 'components/EndUserBanner'
import PublicProfileInfo from 'components/PublicProfileInfo'
import Timestamp from 'components/Timestamp'
import StyleishModal from 'components/StyleishModal'
import EndUserAvatar from 'components/EndUserAvatar'
import IconRow from 'components/IconRow'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import Button from 'components/Button'
import Header from 'components/Header'
import HeaderedContent from 'components/HeaderedContent'
import HeaderedContentBox from 'components/HeaderedContentBox'
import ChatButton from 'components/ChatButton'
import Subtext from 'components/Subtext'
import { filterByOptions, sortByOptions } from 'lib/SortByOptions'

import './index.sass'

export default function OrganizationMembersList({
  header,
  collapsable,
  organization,
  canEdit,
  admins,
  selectedUsername,
  pathPrefix = `/${organization.apikey}/members`,
  className = '',
  withContentBox = true,
  membershipPills = true,
  collapsedByDefault,
  sortByButton,
  filterByButton,
}) {
  const { isAdmin, isCurator } = useMyOrganizationMembership(organization.apikey, 'OrganizationMembersList')
  const { listmuted, mutedUsers = [] } = useMutedUsers('OrganizationMembersList')

  if (typeof canEdit === 'undefined') canEdit = (isAdmin || isCurator)

  const { formerMemberships = [] } = useFormerMembershipsForOrganization(
    organization.apikey, canEdit, 'OrganizationMembersList')


  const myPublicProfileDid = useMyPublicProfileDid()

  const handleMutedUsersUpdate = () => {
    if(organization?.did) listmuted({ organizationDid: organization.did })
  }

  const loggedIn = !!myPublicProfileDid
  const {
    removingMembership,
    removeMembership,
  } = useRemoveOrganizationMembership('OrganizationMembersList')
  const {
    acceptedOrganizationMemberships,
  } = useAcceptedMembershipsForOrganization(
    organization.apikey,
    'OrganizationMembersList'
  )
  let publicProfileDids = []

  acceptedOrganizationMemberships.forEach(organizationMembership => {
    if (
      organizationMembership.organizationApikey === organization.apikey &&
      organizationMembership.memberUserDid
    ) publicProfileDids.push(organizationMembership.memberUserDid)
  })

  const { allOrganizationMemberships } =
  useAllMembershipsForOrganization(organization.apikey, 'OrganizationMembersList')


  const { publicProfiles } = usePublicProfiles(publicProfileDids, 'OrganizationMembersList')

  const deletedPublicProfileDids = []

  formerMemberships.forEach(formerMembership => {
    if (
      formerMembership.organizationApikey === organization.apikey &&
      formerMembership.userDid
    ) deletedPublicProfileDids.push(formerMembership.userDid)
  })

  const { publicProfiles: deletedPublicProfiles } = usePublicProfiles(
    deletedPublicProfileDids,
    'OrganizationMembersList'
  )

  const disabled = !!removingMembership

  const people = publicProfiles
    .map(publicProfile => {
      const organizationMembership = allOrganizationMemberships
        .find(organizationMembership => organizationMembership.memberUserDid === publicProfile.did)
      return {publicProfile, organizationMembership}
    })
    .filter(({organizationMembership, publicProfile}) => {
      if (!publicProfile.username) return false
      const isAdmin = organizationMembership.admin || organizationMembership.curator
      return admins ? isAdmin : !isAdmin
    })
    .sort((a, b) => {
      a = a.organizationMembership
      b = b.organizationMembership
      return (
        (a.admin && !b.admin) ? -1 : (!a.admin && b.admin) ? 1 :
        (a.curator && !b.curator) ? -1 : (!a.curator && b.curator) ? 1 :
        a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 :
        0
      )
    })
    .map(({publicProfile, organizationMembership}) => {
      const myMembership = organizationMembership.memberUserDid === myPublicProfileDid
      const linkToPublicProfile = publicProfileToPathname(publicProfile)
      const buttons = []

      const isMuted = mutedUsers?.some(mu => mu.user_did === publicProfile.did)
      const isFormer = formerMemberships.some(fm => fm.memberUserDid === publicProfile.did)

      if (!isFormer) {
        if (canEdit) {
          buttons.push(
            <EditMembershipButton {...{ key: 'edit', publicProfile, organizationMembership, organization,
              onMutedUsersChange: handleMutedUsersUpdate, }} />
          )
          buttons.push(
            <IconButton {...{
              className: 'OrganizationMembersList-removeMembershipButton',
              key: 'remove',
              disabled: myMembership || disabled || organizationMembership.admin,
              type: 'cancel-circled',
              onClick: () => {
                if (myMembership || organizationMembership.admin) return
                if (!confirm(`Are you sure you want to remove ${publicProfile.username}?`)) return // eslint-disable-line
                removeMembership(organizationMembership)
              },
            }} />
          )
        }
      }
      buttons.push(
        <ChatButton
          {...{
            key: 'chat',
            publicProfileDid: publicProfile.did,
            organizationApikey: organizationMembership.organizationApikey,
          }}
        />
      )
      buttons.push(
        <Button
          className={'OrganizationMembersList-visit'}
          key={'visit'}
          type={'normal'}
          value={'Visit'}
          disabled={!linkToPublicProfile}
          href={linkToPublicProfile}
        />
      )
      return {
        publicProfile,
        organizationMembership,
        buttons,
        suffix: membershipPills && <OrganizationMembershipPills {...{ organizationMembership, key: 'pills'  }} />,
        mutedUsers,
        isMuted
      }
    })
    .concat(
      formerMemberships
        .filter(
          membership => membership.organizationApikey === organization.apikey
        )
        .map(membership => {
          const publicProfile = deletedPublicProfiles.find(profile => profile.did === membership.userDid)
          const linkToPublicProfile = publicProfileToPathname(publicProfile)
          const buttons = []
          buttons.push(
            <Button
              className="OrganizationMembersList-visit"
              key="visit"
              type="normal"
              value="Visit"
              disabled={!publicProfile}
              href={linkToPublicProfile}
            />
          )
          return {
            publicProfile,
            organizationMembership: membership,
            buttons,
            suffix: membershipPills && <OrganizationMembershipPills {...{
              organizationMembership: membership, key: 'Pills'
            }} />,
            mutedUsers,
            isMuted: false,
            isFormer: true
          }
        })
        .sort((a, b) => {
          a = a.organizationMembership
          b = b.organizationMembership
          return (
            a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0
          )
        })
    )

  const [selectedOption, setSelectedOption] = useState('AToZ')
  const [filter, setFilter] = useState('Active')
  const [selectedPeople, setSelectedPeople] = useState(people)

  useEffect(() => {
    if (organization?.did) {
      listmuted({ organizationDid: organization.did })
    }
  }, [listmuted, organization?.did])

  const updatedPeople = useMemo(() => {
    return people.map(person => ({
      ...person,
      isMuted: mutedUsers?.some(mu => mu.user_did === person.publicProfile.did)
    }))
  }, [people, mutedUsers])

  const filteredAndSortedPeople = useMemo(() => {
    const filteredPeople = filterByOptions(updatedPeople, filter)
    return sortByOptions(filteredPeople, selectedOption)
  }, [updatedPeople, filter, selectedOption])

  useEffect(() => {
    setSelectedPeople(filteredAndSortedPeople)
  }, [filteredAndSortedPeople])

  const expandedIndex = selectedUsername
    ? selectedPeople.findIndex(person =>
      person.publicProfile.username === selectedUsername
    )
    : undefined

  const headerButtons = []

  const HeaderComponent = withContentBox ? HeaderedContentBox : HeaderedContent
  return <Fragment><HeaderComponent {...{
    className: classNames('OrganizationMembersList', { className }),
    header,
    collapsable,
    buttons: headerButtons,
    collapsedByDefault,
    sortByButton,
    filterByButton,
    setSelectedOption,
    setFilter,
  }}>
    {people.length === 0
      ? <p>
        <Header size="sm" centered italic>
          {organization.name} has no {admins ? 'admins' : 'members'}
        </Header>
      </p>
      : <IconRowAccordion {...{expandedIndex}}>
        {selectedPeople.map((person, index) =>
          <div>
            <PeopleListMember {...{
              ...person,
              isMuted: person.isMuted,
              href: (
                `${pathPrefix}` +
                (index === expandedIndex ? '' : `/@${person.publicProfile.username}`)
              ),
            }} />
            <PersonPreview {...{ loggedIn, ...person }} mutedUsers={person.mutedUsers} />

          </div>
        )}
      </IconRowAccordion>
    }

  </HeaderComponent>

  </Fragment>
}

function PersonPreview({
  loggedIn, publicProfile, organizationMembership, mutedUsers
}) {

  const muteDetails = mutedUsers?.find(mu => mu.user_did === publicProfile.did)

  return <div className="OrganizationMembersList-preview">
    <div>
      <LinkToPublicProfile {...{ publicProfile }}>
        <EndUserBanner {...{ publicProfile }} />
      </LinkToPublicProfile>
    </div>

    {muteDetails && <div>
      <p> Paused from  <Subtext>
        <Timestamp time={muteDetails.muted_at} format="MMM Do YYYY" />
        to <Timestamp time={muteDetails.expires_at} format="MMM Do YYYY" /></Subtext> </p>
      <p>Reason: <Subtext>{muteDetails.reason}</Subtext></p>
      <p> Comments: <Subtext>{muteDetails.comments}</Subtext></p>
    </div>}


    {loggedIn &&
      <PublicProfileInfo {...{
        publicProfile,
        extraDetails: [
          ['Joined on', <Timestamp time={organizationMembership.createdAt} format="MMM Do YYYY" />],

        ],
      }}/>
    }
  </div>
}

function EditMembershipButton({ organizationMembership, publicProfile, organization, onMutedUsersChange }) {
  const [isOpen, open, close] = useToggle(false)
  return <Fragment>
    <IconButton {...{
      className: 'OrganizationMembersList-EditMembershipButton',
      type: 'edit',
      onClick: open,
    }}/>
    <StyleishModal
      open={isOpen}
      onClose={close}
      title={
        <IconRow
          icon={<EndUserAvatar size="sm" publicProfile={publicProfile} />}
          row={`${publicProfileToDisplayName(publicProfile)}'s Roles`}
        />
      }
    >
      <OrganizationMembershipEditForm {...{
        organizationMembership,
        publicProfile,
        organization,
        onSuccess: close,
        onMutedUsersChange: onMutedUsersChange
      }} />
    </StyleishModal>
  </Fragment>
}

