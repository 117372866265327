import { h } from 'preact'

import classNames from 'lib/classNames'

import looking from './looking.svg'

export default function Jlinx({ className, type, src, ...props }){
  if (type) src = Jlinx.types[type]
  return <img {...{
    ...props,
    className: classNames('Jlinx', { type, className }),
    src,
  }}/>
}


Jlinx.types = Object.freeze({
  looking,
})
