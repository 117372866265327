import { h } from 'preact'
import PropTypes from 'prop-types'

import dataYogiPng from './datayogi.png'
import TruTSVG from './truT.svg'
import './index.sass'
const src = isDataYogi ? dataYogiPng : TruTSVG

export default function TruT({
  className = '',
  size = 'md',
  ...props
}) {
  return <img
    {...props}
    className={`TruT TruT-${size} ${className}`}
    src={src}
  />
}

TruT.SIZES = Object.freeze(['sm', 'md', 'lg'])
TruT.propTypes = {
  size: PropTypes.oneOf(TruT.SIZES),
  className: PropTypes.node,
}
