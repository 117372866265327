import { h, Fragment } from 'preact'
import { useEffect } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import { useAppState } from 'lib/appState'
import { COMMUNICATION_CHANNELS_HELP, areThereStagedConsentChanges } from 'lib/accountDataSpec'

import { useRedirect } from 'resources/location'
import { useSISA } from 'resources/SISAs'

import ContentBox from 'components/ContentBox'
import TwoColumns from 'components/TwoColumns'
import HeaderedContentBox from 'components/HeaderedContentBox'
import Loading from 'components/Loading'
import Header from 'components/Header'
import Icon from 'components/Icon'
import Alert from 'components/Alert'
import ErrorMessage from 'components/ErrorMessage'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import ButtonWithDropdown from 'components/ButtonWithDropdown'
import AliceConsentsForm from 'components/AliceConsentsForm'
import AliceOrganizationPersonalDataForm from 'components/AliceOrganizationPersonalDataForm'
import AliceOrganizationCommunicationChannelsForm from 'components/AliceOrganizationCommunicationChannelsForm'
import HeaderedContentWithUnsaved from 'components/HeaderedContentWithUnsaved'
import HeaderedContentWithUnsavedAndHelpButton from 'components/HeaderedContentWithUnsavedAndHelpButton'

import './index.sass'

export default function OrganizationPersonalDataPage(props){
  const { organizationApikey, organization } = props
  const {
    takeAction,
    updateError,
    changes,
    updatingOrganizationAccountData,
    importDefaultAccountDataError,
    organizationAccountData,
    loadingDefaultAccountData,
    defaultAccountData,
  } = useAppState(
    {
      [`updatingOrganizationAccountDataError:${organizationApikey}`]: 'updateError',
      [`organizationAccountDataStagedChanges:${organizationApikey}`]: 'changes',
      [`updatingOrganizationAccountData:${organizationApikey}`]: 'updatingOrganizationAccountData',
      [`importDefaultAccountDataError:${organizationApikey}`]: 'importDefaultAccountDataError',
      [`organizationAccountData:${organizationApikey}`]: 'organizationAccountData',
      loadingDefaultAccountData: 'loadingDefaultAccountData',
      defaultAccountData: 'defaultAccountData',
    },
    'OrganizationPersonalDataPage'
  )

  useEffect(() => {
    takeAction('loadDefaultAccountData')
    takeAction('loadOrganizationAccountData', organizationApikey)
  })

  const stageOrganizationAccountDataChange = prop =>
    change => {
      takeAction(
        'stageOrganizationAccountDataChanges',
        organizationApikey,
        { [prop]: change }
      )
    }

  return <TwoColumns className="OrganizationPersonalDataPage">
    <div>
      <OrganizationPersonalDataPageContent {...{
        organizationApikey,
        organization,
        organizationAccountData,
        changes: changes || {},
        updating: !!updatingOrganizationAccountData,
        loadingDefaultAccountData,
        defaultAccountData,
        error: importDefaultAccountDataError || updateError,
        importDefaultAccountData: () => takeAction('importDefaultAccountData', organizationApikey),
        onCommunicationChannelsChange: stageOrganizationAccountDataChange('communication_channels'),
        onSharedPersonalDataChange: stageOrganizationAccountDataChange('shared_personal_data'),
        onPersonalDataChange: stageOrganizationAccountDataChange('personal_data'),
        onConsentChange: stageOrganizationAccountDataChange('consents'),
        reset: () => takeAction('clearOrganizationAccountDataStagedChanges', organizationApikey),
        save: options => takeAction('commitOrganizationAccountDataStagedChanges', {organizationApikey, ...options}),
      }}/>
    </div>
    <div>
      <Explanation />
    </div>
  </TwoColumns>
}

OrganizationPersonalDataPage.pageTitle = 'My Data'

const OrganizationPersonalDataPageContent = props => {
  const {
    organizationApikey,
    organization,
    organizationAccountData,
    loadingDefaultAccountData,
    defaultAccountData,
    onCommunicationChannelsChange,
    onSharedPersonalDataChange,
    onConsentChange,
    onPersonalDataChange,
    importDefaultAccountData,
    updating,
    changes,
    error,
    reset,
    save,
  } = props

  const sisa = useSISA(organizationApikey, { reload: true })
  const noSisa = !!sisa._notFoundAt

  useRedirect({
    to: `/${organizationApikey}/sisa`,
    if: noSisa,
  })
  if (noSisa) return <ContentBox padded>
    <Alert type="error">You do not have a SISA with {organization.name}</Alert>
  </ContentBox>

  if (
    !organizationAccountData ||
    !defaultAccountData
  ) return <Loading type="fullPage"/>

  const hasChanges = Object.keys(changes).length > 0
  const disabled = updating

  return <HeaderedContentBox
    padded
    header={
      <div className="flex-row">
        <Icon type="data-tr" size="lg" blue/>&nbsp;&nbsp;
        <Header size="lg">SISA - Shared Data</Header>
      </div>
    }
    size="lg"
    buttons={
      <Buttons
        loadingDefaultAccountData={loadingDefaultAccountData}
        importDefaultAccountData={importDefaultAccountData}
        organization={organization}
        hasChanges={hasChanges}
        updating={updating}
        reset={reset}
        save={save}
      />
    }
  >
    <ErrorMessage error={error} />
    <PersonalDataForms
      changes={changes}
      organization={organization}
      disabled={disabled}
      organizationAccountData={organizationAccountData}
      onPersonalDataChange={onPersonalDataChange}
      onSharedPersonalDataChange={onSharedPersonalDataChange}
      onConsentChange={onConsentChange}
      onCommunicationChannelsChange={onCommunicationChannelsChange}
    />
  </HeaderedContentBox>
}

const PersonalDataForms = ({
  changes,
  disabled,
  organization,
  organizationAccountData,
  onPersonalDataChange,
  onSharedPersonalDataChange,
  onConsentChange,
  onCommunicationChannelsChange,
}) => (
  <Fragment>
    <HeaderedContentWithUnsaved
      header="Personal Data"
      unsaved={!!changes.personal_data || !!changes.shared_personal_data}
    >
      <AliceOrganizationPersonalDataForm
        disabled={disabled}
        requestedPersonalData={organization.requested_data}
        personalData={organizationAccountData.personal_data}
        personalDataStagedChanges={changes.personal_data}
        onPersonalDataChange={onPersonalDataChange}
        sharedPersonalData={organizationAccountData.shared_personal_data}
        sharedPersonalDataStagedChanges={changes.shared_personal_data}
        onSharedPersonalDataChange={onSharedPersonalDataChange}
      />
    </HeaderedContentWithUnsaved>
    <HeaderedContentWithUnsaved
      header="GDPR Permissions"
      unsaved={areThereStagedConsentChanges({
        type: 'GDPR',
        changes: changes.consents,
      })}
    >
      <AliceConsentsForm
        organization={organization}
        disabled={disabled}
        consents={organizationAccountData.consents}
        stagedChanges={changes.consents}
        onChange={onConsentChange}
        useOrgNameInDescriptions
        type="GDPR"
      />
    </HeaderedContentWithUnsaved>
    <HeaderedContentWithUnsaved
      header="CCPA Permissions"
      unsaved={areThereStagedConsentChanges({
        type: 'CCPA',
        changes: changes.consents,
      })}
    >
      <AliceConsentsForm
        organization={organization}
        disabled={disabled}
        consents={organizationAccountData.consents}
        stagedChanges={changes.consents}
        onChange={onConsentChange}
        useOrgNameInDescriptions
        type="CCPA"
      />
    </HeaderedContentWithUnsaved>
    <HeaderedContentWithUnsavedAndHelpButton
      header="Communication Channel Preferences"
      unsaved={!!changes.communication_channels}
      helpText={COMMUNICATION_CHANNELS_HELP}
    >
      <AliceOrganizationCommunicationChannelsForm
        disabled={disabled}
        requestedCommunicationChannels={organization.communication_channels}
        communicationChannels={organizationAccountData.communication_channels}
        communicationChannelsStagedChanges={changes.communication_channels}
        onChange={onCommunicationChannelsChange}
      />
    </HeaderedContentWithUnsavedAndHelpButton>
  </Fragment>
)

const Buttons = props => {
  const {
    updating,
    hasChanges,
    reset,
    save,
    importDefaultAccountData,
    organization,
  } = props

  const buttons = []

  const saveButtonValue = updating
    ? "Saving…"
    : "Save"

  if (hasChanges || updating){
    if (!updating){
      buttons.push(
        <Button
          key="cancel"
          type="normal"
          value="reset"
          disabled={updating}
          onClick={reset}
        />
      )
    }
    buttons.push(
      <ButtonWithDropdown
        key="save"
        type="success"
        disabled={updating}
        buttonValue={saveButtonValue}
        onClick={save}
        dropdownOptions={[
          {
            title: 'Save & Apply to Defaults',
            onClick: () => { save({ applyToDefaults: true }) }
          },
          {
            title: `Save & Apply to all of my ${PNFO.plural}`,
            onClick: () => { save({ applyToAllOrgs: true }) }
          },
        ]}
      />
    )
  } else {
    buttons.push(
      <Button
        key="View SISA"
        type="normal"
        value="View SISA"
        href={`/${organization.apikey}/sisa`}
      />
    )
    buttons.push(
      <Button
        type="normal"
        value="Edit Defaults"
        href="/my-data/defaults"
      />
    )
    if (!isDataYogi)
      buttons.push(
        <Button
          key="Import My Defaults"
          type="primary"
          disabled={hasChanges || updating}
          value={'Load Defaults'}
          onClick={importDefaultAccountData}
        />
      )
  }

  return <ButtonRow>{buttons}</ButtonRow>
}

function Explanation(){
  return <HeaderedContentBox
    header="Control your personal data defaults"
    padded
  >
    <p>
      Your personal data sharing permissions and settings are controlled
      here.
    </p>
    <p>
      This system was built for GDPR, a new law in Europe that give each person rights to control
      their personal data. In the US, this system is available for companies that wish to offer it to you.
    </p>
    <p>
      Each organization that shares the data they have for you contributes it to this set,
      where you can add to or edit it. The system allows you to save your own default data and permissions,
      and then share that with new organizations in one click.
    </p>
    <p>
      When you share personal data with an organization under a Standard Information Sharing Agreement (SISA),
      that digitally signed agreement governs what they can do with your data. This gives you control over
      your data - after it is shared.
    </p>
    <p>
      If you change the permission or update the data, the organization's system automatically signs that update
      and follows your instruction.
    </p>
    <p>
      This provides a working solution for legally binding data rights under GDPR in the EU. It also provides
      the basis for a far more valuable marketing relationship for leading companies in the US and around the world.
    </p>
  </HeaderedContentBox>
}
