import api from "../api"


export async function muteUser({
  organizationDid,
  userDid,
  muteDetails
}) {
  if (!organizationDid || !userDid) throw new Error('Organization DID and User DID are required')

  const mutingKey = `mutingUser:${userDid}`
  const errorMutingKey = `${mutingKey}:error`

  if (this.getState()[mutingKey]) return

  this.setState({
    [mutingKey]: true,
    [errorMutingKey]: undefined,
  })

  try {
    const response = await api.muteUser({
      organizationDid,
      userDid,
      muteDetails
    })

    console.log('User muted successfully', response)

  } catch (error) {
    console.error('Error muting user:', error)
    this.setState({ [errorMutingKey]: error })
  } finally {
    this.setState({ [mutingKey]: false })
  }
}


export async function unMuteUser({
  organizationDid,
  userDid,
  unMuteDetails
}) {
  if (!organizationDid || !userDid) throw new Error('Organization DID and User DID are required')

  const unmutingKey = `unmutingUser:${userDid}`
  const errorUnmutingKey = `${unmutingKey}:error`

  if (this.getState()[unmutingKey]) return

  this.setState({
    [unmutingKey]: true,
    [errorUnmutingKey]: undefined,
  })

  try {
    const response = await api.unMuteUser({
      organizationDid,
      userDid,
      unMuteDetails
    })

    console.log('User unmuted successfully', response)

  } catch (error) {
    console.error('Error unmuting user:', error)
    this.setState({ [errorUnmutingKey]: error })
  } finally {
    this.setState({ [unmutingKey]: false })
  }
}


