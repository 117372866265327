import { h } from "preact"
import { useState, useEffect } from "preact/hooks"
import PropTypes from "prop-types"
import Button from "components/Button"
import ButtonRow from "components/ButtonRow"
import PeopleList from "components/PeopleList"
import { usePublicProfiles } from "lib/membershipAppStateHooks"
import { useAssignChatChannelAdminRole, useChatChannel, useChatChannelMembershipDetails,
  useLeaveChatChannel } from "lib/chatHooks"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import IconButton from "components/IconButton"
import SearchInput from "components/SearchInput"
import { useSet } from "lib/useSetHook"

import "./index.sass"

export default function AssignAdminModal({
  open,
  onClose,
  myPublicProfile,
  chatChannel: chatChannelUid,
  ...props
}) {
  const { chatChannel } = useChatChannel(chatChannelUid, "AssignAdminModal")
  const [recipients, addRecipient, removeRecipient] = useSet()
  const [searchTerm, setSearchTerm] = useState("")
  const memberUserDids = chatChannel?.memberUserDids || []
  const { chatChannelMembershipDetails: membershipDetails } =
  useChatChannelMembershipDetails(chatChannelUid, "AssignAdminModal")
  const leaveChannel = useLeaveChatChannel()

  const { publicProfiles } = usePublicProfiles(
    [...memberUserDids],
    "AssignAdminModal"
  )
  const assignChatChannelAdminRole =
    useAssignChatChannelAdminRole("AssignAdminModal")


  useEffect(() => {}, [recipients])

  const handleAssignAdminAndLeave = async (channelUid, adminUserDids) => {
    if (!adminUserDids.length || !channelUid) {
      console.error("Missing channelUid or adminUserDids")
      return
    }

    try {
      for (const adminUserDid of adminUserDids) {
        try {
          await assignChatChannelAdminRole(channelUid, adminUserDid)
        } catch (error) {
          console.error(
            `Failed to assign admin role for user`,
            error.message,
            error.stack
          )
        }
      }

      await leaveChannel(channelUid)
    } catch (error) {
      console.error(
        "Error in handleAssignAdminAndLeave:",
        error.message,
        error.stack
      )
    }
  }

  return (
    <TransitionGroup component={null}>
      {open && (
        <CSSTransition
          timeout={300}
          classNames="modal-transition"
          unmountOnExit
        >
          <div className="AssignAdminModal">
            <div className="AssignAdminModal-header">
              <IconButton
                type="return"
                onClick={onClose}
                className="AssignAdminModal-header-returnButton"
              />
              <h2 className="AssignAdminModal-header-title">Manage Members</h2>
            </div>

            <SearchInput
              {...{
                placeholder: "Search…",
                className: "AssignAdminModal-SearchBar",
                interfaceHelp: {
                  position: "tl",
                  inside: true,
                  title: "Search",
                  content: `Search Members`,
                },
                value: searchTerm,
                onChange: setSearchTerm,
              }}
            />

            <div className="AssignAdminModal-content">
              <span className="admin-modal-title">
                *Please select new chat admin(s).
              </span>
              <AssignAdminForm
                {...{
                  ...props,
                  onClose,
                  assignChatChannelAdminRole,
                  chatChannelUid: chatChannelUid,
                  searchTerm,
                  publicProfiles: publicProfiles.filter((profile) =>
                    membershipDetails.memberships.some(member =>
                      member.memberUserDid === profile.did && !member.leftAt &&
                      (member.memberUserDid !== myPublicProfile?.did))),
                  myPublicProfile,
                  memberUserDids,
                  recipients,
                  addRecipient,
                  removeRecipient,
                }}
              />
            </div>

            <div className="AssignAdminModal-actions">
              <ButtonRow>
                <Button type="normal" value="cancel" onClick={onClose} />
                <Button
                  type="primary"
                  value="Leave Chat"
                  disabled={!recipients.size || !chatChannelUid}
                  onClick={async () => {
                    await handleAssignAdminAndLeave(
                      chatChannelUid,
                      Array.from(recipients)
                    )
                    onClose()
                  }}
                />
              </ButtonRow>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

AssignAdminModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  chatChannelUid: PropTypes.string.isRequired,
  chatChannel: PropTypes.string.isRequired,
  myPublicProfile: PropTypes.object.isRequired,
}

function AssignAdminForm({ searchTerm,
  publicProfiles,
  recipients,
  addRecipient,
  removeRecipient,
}) {
  const [selectedAdmin, setSelectedAdmin] = useState(null)

  const filteredProfiles = publicProfiles.filter((profile) =>
    profile.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const people = filteredProfiles.map((publicProfile) => {
    const selected = recipients.has(publicProfile.did)
    const onClick = () => {
      if (selected) {
        removeRecipient(publicProfile.did)
        setSelectedAdmin(null)
      } else {
        addRecipient(publicProfile.did)
        setSelectedAdmin(publicProfile.did)
      }
    }
    return {
      ...publicProfile,
      className: "AssignAdminModal-recipient",
      href: null,
      onClick,
      buttons: (
        <IconButton
          {...{
            type: selected ? "selected" : "create",
            onClick,
            disabled: !selectedAdmin
          }}
        />
      ),
    }
  })

  return (
    <div className="AssignAdminModal-form">
      <PeopleList {...{ people }} />
    </div>
  )
}
