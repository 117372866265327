import { h } from 'preact'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import HeaderedContent from 'components/HeaderedContent'
import './index.sass'

export default function HeaderedContentWithUnsaved({
  className = '',
  unsaved,
  buttons = [],
  ...props
}) {

  const buttonsWithUnsaved = [...buttons]
  if (unsaved) {
    buttonsWithUnsaved.push(<Alert className="HeaderedContentWithUnsaved-unsavedAlert" type="success">changed</Alert>)
  }

  return <HeaderedContent
    {...props}
    className={`${className} HeaderedContentWithUnsaved`}
    buttons={buttonsWithUnsaved}
    collapsable
  />
}

HeaderedContentWithUnsaved.propTypes = {
  ...HeaderedContent.propTypes,
  unsaved: PropTypes.bool,
}
