import { h } from 'preact'
import ChatChannelAvatar from 'components/ChatChannelAvatar'
import { ChatListMember } from './ChatListMember'
import './index.sass'
import { useMyOrganizations } from 'lib/membershipAppStateHooks'
import Icon from 'components/Icon'


export function GeneralChatListMember({ organization, ...props }) {

  const { myOrganizations } = useMyOrganizations('OrganizationChatPage')
  const orgName = myOrganizations.find(org => org.apikey === props.organizationApikey)?.name || props.organizationApikey

  return <ChatListMember {...{
    ...props,
    organization,
    name: `${orgName} - General`,
    avatar: <ChatChannelAvatar size="sm" value={
      props.imageUrl.startsWith('#') ? (
        <div className={'avatarImg'} style={{ backgroundColor: props.imageUrl }}>
          <Icon type="chaty" />
        </div>
      ) : (
        <img
          src={props.imageUrl}
          alt="Chat Image"
          className={'avatarImg'}
          style={{padding:0}}
        />
      )
    } />
  }} />
}
