import { views, takeAction } from 'resources/aServer'
import { isLoggedIn, useLoggedIn } from 'resources/auth'

export async function reloadOrganizationAnnouncements(organizationApikey){
  return views.reload(isLoggedIn()
    ? `OrganizationAnnouncements.${organizationApikey}.all`
    : undefined
  )
}

export async function createOrganizationAnnouncement(organizationApikey, value){
  const record = await takeAction(
    'OrganizationAnnouncements.create',
    { organizationApikey, value }
  )
  await reloadOrganizationAnnouncements(organizationApikey)
  return record.uid
}

export async function updateOrganizationAnnouncement(organizationApikey, uid, value){
  await takeAction(
    'OrganizationAnnouncements.update',
    { organizationApikey, uid, value }
  )
  await reloadOrganizationAnnouncements(organizationApikey)
}

export async function destroyOrganizationAnnouncement({organizationApikey, uid}){
  await takeAction(
    'OrganizationAnnouncements.destroy',
    { organizationApikey, uid }
  )
  await reloadOrganizationAnnouncements(organizationApikey)
}

export async function markOrganizationAnnouncementAsSeen({organizationApikey, uid}){
  await takeAction(
    'OrganizationAnnouncements.markAsSeen',
    { organizationApikey, uid }
  )
  await reloadOrganizationAnnouncements(organizationApikey)

}

export function useOrganizationAnnouncements(organizationApikey){
  const isLoggedIn = useLoggedIn()
  // const organizationMembership = useOrganizationMembership(organizationApikey)
  let viewId
  // if (isLoggedIn && organizationMembership) viewId = `OrganizationAnnouncements.${organizationApikey}.all`
  if (isLoggedIn) viewId = `OrganizationAnnouncements.${organizationApikey}.all`
  return views.use(viewId, { reload: true })
}
