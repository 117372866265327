import {h} from "preact"
import TextArea from "components/TextArea"
import Button from "components/Button"
import Icon from "components/Icon"
import {useState} from "preact/hooks"
import RemoveButton from "components/RemoveButton"
import Tooltip from "components/Tooltip"

export function FilePreviewModal({
  files,
  initialCaptions,
  onCaptionChange,
  onSubmit,
  onAddMoreFiles,
  onRemoveFile,
}) {
  const [selectedFileIndex, setSelectedFileIndex] = useState(0)
  const [captions, setCaptions] = useState(initialCaptions || {})

  const handleCaptionChange = (index, value) => {
    const newCaptions = {...captions, [index]: value}
    setCaptions(newCaptions)
    onCaptionChange(index, value)
  }

  const handleFileClick = (index) => {
    setSelectedFileIndex(index)
  }

  const handleAddMoreFiles = (event) => {
    const newFiles = Array.from(event.target.files)
    if (onAddMoreFiles) {
      onAddMoreFiles(newFiles)
    }
  }

  return (
    <div className="file_preview_container">
      <div className="file-previews">
        {files.map((file, index) => (
          <div
            key={index}
            style={{padding: "0.2rem", position: "relative"}}
            className={`file-preview ${
              selectedFileIndex === index ? "selectedFileContainer" : ""
            }`}
            onClick={() => handleFileClick(index)}
          >
            <Tooltip
              text={
                captions[index] ? captions[index] : "Click image to add caption"
              }
            >
              <img
                src={URL.createObjectURL(file)}
                alt={`Preview of ${file.name}`}
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
            </Tooltip>
            <RemoveButton
              style={{position: "absolute", top: 0, right: 0}}
              onClick={() => onRemoveFile(index)}
            />
          </div>
        ))}

        <div className="add-more-files" style={{padding: "0.5rem"}}>
          <input
            type="file"
            id="add-more-input"
            multiple
            style={{display: "none"}}
            accept="image/*"
            onChange={handleAddMoreFiles}
          />
        </div>
      </div>

      {selectedFileIndex !== null && (
        <div className="caption-input">
          <label className="FileButton" htmlFor="add-more-input">
            <div style={{padding: "0.5rem"}}>
              <Icon type="plus" size="lg" />
            </div>
          </label>
          <div className="textStyle">
            <TextArea
              className="TextArea"
              autoResizeVertically
              minRows={1}
              maxRows={4}
              resize="none"
              style={{borderRadius: "30px"}}
              placeholder="Enter a caption..."
              value={captions[selectedFileIndex] || ""}
              onInput={(e) => handleCaptionChange(selectedFileIndex, e)}
            />
            <img
              className="EmojiIcon"
              src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
              alt="Emoji Icon"
            />
          </div>
          <Button onClick={onSubmit} type="primary">
            <Icon type="send" />
          </Button>
        </div>
      )}
    </div>
  )
}
