import { useEffect } from 'preact/hooks'
import { getVerticalScrollParent } from 'lib/DOMHelpers'

export default function useOnScolledIntoView(ref, handler, args = [], options = {}){
  const {
    rootMargin = '0px',
    threshold = 1,
  } = options

  useEffect(
    () => {
      let node = ref && ref.current && (ref.current.base || ref.current)
      if (!node) return
      const scrollParent = getVerticalScrollParent(node)
      const observer = new global.IntersectionObserver(
        entries => {
          const entry = entries.find(entry => entry.target === node)
          if (entry && entry.intersectionRect.top > 0) handler(node)
        },
        {
          root: scrollParent,
          rootMargin,
          threshold,
        },
      )
      observer.observe(node)
      return () => { observer.disconnect() }
    },
    [ref, ...args],
  )
}
