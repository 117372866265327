import { h } from 'preact'
import { useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

export default function SlideInAnimation({
  className,
  children,
  ...props
}){
  const ref = useRef()
  useEffect(
    () => {
      const base = ref.current
      const container = base.firstElementChild
      const isOpen = !!container.firstElementChild
      const height = isOpen ? container.clientHeight : 0
      base.style.height = `${height}px`
    },
    [children]
  )
  return <div
    {...props}
    className={classNames('SlideInAnimation', { className })}
    ref={ref}
  ><div>{children}</div></div>
}

SlideInAnimation.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
