import { h } from 'preact'

import classNames from 'lib/classNames'
import './index.sass'

export default function SingleColumn({
  className,
  children,
  ...props
}){
  return <div {...props} className={classNames('SingleColumn', { className })}>
    {children}
  </div>
}
