export default function joinStringsInBatches(strings, maxLength){
  const batches = []
  strings = strings.sort()
  for (const string of strings){
    const joined = batches.length > 0 && batches[0] + ',' + string
    if (joined && joined.length <= maxLength) batches[0] = joined
    else batches.unshift(string)
  }
  return batches.reverse()
}
