import { useReducer, useMemo, useCallback } from 'preact/hooks'
import useForceUpdate from 'lib/useForceUpdate'

export function useUpdateOnlyState(initialState = {}){
  return useReducer(updateOnlyStateReducer, initialState)
}

function updateOnlyStateReducer(state, changes){
  return {...state, ...changes}
}

export function useRefState(initialState = {}, args = []){
  const forceUpdate = useForceUpdate()
  const state = useMemo(() => initialState, args)
  const setState = useCallback(
    changes => {
      Object.assign(state, changes)
      forceUpdate()
    },
    [state]
  )
  return [state, setState]
}
