import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

export default function Letterbox({
  className,
  children,
  height,
  width,
  style = {},
  ...props
}){
  if (typeof height === 'number' && typeof width === 'number')
    style['--aspect-ratio'] = ` ${width} / ${height}`
  return <div {...{
    ...props,
    style,
    className: classNames('Letterbox', { className }),
  }}>{children}</div>
}

Letterbox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.number,
  width: PropTypes.number,
  style: PropTypes.object,
}
