import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

export default function TwoColumns({
  className,
  children,
  ...props
}){
  return <div {...props} className={classNames('TwoColumns', { className })}>
    <div className="TwoColumns-left">{children[0]}</div>
    <div className="TwoColumns-right"><div>{children[1]}</div></div>
  </div>
}

TwoColumns.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
}
