import { h, Fragment } from 'preact'
import './index.sass'

import {
  publicProfileToDisplayName,
  publicProfileToPathname,
} from 'lib/publicProfiles'
import classNames from 'lib/classNames'

import IconRow from 'components/IconRow'
import EndUserAvatar from 'components/EndUserAvatar'

export default function DisplayNameAndAvatarRow({
  className, username, displayName, avatar, href,
  suffix, buttons, asMemberOf, isAdmin, ...props
}){
  if (typeof href === 'undefined')
    href = publicProfileToPathname({ username, asMemberOf })
  return <IconRow {...{
    ...props,
    className: classNames('DisplayNameAndAvatarRow', { className }),
    href,
    icon:  <EndUserAvatar size="sm" publicProfile={{avatar, username}} />,
    row: <Fragment>
      <span className="DisplayNameAndAvatarRow-name">
        {publicProfileToDisplayName({ displayName, username })}
      </span>
      {isAdmin && <span className="adminBadge">Admin</span>}
      &nbsp;
      {suffix}
    </Fragment>,
    buttons,
  }}/>
}
