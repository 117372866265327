import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'

import {
  publishOrganizationForumPost,
} from 'jlinc-shared/feedPosts'

import classNames from 'lib/classNames'
import { usePublishOrganizationForumFeedPost } from 'lib/feedPostHooks'
import { useMyPublicProfileDid } from 'resources/auth'
import {
  feedPostPropType,
} from 'lib/feedPostHelpers'

import StyleishModal from 'components/StyleishModal'
import Button from 'components/Button'
import Form from 'components/Form'
import FormBuilder from 'components/FormBuilder'
import FeedPostVisibilityDropdown from 'components/FeedPostVisibilityDropdown'
import OrganizationFeedPost from 'components/OrganizationFeedPost'
import FeedPostFormWithPreview from 'components/FeedPostFormWithPreview'

export default function OrganizationForumFeedPostPublishModal({
  className,
  post,
  open,
  onClose,
  ...props
}){
  return <StyleishModal {...{
    padded: false,
    title: `Publish ${post && post.title ? `"${post.title}"` : 'Feed Post'}`,
    open,
    onClose,
    ...props,
    className: classNames('OrganizationForumFeedPostPublishModal', {className}),
  }}>
    <Contents {...{ onClose, post }}/>
  </StyleishModal>
}

OrganizationForumFeedPostPublishModal.propTypes = {
  className: PropTypes.string,
  post: feedPostPropType.isRequired,
  open: StyleishModal.propTypes.open,
  onClose: StyleishModal.propTypes.onClose,
}

const Contents = memo(({ onClose, post }) => {
  const myPublicProfileDid = useMyPublicProfileDid()

  const {
    publishOrganizationForumFeedPost,
    publishingOrganizationForumFeedPost,
    errorPublishingOrganizationForumFeedPost,
  } = usePublishOrganizationForumFeedPost(
    post, 'OrganizationForumFeedPostPublishModal'
  )

  const visibilityRange = {
    min: 2, // TODO change to 1 when networks
    max: post.maxVisibleTo,
  }

  const [value, setValue] = useState({
    visibleTo: visibilityRange.min,
  })

  const hasVisibleToOptions = visibilityRange.max - visibilityRange.min > 0

  return <FormBuilder {...{
    className: 'OrganizationForumFeedPostPublishModal-form',
    value,
    onChange: setValue,
    onSubmit: publishOrganizationForumFeedPost,
    submitting: !!publishingOrganizationForumFeedPost,
    error: errorPublishingOrganizationForumFeedPost,
    render: form => (
      <FeedPostFormWithPreview>
        <FeedPostFormWithPreview.ScrollArea>
          <Form.Label>PREVIEW</Form.Label>
          <OrganizationFeedPost {...{
            disabled: true,
            post: publishOrganizationForumPost({
              post: {
                ...post,
                ancestors: []
              },
              posterUserDid: myPublicProfileDid,
              visibleTo: value.visibleTo,
            }),
          }}/>
        </FeedPostFormWithPreview.ScrollArea>
        <FeedPostFormWithPreview.ButtonRow>
          {hasVisibleToOptions &&
            <Fragment>
              {form.bindInput({
                valueProp: 'visibleTo',
                placeholder: 'Visible To',
                input: <FeedPostVisibilityDropdown />,
                min: visibilityRange.min,
                max: visibilityRange.max,
              })}
            </Fragment>
          }
          <Button
            disabled={form.disabled}
            type="normal"
            value="cancel"
            onClick={onClose}
          />
          {form.submitButton({
            value: 'Publish',
            submittingValue: 'Publishing…',
          })}
        </FeedPostFormWithPreview.ButtonRow>
      </FeedPostFormWithPreview>
    )
  }}/>
})
