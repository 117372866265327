import { h, Fragment } from 'preact'
import { memo, useState, useEffect} from 'preact/compat'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import usePrevious from 'lib/usePreviousHook'
import classNames from 'lib/classNames'
import { useLocation } from 'lib/locationResource'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import Header from 'components/Header'
import OrganizationIcon from 'components/OrganizationIcon'
import IconRow from 'components/IconRow'
import IconRowList from 'components/IconRowList'
import Link from 'components/Link'
import OrganizationNameAndTagLine from 'components/OrganizationNameAndTagLine'
import OrganizationMembershipPills from 'components/OrganizationMembershipPills'
import { hrefToOrganization } from 'components/LinkToOrganization'
import Form from 'components/Form'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import Modal from 'components/Modal'

import './index.sass'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import { useRefState } from 'lib/stateHooks'
import { useOrganizationMembershipVisibility,
  useOrganizationMembershipsByDisplayOrder } from 'lib/membershipAppStateHooks'
export default function OrganizationList({
  className,
  error,
  organizations,
  filter,
  textFilter,
  map,
  sortBy,
  activeOrganizationApikey,
  onEmpty,
  onEmptyAfterTextFilter = `No ${PNFO.plural} match "${textFilter}"`,
  showMembershipIcon,
  showMembershipPills,
  animateChanges,
  organizationsLoading,
  isEditClickedDone,
  ...props
}) {
  if (error) return <ErrorMessage {...{ className, error }} />
  if (!organizations || organizationsLoading) return <Loading {...{ className, type: 'block' }} />

  organizations = organizations.map(organization => ({
    ...organization,
    active: organization.apikey === activeOrganizationApikey,
  }))

  if (filter) organizations = organizations.filter(filter)

  const empty = organizations.length === 0

  if (textFilter) textFilter = textFilter.toLowerCase().trim()
  if (textFilter) organizations = organizations.filter(organization =>
    `${organization.name} ${organization.tag_line}`.toLowerCase().includes(textFilter)
  )


  console.log("textFilter", textFilter)

  const emptyAfterTextFilter = organizations.length === 0

  if (map) organizations = organizations.map(map)
  if (sortBy) organizations.sort(sortBy)
  console.log("organizations2", organizations)

  return <IconRowList
    className={classNames('OrganizationList', { animateChanges, className })}
    {...props}
  >
    {
      empty ? (onEmpty ? <Empty>{onEmpty}</Empty> : null) :
      emptyAfterTextFilter ? <Empty>{onEmptyAfterTextFilter}</Empty> :
      <Members {...{
        organizations,
        showMembershipIcon,
        showMembershipPills,
        isEditClickedDone,
      }} />
    }
  </IconRowList>
}

OrganizationList.propTypes = {
  className: PropTypes.string,
  error: ErrorMessage.propTypes.error,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      apikey: PropTypes.string.isRequired,
      consumer_icon: PropTypes.string,
      name: PropTypes.string.isRequired,
      tag_line: PropTypes.string,
      buttons: PropTypes.node,
      subscription: PropTypes.object,
      membership: PropTypes.object,
      haveSISA: PropTypes.bool,
      hasPendingOrganizationMemberships: PropTypes.bool,
    })
  ),
  filter: PropTypes.func,
  textFilter: PropTypes.string,
  map: PropTypes.func,
  sortBy: PropTypes.func,
  activeOrganizationApikey: PropTypes.string,
  onEmpty: PropTypes.node,
  onEmptyAfterTextFilter: PropTypes.node,
  showMembershipIcon: PropTypes.bool,
  showMembershipPills: PropTypes.bool,
  animateChanges: PropTypes.bool,
  organizationsLoading: PropTypes.bool,
  isEditClickedDone: PropTypes.bool,
}

function Empty({ children }) {
  return <Header size="sm" centered italic className="OrganizationList-Empty">
    {children}
  </Header>
}

function Members({  isEditClickedDone, organizations, ...options }) {
  const organizationApikey = organizations[0]?.apikey
  const {
    updateMembershipsDisplayOrder
  } = useOrganizationMembershipsByDisplayOrder(organizationApikey, 'OrganizationList')

  const [organization, setorganizations] = useState(organizations)

  const dragItem = useRefState(null)
  const dragOverItem = useRefState(null)

  const handleSort = async () => {
    let _organization = [...organization]

    const draggedItemContext = _organization.splice(dragItem.current, 1)[0]

    _organization.splice(dragOverItem.current, 0, draggedItemContext)

    dragItem.current = null
    dragOverItem.current = null

    setorganizations(_organization)

    const displayOrder = _organization.map(org => org.apikey)

    try {
      await updateMembershipsDisplayOrder(displayOrder)
    } catch (errorUpdatingOrder) {
      console.error('Error updating membership display order:', errorUpdatingOrder)
    }
  }

  useEffect(() => {
    if (isEditClickedDone) {
      handleSort()
    }
  }, [isEditClickedDone])


  const members = organization.map((props, index) =>
    <div
      key={index}
      style={{ '--index': index }}
      draggable
      onDragStart={() => dragItem.current = index}
      onDragEnter={() => dragOverItem.current = index}
      onDragEnd={isEditClickedDone ? handleSort : undefined}
      onDragOver={(e) => e.preventDefault()}
    >
      <OrganizationList.Member {...{ ...options, ...props,  isEditClickedDone}} />
    </div>
  )

  const previousMembers = usePrevious(members)
  if (previousMembers) {
    // we have to keep the new members in the same sort order as the previous members
    // to ensure Preact doesnt create new dom nodes so the css animation works
    const keyOrder = previousMembers.map(x => x.key)
    members.sort((a, b) => keyOrder.indexOf(a.key) - keyOrder.indexOf(b.key))
  }

  if (members.length > 0)
    members[members.length - 1].props.className = "OrganizationList-Member-last"

  return(
    <div
      className="OrganizationList-Members"
      style={{ '--length': members.length }}
    >
      {members}
    </div>
  )
}

OrganizationList.Member = memo(function({
  className,
  showMembershipPills,
  apikey,
  consumer_icon,
  name,
  tag_line,
  active,
  selected,
  membership,
  isEditClickedDone,
  hasPendingOrganizationMemberships,
  href = hrefToOrganization(apikey),
  buttons,
}) {
  const {
    hideMembership,
    unhideMembership,
  } = useOrganizationMembershipVisibility(apikey, membership?.memberUserDid, 'OrganizationList')
  const location = useLocation()
  const [passkeyModal, setPasskeyModal] = useState(false)
  const [isMemberVisible, setIsMemberVisible] = useState(!membership?.isHidden)
  const memberVisibilityClass = isMemberVisible ? 'visible' : 'hidden'

  const passModal = () => {
    setPasskeyModal(!passkeyModal)
  }


  useEffect(() => {
    setIsMemberVisible(!membership?.isHidden)
  }, [membership?.isHidden])


  const toggleMemberVisibility = async () => {
    if (isMemberVisible) {
      await hideMembership()
    } else {
      await unhideMembership()
    }
    setIsMemberVisible(oldState => !oldState)
  }

  return(
    <div className={`OrganizationList-Member ${memberVisibilityClass}`}>
      <IconRow {...{
        drag: isEditClickedDone && <IconButton type={"drag"}/>,
        className: classNames('OrganizationList-Member', {
          className, active, selected,
        }),
        href,
        icon: <OrganizationIcon
          size="sm"
          organization={{ apikey, name, consumer_icon }}
          brandingType="consumer"
        />,
        row: <OrganizationNameAndTagLine
          classNamePrefix="OrganizationList-Member"
          organizationName={name}
          organizationTagLine={tag_line}
        />,
        buttons: <Fragment>
          {hasPendingOrganizationMemberships &&
        <Link href={`/${apikey}/members`} tabIndex={-1}>❗</Link>
          }
          {location.pathname === '/admin/dashboard' ? <p className="admin_btn">super admin</p> :
          <p>  {showMembershipPills && membership &&
          <OrganizationMembershipPills {...{ organizationMembership: membership }} />}
          </p>}
          {buttons}
          {location.pathname === '/admin/dashboard' && <Icon type="delete" onclick={passModal} />}

          {passkeyModal && <Modal>
            <p className="passkey_center"> Confirm password</p>
            <Form>
              <Form.Item>
                <Form.Label>Password</Form.Label>
                <TextInput type="password" autocomplete="current-password" />
              </Form.Item>
              <Form.ButtonRow>
                <Button type="primary" submit>Submit</Button>
              </Form.ButtonRow>
            </Form>
          </Modal>}

        </Fragment>,
        eye:isEditClickedDone && <IconButton
          type={isMemberVisible ? 'eye' : 'eyeoff'}
          onClick={toggleMemberVisibility}/>
      }} />
    </div>
  )
})
