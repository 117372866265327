export const sortByOptions = (people, selectedOption) => {
  if (selectedOption === 'AToZ') {
    people.sort((a, b) => {
      return a.publicProfile.username.localeCompare(b.publicProfile.username || '')
    })
  }
  if (selectedOption === 'ZToA') {
    people.sort((a, b) => {
      return b.publicProfile.username.localeCompare(a.publicProfile.username || '')
    })
  }
  if (selectedOption === 'Oldest') {
    people.sort((a, b) => {
      return new Date(a.organizationMembership.createdAt) - new Date(b.organizationMembership.createdAt)
    })
  }
  if (selectedOption === 'Newest') {
    people.sort((a, b) => {
      return new Date(b.organizationMembership.createdAt) - new Date(a.organizationMembership.createdAt)
    })
  }
  return people
}

export const sortRequestsByOptions = (people, selectedOption) => {
  if (selectedOption === 'AToZ') {
    people.sort((a, b) => {
      return a.username.localeCompare(b.username || '')
    })
  }
  if (selectedOption === 'ZToA') {
    people.sort((a, b) => {
      return b.username.localeCompare(a.username || '')
    })
  }
  if (selectedOption === 'Oldest') {
    people.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt)
    })
  }
  if (selectedOption === 'Newest') {
    people.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt)
    })
  }
  return people
}

export const filterByOptions = (people, filter) => {
  if (filter === 'Active') {
    return people.filter(person => person.organizationMembership.acceptedAt)
  }
  if (filter === 'Former') {
    return people.filter(person => person.isFormer)
  }
  if(filter === 'Paused'){
    return people.filter(person => person.isMuted)
  }
  return people
}
