/* eslint-disable max-len */
import PNFO from 'jlinc-shared/PNFO'

import PathnameRouter from 'lib/PathnameRouter'

import RedirectPage from './pages/RedirectPage'
import PageLoadingPage from './pages/PageLoadingPage'
import DataPage from './pages/DataPage'
import AuthPage from './pages/AuthPage'
import SearchPage from './pages/SearchPage'
import ChatPage from './pages/ChatPage' // TODO: Original One
//import NotificationChatPage from './pages/NotificationChatPage'
import UserProfilePage from './pages/UserProfilePage'
import OrganizationPage from './pages/OrganizationPage'
import OrganizationJoinPage from './pages/OrganizationJoinPage'
import HubsPage from './pages/HubsPage'
import OrganizationFeedPostPage from './pages/OrganizationFeedPostPage'
import SuperAdminPage from './pages/SuperAdminPage'

// Lazy loaded pages
const lazy = loader => PageLoadingPage.forImport(loader)
const HomePage
  = lazy(() => import(/* webpackChunkName: "HomePage" */ './pages/HomePage'))
const SettingsPage
  = lazy(() => import(/* webpackChunkName: "SettingsPage" */ './pages/SettingsPage'))
const SecurityPage
  = lazy(() => import(/* webpackChunkName: "SecurityPage" */ './pages/SecurityPage'))
const OrganizationCreatePage
  = lazy(() => import(/* webpackChunkName: "OrganizationCreatePage" */ './pages/OrganizationCreatePage'))
const FaqPage
  = lazy(() => import(/* webpackChunkName: "FaqPage" */ './pages/FaqPage'))
const AdminDashboardPage
  = lazy(() => import(/* webpackChunkName: "AdminDashboardPage" */ './pages/AdminDashboardPage'))
const SISAInvitePage
  = lazy(() => import(/* webpackChunkName: "sisas" */ './pages/SISAInvitePage'))
const MyDataPage
  = lazy(() => import(/* webpackChunkName: "sisas" */ './pages/MyDataPage'))
const MyDataDefaultsPage
  = lazy(() => import(/* webpackChunkName: "sisas" */ './pages/MyDataDefaultsPage'))
const MySISAsPage
  = lazy(() => import(/* webpackChunkName: "sisas" */ './pages/MySISAsPage'))
const ExternalPage
  = lazy(() => import(/* webpackChunkName: "sisas" */ './pages/ExternalPage'))


// Page Options
// const requireNotLoggedIn = true
const requireLoggedIn = true
// const noLayout = true

export default new PathnameRouter(map => {
  map('/__data', DataPage, {})
  map('/admin/dashboard', AdminDashboardPage, { requireLoggedIn })

  map('/regauth/:inviteToken', SISAInvitePage, {})
  map('/', isDataYogi ? RedirectPage.redirectTo(`/${PNFO.plural}`) : HomePage)
  map(`/${PNFO.singular}/new/:path*`, OrganizationCreatePage, {requireLoggedIn})
  map(`/${PNFO.plural}/(subscriber|all|favorites)?`, HubsPage, {})
  map('/superadmin',                           SuperAdminPage, {})

  map(`/search/:searchQuery`, SearchPage, {})
  map(`/search/(${PNFO.plural}|members|posts)/:searchQuery`, SearchPage, {})

  map('/(login|logout|signup|join)',                        AuthPage,                    {})
  map('/:organizationApikey/join/:inviteToken?',            OrganizationJoinPage,        {})
  map('/faq',                                               FaqPage,                     {})
  map('/chat/:path*',                                              ChatPage,                    {requireLoggedIn})// the original one
  //map('/chat',                                              NotificationChatPage,                    {requireLoggedIn})
  map('/my-data',                                           MyDataPage,                  {requireLoggedIn})
  map('/my-data/defaults',                                  MyDataDefaultsPage,          {requireLoggedIn})
  map('/my-data/sisas',                                     MySISAsPage,                 {requireLoggedIn})
  map('/settings',                                          SettingsPage,                {requireLoggedIn})
  map('/security',                                          SecurityPage,                {requireLoggedIn})
  map('/external',                                          ExternalPage,                {})
  map('/@:username',                                        UserProfilePage,             {})
  map('/@:username/:path*',                                 UserProfilePage,             {})
  map('/:organizationApikey',                               OrganizationPage,            {})
  map('/:organizationApikey/published/:feedPostUid',        OrganizationFeedPostPage,    {})
  map('/:organizationApikey/:path*',                        OrganizationPage,            {})
})
