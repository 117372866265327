import { h } from 'preact'
import { useRef, useCallback, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import {
  disableDocumentScrolling,
  enableDocumentScrolling,
} from 'lib/modalHelpers'

import ContextPortal from 'components/ContextPortal'
import './index.sass'

export default function ContextMenu({
  disabled,
  className,
  rightAligned,
  menuRef,
  anchorRef,
  open,
  onClose,
  closeOnWindowBlur,
  children,
  ...props
}){
  return <ContextPortal {...{
    className: classNames('ContextMenu', {className}),
    isOpen: open,
    onClose,
    closeOnWindowBlur,
  }}>
    <Menu {...{
      ref: menuRef,
      disabled,
      close: onClose,
      rightAligned,
      buttonRef: anchorRef,
      children,
      ...props
    }}/>
  </ContextPortal>
}

ContextMenu.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  rightAligned: PropTypes.bool,
  menuRef: PropTypes.object,
  anchorRef: PropTypes.object,
  open: ContextPortal.propTypes.open,
  onClose: ContextPortal.propTypes.onClose,
  closeOnWindowBlur: ContextPortal.propTypes.closeOnWindowBlur,
  children: PropTypes.node,
  onKeyDown: PropTypes.func,
}

function Menu({ rightAligned, buttonRef, children, ...props }){
  const menuRef = useRef()

  const reposition = useCallback(
    () => {
      const button = buttonRef.current
      const menu = menuRef.current
      if (!button || !menu) return
      const viewportHeight = Math.max(
        window.document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      )
      const viewportWidth = Math.max(
        window.document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
      const {
        // top: buttonTop,
        bottom: buttonBottom,
        // right: buttonRight,
        left: buttonLeft,
        width: buttonWidth,
      } = button.base.getBoundingClientRect()
      const {
        height: menuHeight,
        width: menuWidth
      } = menu.getBoundingClientRect()

      let top = buttonBottom
      let left = buttonLeft

      if (rightAligned){
        left = left - menuWidth + buttonWidth
      }

      if (top < 0) top = 0
      else if (top > viewportHeight - menuHeight) {
        top = viewportHeight - menuHeight
        // if were moving the menu up, also movie it over
        if (rightAligned) {
          left -= buttonWidth
        }else{
          left += buttonWidth
        }
      }

      if (left < 0) left = 0
      else if (left > viewportWidth - menuWidth) {
        left = viewportHeight - menuHeight
      }

      menu.style.top = `${top}px`
      menu.style.left = `${left}px`
      menu.style.visibility = 'visible'
    },
    [rightAligned]
  )

  useEffect(
    () => {
      reposition()
      disableDocumentScrolling()
      window.addEventListener('resize', reposition)
      const observer = new global.MutationObserver(reposition)
      observer.observe(
        menuRef.current,
        { attributes: true, childList: true, subtree: true },
      )
      return () => {
        observer.disconnect()
        window.removeEventListener('resize', reposition)
        enableDocumentScrolling()
      }
    },
    [reposition],
  )

  return <div {...{
    ...props,
    ref: menuRef,
    className: 'ContextMenu-Menu',
  }}>
    {children}
  </div>
}


ContextMenu.Hr = function(){
  return <span className="ContextMenu-Hr"/>
}
