import api from '../api'

export async function createReport(covidReport){
  const creatingKey = `covidReports:creating`
  const errorKey = `${creatingKey}:error`
  const reportsKey = 'covidReports'
  if (this.getState()[creatingKey]) return

  this.setState({
    [creatingKey]: true,
    [errorKey]: undefined,
  })

  try {
    const { covidReport: createdCovidReport } = await api.createCovidReport(covidReport)
    this.setState({
      [reportsKey]: [
        createdCovidReport,
        ...(this.getState()[reportsKey] || []),
      ],
    })
  } catch(error) {
    this.setState({ [errorKey]: error })
  } finally {
    this.setState({ [creatingKey]: undefined })
  }
}

export async function loadReports() {
  const loadingKey = 'covidReports:loading'
  const errorKey = `${loadingKey}:error`
  const reportsKey = 'covidReports'
  if (this.getState()[loadingKey]) return

  this.setState({
    [loadingKey]: true,
    [errorKey]: undefined,
  })

  try {
    const { covidReports } = await api.getCovidReports()
    this.setState({ [reportsKey]: covidReports })
  } catch(error) {
    this.setState({
      [reportsKey]: null,
      [errorKey]: error,
    })
  } finally {
    this.setState({ [loadingKey]: undefined })
  }
}
