import { h } from 'preact'
import Letterbox from 'components/Letterbox'
import './index.sass'


export function MediaAttachment({ type, url, height, width }) {
  const props = { src: url }
  return <Letterbox {...{ height, width }}>
    {type === 'image' ? <img {...props} /> :
    type === 'video' ? <video controls muted loading="lazy" {...props} /> :
    type === 'audio' ? <audio controls muted loading="lazy" {...props} /> :
    null}
  </Letterbox>
}
