import { h, Fragment } from 'preact'
import { useRef } from 'preact/hooks'
import { memo } from 'preact/compat'

import classNames from 'lib/classNames'
import useToggle from 'lib/useToggleHook'
import { useNotifications } from 'lib/notificationsHooks'

import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import NotificationsModal from 'components/NotificationsModal'
import NotificationBadge from 'components/NotificationBadge'
import './index.sass'

export default function NotificationsButton(props){
  let { numberOfUnreadNotifications } = useNotifications('NotificationsButton')
  return <Content {...{ numberOfUnreadNotifications, ...props }}/>
}

const Content = memo(({
  numberOfUnreadNotifications,
  className,
  type = 'notifications',
  size = 'lg',
  ...props
}) => {
  const ref = useRef()
  const [isOpen, open, close] = useToggle(false)
  return <Fragment>
    <IconButton {...{
      ...props,
      className: classNames('NotificationsButton', { className }),
      ref,
      onClick: open,
    }}>
      <Icon {...{type, size}}/>
      <NotificationBadge topRight total={numberOfUnreadNotifications}/>
    </IconButton>
    <NotificationsModal rightAligned {...{
      open: isOpen,
      onClose: close,
      anchorRef: ref,
    }}/>
  </Fragment>
})
