import {Fragment, h} from "preact"
import classNames from "lib/classNames"
import Icon from "components/Icon"
import Header from "components/Header"
import FileSize from "components/FileSize"
import {MediaAttachment} from "../../a-portal/pages/OrganizationChatPage/MediaAttachment"

export default function FileAttachment({
  type,
  url,
  name,
  size,
  height,
  width,
  imageGroup,
  handleClickImage
}) {
  const isMedia = type && type.match(/(image|video|audio)/i)
  const className = classNames("OrganizationChatPage-FileAttachment", {
    media: isMedia || (imageGroup && imageGroup.length > 0),
    file: !isMedia && !(imageGroup && imageGroup.length > 0),
  })
  let image
  if (imageGroup && imageGroup.message) {
    try {
      const jsonString = imageGroup.message.substring(
        imageGroup.message.indexOf("{"),
        imageGroup.message.lastIndexOf("}") + 1
      )
      image = JSON.parse(jsonString)
    } catch (error) {
      console.error("Error parsing JSON from message:", error)
      image = null
    }
  }


  return (
    <Fragment>
      {imageGroup ? (
        <div className="image-group-container" onClick={() => handleClickImage()}>
          <img src={image.url}  />
        </div>
      ) : (
        <div onClick={() => handleClickImage()} className={className}>
          {isMedia ? (
            <MediaAttachment
              {...{
                type: isMedia[0].toLowerCase(),
                url,
                height,
                width,
              }}
            />
          ) : (
            <Fragment>
              <Icon size="lg" type="brandposts" />
              <div className="OrganizationChatPage-fileInfo">
                <Header size="lg">{name}</Header>
                <Header size="md">
                  <FileSize {...{size}} />
                </Header>
              </div>
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  )
}
