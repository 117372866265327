import api from '../api'
import { startRegistration } from '@simplewebauthn/browser'

export async function add(){
  if (this.getState().addingPasskey) return

  this.setState({
    addingPasskey: true,
    gettingPasskeysError: undefined,
    addingPasskeyError: undefined,
    deletingPasskeyError: undefined,
  })

  try {
    const options = await api.webauthRegistration()
    const regOptions = options.regOptions
    regOptions.authenticatorSelection.residentKey = 'required'
    regOptions.authenticatorSelection.requireResidentKey = true
    regOptions.extensions = {
      credProps: true,
    }

    const authRes = await startRegistration(regOptions)

    const { passkey } =  await api.addPasskey({ authRes })
    const passkeys = [
      ...this.getState().passkeys,
      passkey,
    ]
    this.setState({
      passkeys,
    })

  } catch(addingPasskeyError) {
    this.setState({ addingPasskeyError })
  } finally {
    this.setState({ addingPasskey: undefined })
  }
}


export async function load() {
  if (this.getState().gettingPasskeys) return

  this.setState({
    gettingPasskeys: true,
    gettingPasskeysError: undefined,
    addingPasskeyError: undefined,
    deletingPasskeyError: undefined,
  })

  try {
    const { webauthns } = await api.getPasskeys()
    console.log("webauthns", webauthns)
    this.setState({ passkeys:webauthns })
  } catch(gettingPasskeysError) {
    this.setState({ gettingPasskeysError })
  } finally {
    this.setState({ gettingPasskeys: undefined })
  }
}

async function _delete({ credentialID }){
  if (this.getState().deletingPasskey) return

  this.setState({
    deletingPasskey: credentialID,
    gettingPasskeysError: undefined,
    addingPasskeyError: undefined,
    deletingPasskeyError: undefined,
  })

  try {
    await api.deletePasskey({ credentialID })
    const passkeys = [...this.getState().passkeys]
    const newpasskeys = passkeys.filter(x => x.credentialID !== credentialID)
    this.setState({ passkeys:newpasskeys })
  } catch(deletingPasskeyError) {
    this.setState({ deletingPasskeyError })
  } finally {
    this.setState({ deletingPasskey: undefined })
  }
}

export { _delete as delete }

