import { useEffect } from 'preact/hooks'

export default function useOnWindowBlur(onblur, ...args){
  useEffect(
    () => {
      if (!onblur) return
      const handler = (event, ...args) => {
        if (event.target !== window) return
        return onblur(event, ...args)
      }
      window.addEventListener('blur', handler, true)
      return () => {
        window.removeEventListener('blur', handler)
      }
    },
    [onblur, ...args],
  )
}
