import api from '../api'

export default function({organizationApikey, applyToDefaults = false, applyToAllOrgs = false}){
  const changesKey       = `organizationAccountDataStagedChanges:${organizationApikey}`
  const updatingKey      = `updatingOrganizationAccountData:${organizationApikey}`
  const errorUpdatingKey = `updatingOrganizationAccountDataError:${organizationApikey}`

  const changes = this.getState()[changesKey]
  if (!changes) return

  this.setState({
    [updatingKey]: true,
    [errorUpdatingKey]: undefined,
  })

  return (
    applyToAllOrgs
      ? this.takeAction('updateAllOrganizationAccountData', { changes })
      : this.takeAction('updateOrganizationsAccountData', { organizationApikeys: [organizationApikey], changes })
  )
    .then(() => {
      this.setState({
        [updatingKey]: undefined,
        [changesKey]: undefined,
      })
      if (!applyToDefaults) return
      return api.updateDefaultAccountData({ defaultAccountData: changes })
        .then(defaultAccountData => {
          this.setState({ defaultAccountData })
        })
        .catch(error => {
          this.setState({ updatingDefaultAccountDataError: error })
        })
    })
    .catch(updatingOrganizationAccountDataError => {
      this.setState({
        [updatingKey]: undefined,
        [errorUpdatingKey]: updatingOrganizationAccountDataError,
      })
    })
}
