import { h } from 'preact'
import PropTypes from 'prop-types'

import Form from 'components/Form'
import './index.sass'

export default function CropImageInputFormRow({
  className = '',
  cropImageInput,
  children,
  ...props
}){
  return <Form.Row {...props} className={`CropImageInputFormRow ${className}`}>
    <Form.Item>{cropImageInput}</Form.Item>
    <div>{children}</div>
  </Form.Row>
}

CropImageInputFormRow.propTypes = {
  className: PropTypes.string,
  cropImageInput: PropTypes.node,
  children: PropTypes.node,
}
