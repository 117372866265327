import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Button from 'components/Button'
import Icon from 'components/Icon'

import './index.sass'

export default class ButtonWithDropdown extends Component {
  static propTypes = {
    buttonValue: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      'normal',
      'success',
      'primary',
    ]).isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    dropdownOptions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }).isRequired
    ).isRequired,
  }

  componentDidMount() {
    document.body.addEventListener('click', this.clickOnBody)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.clickOnBody)
  }

  state = { open: false, activeOptionIndex: null }

  clickOnBody = event => { if (!this.buttonWithDropdown.contains(event.target)) { this.close() } }

  close = () => {
    this.setState({ open: false })
  }

  toggleDropdown = () => {
    if (this.props.disabled) return
    this.setState({ open: !this.state.open })
  }

  selectOption = onClick => {
    onClick()
    this.close()
  }

  handleKeyDown = event => {
    const { dropdownOptions } = this.props
    const { activeOptionIndex } = this.state

    if (event.code === 'ArrowUp' && activeOptionIndex > 0) {
      event.preventDefault()
      this.setState({activeOptionIndex: activeOptionIndex - 1})
    } else if (event.code === 'ArrowDown') {
      event.preventDefault()
      if (activeOptionIndex === null) {
        this.setState({activeOptionIndex: 0})
      } else if (activeOptionIndex < dropdownOptions.length) {
        this.setState({activeOptionIndex: activeOptionIndex + 1})
      }
    } else if (event.code === 'Enter') {
      event.preventDefault()
      if (dropdownOptions[activeOptionIndex]) {
        this.selectOption(dropdownOptions[activeOptionIndex].onClick)
      } else {
        this.close()
      }
    } else if (event.code === 'Escape') {
      this.close()
    }
  }

  render(){
    const {
      dropdownOptions,
      buttonValue,
      className = '',
      disabled,
      onClick,
      type,
    } = this.props
    const { open } = this.state

    const dropdownMenu = dropdownOptions.map(({ title, onClick }, index) => {
      const { activeOptionIndex } = this.state
      let optionClassName = `ButtonWithDropdown-option`
      if (activeOptionIndex === index) optionClassName += ' ButtonWithDropdown-option-active'

      return <div
        onClick={() => this.selectOption(onClick)}
        className={optionClassName}
      >
        { title }
      </div>
    })

    let wrapperClass = `ButtonWithDropdown ButtonWithDropdown-${type} ${className}`
    if (open) wrapperClass += ' ButtonWithDropdown-open'
    if (disabled) wrapperClass += ' ButtonWithDropdown-disabled'

    let iconContainerClass = `ButtonWithDropdown-iconContainer ButtonWithDropdown-iconContainer-${type}`
    if (disabled) {
      iconContainerClass += ` ButtonWithDropdown-iconContainer-${type}-disabled`
      iconContainerClass += ` ButtonWithDropdown-iconContainer-disabled`
    }

    return <div
      className={wrapperClass}
      onKeyDown={this.handleKeyDown}
      ref={node => {this.buttonWithDropdown = node}}
      tabIndex="0"
    >
      <Button
        className="ButtonWithDropdown-button"
        value={buttonValue}
        disabled={disabled}
        onClick={onClick}
        type={type}
      />
      <div
        className={iconContainerClass}
        onClick={this.toggleDropdown}
      >
        <Icon className="ButtonWithDropdown-collapseIcon" type="down-open" />
      </div>
      <TransitionGroup>
        {open && <CSSTransition classNames="ButtonWithDropdown-slide" timeout={200}>
          <div className="ButtonWithDropdown-dropdownMenu">
            {dropdownMenu}
          </div>
        </CSSTransition>}
      </TransitionGroup>
    </div>
  }
}
