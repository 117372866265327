import { h } from 'preact'
import { usePublicProfiles } from 'lib/membershipAppStateHooks'
import { useChatChannel } from 'lib/chatHooks'
import EndUserDisplayNameList from 'components/EndUserDisplayNameList'
import { ChatListMember } from './ChatListMember'
import './index.sass'
import EndUserDMAvatar from 'components/EndUserDMAvatar'


export function DMChatListMember({
  isSelected, forwarding, onToggleSelection, organization, memberUserDids, myPublicProfile, ...props
}) {
  const otherUserDids = memberUserDids.filter(did => did !== myPublicProfile.did)
  const { publicProfiles } = usePublicProfiles(otherUserDids, 'OrganizationChatPage')


  const {
    chatChannel,
  } = useChatChannel(props.uid, null, 'OrganizationChatPage')

  const chatImage = chatChannel.imageUrl
  return <ChatListMember {...{
    ...props,
    organization,
    myPublicProfile,
    isSelected,
    forwarding,
    onToggleSelection,
    name: chatChannel.name || <EndUserDisplayNameList noLinks {...{
      publicProfiles,
    }} />,
    avatar: <EndUserDMAvatar publicProfiles={publicProfiles} chatImage={chatImage} size="sm"  />,
    uid: chatChannel.uid,
  }} />
}
