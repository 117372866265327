import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import {
  publicProfileToDisplayName,
  publicProfileToPathname,
} from 'lib/publicProfiles'

import Link from 'components/Link'
import EndUserAvatar from 'components/EndUserAvatar'
import IconRow from 'components/IconRow'
import SingleLine from 'components/SingleLine'
import './index.sass'

export default function PeopleListMember({
  className,
  disabled,
  publicProfile = {},
  href,
  suffix,
  linkToProfile = false,
  isMuted,
  ...props
}){
  if (linkToProfile) href = publicProfileToPathname(publicProfile)
  return <IconRow {...{
    ...props,
    disabled,
    href,
    className: classNames('PeopleListMember', { className }),
    icon: <EndUserAvatar size="sm" publicProfile={publicProfile} />,
    linkRow: false,
    row: <Fragment>
      <Link {...{ href, className: 'PeopleListMember-name' }}>
        <SingleLine>{publicProfileToDisplayName(publicProfile)}</SingleLine>
        {isMuted && <span className="InterfaceHelp">
          <span className="Pill Pill-info " style={{margin:'0 0.4rem'}}>Paused</span> </span>}
      </Link>
      &nbsp;
      {suffix}
      <Link {...{ href, className: 'PeopleListMember-space' }}/>
    </Fragment>,
    organizationMembership: undefined,
  }}/>
}

PeopleListMember.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isMuted:PropTypes.bool,
  publicProfile: PropTypes.shape({
    username: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
  }),
  href: IconRow.propTypes.href,
  suffix: PropTypes.node,
  linkToProfile: PropTypes.bool,
  organizationMemberhip: PropTypes.object,
}
