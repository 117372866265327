import { h } from 'preact'
import classNames from 'lib/classNames'
import ChatChannelListMember from 'components/ChatChannelListMember'
import './index.sass'

export function ChatListMember({ isSelected, onToggleSelection, uid, forwarding,
  unread = 0, latestAt, preview, selected, href, avatar, name, showDropDown, isPersonalChatPage, synopsis, ...props
}) {
  return <div><ChatChannelListMember {...{
    className: classNames('OrganizationChatPage-ChatListMember', { selected, unread }),
    date: latestAt,
    preview,
    avatar,
    name,
    href,
    showDropDown,
    isPersonalChatPage,
    synopsis,
    unread,
    isSelected,
    forwarding,
    onToggleSelection,
    uid,
    ...props,
    badge: <div className={`OrganizationChatPage-ChatListMember-unreadBadge ${unread ? 'unread' : '' }`}>{unread}</div>,
  }} />
  </div>
}
