import { h } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import classNames from 'lib/classNames'

import ButtonRow from 'components/ButtonRow'
import Button from 'components/Button'
import StyleishModal from 'components/StyleishModal'

export default function WhatIsASISAModal({ className, onClose, ...props }){

  return <StyleishModal {...{
    title: 'What is a Standard Information Sharing Agreement or SISA?',
    ...props,
    onClose,
    className: classNames('WhatIsASISAModal', { className }),
  }}>
    <p>
      A Standard Information Sharing Agreement (SISA) gives you a
      way to manage your personal data across the Internet by
      controlling exactly what each party may do with your
      information.
    </p>
    <p>
      {PNFO.plural} sign the SISA as the "Data Custodian", and
      agree to abide by the data sharing controls and permissions
      that you, the "Data Rights Holder" set using JLINC technology.
    </p>
    <p>
      {APP_NAME} automatically generates a digitally signed
      “SISA Event”, and stores a copy for you, each time you change
      any setting for your personal data, permissions, communication
      preference or marketing consent.
    </p>
    <p>
      A digital proof of each SISA Event is also written to a public
      audit record, which will determine the reputation of each
      Data Custodian under the SISA.
    </p>
    <p>
      By signing the SISA you agree to share your data under your
      control with this Data Custodian using {APP_NAME}
    </p>
    <ButtonRow>
      <Button type="normal" value="close" onClick={onClose}/>
    </ButtonRow>
  </StyleishModal>
}

WhatIsASISAModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}
