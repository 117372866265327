import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import EndUserAvatar from 'components/EndUserAvatar'
import './index.sass'


export default function EndUserAvatarInlineDisplay({
  className,
  publicProfiles,
  size,
  bordered,
  ...props
}){
  if (publicProfiles.length === 0) return null
  return (
    <div
      {...props}
      className={classNames('EndUserAvatarInlineDisplay', className, {[size]: true})}
    >
      {publicProfiles.map((publicProfile, index) => (
        <EndUserAvatar
          key={index}
          publicProfile={publicProfile}
          size={size}
          bordered={bordered}
        />
      ))}
    </div>
  )
}


EndUserAvatarInlineDisplay.propTypes = {
  className: PropTypes.string,
  publicProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: EndUserAvatar.propTypes.src,
    })
  ),
  size: EndUserAvatar.propTypes.size,
  bordered: EndUserAvatar.propTypes.bordered,
}
