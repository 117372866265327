import { h } from 'preact'

import classNames from 'lib/classNames'

import IconButton from 'components/IconButton'
import './index.sass'

export default function RemoveButton({ className, ...props }){
  return <IconButton {...{
    type: 'cancel-circled',
    ...props,
    className: classNames('RemoveButton', { className }),
  }}/>
}
