import { h } from 'preact'
import PropTypes from 'prop-types'

import useToggle from 'lib/useToggleHook'
import classNames from 'lib/classNames'

import HeaderWithButtons from 'components/HeaderWithButtons'
import Button from 'components/Button'
import Icon from 'components/Icon'
import './index.sass'
import ButtonWithDropdown from 'components/ButtonWithDropdown'

export default function HeaderedContent({
  collapsed,
  toggle,
  ...props
}){
  if (toggle && typeof collapsed === 'boolean') return <Content {...{
    ...props,
    collapsed,
    toggle,
  }} />

  return <NonControlledHeaderedContent {...{
    ...props,
    collapsed,
  }}/>
}

function NonControlledHeaderedContent({ collapsedByDefault, collapsable, ...props }) {
  const [collapsed, , , toggle] = useToggle(collapsable && collapsedByDefault)

  return <Content {...{
    ...props,
    collapsable,
    collapsed,
    toggle,
  }} />
}

function Content({
  padded = false,
  size = 'md',
  onClick,
  toggle,
  collapsable,
  children,
  buttons = [],
  className,
  icon,
  header,
  collapsed,
  interfaceHelp,
  sortByButton,
  filterByButton,
  setSelectedOption,
  setFilter,
  edit,
  ...props
}) {

  const filterDropdownOptions = [
    {
      title: 'Active Members',
      onClick: () => setFilter('Active')
    },
    {
      title: 'Paused Members',
      onClick: () => setFilter('Paused')
    },
    {
      title: 'Former Members',
      onClick: () => setFilter('Former')
    },
  ]

  const sortDropdownOptions = [
    {
      title: 'Sort A - Z',
      onClick: () => setSelectedOption('AToZ')
    },
    {
      title: 'Sort Z - A',
      onClick: () => setSelectedOption('ZToA')
    },
    {
      title: 'Sort Oldest to Newest',
      onClick: () => setSelectedOption('Oldest')
    },
    {
      title: 'Sort Newest to Oldest',
      onClick: () => setSelectedOption('Newest')
    },
  ]

  if(filterByButton && !collapsed) {
    buttons = [
      ...Array.from(buttons),
      <ButtonWithDropdown buttonValue="Filter by" type="normal"
        dropdownOptions={filterDropdownOptions}
        className="HeaderedContent-filterby-text">
        Filter by
      </ButtonWithDropdown>,
    ]
  }

  if(sortByButton && !collapsed) {
    buttons = [
      ...Array.from(buttons),
      <ButtonWithDropdown buttonValue="Sort by" type="normal"
        dropdownOptions={sortDropdownOptions}
        className="HeaderedContent-sortby-text">
        Sort by
      </ButtonWithDropdown>,
    ]
  }

  if (collapsable){
    buttons = [
      ...Array.from(buttons),
      <Button className="HeaderedContent-collapse">
        <Icon onClick={toggle} type="right-open" rotate={collapsed ? 0 : 90} transitionRotation/>
      </Button>,
    ]
  }


  const hasContent = !collapsed && typeof children === 'object'
  return <div {...{
    className: classNames(
      'HeaderedContent',
      { className, padded, collapsed: (collapsable ? !!collapsed : undefined) }
    ),
    ...props
  }}>
    <HeaderWithButtons {...{
      buttons,
      size,
      underlined: hasContent,
      onClick,
      interfaceHelp,
    }}>
      {icon && <Icon type={icon} blue size="lg" className="HeaderedContent-icon"/>}
      {header}
      {edit}
    </HeaderWithButtons>
    <div className="HeaderedContent-content">{children}</div>
  </div>
}

HeaderedContent.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  header: PropTypes.string,
  buttons: PropTypes.node,
  edit: PropTypes.node,
  collapsable: PropTypes.bool,
  collapsedByDefault: PropTypes.bool,
  children: PropTypes.node,
  size: HeaderWithButtons.propTypes.size,
  onClick: PropTypes.func,
  toggle: PropTypes.func,
  padded: PropTypes.bool,
  collapsed: PropTypes.bool,
  interfaceHelp: PropTypes.node,
}
