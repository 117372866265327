import { h } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import SwitchListWithDescription from 'components/SwitchListWithDescription'
import CategorizedConsentsList from 'components/CategorizedConsentsList'
import Header from 'components/Header'

import {
  getAliceConsentDescriptionsWithOrgName,
  getCCPAConsents,
  CONSENTS_PROP_TYPE,
  getConsentsByCategory,
} from 'lib/accountDataSpec'

export default function AliceConsentsForm({ className = '', type, useOrgNameInDescriptions, ...props }) {
  props.className = `AliceConsentsForm ${className}`
  props.organization = props.organization || {}
  props.consentDescriptions = getAliceConsentDescriptionsWithOrgName({
    organizationName: useOrgNameInDescriptions ? props.organization.name : 'The organisation',
  })

  if (type === 'GDPR') return <GDPRConsents {...props} />
  if (type === 'CCPA') return <CCPAConsents {...props} />
}

AliceConsentsForm.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  consents: CONSENTS_PROP_TYPE.isRequired,
  stagedChanges: CONSENTS_PROP_TYPE,
  onChange: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  useOrgNameInDescriptions: PropTypes.bool,
  type: PropTypes.oneOf([
    'GDPR',
    'CCPA',
  ]).isRequired,
}

function CCPAConsents({
  className,
  disabled,
  consents,
  onChange,
  stagedChanges = {},
  organization,
  consentDescriptions,
}) {
  className = `AliceCCPAConsentsForm ${className}`
  const CCPAConsents = getCCPAConsents({ requestedConsents: organization.consents })

  if (CCPAConsents.length === 0) {
    return <Header className={className} size="xs">
      This {PNFO.singular} is not requesting any CCPA permissions.
    </Header>
  }

  const switchesAndText = CCPAConsents.map(consent => {
    const unsaved = consent in stagedChanges
    const checked = unsaved
      ? !!stagedChanges[consent]
      : !!consents[consent]
    return {
      title: consent,
      description: consentDescriptions[consent],
      checked,
      unsaved,
    }
  })

  return <SwitchListWithDescription
    className={className}
    onSwitchChange={onChange}
    switchesAndText={switchesAndText}
    disabled={disabled}
  />
}

function GDPRConsents({
  className,
  disabled,
  consents,
  onChange,
  stagedChanges = {},
  organization,
  consentDescriptions,
}) {
  className = `AliceGDPRConsentsForm ${className}`
  const consentsByCategory = getConsentsByCategory({ requestedConsents: organization.consents })

  if (Object.keys(consentsByCategory).length === 0) {
    return <Header className={className} size="xs">
      This {PNFO.singular} is not requesting any GDPR permissions.
    </Header>
  }

  return <CategorizedConsentsList
    className={className}
    changes={stagedChanges}
    onChange={onChange}
    disabled={disabled}
    enabledConsents={consents}
    descriptions={consentDescriptions}
    consentsByCategory={consentsByCategory}
  />
}
