import { useCallback } from 'preact/hooks'

export function useConfirmationBefore(work, message){
  return useCallback(
    (...args) => {
      if (!confirm(message)) return // eslint-disable-line no-alert
      return work(...args)
    },
    [work, message]
  )
}
