import { useState, useEffect } from 'preact/hooks'
import capitalize from 'lib/capitalize'


let currentMediaBreakpoint
export function getMediaBreakpoint(){
  const style = global.getComputedStyle(global.document.body)
  let name = style.getPropertyValue('--media-breakpoint-name')
  if (name.match(/"(.+)"/)) name = RegExp.$1
  if (!currentMediaBreakpoint || currentMediaBreakpoint.name !== name){
    currentMediaBreakpoint = { name, ...decorations(name) }
  }
  return currentMediaBreakpoint
}

export function useMediaBreakpoint(){
  const [value, update] = useState(currentMediaBreakpoint)
  useEffect(
    () => {
      subscribe(update)
      return () => { unsubscribe(update) }
    },
    [],
  )
  return value
}

const breakpoints = `phone phablet tablet desktop`.split(' ').reverse()
const decorations = name => {
  const d = {}
  const currentIndex = breakpoints.indexOf(name)
  breakpoints.forEach((breakpoint, index) => {
    const name = capitalize(breakpoint)
    d[`narrowerThan${name}`] = index <= currentIndex
    d[`widerThan${name}`] = index > currentIndex
  })
  return d
}

const subscribers = new Set()

const refresh = () => {
  const current = currentMediaBreakpoint
  if (getMediaBreakpoint() !== current){
    subscribers.forEach(subscriber => { subscriber(currentMediaBreakpoint) })
  }
}
refresh()
window.addEventListener('resize', refresh)

const subscribe = handler => {
  subscribers.add(handler)
  refresh()
}
const unsubscribe = handler => {
  subscribers.delete(handler)
}
