import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import IconButton from 'components/IconButton'
import OrganizationList from 'components/OrganizationList'
import './index.sass'

export default function SortableOrganizationList({
  className,
  organizations,
  value = [],
  onChange,
  disabled = false,
  editing = true,
  ...props
}){
  const order = sortOrganizationApikeysBySubscribedToOrder(
    value, organizations.map(o => o.apikey)
  )
  const minIndex = 0
  const maxIndex = order.length - 1

  const moveOrganization = (organizationApikey, delta) => {
    const newValue = organizations.map(o => o.apikey)
    const index = newValue.indexOf(organizationApikey)
    newValue.splice(index, 1)
    newValue.splice(index + delta, 0, organizationApikey)
    onChange(newValue)
  }

  organizations = order.map(organizationApikey => {
    const organization = organizations.find(o => o.apikey === organizationApikey)
    const buttons = organization.buttons || []
    let index = value.indexOf(organization.apikey)
    if (index === -1) index = Infinity
    if (editing){
      buttons.unshift(
        <IconButton
          className="SortableOrganizationList-up"
          key="up"
          value="▲"
          disabled={disabled || index === minIndex}
          onClick={() => { moveOrganization(organizationApikey, -1) }}
        />,
        <IconButton
          className="SortableOrganizationList-down"
          key="down"
          value="▼"
          disabled={disabled || index >= maxIndex}
          onClick={() => { moveOrganization(organizationApikey, 1) }}
        />,
      )
    }
    return {...organization, index, buttons}
  })

  return <OrganizationList {...{
    animateChanges: true,
    ...props,
    className: classNames('SortableOrganizationList', { className }),
    organizations,
    sortBy: null,
  }}/>
}

SortableOrganizationList.propTypes = {
  className: PropTypes.string,
  organizations: OrganizationList.propTypes.organizations,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  editing: PropTypes.bool,
}


export function sortOrganizationApikeysBySubscribedToOrder(
  organzationSubscribedToOrder,
  organizationApikeys,
){
  return organizationApikeys.sort((a, b) => {
    a = organzationSubscribedToOrder.indexOf(a)
    b = organzationSubscribedToOrder.indexOf(b)
    if (a === -1) a = Infinity
    if (b === -1) b = Infinity
    return a < b ? -1 : a > b ? 1 : 0
  })
}


export function sortOrganizationsBySubscribedToOrder(
  organzationSubscribedToOrder,
  organizations,
){
  return sortOrganizationApikeysBySubscribedToOrder(
    organzationSubscribedToOrder,
    organizations.map(o => o.apikey),
  ).map(apikey => organizations.find(o => o.apikey === apikey))
}
