export * from 'lib/locationResource'

import { Location } from 'lib/locationResource'

Object.assign(Location.prototype, {
  toLogin(){
    return linkToLoginOrSignup(this, '/login')
  },
  toSignup(){
    return linkToLoginOrSignup(this, '/signup')
  },
})

function linkToLoginOrSignup(location, pathname){
  return new Location({
    pathname,
    query: location.pathname.match(/^\/(login|signup)/i)
      ? {r: location.query.r}
      : {r: location},
  })
}
