export function createFetchMultiplexer(handler, delay = 1){
  const requests = []
  let pendingRequest

  function scheduler(id){
    return new Promise((resolve, reject) => {
      // TODO look at requests for existing reqiest and pigguback
      if (!pendingRequest) {
        pendingRequest = {}
        setTimeout(fetchPendingRequest, delay)
      }
      if (!pendingRequest[id]) pendingRequest[id] = []
      pendingRequest[id].push([resolve, reject])
    })
  }

  function fetchPendingRequest(){
    const request = pendingRequest
    pendingRequest = undefined
    requests.push(request)
    const ids = Object.keys(request)

    const emitResult = (id, result) => {
      if (!(id in request)) {
        console.warn('UNEXPECTED RESULT', id, result, 'request=', Object.keys(request))
        return
      }
      const listeners = request[id]
      delete request[id]
      const index = result instanceof Error
        ? 1 // reject
        : 0 // resolve
      listeners.forEach(l => { l[index](result) })
    }

    handler(ids, emitResult)
      .catch(error => {
        console.error('ERROR FETCHING VIEWS', error)
      })
      .then(() => {
        requests.splice(requests.indexOf(request), 1)
      })
  }

  return scheduler
}

