import { h } from 'preact'

import { setPageTitle } from 'lib/Page'

import FullPageCard from 'components/FullPageCard'
import Card from 'components/Card'
import APortalWelcomeModal from 'components/APortalWelcomeModal'

export default function WelcomePage({ setJustSignedUp }){
  const title = `Welcome to ${APP_NAME}`
  setPageTitle(title)
  return <FullPageCard className="WelcomePage">
    <Card.Body>
      <APortalWelcomeModal.Content {...{
        onClose: () => { setJustSignedUp(false) },
      }}/>
    </Card.Body>
  </FullPageCard>
}
