import { h } from 'preact'

import { useAppState } from 'lib/appState'

export default function useUploadAssets(componentName){
  const {
    appAction,
    uploadingAssets = {},
  } = useAppState(
    {
      ['assets:uploads']: 'uploadingAssets',
    },
    componentName,
  )

  return {
    uploadAsset: appAction('assets.upload'),
    uploadingAssets,
    deleteUploadingAsset: appAction('assets.deleteUpload'),
  }
}
