import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import {
  publicProfileToDisplayName,
  publicProfileToPathname,
} from 'lib/publicProfiles'

import Link from 'components/Link'

export default function LinkToPublicProfile({
  className,
  publicProfile,
  children,
  username = false,
  ...props
}){
  className = classNames('LinkToPublicProfile', { className })
  const pathname = publicProfileToPathname(publicProfile)
  const content = (
    children ||
    (
      (username && publicProfile.username)
        ? `@${publicProfile.username}`
        : publicProfileToDisplayName(publicProfile)
    )
  )
  if (!pathname) return <span {...{ ...props, className }}>{content}</span>

  return <Link {...{
    type: 'subtle',
    ...props,
    className,
    href: pathname,
  }}>{content}</Link>
}

LinkToPublicProfile.propTypes = {
  className: PropTypes.string,
  publicProfile: PropTypes.shape({
    username: PropTypes.string.isRequired,
    displayName: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  username: PropTypes.bool,
}
