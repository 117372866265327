import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import { useEditOrganization } from 'lib/organizationAdminHooks'
import { isLegalEntity } from 'lib/organizationProfileHelpers'
import useUploadingSet from 'lib/useUploadingSetHook'
import classNames from 'lib/classNames'

import FormBuilder from 'components/FormBuilder'
import WYSIWYGInput from 'components/WYSIWYGInput'

export default function OrganizationEditForm({
  className,
  organization,
  value,
  onChange,
  onSuccess,
  onReset,
  render,
  ...props
}){
  const legalEntity = isLegalEntity(organization.type)
  const isPersonalOrTopical = organization.purpose !== 'Organizational' && organization.purpose !== 'editorial_board'
  const [uploading, createUploadingReporter] = useUploadingSet()
  const reset = useCallback(() => { onChange() }, [onChange])
  const {
    updateOrganization,
    updatingOrganization,
    updatingOrganizationError,
  } = useEditOrganization(
    organization.apikey,
    'OrganizationEditForm'
  )
  return <FormBuilder {...{
    ...props,
    className: classNames('OrganizationEditForm', { className }),
    persistedValues: organization,
    disabled: !!uploading,
    value,
    onChange,
    onReset: useCallback(
      (...args) => {
        reset()
        if (onReset) onReset(...args)
      },
      [reset]
    ),
    onSubmit: updateOrganization,
    onSuccess: useCallback(
      (...args) => {
        reset()
        if (onSuccess) onSuccess(...args)
      },
      [reset, onSuccess]
    ),
    submitting: updatingOrganization,
    error: updatingOrganizationError,
    validator,
    render: form => render({
      form,
      legalEntity,
      isPersonalOrTopical,
      createUploadingReporter,
      formControls(){
        return form.buttonRow(
          ...(uploading
            ? ['Uploading…', 'Uploading…']
            : [`Update ${PNFO.singular}`, `Updating ${PNFO.singular}…`]
          )
        )
      },
    }),
  }}/>
}

OrganizationEditForm.propTypes = {
  className: PropTypes.string,
  organization: PropTypes.shape({
    apikey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    purpose: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onSuccess: PropTypes.func,
  onReset: PropTypes.func,
  render: PropTypes.func,
}

function validator(changes, persistedValues){
  const organization = {...persistedValues, ...changes}
  const isPersonalOrTopical = organization.purpose !== 'Organizational' && organization.purpose !== 'editorial_board'

  if ('name' in organization && organization.name.trim() === '')
    return 'Name is required'

  if (!isPersonalOrTopical && 'legal_name' in organization && organization.legal_name.trim() === '')
    return 'Entity Legal Name is required'

  if (!isPersonalOrTopical && !WYSIWYGInput.trim(organization.consumer_description))
    return 'Description is required'

  if (changes.publicly_listed && (!organization.consumer_banner || !organization.consumer_icon))
    return 'Banner & Icon are required to become publicly listed'
}
