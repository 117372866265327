import { h } from 'preact'

import { toHref } from 'resources/location'
import { useLoggedIn } from 'resources/auth'
import RedirectPage from '../RedirectPage'

export default function OrganizationJoinPage(props){
  const { location, params: { organizationApikey } } = props
  const inviteToken = location.query.i
  const loggedIn = useLoggedIn()
  const to = loggedIn
    ? toHref(`/${organizationApikey}`, {
      s: 1,
      i: inviteToken,
    })
    : toHref('/signup', {
      o: organizationApikey,
      i: inviteToken,
      r: toHref(`/${organizationApikey}`, {s: 1}),
    })
  return <RedirectPage {...{ to }}/>
}
