import { h } from 'preact'

import Form from 'components/Form'
import HugeChoiceInput from 'components/HugeChoiceInput'

export default function OrganizationMembershipSettingsFormSection({ form }) {
  const closedMemberships = form.getValue('closed_memberships')
  const usersCanRequestMembership = form.getValue('users_can_request_membership')
  const options = [
    {
      value: 0,
      buttonValue: 'Open',
      disabled: form.getValue('is_private'),
      description: <div>Anyone can join</div>,
    },
    {
      value: 1,
      buttonValue: 'By Request',
      description: <div>Admin must approve each</div>,
    },
    {
      value: 2,
      buttonValue: 'Invite Only',
      description: <div>Admin must invite each</div>,
    },
  ]
  const value = (
    (
      typeof closedMemberships !== 'boolean' ||
      typeof usersCanRequestMembership !== 'boolean'
    ) ? undefined :
      (
        closedMemberships
          ? usersCanRequestMembership ? 1 : 2
          : 0
      )
  )
  const onChange = value => {
    form.change({
      closed_memberships: value !== 0,
      users_can_request_membership: value === 1,
    })
  }
  return <Form.Row>
    <Form.Item>
      <Form.Label>MEMBERSHIP TYPE</Form.Label>
      <HugeChoiceInput fat block {...{
        disabled: form.disabled,
        options,
        value,
        onChange,
      }}/>
    </Form.Item>
  </Form.Row>
}
