import { h } from 'preact'
import PropTypes from 'prop-types'

import HelpButton from 'components/HelpButton'
import HeaderedContentWithUnsaved from 'components/HeaderedContentWithUnsaved'
import './index.sass'

export default function HeaderedContentWithUnsavedAndHelpButton({
  className = '',
  helpText,
  buttons = [],
  ...props
}) {
  return <HeaderedContentWithUnsaved
    {...props}
    className={`${className} HeaderedContentWithUnsavedAndHelpButton`}
    buttons={[
      ...buttons,
      <HelpButton>{helpText}</HelpButton>,
    ]}
  />
}

HeaderedContentWithUnsaved.propTypes = {
  ...HeaderedContentWithUnsaved.propTypes,
  helpText: PropTypes.string,
}
