import { h } from 'preact'

import { useOrganizationFeedPost } from 'lib/feedPostHooks'

import ContentBox from 'components/ContentBox'
import PageNotFound from 'components/PageNotFound'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationFeedPost from 'components/OrganizationFeedPost'

export default function UserProfileFeedPostPage(props){
  const { params: { feedPostUid } } = props
  return <div className="UserProfileFeedPostPage">
    <PageContent key={feedPostUid} {...{...props, feedPostUid}}/>
  </div>
}

UserProfileFeedPostPage.pageTitle = 'post'

function PageContent({ feedPostUid, setPageTitle }){

  const {
    feedPost,
    feedPostLoading,
    feedPostLoadingError,
    feedPostNotFound,
  } = useOrganizationFeedPost(
    feedPostUid,
    'OrganizationFeedPostPage',
  )

  if (feedPostLoadingError) return <ContentBox padded>
    <ErrorMessage error={feedPostLoadingError} />
  </ContentBox>

  if (feedPostNotFound) return <PageNotFound/>

  if (feedPostLoading || !feedPost) return <Loading />

  if (feedPost.title) setPageTitle(feedPost.title)
  return <OrganizationFeedPost {...{post: feedPost}}/>
}
