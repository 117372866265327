import {h} from "preact"
import PropTypes from "prop-types"
import "./index.sass"

import DisplayNameAndAvatarRow from "components/DisplayNameAndAvatarRow"

const Chiplist = ({
  className = "",
  people,
  filter,
  onEmpty,
  onDelete,
}) => {
  let filteredPeople = people

  // Apply filter if specified
  if (filter) {
    const filterLowerCase = filter.toLowerCase()
    filteredPeople = people.filter(
      (person) =>
        person.displayName.toLowerCase().includes(filterLowerCase) ||
        person.username.toLowerCase().includes(filterLowerCase)
    )
  }
  // Handle empty state
  if (!filteredPeople.length) {
    return <div className={`${className} chip-list-empty`}>{onEmpty}</div>
  }

  return (
    <div className={`${className} chip-list`}>
      {filteredPeople.map((person) => (

        <div key={person.username} className="chip">
          <DisplayNameAndAvatarRow {...person} />
          {onDelete && (
            <button
              className="chip-delete"
              onClick={() => onDelete(person.did)}
            >
            X
            </button>
          )}
        </div>
      ))}
    </div>
  )
}

Chiplist.propTypes = {
  className: PropTypes.string,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      avatar: PropTypes.string,
      active: PropTypes.bool,
      href: PropTypes.string,
      suffix: PropTypes.node,
      buttons: PropTypes.node,
    })
  ).isRequired,
  filter: PropTypes.string,
  onEmpty: PropTypes.node,
  onDelete: PropTypes.func,
}

export default Chiplist
