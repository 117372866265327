import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'
import PERSONAL_DATA_LABELS from 'jlinc-shared/personal_data_labels'

import {
  isCustomPersonalDataField,
  getLabelForPersonalDataField,
} from 'lib/accountDataSpec'

import Form from 'components/Form'
import TextInputWithSwitch from 'components/TextInputWithSwitch'
import TextAreaWithSwitch from 'components/TextAreaWithSwitch'
import CountryDropdownPicker from 'components/CountryDropdownPicker'
import Switch from 'components/Switch'
import Header from 'components/Header'
import Tooltip from 'components/Tooltip'

import './index.sass'

import {
  SHARED_PERSONAL_DATA_PROP_TYPE,
  PERSONAL_DATA_PROP_TYPE,
} from 'lib/accountDataSpec'

export default class AliceOrganizationPersonalDataForm extends Component {

  static propTypes = {
    requestedPersonalData:           SHARED_PERSONAL_DATA_PROP_TYPE.isRequired,
    sharedPersonalData:              SHARED_PERSONAL_DATA_PROP_TYPE.isRequired,
    sharedPersonalDataStagedChanges: SHARED_PERSONAL_DATA_PROP_TYPE,
    personalData:                    PERSONAL_DATA_PROP_TYPE.isRequired,
    personalDataStagedChanges:       PERSONAL_DATA_PROP_TYPE,
    onPersonalDataChange:            PropTypes.func.isRequired,
    onSharedPersonalDataChange:      PropTypes.func.isRequired,
    disabled:                        PropTypes.bool,
  }

  render(){
    const {
      disabled,
      personalData,
      personalDataStagedChanges = {},
      sharedPersonalData,
      sharedPersonalDataStagedChanges = {},
      onPersonalDataChange,
      onSharedPersonalDataChange,
      requestedPersonalData
    } = this.props

    const tooltipText = name => `Please enter a ${name} to enable sharing`
    const formItem = (prop, name, type) => {
      if (!requestedPersonalData[prop]) return
      const label = getLabelForPersonalDataField(prop)
      const unsaved = prop in personalDataStagedChanges
      const value = unsaved ? personalDataStagedChanges[prop] : personalData[prop]
      const shareUnsaved = prop in sharedPersonalDataStagedChanges
      const shared = shareUnsaved ? sharedPersonalDataStagedChanges[prop] : sharedPersonalData[prop]
      return <Form.Item>
        <Form.Label>{label.toUpperCase()}</Form.Label>
        { type === 'textarea'
          ? <TextAreaWithSwitch
            key={prop}
            disabled={disabled}
            textAreaUnsaved={unsaved}
            switchUnsaved={shareUnsaved}
            switchTooltip={!value}
            switchDisabled={!value || disabled}
            tooltipText={!value ? tooltipText(label) : ''}
            value={value || ''}
            checked={shared}
            name={name}
            maxLines={3}
            onTextAreaInput={value => { onPersonalDataChange({[prop]: value}) }}
            onSwitchChange={checked => { onSharedPersonalDataChange({[prop]: checked}) }}
          />
          : <TextInputWithSwitch
            key={prop}
            disabled={disabled}
            textInputUnsaved={unsaved}
            switchDisabled={!value || disabled}
            switchUnsaved={shareUnsaved}
            switchTooltip={!value}
            tooltipText={!value ? tooltipText(label) : ''}
            value={value || ''}
            checked={shared}
            name={name}
            type={type}
            onTextInput={value => { onPersonalDataChange({[prop]: value}) }}
            onSwitchChange={checked => { onSharedPersonalDataChange({[prop]: checked}) }}
            noDatePickerIcon
          />
        }
      </Form.Item>
    }

    const countryFormItem = prop => {
      if (!requestedPersonalData[prop]) return
      const shareUnsaved = prop in sharedPersonalDataStagedChanges
      const shared = shareUnsaved ? sharedPersonalDataStagedChanges[prop] : sharedPersonalData[prop]
      const unsaved = prop in personalDataStagedChanges
      const value = unsaved ? personalDataStagedChanges[prop] : personalData[prop]
      const label = PERSONAL_DATA_LABELS[prop].toUpperCase()
      let switchElement = <Switch
        disabled={!value || disabled}
        checked={shared}
        unsaved={shareUnsaved}
        onChange={checked => { onSharedPersonalDataChange({[prop]: checked}) }}
        tabIndex={-1}
      />
      if (!value) {
        switchElement = <Tooltip text={tooltipText(label)}>
          {switchElement}
        </Tooltip>
      }
      return <Form.Item>
        <Form.Label>{label}</Form.Label>
        <div className="AliceOrganizationPersonalDataForm-countryField">
          <CountryDropdownPicker
            unsaved={unsaved}
            onChange={country => { onPersonalDataChange({[prop]: country}) }}
            disabled={disabled}
            value={value}
          />
          {switchElement}
        </div>
      </Form.Item>
    }

    if (Object.values(requestedPersonalData).every(value => value === false)) {
      return <div className="AliceOrganizationPersonalDataForm">
        <Header size="xs">
          This {PNFO.singular} is not requesting any personal data.
        </Header>
      </div>
    }

    const customFields = Object.keys(requestedPersonalData)
      .filter(propName =>
        requestedPersonalData[propName] === true &&
        isCustomPersonalDataField(propName)
      )

    return <Form className="AliceOrganizationPersonalDataForm">
      <Form.Row>
        {formItem('firstname', 'given-name')}
        {formItem('lastname', 'family-name')}
      </Form.Row>

      <Form.Row>
        {formItem('email', 'email', 'email')}
        {formItem('salutation', 'honorific-suffix')}
      </Form.Row>

      {formItem('mailingstreet', 'street-address', 'textarea')}

      <Form.Row>
        {formItem('mailingcity', 'city')}
        {formItem('mailingstate', 'state')}
      </Form.Row>

      <Form.Row>
        {formItem('mailingpostalcode', 'postal-code')}
        {countryFormItem('mailingcountry')}
      </Form.Row>

      <Form.Row>
        {formItem('birthdate', 'birthday', 'date')}
        {formItem('gender', 'gender', 'text')}
      </Form.Row>

      <Form.Row>
        {formItem('mobilephone', 'mobile', 'tel')}
        {formItem('homephone', 'phone', 'tel')}
      </Form.Row>

      <Form.Row>
        {formItem('faxnumber', 'fax', 'tel')}
        {formItem('twitter', 'twitter')}
      </Form.Row>

      <Form.Row>
        {formItem('linkedin', 'linkedin')}
        {formItem('google', 'google')}
      </Form.Row>

      <Form.Row>
        {formItem('instagram', 'instagram')}
        {formItem('facebook', 'facebook')}
      </Form.Row>

      <Form.Row>
        {formItem('businessname', 'organization')}
        {formItem('businessindustry', 'organization-industry')}
      </Form.Row>

      <Form.Row>
        {formItem('title', 'organization-title')}
      </Form.Row>

      <Form.Row>
        {formItem('businessstreet', 'organization-street-address', 'textarea')}
      </Form.Row>

      <Form.Row>
        {formItem('businesscity', 'organization-city')}
        {countryFormItem('businesscountry')}
      </Form.Row>

      <Form.Row>
        {formItem('businesspostalcode', 'organization-postal-code')}
        {formItem('businessphone', 'organization-phone', 'tel')}
      </Form.Row>

      {customFields.map(propName =>
        <Form.Row>
          {formItem(propName)}
        </Form.Row>
      )}
    </Form>
  }
}
