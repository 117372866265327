import appState from '../state'
import api from '../api'
import { useAppState } from 'lib/appState'
export { useAppState }

const context = appState.createActorContext('backdoor2oldAppState')
context.setSessionId = sessionId => {
  api.sessionId = sessionId
}
export default context
