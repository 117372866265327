import { h } from 'preact'
import { ChatContentBox } from '../OrganizationChatPage/ChatContentBox'
import './index.sass'


export default function ChatPage(){
  return <div className="OrganizationChatPage">
    <ChatContentBox pageType="all" />
  </div>
}
