import { useEffect } from 'preact/hooks'
import { createStore } from 'lib/store'


/*


  stores data in three places

  state (in-memory)
  store (shared/persisted storage)

 */

export function createViewStore({
  logger,
  fetchView,
}){
  // in-memory state, subscribable
  const stateStoreValues = {}
  const stateStore = createStore({ values: stateStoreValues })
  // in-memory for now, maybe persisted in localStorage one day
  const recordStoreValues = {}
  const recordStore = createStore({ values: recordStoreValues })

  function clear(){
    stateStore.clear()
    recordStore.clear()
  }

  const getState = id =>
    stateStore.get(id) || {}

  const setState = (id, changes) => {
    stateStore.set(id, {...getState(id), ...changes, id})
  }

  const getRecord = id =>
    mergeRecordWithMetadata(recordStore.get(id))

  const delRecord = id => {
    stateStore.set(id, undefined)
    recordStore.set(id, undefined)
  }

  const setRecord = (id, value, metadata = {}) => {
    const current = recordStore.get(id)
    if (current) metadata = {...current.metadata, ...metadata}
    metadata.loadedAt = new Date()
    if (value) {
      value = stripUnderscoredProps(value)
      delete metadata.notFoundAt
    }else{
      metadata.notFoundAt = new Date()
    }
    logger.debug('setRecord', id, { value, metadata })
    recordStore.set(id, { value, metadata })
  }

  const reload = id => {
    if (getState(id).loading) return
    logger.debug('loading', id)
    const promise = fetchView(id)
      // TODO handle the edge-case where this store is cleared midflight
      // by checking for matching promise in state or bail
      .then(
        value => {
          setRecord(id, value)
        },
        loadingError => {
          logger.error('loading error', loadingError)
          setState(id, { loadingError })
        },
      )
      .then(() => {
        setState(id, { loading: undefined })
      })
    setState(id, { loading: promise, loadingError: undefined })
    return promise.then(() => get(id))
  }

  const load = id => {
    const record = getRecord(id)
    const state = getState(id)
    if (!record._loadedAt && !state.loadingError) reload(id)
    return record
  }

  function get(id){
    const record = load(id)
    const state = getState(id)
    return mergeRecordWithState(id, record, state)
  }

  function useStore(ids, options = {}){
    if (!ids) ids = []
    const arrayOfIds = Array.isArray(ids)
    if (!arrayOfIds) ids = [ids]
    const states = stateStore.use(ids)
    const records = recordStore.use(ids)
    useEffect(
      () => { ids.forEach(options.reload ? reload : load) },
      [...ids],
    )
    let result = ids.map((id, index) =>
      mergeRecordWithState(id, mergeRecordWithMetadata(records[index]), states[index]),
    )
    if (!arrayOfIds) result = result[0]
    return result
  }

  return {
    stateStoreValues,
    recordStoreValues,
    stateStore,
    recordStore,
    clear,
    getRecord,
    setRecord,
    delRecord,
    get,
    load,
    reload,
    use: useStore,
  }
}

function mergeRecordWithMetadata(record = {}){
  const { value, metadata } = record
  return { ...value, ...underscoreProps(metadata) }
}

function mergeRecordWithState(id, record, state){
  return { ...record, ...underscoreProps(state) }
}

const underscoreProps = object => (
  object
    ? Object.entries(object).reduce(
      (newObject, [prop, value]) => {
        newObject[`_${prop}`] = value
        return newObject
      },
      {}
    )
    : {}
)

const stripUnderscoredProps = object => (
  object
    ? Object.entries(object).reduce(
      (newObject, [prop, value]) => {
        if (prop[0] !== '_') newObject[prop] = value
        return newObject
      },
      {}
    )
    : {}
)
