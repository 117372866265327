import { useReducer } from 'preact/hooks'

export default function useUploadingSet(){
  const [componentsUploading, reportUploading] = useReducer(
    (state, report) => {
      const newState = new Set(state)
      if (report.uploading){
        if (state.has(report.name)) return state
        newState.add(report.name)
      }else{
        if (!state.has(report.name)) return state
        newState.delete(report.name)
      }
      return newState
    },
    new Set(),
  )
  const uploading = componentsUploading.size > 0
  const createUploadingReporter = name => {
    return uploading => {
      reportUploading({name, uploading})
    }
  }
  return [uploading, createUploadingReporter]
}
