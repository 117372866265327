import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

export default function ProgressPie({
  className, value, size, ...props
}){
  if (typeof value === 'string') value = parseInt(value, 10)
  if (typeof value !== 'number' || value < 0) value = 0
  if (value > 100) value = 100
  return <div
    {...props}
    className={classNames('ProgressPie', { className, [size]: 1 })}
    data-value={value}
  />
}

ProgressPie.SIZES = Object.freeze(['sm', 'md', 'lg', 'xl'])

ProgressPie.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  size: PropTypes.oneOf(ProgressPie.SIZES).isRequired,
}

ProgressPie.defaultProps = {
  size: 'md',
}
