import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import { useUsernameAvailability } from 'resources/auth'

import classNames from 'lib/classNames'
import TextInputWithPrefix from 'components/TextInputWithPrefix'
import Spinner from 'components/Spinner'
import Icon from 'components/Icon'
import './index.sass'

export default function UsernameInput({ className, onChange, ...props }){
  let username = props.value || ''
  const invalid = UsernameInput.isInvalid(username)
  let available = useUsernameAvailability(invalid ? '' : username)
  if (invalid) available = false

  return <TextInputWithPrefix {...{
    ...props,
    className: classNames('UsernameInput', { className }),
    onChange: useCallback(
      (value, event) => {
        const legalValue = value.replace(/[^\w-_]/gi, '')
        event.target.value = legalValue
        if (onChange) onChange(legalValue)
      },
      [onChange]
    ),
    prefix: '@',
    pattern: '[A-Za-z0-9-_]{3,35}',
  }}>
    <div className="UsernameInput-status">
      {
        username
          ? typeof available === 'boolean'
            ? <Icon type={available ? 'checkmark' : 'cancel-circled'} />
            : <Spinner/>
          : null
      }
    </div>
  </TextInputWithPrefix>
}

UsernameInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

UsernameInput.isInvalid = username => {
  username = typeof username === 'string' ? username.trim() : ''
  if (username.length < 3) return 'minimum 3 characters'

}
