import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import ButtonRow from 'components/ButtonRow'
import EntityInfo, { Detail, Address, Url } from 'components/EntityInfo'
import { useLoggedIn } from 'resources/auth'
import './index.sass'

export default function OrganizationInfo({
  className,
  organization,
  buttons,
  minimal,
  ...props
}){
  const loggedIn = useLoggedIn()
  return <EntityInfo
    {...props}
    className={classNames('OrganizationInfo', { className, minimal })}
  >
    <div className="OrganizationInfo-desc">{organization.short_description}</div>
    {loggedIn &&
      <div className="OrganizationInfo-deets">
        <div>
          {minimal || <Detail label="Legal Name" value={organization.legal_name} />}
          <Url
            className={classNames('OrganizationInfo-domain', { minimal })}
            value={organization.domain}
          />
        </div>
        <Address {...{minimal, ...organization}}/>
      </div>
    }
    {buttons && <ButtonRow>{buttons}</ButtonRow>}
  </EntityInfo>
}

OrganizationInfo.propTypes = {
  className: PropTypes.string,
  buttons: ButtonRow.propTypes.children,
  minimal: PropTypes.bool,
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired,
    domain: PropTypes.string,
    short_description: PropTypes.string,
    legal_name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    post_code: PropTypes.string,
    state: PropTypes.string,
  }),
}
