import { h } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Subtext from 'components/Subtext'
import './index.sass'

export default function OrganizationNameAndTagLine({
  className = '',
  size = 'lg',
  organizationName,
  organizationTagLine,
  classNamePrefix,
  ...props
}){
  const nameClassName = classNamePrefix ? `${classNamePrefix}-name` : ''
  const tagLineClassName = classNamePrefix ? `${classNamePrefix}-tagLine` : ''
  return <div {...props} className={`OrganizationNameAndTagLine ${className}`}>
    <Header className={nameClassName} size={size}>
      {organizationName || <span>&nbsp;</span>}
    </Header>
    {organizationTagLine &&
      <Subtext className={tagLineClassName}>
        {organizationTagLine}
      </Subtext>
    }
  </div>
}

OrganizationNameAndTagLine.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Header.SIZES),
  organizationName: PropTypes.string,
  organizationTagLine: PropTypes.string,
  classNamePrefix: PropTypes.string,
}
