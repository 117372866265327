import api from '../api'
import { uploadAsset } from 'lib/assets'
import { getFilePreview } from 'lib/fileHelpers'

export async function uploadDirect(file){
  const assetUploadToken = await api.getAssetUploadToken()
  return uploadAsset({ file, assetUploadToken })
}

const uploadsKey = 'assets:uploads'
let latestUploadingAssetId = 0
export function upload(file){
  const id = ++latestUploadingAssetId

  const getUploads = () =>
    this.getState()[uploadsKey] || {}

  const getUpload = () =>
    getUploads()[id]

  const setUpload = upload =>
    this.setState({[uploadsKey]: {...getUploads(), [id]: upload}})

  setUpload({ loading: true, progress: 0, type: file.type })

  const update = changes => {
    const upload = getUpload()
    if (upload) setUpload({...upload, ...changes})
  }

  const cancelFakeUploadProgress = fakeUploadProgress(progress => {
    const upload = getUpload()
    if (upload && upload.progress < progress) update({ progress })
  })

  const previewPromise = getFilePreview(file).then(
    preview => {
      if (preview) update({ preview })
    },
    previewError => {
      console.error('error loading preview for uploading asset', previewError)
      update({ preview: { error: previewError.message } })
    },
  )

  const uploadPromise = uploadDirect(file)
    .then(
      url => ({ url }),
      error => ({ error }),
    )
    .then(changes => {
      cancelFakeUploadProgress()
      update({
        loading: undefined,
        progress: undefined,
        ...changes
      })
    })

  const promise = Promise.all([previewPromise, uploadPromise]).then(getUpload)
  update({ promise })

  return id
}

export async function waitForUpload(id){
  const uploads = this.getState()[uploadsKey]
  if (!uploads) return
  const upload = uploads[id]
  if (!upload || !upload.promise) return
  return await upload.promise
}

export function deleteUpload(id){
  const uploads = {...(this.getState()[uploadsKey] || {})}
  delete uploads[id]
  this.setState({[uploadsKey]: uploads})
}

function fakeUploadProgress(onChange){
  let progress = 0
  let timeout
  function cancel(){
    clearTimeout(timeout)
  }
  function step(){
    if (progress >= 90) return
    cancel()
    let newProgress = progress + Math.floor(Math.random() * 5) + 1
    if (newProgress > 90) newProgress = 90
    onChange(progress = newProgress)
    timeout = setTimeout(step, 200)
  }
  step()
  return cancel
}
