import { h, Fragment } from 'preact'
import { useMemo, useCallback } from 'preact/hooks'
import { setLocation } from 'resources/location'

import {
  useLoadOrganizationMembershipInvite,
  useOrganizationMembershipInviteFor,
  useMyPendingOrganizationMemberships,
} from 'lib/membershipAppStateHooks'
import useInvitedToOrganization from 'lib/useInvitedToOrganizationHook'

import Header from 'components/Header'
import Loading from 'components/Loading'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationMembershipButton from 'components/OrganizationMembershipButton'
import StyleishModal from 'components/StyleishModal'
import OrganizationIcon from 'components/OrganizationIcon'
import './index.sass'

export default function OrganizationJoinModal(props){
  const { organization, location, currentUser, isMember } = props
  const organizationApikey = organization.apikey
  const inviteToken = location.query.i
  const joinModalOpen = currentUser && location.query.s === '1'
  const { invitedToOrganization, setInvitedToOrganization } = useInvitedToOrganization(organizationApikey)

  const { myPendingOrganizationMemberships } = useMyPendingOrganizationMemberships('OrganizationJoinModal')
  const pendingMembership = myPendingOrganizationMemberships.find(pendingMembership =>
    pendingMembership.organizationApikey === organizationApikey
  )
  const requestedToJoin = pendingMembership &&
  pendingMembership.memberUserDid === pendingMembership.createdByUserDid
  const {
    loadingOrganizationMembershipInvite: loadingInvite,
    errorLoadingOrganizationMembershipInvite: errorLoadingInvite,
  } = useLoadOrganizationMembershipInvite(inviteToken, 'OrganizationJoinModal')
  const {
    organizationMembershipInvite,
  } = useOrganizationMembershipInviteFor(organizationApikey, 'OrganizationJoinModal')

  const closeLocation = useMemo(
    () => location.withQuery({ s: undefined, i: undefined, l: undefined }),
    [location],
  )

  const onClose = useCallback(
    () => { setLocation(closeLocation) },
    [closeLocation]
  )
  const isInvited = organizationMembershipInvite || invitedToOrganization
  const loggedIn = !!currentUser

  if (
    isDataYogi || (
      (organization.is_private && !organization.users_can_request_membership) &&
      (!organizationMembershipInvite && !isMember && !errorLoadingInvite)
    )
  ) return

  let title
  if (isInvited && !loggedIn) title = `${organization.name} has invited you to join ${APP_NAME}`
  else if (loggedIn && requestedToJoin) title = `Requested to join ${organization.name}`
  else if ((!isInvited && !loggedIn) || (loggedIn && !isMember)) title = `Join ${organization.name}`
  else title = `You have joined ${organization.name}`

  return <StyleishModal {...{ className: 'OrganizationJoinModal', open: joinModalOpen, title, onClose }}>
    <ErrorMessage error={errorLoadingInvite} />
    {
      errorLoadingInvite ? null :
      (inviteToken && loadingInvite) ? <Loading /> :
      !currentUser ? null :
      <Fragment>
        <OrgBranding {...{organization}}/>
        <Buttons {...{organization, closeLocation, location, setInvitedToOrganization, setLocation, isMember}}/>
      </Fragment>
    }
  </StyleishModal>
}

function OrgBranding({ organization }){
  return <div className="flex-col flex-align-center">
    <OrganizationIcon size="lg" {...{organization}}/>
    <Header size="md" centered>
      {organization.welcome_text || `Welcome to ${organization.name}`}
    </Header>
  </div>
}

function Buttons({
  organization, closeLocation, setInvitedToOrganization, setLocation, isMember,
}) {
  const onJoin = useCallback(
    () => {
      setInvitedToOrganization()
      setLocation(closeLocation.replaceQuery({ s: 1 }))
    },
    [closeLocation]
  )

  const buttons = [
    <Button {...{
      type: 'subtle',
      value: 'Close',
      href: closeLocation,
    }}/>,
    <Button {...{
      type: 'normal',
      value: 'Take Tour',
      href: `/${organization.apikey}?iw=0`
    }}/>,
  ]

  if (!isMember) buttons.push(
    <OrganizationMembershipButton
      includeOrgName
      {...{ organization, onJoin }}
    />
  )

  return <ButtonRow>{buttons}</ButtonRow>
}
