import api from '../api'

import { loadEntity } from 'lib/actionHelpers'

export async function load() {
  return loadEntity.call(this, {
    reload: false,
    entityKey: `notificationSettings`,
    request: async () => (await api.getNotificationSettings()).notificationSettings,
  })
}

export async function update(changes) {
  const updatingKey = 'updatingNotificationSettings'
  const errorKey = `${updatingKey}Error`
  if (this.getState()[updatingKey]) return

  this.setState({
    [updatingKey]: true,
    [errorKey]: undefined,
  })

  try {
    const { notificationSettings } = await api.updateNotificationSettings(changes)
    this.setState({ notificationSettings })
  } catch(error) {
    this.setState({ [errorKey]: error })
  } finally {
    this.setState({ [updatingKey]: undefined })
  }
}
