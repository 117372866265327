import { createLogger } from 'lib/logger'
import defer from 'lib/defer'
import { tx, createInMemoryStore } from 'lib/stores'
import { isInvalidPassword } from 'lib/validators'

import aServer from './aServer'
import { onAuthChange, useSession } from './session'
import { setLogin } from './auth'
import { createPageAlert, setPageError } from './pageAlerts'
import { consumeCaptcha } from './captcha'
const logger = createLogger('password', 'color: orange')

const state = createInMemoryStore({
  // resettingPassword:
  // requestingPasswordReset: false,
  // requestingPasswordResetError: undefined,
})


// ACTIONS

function startResettingPassword(){
  state.set({ resettingPassword: true })
}
function stopResettingPassword(){
  state.set({ resettingPassword: undefined })
}

const setPasswordResetFailedError = error =>
  setPageError('Reset Password Failed', error)

export function cancelPasswordReset(){
  setPasswordResetFailedError()
  state.set({
    passwordResetRequested: undefined,
    verifiedPasswordResetCode: undefined,
  })
}

export function requestPasswordReset({ login, captchaSolve }){
  logger.info('requesting password reset')
  // assertNotLoggedIn()
  cancelPasswordReset()
  const captcha = consumeCaptcha()
  return tx(state, 'requestingPasswordReset', async () => {
    try{
      const response = await aServer.postJSON(`/reset_password/request`, {
        login,
        captchaEncValue: captcha.encValue,
        captchaSolve
      })
      logger.info('password reset request', response)
      // const { loginVerificationRequired } = response
      // if (loginVerificationRequired) setSession({ loginVerificationRequired })
      state.set({ passwordResetRequested: { login } })
    }catch(error){
      setPasswordResetFailedError(error)
    }
  })
}

export function verifyPasswordResetCode({ code }){
  logger.info('verifyingPasswordResetCode', code)
  setPasswordResetFailedError()
  return tx(state, 'verifyingPasswordResetCode', async () => {
    try{
      await aServer.getJSON(`/reset_password/verify/${code}`)
      state.set({ verifiedPasswordResetCode: code })
    }catch(error){
      setPasswordResetFailedError(error)
    }
  })
}

export function changePassword({ password, passwordConfirmation }){
  logger.info('changingPassword')
  setPasswordResetFailedError()
  return tx(state, 'changingPassword', async () => {
    try{
      const invalidPassword = isInvalidPassword(password)
      if (invalidPassword) throw new Error(`Invalid password: ${invalidPassword}`)
      if (password !== passwordConfirmation) throw new Error(`passwords don't match`)

      const { verifiedPasswordResetCode } = state.get()
      const response = await aServer.postJSON(`/reset_password`, {
        resetPasswordToken: verifiedPasswordResetCode,
        password,
        passwordConfirmation,
      })
      logger.info('password changed?', response)
      state.set({}, true)
      createPageAlert({type: 'success', message: 'Password Changed!'})
    }catch(error){
      setPasswordResetFailedError(error)
    }
  })
}

export function changePasswordWithOldPassword({ oldPassword, password, passwordConfirmation }){
  logger.info('changePasswordWithOldPassword')
  setPasswordResetFailedError()
  return tx(state, 'changePasswordWithOldPassword', async () => {
    try{
      const invalidPassword = isInvalidPassword(password)
      if (invalidPassword) throw new Error(`Invalid password: ${invalidPassword}`)
      if (password !== passwordConfirmation) throw new Error(`passwords don't match`)

      const response = await aServer.postJSON(`/reset_password`, {
        oldPassword,
        password,
        passwordConfirmation,
      })
      logger.info('password changed?', response)
      state.set({}, true)
      createPageAlert({type: 'success', message: 'Password Changed!'})
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }catch(error){
      setPasswordResetFailedError(error)
    }
  })
}

// HOOKS

export function useResettingPassword(){
  return [
    state.useStore(['resettingPassword']).resettingPassword,
    startResettingPassword,
    stopResettingPassword,
  ]
}

export function usePasswordReset(){
  return {
    ...useSession(['login']),
    ...state.useStore([
      'requestingPasswordReset',
      'requestingPasswordResetError',
      'passwordResetRequested',
      'verifyingPasswordResetCode',
      'verifiedPasswordResetCode',
      'changingPassword',
    ]),
    setLogin,
    cancelPasswordReset,
    requestPasswordReset,
    verifyPasswordResetCode,
    changePassword,
  }
}

export function usePasswordChange(){
  return {
    ...state.useStore([
      'changingPassword',
      'requestingPasswordResetError',
    ]),
    changePassword:changePasswordWithOldPassword,
  }
}

// INIT

defer(() => {
  onAuthChange(() => {
    state.set({}, true) // clear
  })
})

// DEBUG

Object.assign(DEBUG, {
  passwordState: state,
})
