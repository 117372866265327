import querystring from 'querystring'

import defer from 'lib/defer'
import JLINCAPI from 'lib/JLINCAPI'
import { createLogger } from 'lib/logger'
import { createViewStore } from 'lib/viewStore'
import { createFetchMultiplexer } from 'lib/fetchMultiplexer'
import joinStringsInBatches from 'lib/joinStringsInBatches'

const logger = createLogger('aServer', 'color: red')

const aServer = new JLINCAPI({ urlPrefix: JLINC_A_API })

export default aServer

const views = createViewStore({
  logger: createLogger('views', 'color: pink'),
  fetchView: createFetchMultiplexer(async (viewIds, emitResult) => {
    // TODO uniq IDs here
    // we have to batch these requests incase we go over the 2083 url character limit
    const batches = joinStringsInBatches(viewIds, 2000)
    await Promise.all(
      batches.map(async viewIds => {
        const views = await fetchViews(viewIds)
        // emit results for each requested view
        viewIds = viewIds.split(',')
        for (const viewId of viewIds) {
          if (viewId in views){
            let result = views[viewId]
            delete views[viewId]
            if (result && result.error){
              const error = new Error(result.error.message)
              error.stack = result.error.stack
              emitResult(viewId, error)
            }else{
              emitResult(viewId, result)
            }
          }
        }
        // store any embeded views
        defer(() => {
          for (const embededViewId in views){
            aServer.views.setRecord(embededViewId, views[embededViewId])
          }
        })
      })
    )
  }),
})

async function fetchViews(viewIds){
  logger.trace(`fetchViews`, viewIds)
  const response = await aServer.getJSON(`/views/${viewIds}`)
  logger.trace(`fetchViews`, viewIds, response)
  return response.views
}

function takeAction(name, options){
  const action = {
    action: name,
    options,
    at: new Date(),
  }
  logger.trace(`takeAction START`, action)

  action.promise = aServer
    .postJSON(`/actions/${name}`, options)
    .then(
      ({ results }) => {
        action.results = results
        logger.debug(`takeAction COMPLETE`, action)
        return results
      },
      error => {
        action.error = error
        logger.error(`takeAction ERROR "${name}" failed with`, error)
        logger.error(action)
        throw error
      }
    )

  action.then = (...args) =>
    action.promise.then(...args)

  return action
}

function linkTo(pathname, query = {}){
  query.sessionId = aServer.sessionId
  return `${JLINC_A_API}${pathname}?${querystring.stringify(query)}`
  aServer.sessionId
}

Object.assign(aServer, {
  views,
  takeAction,
  linkTo,
})
export { views, takeAction }

DEBUG.views = views
DEBUG.takeAction = takeAction
