import useAsyncAction from 'lib/useAsyncAction'
import { useOrganizations } from 'lib/membershipAppStateHooks'

import { views, takeAction } from 'resources/aServer'
import oldAppState from 'resources/oldAppState'
import { useViewIfLoggedIn } from 'resources/auth'

const sisaKey = organizationApikey =>
  organizationApikey ? `SISAs.sisa.${organizationApikey}` : null

// ACTIONS

export async function signSISA({ organizationApikey, inviteToken }){
  const { organizationAccountData } =
    await takeAction('SISAs.signSISA', { organizationApikey, inviteToken })
  oldAppState.setState({
    [`organizationAccountData:${organizationApikey}`]: organizationAccountData,
  })
  views.reload(sisaKey(organizationApikey))
}


// HOOKS

export function useSISAInvite(inviteToken, options){
  return views.use(
    inviteToken ? `SISAs.invite.${inviteToken}` : undefined,
    options
  )
}

export function useMySISAs(options){
  return views.use('SISAs.mine', options)
}

export function useMySISAsWithOrganizations(){
  const mySISAs = useMySISAs({ reload: true })
  const sisas = (mySISAs.sisas || [])
  const {
    organizations,
    organizationsLoadingError,
  } = useOrganizations(
    sisas.map(s => s.organizationApikey),
    'MySISAsPage'
  )
  return {
    loading: !mySISAs.sisas,
    _loadingError: mySISAs._loadingError || organizationsLoadingError,
    sisas,
    organizations,
  }
}

export function useSISA(organizationApikey, options){
  return useViewIfLoggedIn(sisaKey(organizationApikey), options)
}

export function useSignSISA({ organizationApikey, inviteToken }){
  return useAsyncAction(
    () => signSISA({ organizationApikey, inviteToken }),
    [organizationApikey, inviteToken],
  )
}

export function useSISAEvents(organizationApikey, options){
  return views.use(`SISAs.events.${organizationApikey}`, options)
}

export function useSISAEvent(sisaEventId, options){
  return views.use(`SISAs.event.${sisaEventId}`, options)
}
