export default function(){
  const { defaultAccountData } = this.getState()
  if (!defaultAccountData) return

  this.setState({
    applyingDefaultAccountDataToAllOrgs: true,
  })

  return this.takeAction('updateAllOrganizationAccountData', { changes: defaultAccountData })
    .catch(error => {
      this.setState({
        errorApplyingDefaultAccountDataToAllOrgs: error,
      })
    })
    .then(() => {
      this.setState({
        applyingDefaultAccountDataToAllOrgs: undefined,
      })
    })
}
