import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import IconButton from 'components/IconButton'

export default function EditButton({className, ...props}){
  props.className = classNames('EditButton', { className })
  return h(IconButton, props)
}

EditButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

EditButton.defaultProps = {
  type: 'edit',
  size: 'sm',
  title: 'Edit',
  bordered: true,
  padded: true,
}
