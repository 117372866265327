import { h } from 'preact'
import PropTypes from 'prop-types'

import './index.sass'

export default function Pill({className = '', type, children, ...props}){
  return <span {...props} className={`Pill Pill-${type} ${className}`}>
    {children}
  </span>
}

Pill.TYPES = Object.freeze([
  'primary',
  'success',
  'info',
])

Pill.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf(Pill.TYPES).isRequired,
}

