import { h } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import InterfaceHelp from 'components/InterfaceHelp'
import Pill from 'components/Pill'
import './index.sass'

export default function OrganizationMembershipPills({ className = '', organizationMembership }){
  return <div className={`OrganizationMembershipPills ${className}`}>
    {organizationMembership.admin && role('admin')}
    {(organizationMembership.admin || organizationMembership.curator) && role('curator')}
  </div>
}

OrganizationMembershipPills.propTypes = {
  className: PropTypes.string,
  organizationMembership: PropTypes.shape({
    admin: PropTypes.bool,
    curator: PropTypes.bool,
  }).isRequired,
}


const helpCopy = {
  superadmin:{
    title: `${PNFO.singular} Super Admin`,
    content: (
      `${PNFO.singular} Super Admins have full control over all ${PNFO.singular}. They can change ` +
      `the ${PNFO.singular}'s settings, promote members to Curators or Admins, invite people ` +
      `to join and approve requests to join any ${PNFO.singular} , ban users , delete hubs on requests  .`
    ),
  },
  admin: {
    title: `${PNFO.singular} Admin`,
    content: (
      `${PNFO.singular} Admins have full control over a ${PNFO.singular}. They can change ` +
      `the ${PNFO.singular}'s settings, promote members to Curators or Admins, invite people ` +
      `to join and approve requests to join their ${PNFO.singular}.`
    ),
  },
  curator: {
    title: `${PNFO.singular} Curator`,
    content: (
      `Curators don't have as many privileges as Admins, but they serve an important role by ` +
      `publishing the most highly elevated content from their ${PNFO.singular}'s forum. Think of them as the ` +
      `sidekicks for the ${PNFO.singular} Admins.`
    ),
  },
}
const role = role =>
  <InterfaceHelp {...helpCopy[role]}><Pill type="info">{role}</Pill>
  </InterfaceHelp>
