import { h } from 'preact'
import { useMemo } from 'preact/hooks'
import { useAppState } from 'lib/appState'
import useLoadedCollection from 'lib/useLoadedCollectionHook'
import { entityKeys } from 'lib/actionHelpers'
import { useOrganizations } from 'lib/membershipAppStateHooks'

export const getCollectionKey = organizationApikey =>
  `organization:${organizationApikey}:network:memberships`

export const memberToId = ({organizationApikey, memberOrganizationApikey}) => {
  if (!organizationApikey || !memberOrganizationApikey)
    console.warn('memberToId', {organizationApikey, memberOrganizationApikey})
  return `${organizationApikey}:${memberOrganizationApikey}`
}

export const memberIdToKey = id => `organizationNetworkMembership:${id}`

export const memberToKey = membership => memberIdToKey(memberToId(membership))

export const getMemberKeys = membership =>
  entityKeys(memberToKey(membership), ['creating', 'updating'])

export function useOrganizationNetworkMemberships(organizationApikey, componentName){
  const collection = useLoadedCollection({
    entityKey: getCollectionKey(organizationApikey),
    entityName: `organizationNetworkMemberships`,
    memberKey: memberIdToKey,
    loadCollection: takeAction => {
      return takeAction('organizationNetworkMemberships.load', organizationApikey)
    },
    componentName,
  })
  collection.organizationNetworkMemberships = useMemo(
    () => (
      collection.organizationNetworkMemberships &&
      Object.values(collection.organizationNetworkMemberships) ||
      undefined
    ),
    [collection.organizationNetworkMemberships]
  )
  return collection
}

export function useOrganizationNetworkMembershipsWithOrganizations(organizationApikey, componentName){
  let { organizationNetworkMemberships = [], ...other} =
    useOrganizationNetworkMemberships(organizationApikey, componentName)
  const apikeys = new Set()
  organizationNetworkMemberships.forEach(m => {
    apikeys.add(m.organizationApikey)
    apikeys.add(m.memberOrganizationApikey)
  })
  const { organizations = [] } = useOrganizations(apikeys, componentName)
  organizationNetworkMemberships = organizationNetworkMemberships.map(membership => {
    const organization = organizations.find(o => o.apikey === membership.organizationApikey)
    const memberOrganization = organizations.find(o => o.apikey === membership.memberOrganizationApikey)
    return {...membership, organization, memberOrganization}
  })
  return {...other, organizationNetworkMemberships}
}

export function useOrganizationNetworkMembership(
  organizationApikey, memberOrganizationApikey, componentName
){
  const {
    entityKey,
    entityCreatingKey,
    entityCreatingErrorKey,
    entityUpdatingKey,
    entityUpdatingErrorKey,
  } = getMemberKeys({organizationApikey, memberOrganizationApikey})

  const {
    appAction,
    organizationNetworkMembership,
    creatingOrganizationNetworkMembership,
    creatingOrganizationNetworkMembershipError,
    updatingOrganizationNetworkMembership,
    updatingOrganizationNetworkMembershipError,
  } = useAppState(
    {
      [entityKey]: 'organizationNetworkMembership',
      [entityCreatingKey]: 'creatingOrganizationNetworkMembership',
      [entityCreatingErrorKey]: 'creatingOrganizationNetworkMembershipError',
      [entityUpdatingKey]: 'updatingOrganizationNetworkMembership',
      [entityUpdatingErrorKey]: 'updatingOrganizationNetworkMembershipError',
    },
    componentName
  )

  const createOrganizationNetworkMembership = appAction(
    'organizationNetworkMemberships.create',
    {organizationApikey, memberOrganizationApikey},
  )

  const acceptOrganizationNetworkMembership = appAction(
    'organizationNetworkMemberships.update',
    {organizationApikey, memberOrganizationApikey, changes: {accepted: true}},
  )

  const removeOrganizationNetworkMembership = appAction(
    'organizationNetworkMemberships.remove',
    {organizationApikey, memberOrganizationApikey},
  )

  return {
    organizationNetworkMembership,
    createOrganizationNetworkMembership,
    creatingOrganizationNetworkMembership,
    creatingOrganizationNetworkMembershipError,
    acceptOrganizationNetworkMembership,
    removeOrganizationNetworkMembership,
    updatingOrganizationNetworkMembership,
    updatingOrganizationNetworkMembershipError,
  }
}

export function useSortOrganizationNetworkMemberships(organizationApikey, componentName) {
  const collectionKey = getCollectionKey(organizationApikey)
  const {
    appAction,
    sortingOrganizationNetworkMemberships,
    sortingOrganizationNetworkMembershipsError
  } = useAppState(
    {
      [`${collectionKey}:sorting`]: 'sortingOrganizationNetworkMemberships',
      [`${collectionKey}:sorting:error`]: 'sortingOrganizationNetworkMembershipsError',
    },
    componentName
  )
  const sortOrganizationNetworkMemberships = appAction(
    'organizationNetworkMemberships.sort',
    organizationApikey,
  )
  return {
    sortOrganizationNetworkMemberships,
    sortingOrganizationNetworkMemberships,
    sortingOrganizationNetworkMembershipsError,
  }
}
