import { h, Fragment } from 'preact'
import { useState, useEffect, useMemo, useRef} from 'preact/hooks'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import Icon from 'components/Icon'
import { useMyPublicProfileDid } from 'resources/auth'
import { useMyPublicProfile } from 'lib/membershipAppStateHooks'
import {
  useAcceptedMembershipsForOrganization,
  usePublicProfiles,
  useSuggestedHubs,
  usePublicOrganizations
} from 'lib/membershipAppStateHooks'
// import { useSet } from 'lib/useSetHook'
import { useCreateChatChannel } from 'lib/chatHooks'
import { useSearchAll } from 'lib/searchHooks'
import classNames from 'lib/classNames'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import IconButton from 'components/IconButton'
import PeopleList from 'components/PeopleList'
import SearchInput from 'components/SearchInput'
import './index.sass'
import ChipList from 'components/Chiplist'
import TextInput from 'components/TextInput'
import RadioInputGroup from 'components/RadioInputGroup'
import { useChatSynopsis } from 'lib/chatHooks'
import { ChatsList } from '../../a-portal/pages/OrganizationChatPage/ChatsList'
import OrganizationIcon from 'components/OrganizationIcon'

import useAssetUpload from 'lib/useAssetUploadHook'
import InterfaceHelp from 'components/InterfaceHelp'
import Tooltip from 'components/Tooltip'

const fallbackColors = ['#177bdf', '#1724DF', '#1aae0e', '#DF7B17', '#DFD217', '#A20EAE', '#520EAE ', '#AE0E1A']

export default function NewChatModal({
  className,
  organizationApikey,
  ...props
}){
  return (
    <div className={classNames('NewChatSection', className)}>
      <NewChatForm {...{...props, organizationApikey}}/>
    </div>
  )
}

NewChatModal.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string.isRequired
}

function NewChatForm({organizationApikey, onClose, organizations}){
  const myPublicProfileDid = useMyPublicProfileDid()
  const { myPublicProfile } = useMyPublicProfile('NewChatModal')
  const [viewType, setViewType] = useState('view1')
  const [chatImage, setChatImage] = useState(null)
  const [chatType, setChatType] = useState(1)
  const [chatName, setChatName] = useState('')
  const [chatNamePlaceholder, setChatNamePlaceholder] = useState('')
  const chatHref = (channel) => `/chat` + (channel ? `/${channel}` : '')
  const [filter, setFilter] = useState('')
  const {searchResults} = useSearchAll(filter, 1, 'NewChatModal')
  const { chatSynopsis: synopsis } = useChatSynopsis('NewChatModal')
  const limitedSynopsis = useMemo(() => synopsis.slice(0, 3), [synopsis])
  const [selectedOrgApikey, setSelectedOrgApikey] = useState('')
  const {uploadAsset, assetUrl} = useAssetUpload("EditChatInfoModal")
  const [isChatNameValid, setIsChatNameValid] = useState(true)
  const [isChatNameChanged, setIsChatNameChanged] = useState(false)


  const {
    acceptedOrganizationMemberships,
  } = useAcceptedMembershipsForOrganization(organizationApikey, 'NewChatModal')
  const publicProfileDids = new Set()
  acceptedOrganizationMemberships.forEach(m => {
    if (m.memberUserDid && m.memberUserDid !== myPublicProfileDid)
      publicProfileDids.add(m.memberUserDid)
  })
  const { publicProfiles } = usePublicProfiles(
    [...publicProfileDids],
    'NewChatModal'
  )
  const createChatChannel = useCreateChatChannel('NewChatForm')
  const {publicOrganizations} = usePublicOrganizations('NewChatForm')

  const [recipients, setRecipients] = useState(new Set())
  const [disabledOrgApikeys, setDisabledOrgApikeys] = useState(new Set())
  const addRecipient = (did) => {
    setRecipients((prevRecipients) => new Set(prevRecipients).add(did))
  }

  const removeRecipient = (did) => {
    setRecipients((prevRecipients) => {
      const newRecipients = new Set(prevRecipients)
      newRecipients.delete(did)
      return newRecipients
    })
  }
  const handleDeleteRecipient = (did) => {
    removeRecipient(did)
  }
  const profileDids = useMemo(() => [myPublicProfileDid,
    ...Array.from(recipients).filter(did => did !== myPublicProfileDid)], [recipients, myPublicProfileDid])

  const otherProfileDids =  profileDids.slice(1)
  const [hubSearchQuery, setHubSearchQuery] = useState('')


  const { suggestedHubs }
 = useSuggestedHubs(profileDids, 'NewChatForm')

  useEffect(() => {

    const newDisabledOrgApikeys = new Set(
      suggestedHubs?.filter(hub => hub.memberCount !== otherProfileDids.length).map(hub => hub.organizationApikey)
    )
    setDisabledOrgApikeys(newDisabledOrgApikeys)
  }, [suggestedHubs, otherProfileDids.length])

  const handleOrgSelect = (apikey, event) => {
    event.preventDefault()
    if (!disabledOrgApikeys.has(apikey)) {
      setSelectedOrgApikey(apikey)
    }
  }
  const fileInputRef = useRef(null)

  const triggerFileInputClick = () => {
    fileInputRef.current.click()
  }


  const organizationListData = suggestedHubs?.map(hub => ({
    apikey: hub.organizationApikey,
    name: hub.organizationApikey,
  }))

  const filteredOrganizationListData = useMemo(() => {
    if (!hubSearchQuery) return organizationListData
    return publicOrganizations.filter(organization =>
      organization.name.toLowerCase().includes(hubSearchQuery.toLowerCase())
    )
  }, [publicOrganizations, hubSearchQuery])

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setChatImage(file)
      uploadAsset(file)
    }
  }


  const renderOrganizationList = () => {

    return (
      <div>
        {filteredOrganizationListData?.length > 0 && filteredOrganizationListData.map((org) => (
          <div
            key={org.apikey}
            className={`organization-item-hublist ${selectedOrgApikey === org.apikey ? 'selected' : ''}
             ${disabledOrgApikeys.has(org.apikey) ? 'disabled' : ''}`}
            onClick={(e) => handleOrgSelect(org.apikey, e)}
          >
            <div className="organization-hublist-item">
              <OrganizationIcon size="sm"
                organization={{ apikey: org.apikey, name: org.name, consumer_icon: org.consumer_icon }}
                brandingType="consumer" />
              <div className="organization-name">{org.name}</div>
            </div>
            {selectedOrgApikey === org.apikey && <IconButton type="selected" />}
          </div>
        ))}
      </div>
    )
  }
  const countDids = {}

  if (synopsis) {
    synopsis.forEach(chat => {
      (chat.memberUserDids || []).forEach(did => {
        if (did !== myPublicProfileDid) {
          countDids[did] = (countDids[did] || 0) + 1
        }
      })
    })
  }

  const sortedDids = Object.entries(countDids).sort((a, b) => b[1] - a[1]).slice(0, 3).map(entry => entry[0])
  const handleNextOrCreate = () => {
    if (viewType === 'view1') {
      setViewType('view2')
    } else {
      create()
    }
  }
  const { publicProfiles: suggestedProfiles } = usePublicProfiles([...sortedDids], 'NewChatModalSuggested')

  const { publicProfiles:selectedprofiles } = usePublicProfiles(
    [...recipients],
    'NewChatModal'
  )
  const people = (organizationApikey ? publicProfiles : searchResults?.publicProfiles || []).map(publicProfile => {
    const selected = recipients.has(publicProfile.did)
    return {
      ...publicProfile,
      className: classNames('NewChatModal-recipient', { selected }),
      href: null,
      onClick: () => selected ? removeRecipient(publicProfile.did) : addRecipient(publicProfile.did),
      buttons: selected ? (
        <IconButton
          type="selected"
          white={selected}
          onClick={() => selected ? removeRecipient(publicProfile.did) : addRecipient(publicProfile.did)}
        />
      ) : null
    }
  })

  const selectedPublicProfiles = selectedprofiles
    .filter(pp => recipients.has(pp.did))

  useEffect(() => {
    if (recipients.size === 1) {
      const recipientDid = Array.from(recipients)[0]
      const recipientProfile = selectedPublicProfiles.find(profile => profile.did === recipientDid)
      setChatNamePlaceholder(recipientProfile ? recipientProfile.displayName || recipientProfile.username : '')
    } else {
      setChatNamePlaceholder('Chat Name*')
    }
  }, [recipients, selectedPublicProfiles])
  const isOrganizationSelected = false
  useEffect(() => {
    if (!isChatNameChanged) {
      setChatName(chatNamePlaceholder)
    }
  }, [chatNamePlaceholder, isChatNameChanged])
  const [createError, setCreateError] = useState()

  const create = () => {
    if (!chatName.trim()) {
      setIsChatNameValid(false)
      return
    }
    const apiKeyToUse = selectedOrgApikey || organizationApikey

    const selectedColor = fallbackColors[Math.floor(Math.random() * fallbackColors.length)]
    const chatImageValue = assetUrl || selectedColor

    createChatChannel({
      type: 'dm',
      organizationApikey: apiKeyToUse,
      memberUserDids: [...recipients],
      name: chatName || undefined,
      chatImage:chatImageValue
    }).then(onClose, setCreateError)
  }
  const empty = recipients.size === 0

  const handleSearchInput = (query) => {
    setFilter(query)
  }
  let organizationOptions = []

  if (organizations) {
    Array.from(organizations).map(organization => {
      organizationOptions.push({
        label: organization.name,
        value: organization.apikey,
      })
    })
  }
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const renderContentBasedOnViewType = () => {
    if (viewType === 'view1') {
      return (
        <>
          <div className="OrganizationChatPage-header-main"
            style={{fontWeight:'bold', borderBottom:'1px solid rgb(209, 211, 221)'}}>
            <IconButton onClick={onClose} type="arrowback"  /> <span style={{marginLeft:'1rem'}}> Choose People</span>
          </div>
          <Header size="md">
            <SearchInput
              placeholder="Search for a person"
              onInput={handleSearchInput}
              value={filter}
              onKeyDown={e => e.stopPropagation()}
              onClear={() => setFilter('')}
            />
          </Header>
          {Array.from(selectedPublicProfiles).length > 0 && <Header size="md"
            style={!empty ? { borderBottom: '1.09px solid #DBDDE1', padding: '1rem 10px' } : {}}>
            {!empty &&
             <ChipList people={Array.from(selectedPublicProfiles)} onDelete={handleDeleteRecipient}/>
            }
          </Header>}
          {people.length === 0 && organizationApikey && (
            <Header size="sm" italic>
            You are the only member of this {PNFO.singular}.
            </Header>
          )}
          {people?.length > 0
            ? <PeopleList {...{
              people,
              filter: filter?.toLowerCase() || undefined,
              onEmpty: !empty ? <Header size="sm" italic>
                No one matches your search
              </Header> : '',
            }} />
            :   <div className="chatlists" style={{padding:'1rem'}}>
              <Header size="lg" style={{padding:'0 1rem 1rem'}}>Suggestions</Header>
              <PeopleList people={suggestedProfiles.map(profile => {
                const selected = recipients.has(profile.did)
                return {
                  ...profile,
                  className: classNames('NewChatModal-recipient', { selected }),
                  href: null,
                  onClick: () => selected ? removeRecipient(profile.did) : addRecipient(profile.did),
                  buttons: selected ? (
                    <IconButton
                      type="selected"
                      white={selected}
                      onClick={() => selected ? removeRecipient(profile.did) : addRecipient(profile.did)}
                    />
                  ) : null
                }
              })} />
              <Header size="lg" style={{padding:'1rem'}}>Suggested Chats</Header>
              <ChatsList {...{
                chatHref,
                myPublicProfile,
                synopsis:limitedSynopsis,
                isPersonalChatPage: true,
              }}
              />
            </div>
          }

        </>
      )
    } else {
      // Placeholder for view2 content
      return(
        <>
          <div className="OrganizationChatPage-header-main"
            style={{fontWeight:'bold', borderBottom:'1px solid rgb(209, 211, 221)'}}>
            <IconButton onClick={() => setViewType('view1')}
              type="arrowback"  /> <span style={{marginLeft:'1rem'}}> New Chat</span>
          </div>
          <div className="NewChatModal-form" >
            <Header size="md" style={{fontWeight: 'bold', padding:'1rem'}}>
            Members
            </Header>
            <Header size="md" style={!empty ? { borderBottom: '1.09px solid #DBDDE1', padding: '1rem 10px' } : {}}>
              {!empty &&
             <ChipList people={Array.from(selectedPublicProfiles)} onDelete={handleDeleteRecipient}/>
              }
            </Header>
            <div style={{display:'flex', justifyContent:'space-between',
              gap:'2%', alignItems:'center', padding:'1rem'}}>
              <input type="file" onChange={handleFileChange} ref={fileInputRef} hidden  />
              <div onClick={triggerFileInputClick}>

                {assetUrl || chatImage ?
                  <div className="avatarImg1">
                    <img src={assetUrl} alt="Uploaded Asset"
                      style={{ width: '100%', height:'100%', objectFit:'cover', borderRadius:'10px' }} />
                  </div>
                  : <div className="avatarImg1">
                    <Icon type="chaty" />
                  </div>}
              </div>
              <TextInput
                style={{
                  width: '90%',
                  margin: 0,
                  borderColor: isChatNameValid ? 'initial' : 'red',
                }}
                position="right"
                placeholder={chatNamePlaceholder}
                value={chatName === 'Chat Name*' ? '' : chatName}
                onChange={value => {
                  setChatName(value)
                  setIsChatNameChanged(true)
                  if (!isChatNameValid) {
                    // Re-validate as user types
                    setIsChatNameValid(true)
                  }
                }}
              />
            </div>

            <Header size="sm" style={{ border: '1.09px solid #DBDDE1', borderRadius:'10px', margin:'1rem',
              display:'flex', justifyContent: 'space-around'}}>
              <Header size="md">
                <InterfaceHelp content={`Form deeper connections with others, either
                 privately by DM, with any small group, or the entire Hub.`}>
                  Chat Type* <Tooltip  text="Form deeper connections with others, either
                 privately by DM, with any small group, or the entire Hub." > <Icon type="stunted" /></Tooltip>
                </InterfaceHelp>

              </Header>
              <RadioInputGroup
                value={chatType}
                onChange={setChatType}
                options={[
                  {
                    label: <span>Direct</span>,
                  },
                  {
                    label: <span>Hubs</span>,
                  }
                ]}
                placeholder="choose animal"
              />
            </Header>
            <>
              {chatType === 1 &&
            <Header size="lg">

              <SearchInput
                placeholder="Hub Search "
                value={hubSearchQuery}
                onInput={e => setHubSearchQuery(e)}
              />
            </Header>
              }
              <Header size="lg" >
              Suggested Hubs
              </Header>
              {renderOrganizationList()}
            </>
          </div>
        </>
      )
    }
  }
  const isButtonDisabled = useMemo(() => {
    if (chatType === 1) {
      return !chatName || chatName === 'Chat Name*' || !selectedOrgApikey
    }
    return !chatName || chatName === 'Chat Name*'
  }, [chatType, chatName, selectedOrgApikey])


  return <> <div className="NewChatModal-form">
    <ErrorMessage error={createError} onDismiss={() => { setCreateError() }}/>
    {isOrganizationSelected && <Header size="md">
      <select
        className={'NewChatModal-form-select'}
        value={selectedOrganization}
        onChange={e => setSelectedOrganization(e.target.value)}
        placeholder="Select a hub"
      >
        {organizationOptions.map(option => (
          <option className={'NewChatModal-form-option'} key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Header>}
    {renderContentBasedOnViewType()}
  </div>
  <ButtonRow>
    <Button
      type="normal"
      value="cancel"
      onClick={onClose}
    />

    {viewType === "view1" ?
      <Button
        type="primary"
        value="Next"
        disabled={empty}
        className={empty ? 'button-disabled' : ''}
        onClick={handleNextOrCreate}
      /> :
      <Button
        type="primary"
        value="Create"
        disabled={isButtonDisabled}
        className={isButtonDisabled ? 'button-disabled' : ''}
        onClick={handleNextOrCreate}
      />
    }

  </ButtonRow>
  </>
}
