import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import richMedia from 'lib/richMedia'
import * as wysiwyg from 'lib/WYSIWYG'

import WYSIWYGInput from 'components/WYSIWYGInput'
import RichMediaInput from 'components/RichMediaInput'
import Button from 'components/Button'
import Icon from 'components/Icon'

export default function RichMediaOrWYSIWYGInput({ className, ...props }){
  const isWYSIWYG = valueIsWYSIWYG(props.value)
  props.className = classNames('RichMediaOrWYSIWYGInput', { className })
  return h(isWYSIWYG ? WYSIWYGInput : RichMediaInput, props)
}

RichMediaOrWYSIWYGInput.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

RichMediaOrWYSIWYGInput.Toggle = ({value, onChange, ...props}) => {
  const isWYSIWYG = valueIsWYSIWYG(value)
  const toggleIsWYSIWYG = () => {
    wysiwyg.setIsPreferred(!isWYSIWYG)
    onChange(richMedia[!isWYSIWYG ? 'convertToWYSIWYG' : 'convertFromWYSIWYG'](value))
  }

  const buttonValue = isWYSIWYG
    ? <span>
      <span className="hide-above-phone"><Icon size="md" type="doc" /></span>
      <span className="hide-below-phone">Use plain editor</span>
    </span>
    : <span>
      <span className="hide-above-phone"><Icon size="md" type="file-code" /></span>
      <span className="hide-below-phone">Use rich editor</span>
    </span>
  return <Button {...{
    ...props,
    type: 'none',
    onClick: toggleIsWYSIWYG,
    className: "RichMediaOrWYSIWYGInput-Toggle",
  }}>
    {buttonValue}
  </Button>
}

const valueIsWYSIWYG = value => value ? !richMedia.is(value) : false // wysiwyg.isPreferred()

RichMediaOrWYSIWYGInput.analyzeValue = value => {
  return valueIsWYSIWYG(value) ? wysiwyg.analyze(value) : richMedia.analyze(value)
}
