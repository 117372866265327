import { h } from 'preact'
import PathnameRouter from 'lib/PathnameRouter'

import UserProfileFeedPage from '../UserProfileFeedPage'
import UserProfileFeedPostPage from '../UserProfileFeedPostPage'
import UserProfileAboutPage from '../UserProfileAboutPage'
import UserProfileMembershipsPage from '../UserProfileMembershipsPage'
import UserProfileEditPage from '../UserProfileEditPage'

export default new PathnameRouter(map => {
  map('/', UserProfileFeedPage)
  map('/post/:feedPostUid', UserProfileFeedPostPage)
  map('/about', UserProfileAboutPage)
  map('/memberships', UserProfileMembershipsPage)
  map('/edit', UserProfileEditPage)
})
