import { h } from 'preact'
import { useMemo, useRef, useEffect, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import deepcopy from 'deepcopy'
import CKEditorReact from '@ckeditor/ckeditor5-react'
import CKEditor from 'components/CKEditor/build'

import classNames from 'lib/classNames'
import { useAppState } from 'lib/appState'
import { fileToImage } from 'lib/imageHelpers'
import { submitParentForm } from 'lib/DOMHelpers'

// special case containing styles for .ck-content
import 'components/WYSIWYGContent/index.sass'
import './index.sass'

export default function WYSIWYGEditor({
  className,
  disabled,
  unsaved,
  placeholder,
  value,
  onInput,
  onChange,
  onUploading,
  noUploads,
  autoFocus,
}){
  const { takeAction } = useAppState(undefined, 'WYSIWYGEditor')

  const rootRef = useRef()
  const uploadsRef = useRef(new Set())
  const fileInputRef = useRef(null)


  useEffect(() => {
    const handlePDFUploadClick = async (event) => {
      const editor = event.detail.editor

      if (!fileInputRef.current) {
        fileInputRef.current = document.createElement('input')
        fileInputRef.current.type = 'file'
        fileInputRef.current.accept = '.pdf'
        fileInputRef.current.style.display = 'none'

        fileInputRef.current.addEventListener('change', async (e) => {
          if (e.target.files.length) {
            const file = e.target.files[0]


            try {
              const url = await takeAction('assets.uploadDirect', file)

              if (url) {
                editor.model.change(writer => {
                  const linkText = writer.createText(file.name)

                  // The 'linkHref' attribute is used by the Link plugin to create hyperlinks.
                  writer.setAttribute('linkHref', url, linkText)

                  editor.model.insertContent(linkText, editor.model.document.selection)
                })
              }


            } catch (error) {
              console.error('Failed to upload PDF:', error)
            }
          }
        })

        document.body.appendChild(fileInputRef.current)
      }

      fileInputRef.current.click()
    }


    document.addEventListener('pdfUploadClicked', handlePDFUploadClick)

    // Cleanup the listener when the component unmounts.
    return () => {
      document.removeEventListener('pdfUploadClicked', handlePDFUploadClick)
      if (fileInputRef.current) {
        document.body.removeChild(fileInputRef.current)
      }
    }
  }, [])


  useEffect(
    () => {
      if (!autoFocus) return
      rootRef.current.querySelector('[contenteditable="true"]').focus()
    },
    [autoFocus]
  )

  const onUploadStart = useCallback(file => {
    uploadsRef.current.add(file)
    if (onUploading) onUploading(uploadsRef.current.size > 0)
  }, [])

  const onUploadEnd = useCallback(file => {
    uploadsRef.current.delete(file)
    if (onUploading) onUploading(uploadsRef.current.size > 0)
  }, [])


  // NOTE: it wont work to change noUploads dynamically
  const UploadAdapterPlugin = useMemo(
    () => (
      noUploads ? () => {} :
      createPlugin(editor => {
        editor.plugins.get('FileRepository').createUploadAdapter = loader => {
          const uploadAsset = file => takeAction('assets.uploadDirect', file)
          const uploadAdapter = new UploadAdapter()
          Object.assign(uploadAdapter, {loader, uploadAsset, onUploadStart, onUploadEnd})
          return uploadAdapter
        }
      })
    ),
    [noUploads, onUploadStart, onUploadEnd],
  )

  const config = useMemo(
    () => {
      const config = { placeholder }

      config.link = {
        defaultProtocol: 'https://',
        addTargetToExternalLinks: true,
        decorators: {
          addTargetToExternalLinks: {
            mode: 'automatic',
            callback: url => /^(https?:)?\/\//.test(url),
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        }
      }

      config.extraPlugins = [
        SubmitOnMetaEnterPlugin
      ]

      if (noUploads){
        config.toolbar = deepcopy(CKEditor.defaultConfig.toolbar)
        config.toolbar.items = config.toolbar.items
          .filter(item => !['imageUpload', 'mediaEmbed'].includes(item))
      }else{
        config.extraPlugins.push(UploadAdapterPlugin)
      }

      return config
    },
    [placeholder, noUploads]
  )

  return <div {...{
    ref: rootRef,
    className: classNames('WYSIWYGEditor', { className, unsaved })
  }}>
    <input {...{
      // This input is here so we have a backdoor to change the value
      // to make manipulating this input with puppeteer easier
      type: 'hidden',
      value,
      onChange: event => {
        const value = event.target.value
        if (onInput) onInput(value)
        else if (onChange) onChange(value)
      },
    }}/>
    <CKEditorReact {...{
      editor: CKEditor,
      data: value || '',
      disabled,
      config,
      onChange: useCallback(
        (event, editor) => {
          if (onInput) onInput(editor.getData())
        },
        [onInput],
      ),
      onBlur: useCallback(
        (event, editor) => {
          if (onChange) onChange(editor.getData())
        },
        [onChange],
      ),
    }}/>
  </div>
}

class UploadAdapter {
  startFakeProgress(){
    this.loader.uploadTotal = 100
    this.loader.uploaded = 0
    this.timeout
    this.progress = 0
    const fakeProgress = () => {
      if (this.progress >= 90) return
      clearTimeout(this.timeout)
      let newProgress = this.progress + Math.floor(Math.random() * 5) + 1
      if (newProgress > 90) newProgress = 90
      this.loader.uploaded = this.progress = newProgress
      this.timeout = setTimeout(fakeProgress, 200)
    }
    fakeProgress()
  }
  stopFakeProgress(){
    clearTimeout(this.timeout)
  }
  async upload() {
    this.file = await this.loader.file
    this.onUploadStart(this.file)
    this.startFakeProgress()
    this.image = await fileToImage(this.file)
    try{
      const url = await this.uploadAsset(this.file)
      return { default: url }
    }catch(error){
      console.error('WYSIWYGEditor failed to upload asset', error)
      return { default: generateUploadErrorImageUrl(this.file, this.image) }
    }finally{
      this.stopFakeProgress()
      this.onUploadEnd(this.file)
    }
  }
  abort() {
    // abort not implimented yet
    this.stopFakeProgress()
    this.onUploadEnd(this.file)
  }
}

WYSIWYGEditor.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  unsaved: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onUploading: PropTypes.func,
  noUploads: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

function generateUploadErrorImageUrl(file, image){
  let width = image && image.width || 0
  let height = image && image.height || 0
  width  = (width  < 200 || width  > 400) ? 200 : width
  height = (height < 100 || height > 400) ? 100 : height

  const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="${width}" height="${height}">
    <rect width="100%" height="100%" fill="black"/>
    <text fill="white" textLength="${width - 20}" width="100%" height="100%">
      <tspan x="10" dy="1.2em">⚠ Image failed to upload</tspan>
    </text>
  </svg>
  `.replace(/[\n\s]+/g, ' ').trim()
  return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg)
}

function createPlugin(handler){
  return class Plugin {
    constructor(editor){
      this.editor = editor
    }
    init(){
      return handler.call(this, this.editor)
    }
  }
}

const SubmitOnMetaEnterPlugin = createPlugin(editor => {
  editor.editing.view.document.on('enter', (event, data) => {
    if (data.domEvent.metaKey){
      event.stop()
      submitParentForm(data.domEvent.target)
    }
  })
})
