import { h } from 'preact'

import { setPageTitle } from 'lib/Page'

import { useSISAEvent } from 'resources/SISAs'

import NotFoundPage from '../NotFoundPage'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import SingleColumn from 'components/SingleColumn'
import HeaderedContentBox from 'components/HeaderedContentBox'
import SisaEventDescription from 'components/SisaEventDescription'

export default function OrganizationSisaEventPage(props){
  const { organization, params: { sisaEventId } } = props
  setPageTitle(`SISA Event - ${sisaEventId}`)

  const sisaEvent = useSISAEvent(sisaEventId, { reload: true })

  if (sisaEvent._notFoundAt) return <NotFoundPage />

  const linkToSisa = <Button
    type="normal"
    value="View SISA"
    href={`/${organization.apikey}/sisa`}
  />
  return <SingleColumn className="OrganizationSisaEventPage">
    <ErrorMessage error={sisaEvent._loadingError} />
    {sisaEvent._loadedAt
      ? <HeaderedContentBox padded header="SISA Event" buttons={linkToSisa}>
        <SisaEventDescription {...{
          organization,
          sisaEvent,
        }}/>
      </HeaderedContentBox>
      : <Loading type="fullPage"/>
    }
  </SingleColumn>
}
