import { useAppState } from 'lib/appState'
import useLoadedEntity from 'lib/useLoadedEntityHook'

export default function useLoadedCollection({
  entityKey,
  entityName = entityKey,
  memberKey,
  loadCollection,
  componentName,
}){
  const {
    [`${entityName}`]: collectionUids,
    [`${entityName}Loading`]: entityLoading,
    [`${entityName}LoadingError`]: entityLoadingError,
    [`${entityName}NotFound`]: entityNotFound,
  } = useLoadedEntity({
    entityKey,
    entityName,
    loadEntity: loadCollection,
    componentName,
  })

  const appStateKeys = {}
  if (collectionUids){
    Array.from(collectionUids).forEach(uid => {
      const appStateKey = memberKey(uid)
      appStateKeys[appStateKey] = uid
    })
  }

  const {
    ...members
  } = useAppState(
    appStateKeys,
    componentName,
  )

  let entity
  if (collectionUids){
    entity = {}
    Array.from(collectionUids).forEach(uid => {
      entity[uid] = members[uid]
    })
  }

  return {
    [`${entityName}`]: entity,
    [`${entityName}Loading`]: entityLoading,
    [`${entityName}LoadingError`]: entityLoadingError,
    [`${entityName}NotFound`]: entityNotFound,
  }
}
