import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import {
  postToPathname,
} from 'lib/feedPostHelpers'
import {
  useOrganizationFeedPostProvenance,
} from 'lib/feedPostHooks'
import {
  useOrganization,
  usePublicProfile,
} from 'lib/membershipAppStateHooks'
import {
  publicProfileToDisplayName,
} from 'lib/publicProfiles'
import humanizeNumber from 'lib/humanizeNumber'

import ErrorMessage from 'components/ErrorMessage'
import Alert from 'components/Alert'
import Loading from 'components/Loading'
import OrganizationIcon from 'components/OrganizationIcon'
import EndUserAvatar from 'components/EndUserAvatar'
import TimeAgo from 'components/TimeAgo'
import Link from 'components/Link'
import Icon from 'components/Icon'
import './index.sass'

export default function OrganizationFeedPostProvenance({
  className = '',
  feedPostUid,
  ...props
}){
  return <div
    {...props}
    className={`OrganizationFeedPostProvenance ${className}`}
  >
    <Content {...{feedPostUid}}/>
  </div>
}

OrganizationFeedPostProvenance.propTypes = {
  className: PropTypes.string,
  feedPostUid: PropTypes.string.isRequired,
}

function Content({ feedPostUid }){
  const {
    feedPostProvenance,
    feedPostProvenanceLoading,
    feedPostProvenanceLoadingError,
    feedPostProvenanceNotFound,
  } = useOrganizationFeedPostProvenance(
    feedPostUid,
    'OrganizationFeedPostProvenance',
  )

  if (feedPostProvenanceLoadingError)
    return <ErrorMessage error={feedPostProvenanceLoadingError} />

  if (feedPostProvenanceNotFound)
    return <Alert type="error">Feed post provenance not found</Alert>

  if (feedPostProvenanceLoading || !feedPostProvenance)
    return <Loading size="md" />

  return <div>
    {feedPostProvenance.map(ancestor =>
      <Ancestor {...{ key: ancestor.uid, ancestor}}/>
    )}
  </div>
}

function Ancestor({ ancestor }){
  if (!ancestor.posterUserDid && !ancestor.posterOrganizationApikey)
    return <OrganizationAction {...{ancestor, action: 'consumed'}}/>

  if (ancestor.feedOrganizationApikey && ancestor.visibleTo > 0)
    return <OrganizationAction {...{ancestor, action: 'published'}}/>

  if (ancestor.feedUserDid)
    return <UserAction {...{ancestor, action: 'posted'}} showToFeed/>

  if (!ancestor.parentUid){
    return ancestor.posterOrganizationApikey
      ? <OrganizationAction {...{ancestor, action: 'posted'}}/>
      : <UserAction {...{ancestor, action: 'posted'}} showToFeed/>
  }

  return ancestor.posterOrganizationApikey
    ? <OrganizationAction {...{ancestor, action: 'reposted'}}/>
    : <UserAction {...{ancestor, action: 'reposted'}} showToFeed/>
}

function OrganizationAction({ ancestor, action }){
  const organizationApikey = (
    ancestor.posterOrganizationApikey ||
    ancestor.feedOrganizationApikey
  )
  const { organization = {} } = useOrganization(
    organizationApikey,
    'OrganizationFeedPostProvenance.OrganizationAction'
  )
  const icon = <OrganizationIcon {...{organization}}/>
  const name = organization.name || organizationApikey
  return <Event {...{ ancestor, action, icon, name }}/>
}

function UserAction({ ancestor, organization, action, showToFeed }){
  const { publicProfile = {} } = usePublicProfile(
    ancestor.posterUserDid,
    'OrganizationFeedPostProvenance.UserAction'
  )
  const icon = <EndUserAvatar publicProfile={publicProfile} size="sm" />
  const name = publicProfileToDisplayName(publicProfile)
  return <Event {...{ ancestor, organization, action, showToFeed, icon, name }}/>
}

function Event({ ancestor, action, showToFeed, icon, name }){
  const { organization: organizationFeed } =
    useOrganization(ancestor.feedOrganizationApikey, 'OrganizationFeedPostProvenance.Event')
  const { publicProfile: feedUserPublicProfile } =
    usePublicProfile(ancestor.feedUserDid, 'OrganizationFeedPostProvenance.Event')
  const href = postToPathname(ancestor, feedUserPublicProfile)
  const toFeed = showToFeed && <span>
    &nbsp;to&nbsp;
    {
      organizationFeed ? organizationFeed.name :
      feedUserPublicProfile ? `their feed` :
      null
    }
  </span>
  return <Link
    className="OrganizationFeedPostProvenance-event"
    href={href}
  >
    {icon}
    <div>
      <div><span>{name}</span>&nbsp;<span>{action}</span>{toFeed}</div>
      <small>
        <TimeAgo time={ancestor.createdAt} format="full" />
        {ancestor.visibleTo > 0 || <Counts {...{ancestor}}/>}
      </small>
    </div>
  </Link>
}

function Counts({ ancestor }){
  return <Fragment>
    <span title="elevated">
      <Icon type="up-bold"/>&nbsp;
      {humanizeNumber(ancestor.upvoteCount)}
    </span>
    <span title="comments">
      <Icon type="comment"/>&nbsp;
      {humanizeNumber(ancestor.commentCount)}
    </span>
  </Fragment>
}
