import { useEffect } from 'preact/hooks'

import usePrevious from 'lib/usePreviousHook'

export default function useOnSuccessHook(doingIt, error, cb) {
  const previouslyDoingIt = usePrevious(doingIt)
  useEffect(
    () => {
      if (previouslyDoingIt && !doingIt && !error) cb()
    },
    [doingIt, error]
  )
}
