import { useEffect, useMemo } from 'preact/hooks'
import { useAppState, useAppActions } from 'lib/appState'
import useLoadedEntity from 'lib/useLoadedEntityHook'


const appStateKey = (organizationApikey, channel) =>
  `chat:${organizationApikey}:${channel}`

export function useChatChannelsForPerson(componentName){
  return useLoadedEntity({
    entityKey: 'chatChannels',
    loadAction: 'chat.loadChatChannelsForPerson',
    componentName,
  })
}


export function useChatChannelsForPublicProfile({ publicProfileDid, organizationApikey }, componentName){
  return useLoadedEntity({
    entityName: 'chatChannels',
    entityKey: `chatChannels:${organizationApikey}:${publicProfileDid}`,
    loadEntity: takeAction => takeAction(
      'chat.loadChatChannelsForPublicProfile', { organizationApikey, publicProfileDid }
    ),
    componentName,
  })
}


export function countUnreadMessages(synopsis){
  return Array.from(synopsis)
    .filter(s => !s.is_notifications_muted)
    .map(s => s.unread || 0)
    .reduce((a, b) => a + b, 0)
}

export function useChatSynopsis(componentName){
  return useLoadedEntity({
    entityKey: 'chatSynopsis',
    loadAction: 'chat.loadSynopses',
    // loadEntity: takeAction => takeAction('chat.loadSynopses'),
    componentName,
  })
}


export function useOrganizationChatSynopsis(organizationApikey, componentName) {
  const {
    chatSynopsisLoading: organizationChatSynopsisLoading,
    chatSynopsisLoadingError: organizationChatSynopsisLoadingError,
    chatSynopsis,
  } = useChatSynopsis(componentName)
  const organizationChatSynopsis = useMemo(
    () => chatSynopsis && chatSynopsis
      .filter(c => c.organizationApikey === organizationApikey),
    [chatSynopsis],
  )
  return {
    organizationChatSynopsisLoading,
    organizationChatSynopsisLoadingError,
    organizationChatSynopsis,
  }
}

export function useCreateChatChannel(componentName){
  return useAppActions(componentName).appAction('chat.createChatChannel')
}

export function useLeaveChatChannel() {
  const { appAction } = useAppActions()

  const leaveChannel = appAction('chat.leaveChatChannel')

  return leaveChannel
}

export function useDeleteChatChannel(componentName) {
  const { appAction } = useAppActions(componentName)

  const deleteChatChannel = appAction('chat.deleteChatChannel')

  return {
    deleteChatChannel
  }
}

export function useAddMembersToChatChannel(componentName) {
  const { appAction } = useAppActions(componentName)

  const addMembersToChatChannel = appAction('chat.addMembersToChatChannel')

  return addMembersToChatChannel
}

export function useRemoveMembersFromChatChannel(componentName) {
  const { appAction } = useAppActions(componentName)
  const removeMembersFromChatChannel = appAction('chat.removeMembersFromChatChannel')

  return removeMembersFromChatChannel
}

export function useAssignChatChannelAdminRole(componentName) {
  const { appAction } = useAppActions(componentName)

  const assignChatChannelAdminRole = appAction('chat.assignChatChannelAdminRole')


  return assignChatChannelAdminRole
}

export function useRemoveChatChannelAdminRole(componentName) {
  const { appAction } = useAppActions(componentName)

  const removeChatChannelAdminRole = appAction('chat.removeChatChannelAdminRole')

  return removeChatChannelAdminRole
}

export function useChatChannelMembershipDetails(chatChannelUid, componentName) {
  const { appAction } = useAppActions(componentName)
  const {
    chatChannelMembershipDetails,
    loadingChatChannelMembershipDetails,
    errorLoadingChatChannelMembershipDetails
  } = useAppState({
    [`chat:channel:${chatChannelUid}:membershipDetails`]: 'chatChannelMembershipDetails',
    [`chat:channel:${chatChannelUid}:membershipDetails:loading`]: 'loadingChatChannelMembershipDetails',
    [`chat:channel:${chatChannelUid}:membershipDetails:error`]: 'errorLoadingChatChannelMembershipDetails'
  }, componentName)

  const loadChatChannelMembershipDetails = appAction('chat.getChatChannelMembershipDetails', chatChannelUid)

  return {
    chatChannelMembershipDetails,
    loadingChatChannelMembershipDetails,
    errorLoadingChatChannelMembershipDetails,
    loadChatChannelMembershipDetails
  }

}

export function useAddChatChannelDetails(componentName) {
  const { appAction } = useAppActions(componentName)
  const addChatChannelDetails =  appAction('chat.addChatChannelDetails')
  return addChatChannelDetails
}

export function usePendingChatMessages(chatChannelUid, componentName){
  const {
    pendingChatMessages
  } = useAppState(
    {
      [`chat:channel:${chatChannelUid}:messages:pending`]: 'pendingChatMessages',
    },
    componentName,
  )
  return { pendingChatMessages }
}

export function useChatMessages(chatChannelUid, componentName){
  return useLoadedEntity({
    entityName: 'chatMessages',
    // entityKey: `${organizationApikey}:chatMessages:${channel}`,
    entityKey: `chat:channel:${chatChannelUid}:messages`,
    loadEntity: takeAction => {
      if (chatChannelUid) takeAction('chat.loadMessages', chatChannelUid)
    },
    componentName,
  })
}

export function useLoadStarredChatMessages(chatChannelUid, componentName){
  return useLoadedEntity({
    entityName: 'starredChatMessages',
    entityKey: `chat:channel:${chatChannelUid}:starredMessages`,
    loadEntity: takeAction => takeAction('chat.loadStarredMessages', chatChannelUid),
    componentName
  })
}

export function useFasterChatPolling(componentName){
  const { appAction } = useAppState([], componentName)
  const set = appAction('chat.setFasterChatPolling')
  useEffect(() => { set(true); return () => { set(false) } }, [])
}

export function useCreateChatMessage(organizationApikey, channel, componentName){
  const {
    appAction,
    creatingChatMessage,
    creatingChatMessageError,
  } = useAppState(
    {
      [`${appStateKey(organizationApikey, channel)}:creating`]: 'creatingChatMessage',
      [`${appStateKey(organizationApikey, channel)}:creating:error`]: 'creatingChatMessageError',
    },
    componentName,
  )

  const createChatMessage = appAction('chat.createMessage', organizationApikey, channel)

  return {
    createChatMessage,
    creatingChatMessage,
    creatingChatMessageError,
  }
}

export function useEditChatMessage(organizationApikey, channel, componentName){
  const {
    appAction,
    editingChatMessage,
    editingChatMessageError,
  } = useAppState(
    {
      [`${appStateKey(organizationApikey, channel)}:editing`]: 'editingChatMessage',
      [`${appStateKey(organizationApikey, channel)}:editing:error`]: 'editingChatMessageError',
    },
    componentName,
  )

  const editChatMessage = appAction('chat.editMessage', organizationApikey, channel)

  return {
    editChatMessage,
    editingChatMessage,
    editingChatMessageError,
  }
}

export function useReplyToMessage(organizationApikey, channel, componentName) {
  const {
    appAction,
    replyingToMessage,
    replyingToMessageError,
  } = useAppState(
    {
      [`${appStateKey(organizationApikey, channel)}:replying`]: 'replyingChatMessage',
      [`${appStateKey(organizationApikey, channel)}:replying:error`]: 'replyingChatMessageError',
    },
    componentName,
  )
  const replyToMessage = appAction('chat.replyToMessage', organizationApikey, channel)


  return {
    replyToMessage,
    replyingToMessage,
    replyingToMessageError,
  }
}


export function useMarkChatMessagesAsRead(organizationApikey, channel, componentName){
  const { appAction } = useAppActions(componentName)
  return {
    markChatMessagesAsRead:
      appAction('chat.markAsRead', organizationApikey, channel),
  }
}

export function useChangeChatChannelAttributes(componentName){
  const { appAction } = useAppActions(componentName)
  return {
    changeChatChannelAttributes:
      appAction('chat.changeChatChannelAttributes'),
  }
}


export function useForwardChatMessage(componentName) {
  const { appAction } = useAppActions(componentName)

  return {
    forwardMessage:  appAction('chat.forwardChatMessage')
  }
}


export function useStarChatMessage(componentName){
  const { appAction } = useAppActions(componentName)
  return {
    starChatMessage: appAction('chat.starMessage'),
    unstarChatMessage: appAction('chat.unstarMessage'),
  }
}


export function useSearchInChat(chatChannelUid, keyword, componentName){
  return useLoadedEntity({
    entityKey: 'chatSearchResults',
    loadEntity: takeAction => takeAction('chat.searchInChat', chatChannelUid, keyword),
    componentName
  })
}

export function useDeleteChatMessage(chatChannelUid, componentName) {
  const {
    appAction,
    deletingChatMessage,
    deletingChatMessageError,
  } = useAppState(
    {
      [`${appStateKey(chatChannelUid)}:deleting`]: 'deletingChatMessage',
      [`${appStateKey(chatChannelUid)}:deleting:error`]: 'deletingChatMessageError',
    },
    componentName,
  )

  const deleteChatMessage = (messageUid, deletionReason = "", AdminComment = "",) => {
    return appAction(
      "chat.deleteMessage",
      chatChannelUid,
      messageUid,
      { deletionReason, AdminComment }
    )
  }

  return {
    deleteChatMessage,
    deletingChatMessage,
    deletingChatMessageError,
  }
}

export function useUndoDeleteChatMessage(chatChannelUid, componentName) {
  const { appAction } = useAppState(componentName)
  const { chatMessages } = useChatChannel(chatChannelUid, componentName)

  const undoDeleteChatMessage = async (chatChannelUid, messageUid, action) => {
    try {
      const undeletedMessage = await appAction(
        "chat.undoDeleteMessage",
        chatChannelUid,
        messageUid,
        action,
      )
      chatMessages.push(undeletedMessage)
    } catch (error) {
      console.error(`Failed to undo delete chat message`, error)
      throw error
    }
  }

  return {
    undoDeleteChatMessage,
  }
}


export function useCreateChatMessageReaction(chatChannelUid, componentName) {
  const { appAction } = useAppActions(componentName)

  const createChatMessageReaction = async (messageUid, reaction) => {
    try {
      return await appAction('chat.createChatMessageReaction', chatChannelUid, messageUid, reaction)
    } catch (error) {
      console.error(`Failed to create chat message reaction`, error)
      throw error
    }
  }

  return { createChatMessageReaction }
}

export function useRemoveChatMessageReaction(chatChannelUid, componentName) {
  const { appAction } = useAppActions(componentName)

  const removeChatMessageReaction = async (messageUid, reaction) => {
    try {
      return await appAction('chat.removeChatMessageReaction', chatChannelUid, messageUid, reaction)
    } catch (error) {
      console.error(`Failed to remove chat message reaction`, error)
      throw error
    }
  }

  return { removeChatMessageReaction }
}


export function useChatChannel(chatChannelUid, componentName = 'default') {
  const channelKey = `chat:channel:${chatChannelUid}`
  const { appAction } = useAppActions(componentName)
  const { chatSynopsis = [] } = useChatSynopsis(componentName)
  const chatChannel = chatSynopsis.find(c => c.uid === chatChannelUid)


  const {
    chatMessages,
    pendingChatMessages = new Set(),
    creatingChatMessage,
    creatingChatMessageError,
    editingChatMessage,
    editingChatMessageError,
    replyingToMessage,
    replyingToMessageError,
    deletingChatMessage,
    deletingChatMessageError,


  } = useAppState(
    {
      [`${channelKey}:messages`]: 'chatMessages',
      [`${channelKey}:messages:pending`]: 'pendingChatMessages',
      [`${channelKey}:creating`]: 'creatingChatMessage',
      [`${channelKey}:creating:error`]: 'creatingChatMessageError',
      [`${channelKey}:editing`]: 'editingChatMessage',
      [`${channelKey}:editing:error`]: 'editingChatMessageError',
      [`${channelKey}:replying`]: 'replyingToMessage',
      [`${channelKey}:replying:error`]: 'replyingToMessageError',
      [`${channelKey}:deleting`]: 'deletingChatMessage',
      [`${channelKey}:deleting:error`]: 'deletingChatMessageError',
      [`${channelKey}:starredMessages`]: 'starredChatMessages',

    },
    componentName,
  )
  return {
    ...useChatMessages(chatChannelUid, componentName),
    chatChannel,
    chatMessages,
    pendingChatMessages: Array.from(pendingChatMessages)
      .sort(sortByCreatedAtDescending),
    creatingChatMessage,
    creatingChatMessageError,
    editingChatMessage,
    editingChatMessageError,
    replyingToMessage,
    replyingToMessageError,
    deletingChatMessage,
    deletingChatMessageError,
    createChatMessage: appAction('chat.createMessage', chatChannelUid),
    editChatMessage: appAction('chat.editMessage'),
    loadMessages: appAction('chat.loadMessages', chatChannelUid),
    markChatMessagesAsRead: appAction('chat.markAsRead', chatChannelUid),
    replyToMessage: appAction('chat.replyToMessage'),
    deleteChatMessage: appAction('chat.deleteMessage'),
    undoDeleteChatMessage: appAction('chat.undoDeleteMessage'),
    starredChatMessages: appAction('chat.loadStarredMessages', chatChannelUid),
    forwardMessage: appAction('chat.forwardMessage'),
    starChatMessage: appAction('chat.starMessage'),
    unstarChatMessage: appAction('chat.unstarMessage'),
    createChatMessageReaction: appAction('chat.createChatMessageReaction'),
    removeChatMessageReaction: appAction('chat.removeChatMessageReaction'),
    searchInChat: appAction('chat.searchInChat', chatChannelUid),
    leaveChannel: appAction('chat.leaveChatChannel'),
    deleteChatChannel: appAction('chat.deleteChatChannel'),
  }
}

const sortByCreatedAtDescending = (a, b) =>
  new Date(a.createdAt) - new Date(b.createdAt)
