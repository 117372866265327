import { h } from 'preact'
import PropTypes from 'prop-types'
import inspect from 'lib/inspect'
import './index.sass'

export default function InspectObject({ object, indentation }){
  let string
  if (typeof object === 'function'){
    string = object.toString()
  }else if (typeof object === 'undefined'){
    string = 'undefined'
  }else{
    try{
      string = inspect(object, indentation)
    }catch(error){
      string = `ERROR: ${error}`
    }
  }
  return <pre className="InspectObject">
    <code>{string}</code>
  </pre>
}

InspectObject.propTypes = {
  object: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  indentation: PropTypes.number.isRequired,
}

InspectObject.defaultProps = {
  indentation: 4,
}
