import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

export default function ChatChannelAvatar({
  className,
  size,
  value,
  children,
  bordered,
  ...props
}){
  return <div
    {...props}
    className={classNames('ChatChannelAvatar', {
      className, bordered, [size]: 1,
    })}
  >{value || children}</div>
}

ChatChannelAvatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
  value: PropTypes.node,
  children: PropTypes.node,
  bordered: PropTypes.bool,
}
ChatChannelAvatar.defaultProps = {
  size: 'lg',
  bordered: false,
}
