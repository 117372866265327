import { useEffect, useCallback } from 'preact/hooks'

export default function usePageKeyPress(onPageKeyDown, args = []){
  const _onPageKeyDown = useCallback(onPageKeyDown, args)
  useEffect(
    () => {
      const onKeyPress = event => {
        if (
          event.target.nodeName === 'INPUT' ||
          event.target.nodeName === 'TEXTAREA' ||
          event.target.isContentEditable
        ) return
        return _onPageKeyDown(event)
      }
      global.document.addEventListener('keypress', onKeyPress, true)
      return () => {
        global.document.removeEventListener('keypress', onKeyPress, true)
      }
    },
    [_onPageKeyDown]
  )
}
