import logger from 'lib/logger'

export default function(organizationApikey){
  if (!organizationApikey) throw new Error('organizationApikey is required')

  const updatingOrganizationAccountData =
    this.getState()[`updatingOrganizationAccountData:${organizationApikey}`]

  if (updatingOrganizationAccountData){
    logger.warn(
      'refusing to clear organizationAccountDataStagedChanges ' +
      'because were currently updating OrganizationAccountData'
    )
  }

  this.setState({
    [`organizationAccountDataStagedChanges:${organizationApikey}`]: undefined,
    [`updatingOrganizationAccountDataError:${organizationApikey}`]: undefined,
  })
}
