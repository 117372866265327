import api from '../api'

export default function(){
  const { defaultAccountDataStagedChanges } = this.getState()

  this.setState({
    updatingDefaultAccountData: true,
    updatingDefaultAccountDataError: undefined,
  })

  return api.updateDefaultAccountData({
    defaultAccountData: defaultAccountDataStagedChanges
  }).then(
    defaultAccountData => {
      this.setState({
        defaultAccountData: defaultAccountData,
        updatingDefaultAccountData: undefined,
        defaultAccountDataStagedChanges: undefined,
      })
    },
    updatingDefaultAccountDataError => {
      this.setState({
        updatingDefaultAccountDataError,
        updatingDefaultAccountData: undefined,
      })
    }
  )
}
