export default function debounce(func, wait, waitForFirstCall){
  function debounced(...args){
    if (debounced.timeout) return
    const delta = debounced.lastCalled
      ? wait - (Date.now() - debounced.lastCalled)
      : 0
    if (delta <= 0){
      debounced.lastCalled = Date.now()
      func(...args)
    }else{
      debounced.timeout = setTimeout(
        () => {
          delete debounced.timeout
          func(...args)
        },
        delta
      )
    }
  }

  if (waitForFirstCall) debounced.lastCalled = Date.now()

  debounced.clear = function(){
    if (debounced.timeout){
      clearTimeout(debounced.timeout)
      delete debounced.timeout
    }
  }

  return debounced
}
