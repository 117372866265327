import { h } from 'preact'
import { useAppState } from 'lib/appState'

export function useEditOrganization(organizationApikey, componentName){
  const {
    appAction,
    updatingOrganization,
    updatingOrganizationError,
  } = useAppState(
    {
      [`organization:${organizationApikey}:updating`]: 'updatingOrganization',
      [`organization:${organizationApikey}:updatingError`]: 'updatingOrganizationError',
    },
    componentName,
  )
  const updateOrganization = appAction('organization.update', organizationApikey)
  const clearUpdatingOrganizationError = appAction('organization.clearUpdateError', organizationApikey)

  return {
    updateOrganization,
    updatingOrganization,
    updatingOrganizationError,
    clearUpdatingOrganizationError,
  }
}

export function useRequestOrganizationDeletion(organizationApikey, componentName){
  const {
    appAction,
    deletionRequesting,
    deletionRequestError,
    deletionRequested
  } = useAppState(
    {
      [`organization:${organizationApikey}:deletionRequesting`]: 'deletionRequesting',
      [`organization:${organizationApikey}:deletionRequestError`]: 'deletionRequestError',
      [`organization:${organizationApikey}:deletionRequested`]: 'deletionRequested',
    },
    componentName,
  )

  const requestDeletion = appAction('organization.requestOrganizationDeletion', organizationApikey)
  const clearDeletionError = appAction('organization.clearDeletionRequestError', organizationApikey)

  return {
    requestDeletion,
    deletionRequesting,
    deletionRequestError,
    deletionRequested,
    clearDeletionError
  }
}
