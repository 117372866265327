import { useEffect } from 'preact/hooks'
import useForceUpdate from 'lib/useForceUpdate'

export default function useOnlineStatus(handler){
  const forceUpdate = useForceUpdate()
  useEffect(
    () => {
      window.addEventListener('online', forceUpdate)
      window.addEventListener('offline', forceUpdate)
      return () => {
        window.removeEventListener('online', forceUpdate)
        window.removeEventListener('offline', forceUpdate)
      }
    },
    [handler]
  )
  return window.navigator.onLine
}
