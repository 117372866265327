import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import copy from './copy'

export default function generateSteps({ isMember, destinationPath }) {

  const homePage = {
    position: 'bl',
    pathname: '/',
    selector: '.Navbar .Layout-TopNav-My-Feed',
    title: copy.homePage.title,
    content: copy.homePage.content,
  }

  const shareFeedPost = {
    position: 'br',
    pathname: '/',
    selector: '.OrganizationFeedPost-shareButton',
    title: copy.shareFeedPost.title,
    content: copy.shareFeedPost.content,
  }

  const goToForumPost = {
    position: 'br',
    pathname: '/',
    selector: '.OrganizationFeedPost-goToForumPost',
    title: copy.goToForumPost.title,
    content: copy.goToForumPost.content,
  }

  const feedPostTruLine = {
    position: 'br',
    pathname: '/',
    selector: '.OrganizationFeedPost-truLine',
    title: copy.feedPostTruLine.title,
    content: copy.feedPostTruLine.content,
  }

  const repostFeedPost = {
    position: 'br',
    pathname: '/',
    selector: '.OrganizationFeedPost-repost',
    title: copy.repostFeedPost.title,
    content: copy.repostFeedPost.content,
  }

  const allHubsPage = {
    position: 'bl',
    pathname: `/${PNFO.plural}/all`,
    selector: '.Navbar .Layout-TopNav-Hubs',
    title: copy.allHubsPage.title,
    content: copy.allHubsPage.content,
  }

  const createAHub = {
    position: 'tl',
    pathname: `/${PNFO.plural}/all`,
    selector: '.HubsPage-createButton',
    title: copy.createAHub.title,
    content: copy.createAHub.content,
  }

  const publicProfilePage = {
    position: 'bl',
    pathname: '/@:username',
    selector: '.Navbar .LinkToPublicProfile .EndUserAvatar',
    title: copy.publicProfilePage.title,
    content: copy.publicProfilePage.content,
  }

  const hubAboutPage = {
    position: 'tr',
    pathname: '/:organizationApikey',
    selector: '.OrganizationPage-NameAndStats',
    title: copy.hubAboutPage.title,
    // content: copy.hubAboutPage.content,
    content: <div>
      <p>
        {APP_NAME} connects people to real social groups called {PNFO.plural}, that connect to
        each other in networks of {PNFO.plural}. You can customize this page and make it
        stand out with simple inline tools to format images and text.
      </p>
    </div>,
  }

  const hubPublishedPage = {
    position: 'tr',
    pathname: '/:organizationApikey/published',
    selector: '.OrganizationPage-navButton-Published',
    title: copy.hubPublishedPage.title,
    content: copy.hubPublishedPage.content,
  }

  const hubPeoplePage = {
    position: 'tl',
    pathname: '/:organizationApikey/members',
    selector: '.OrganizationPage-navButton-Members',
    title: copy.hubPeoplePage.title,
    content: copy.hubPeoplePage.content,
  }

  const hubForumPage = {
    position: 'tl',
    pathname: '/:organizationApikey/forum',
    selector: '.OrganizationPage-navButton-Forum',
    title: copy.hubForumPage.title,
    content: copy.hubForumPage.content,
  }

  const postToTheForum = {
    position: 'tli',
    pathname: '/:organizationApikey/forum',
    selector: '.FeedPostForm',
    spotlightOptions: { circular: false },
    title: copy.postToTheForum.title,
    content: copy.postToTheForum.content,
  }

  const joinToBrowseTheForum = {
    position: 'tl',
    pathname: '/:organizationApikey/forum',
    selector: '.OrganizationMembershipButton',
    spotlightOptions: { circular: true },
    title: copy.joinToBrowseTheForum.title,
    content: copy.joinToBrowseTheForum.content,
  }

  const chat = {
    position: 'bl',
    // pathname: '/:organizationApikey/forum',
    // selector: '.OrganizationPage-navButton-Chat',
    selector: '.ChatNotificationsButton',
    title: `Chat`,
    content: <div>
      <p>
        Form deeper connections with others, either privately by DM, with any
        small group, or the entire {PNFO.singular}.
      </p>
    </div>,
  }

  const helpButton = {
    pathname: destinationPath,
    position: 'bl',
    selector: '.Layout-HelpDropdownButton',
    title: copy.helpButton.title,
    content: copy.helpButton.content,
  }

  const order = []
  const add = step => order.push(step)
  add(hubAboutPage)
  add(hubPublishedPage)
  add(hubForumPage)
  if (isMember) add(postToTheForum)
  add(hubPeoplePage)
  if (!isMember) add(joinToBrowseTheForum)
  add(homePage)
  add(shareFeedPost)
  add(goToForumPost)
  add(feedPostTruLine)
  add(repostFeedPost)
  add(allHubsPage)
  add(createAHub)
  add(chat)
  add(publicProfilePage)
  add(helpButton)
  return order
}
