import { h } from 'preact'

import { createClassComponent } from 'lib/preactHelpers'

import Form from 'components/Form'
import './index.sass'

const FeedPostFormWithPreview =
  createClassComponent('FeedPostFormWithPreview')

export default FeedPostFormWithPreview

FeedPostFormWithPreview.ScrollArea =
  createClassComponent('FeedPostFormWithPreview-ScrollArea')

FeedPostFormWithPreview.ButtonRow =
  createClassComponent('FeedPostFormWithPreview-ButtonRow', Form.ButtonRow)
