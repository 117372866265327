import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import StyleishModal from 'components/StyleishModal'
import OrganizationForumFeedPostDeleteModal from 'components/OrganizationForumFeedPostDeleteModal'
import './index.sass'

export default function FeedPostDeleteModal({
  className,
  post,
  ...props
}){
  return <StyleishModal scrollInternally {...{
    title: 'Delete Feed Post',
    ...props,
    className: classNames('FeedPostDeleteModal', {className}),
  }}>
    <OrganizationForumFeedPostDeleteModal {...{post}}/>
  </StyleishModal>
}

FeedPostDeleteModal.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired,
}
