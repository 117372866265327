import { h } from 'preact'
import Icon from 'components/Icon'
import Button from 'components/Button'
import Header from 'components/Header'
import './index.sass'


export function NoCoversationSelected({ openNewChatModal }) {
  return <div className="OrganizationChatPage-NoCoversationSelected">
    <div>
      <Icon type="chat" size="lg" />
      <Header size="lg">Your Chats</Header>
      <Header size="md">Start a new chat in a hub with other hub members or directly with a person or group.</Header>
      <Button type="primary" value="Create Chat" onClick={openNewChatModal} />
    </div>
  </div>
}
