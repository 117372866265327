import { h } from 'preact'
import { useRef } from 'preact/hooks'

import { setPageTitle } from 'lib/Page'

import { useLocationQueryState } from 'resources/location'
import aServer from 'resources/aServer'

import Button from 'components/Button'
import Form from 'components/Form'
import TextInput from 'components/TextInput'
import InspectObject from 'components/InspectObject'

export default function DataPage(){
  const inputRef = useRef()
  const [viewName, setViewName] = useLocationQueryState({query: 'v'})
  setPageTitle(`data - ${viewName}`)

  const onSubmit = () => {
    const viewName = TextInput.inputFromRef(inputRef).value
    aServer.views.reload(viewName)
    setViewName(viewName)
  }
  return <div>
    <Form {...{onSubmit}}>
      <Form.ButtonRow>
        <TextInput {...{
          id: 'view_name',
          name: 'view_name',
          autoCorrect: 'on',
          autoComplete: 'on',
          className: 'flex-grow',
          ref: inputRef,
          placeholder: 'view name',
          value: viewName,
        }}/>
        <Button type="primary" submit>load</Button>
      </Form.ButtonRow>
    </Form>
    {viewName && <View {...{key: viewName, viewName}} />}
  </div>
}

function View({ viewName }){
  const view = aServer.views.use(viewName, { reload: true })
  return <div>
    <InspectObject object={view}/>
  </div>
}
