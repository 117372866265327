import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Button from 'components/Button'
import './index.sass'

export default function Switch({
  className,
  unsaved,
  onChange,
  inverted,
  saving,
  disabled = saving,
  ...props
}) {
  const checked = 'value' in props ? props.value : props.checked
  delete props.value
  delete props.checked

  className = classNames('Switch', {
    checked: inverted ? !checked : checked,
    saving,
    disabled,
    unsaved,
    className,
  })

  const onClick = useCallback(
    () => { onChange(!checked) },
    [onChange, checked]
  )


  return <div className={className}>
    <Button {...{
      onClick,
      ...props,
      disabled,
      type: 'none',
      className: 'Switch-pseudo',
    }}>
      <div className="Switch-ball" />
    </Button>
  </div>
}

Switch.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  unsaved: PropTypes.bool.isRequired,
  className: PropTypes.string,
  inverted: PropTypes.bool,
  saving: PropTypes.bool,
  disabled: PropTypes.bool,
}
