import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import HeaderedContentBox from 'components/HeaderedContentBox'
import OrganizationInfo from 'components/OrganizationInfo'
import IconButton from 'components/IconButton'

export default function OrganizationInfoContentBox({
  className,
  organization,
  isAdmin,
  buttons,
  minimal = false,
  ...props
}){
  const headerButtons = []
  if (isAdmin) headerButtons.push(
    <IconButton subtle type="edit"
      href={`/${organization.apikey}/admin/info`}
    />
  )
  return <HeaderedContentBox {...{
    header: `More Info about ${organization.name}`,
    padded: true,
    ...props,
    className: classNames('OrganizationInfoContentBox', { className }),
    buttons: headerButtons,
  }}>
    <OrganizationInfo {...{organization, buttons, minimal}}/>
  </HeaderedContentBox>
}

OrganizationInfoContentBox.propTypes = {
  className: PropTypes.string,
  minimal: PropTypes.bool,
  displayCurators: PropTypes.bool,
  ...OrganizationInfo.propTypes,
}
