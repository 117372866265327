import { useLocalStorage } from 'lib/storageHooks'

export default function useHasSeenInterfaceWalkthrough(){
  const [
    hasSeenInterfaceWalkthrough,
    setHasSeenInterfaceWalkthrough,
  ] = useLocalStorage('APortalInterfaceWalkthrough.seen')

  return {
    hasSeenInterfaceWalkthrough,
    setHasSeenInterfaceWalkthrough,
  }
}

