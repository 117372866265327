import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Loading from 'components/Loading'
import Link from 'components/Link'
import ErrorMessage from 'components/ErrorMessage'
import Icon from 'components/Icon'
import './index.sass'

export default function ChatUrlPreview({
  className,
  loading,
  invalidUrl,
  url,
  type,
  title,
  description,
  image,
  text,
  canClosed,
  onCloseUrlPreview,
  sendUrlPreview,
}){

  if (!sendUrlPreview) return null

  const hostname = urlToHostname(url)

  const link = children =>
    <Link href={url} newWindow>{children}</Link>

  const linkImage = src =>
    link(<img {...{src}}/>)

  let error
  if (invalidUrl) error = `Failed to load preview for "${url}"`

  const media = (
    loading ? <Loading type="block" /> :
    error ? <ErrorMessage error={error} dismissable={false}/> :
    type === 'text' ? <pre>{text}</pre> :
    type === 'image' ? linkImage(url) :
    // type === 'audio' ?
    // type === 'video' ?
    (type === 'html' && image) ? linkImage(image) :
    undefined
  )


  return <div className={classNames('ChatUrlPreview', { className, loading })} >
    {media && <div className="ChatUrlPreview-media">{media}</div>}
    {
      canClosed && (
        <Icon type={'close'} onClick={onCloseUrlPreview} className="ChatUrlPreview-close" />
      )
    }
    <div className="ChatUrlPreview-details">
      {hostname &&
        <Link
          className="ChatUrlPreview-hostname"
          href={`https://${hostname}`}
          newWindow
        >{hostname.toUpperCase()}</Link>
      }
      <Link href={url} newWindow className="ChatUrlPreview-title">{title || url}</Link>
      {description && <div className="ChatUrlPreview-description">{description}</div>}
    </div>
  </div>
}

ChatUrlPreview.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  invalidUrl: PropTypes.bool,
  url: PropTypes.string,
  hostname: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  text: PropTypes.string,
  canClosed: PropTypes.bool,
  onCloseUrlPreview: PropTypes.func,
  sendUrlPreview: PropTypes.bool,
}

function urlToHostname(url){
  try{ return (new URL(url)).hostname }catch(e){ /**/ }
}
