import { useMemo, useEffect } from 'preact/hooks'

import awaitFor from 'lib/awaitFor'
import useForceUpdate from 'lib/useForceUpdate'

export default function useAsyncCall(asyncFunc, args = [], options = {}){
  const { debounce: debounceMs = 100 } = options
  const forceUpdate = useForceUpdate()
  const state = useMemo(
    () => ({ asyncFunc, args, debounceMs }),
    [asyncFunc, ...args],
  )
  useEffect(
    () => {
      callAsyncFunc(state, forceUpdate)
      return () => { state.cancelled = true }
    },
    [state],
  )
  return state
}

async function callAsyncFunc(state, forceUpdate){
  if (state.debounceMs) await awaitFor(state.debounceMs)
  if (state.cancelled) return
  state.loading = true
  forceUpdate()
  try{
    state.result = await state.asyncFunc(...state.args)
  }catch(error){
    state.error = error
  }
  if (state.cancelled) return
  state.loading = false
  forceUpdate()
}


