import defer from 'lib/defer'
import { isPWA } from 'lib/PWAHelpers'
import { createInMemoryStore } from 'lib/stores'
import { onAuthChange } from './session'
import { isLoggedIn } from './auth'
import { onLocationChange } from './location'

const state = createInMemoryStore({
  redirectingToBrandingSite: false,
})

const BRANDING_PATHS = [
  '/video',
  '/networks',
  '/movements',
  '/invest',
]

export function isRedirectingToBrandingSite(){
  return !!state.get().redirectingToBrandingSite
}

function handleRedirects(){
  if (isRedirectingToBrandingSite()) return
  const loggedIn = isLoggedIn()
  const inPWA = isPWA()
  const pathname = window.location.pathname.toLowerCase()

  const redirectTo = location => {
    state.set({ redirectingToBrandingSite: true })
    window.location.replace(location)
  }

  if (!loggedIn && pathname === '/')
    return redirectTo(inPWA ? '/login' : BRANDING_SITE_URL)

  if (inPWA) return
  for (const p of BRANDING_PATHS)
    if (pathname === p) return redirectTo(`${BRANDING_SITE_URL}${p}`)
}
// HOOKS

export function useRedirectingToBrandingSite(){
  return state.useStore(s => [s.redirectingToBrandingSite])[0]
}


// INIT

handleRedirects()
defer(() => {
  onLocationChange(handleRedirects)
  onAuthChange(handleRedirects)
})
