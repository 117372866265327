import api from '../api'

export async function invite({ organizationApikey, publicProfileDid }) {
  const invitingKey = `invitingUserToOrganization`
  const errorKey = `${invitingKey}:error`

  if (this.getState()[invitingKey]) return

  this.setState({
    [invitingKey]: true,
    [errorKey]: undefined,
  })

  try {
    await api.inviteUserToOrganization({ organizationApikey, publicProfileDid })
  } catch(error) {
    this.setState({ [errorKey]: error })
  } finally {
    this.setState({ [invitingKey]: undefined })
  }
}
