import { useRef, useLayoutEffect } from 'preact/hooks'
import shallowCompare from 'zustand/shallow'

import defer from 'lib/defer'
import pickProps from 'lib/pickProps'
import storageBus from 'lib/storageBus'
import useForceUpdate from 'lib/useForceUpdate'

export function createInMemoryStore(state, compare = shallowCompare){
  const { sub, publish, destroy } = createPubSub()
  const deferPublish = createDeferredChangeReporter((next, prev) => {
    if (!compare(prev, next)) publish(next, prev)
  })
  const get = () => ({...state})
  state = get()
  const set = createSet(get, (next, prev) => {
    state = next
    deferPublish(next, prev)
  })
  const useStore = createUseStoreHook(get, sub)
  const api = { get, set, sub, destroy, useStore }
  return api
}

export function createStorageStore(options){
  const {
    key,
    storage = localStorage,
    serialize = serializeJSON,
    deserialize = deserializeJSON,
    default: _default = {},
  } = options

  function get(){
    const json = storageBus.getItem(storage, key)
    return json ? deserialize(json) : {..._default}
  }

  const set = createSet(get, next => {
    if (next !== undefined){
      next = serialize(next)
      if (next === '{}') next = undefined
    }
    storageBus.setItem(storage, key, next)
  })

  const sub = listener =>
    storageBus.subscribe(event => {
      if (event.storageArea !== storage || event.key !== key) return
      listener(deserialize(event.newValue), deserialize(event.oldValue))
    })

  const destroy = () => {
    // TBD: would need to wrap unsub returned from sub and call all here
  }

  const useStore = createUseStoreHook(get, sub)

  const api = { get, set, sub, destroy, useStore }

  return api
}

export function createLocalStorageStore(key, _default){
  const options = typeof key === 'string' ? { key } : key
  key = options.key
  options.storage = window.localStorage
  options.default = _default
  return createStorageStore(options)
}

// TODO: consider moving this. its only really for record
// cache stores. it also wont work for nested state objects.
export async function tx(store, name, block){
  if (store.get()[`${name}`]) {
    throw new Error(`already ${name}!`)
  }
  store.set({
    [`${name}`]: true,
    [`${name}Error`]: undefined,
  })
  try{
    return await block()
  }catch(error){
    // TODO consider:
    //  A) ensuring error is instance of Error
    //  B) attaching a clear callback here
    // error.clear = () => {
    //   store.set({ [`${name}Error`]: undefined })
    // }
    console.error(error)
    store.set({
      [`${name}Error`]: error
    })
  }finally{
    store.set({
      [`${name}`]: undefined,
    })
  }
}

function createPubSub(){
  const listeners = new Set()
  function sub(listener){
    listeners.add(listener)
    return () => { listeners.delete(listener) }
  }
  function publish(...args){
    listeners.forEach(l => { l(...args) })
  }
  const destroy = () => { listeners.clear() }
  return { sub, publish, destroy }
}

function createUseStoreHook(get, sub){
  function useStore(getSlice = (x => x), equalityFn = shallowCompare){
    if (Array.isArray(getSlice) || getSlice instanceof Set) {
      const props = Array.from(getSlice)
      getSlice = state => pickProps(state, props)
      getSlice.toString = () => JSON.stringify(props)
    }
    const forceUpdate = useForceUpdate()
    const ref = useRef()
    const state = getSlice(get())
    useLayoutEffect(() => {
      Object.assign(ref, { state, getSlice, equalityFn })
    })
    useLayoutEffect(
      () =>
        sub(() => {
          const { state, getSlice, equalityFn } = ref
          const newState = getSlice(get())
          if (!equalityFn(state, newState)){
            ref.state = newState
            forceUpdate()
          }
        })
      ,
      [],
    )
    return state
  }
  return useStore
}

export function stripUndefinedProps(object){
  Object.keys(object).forEach(key => {
    if (object[key] === undefined) delete object[key]
  })
}

function createSet(get, onChange){
  function set(partial, replace){
    const prev = get()
    let next = typeof partial === 'function' ? partial(prev) : partial
    if (next === prev) return
    if (!replace) next = {...prev, ...next}
    stripUndefinedProps(next)
    onChange(next, prev)
    return next
  }
  return set
}

const serializeJSON = object => (object && JSON.stringify(object))
const deserializeJSON = json => (json && JSON.parse(json)) || {}


/*
This is a special kind of debounce where we collect the oldest
previous state and the latest new state until the next available
frame so we can delay reporting state changes to subrs
and avoid calling them in cases where the state is changed and
then unchanged before the next available reporting frame.
*/
export function createDeferredChangeReporter(report, delay){
  let timeout
  let firstPrev
  let lastNext
  function reportChange(next, prev){
    lastNext = next
    if (timeout) return
    firstPrev = prev
    const doit = () => {
      timeout = null
      catchAndLog(() => {
        const args = [lastNext, firstPrev]
        lastNext = firstPrev = undefined // release memory ref
        report(...args)
      })
    }
    timeout = defer(doit, delay)
  }
  return reportChange
}

function catchAndLog(func){
  try{ return func() }catch(error){ console.error(error) }
}
