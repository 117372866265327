import { useLayoutEffect } from 'preact/hooks'

import defer from 'lib/defer'
import useForceUpdate from 'lib/useForceUpdate'

export function createStore(options){
  const {
    values = {},
    // could swap in localStorage here
    get: _get = key => values[key],
    set: _set = (key, value) => {
      typeof value === 'undefined' ? delete values[key] : values[key] = value
    },
    keys: _keys = () => Object.keys(values),
    isEqual = (a, b) => a === b,
  } = options

  function clear(){
    for (const key of _keys()) set(key, undefined)
  }

  const subs = new Set()

  function sub(keys, handler){
    keys = new Set(Array.from(keys))
    const sub = { keys, handler }
    subs.add(sub)
    return () => { subs.delete(sub) }
  }

  function pub(keys){
    keys = [...keys]
    subs.forEach(sub => {
      if (!keys.some(key => sub.keys.has(key))) return
      try{
        sub.handler()
      }catch(error){
        console.error(error)
      }
    })
  }

  const destroy = () => { subs.clear() }

  const get = key => _get(key)

  let keyChangeTimeoutId
  const changedKeys = {}
  const deferKeyChange = key => {
    if (!(key in changedKeys)) changedKeys[key] = _get(key)
    if (!keyChangeTimeoutId)
      keyChangeTimeoutId = defer(emitKeyChange)
  }
  const emitKeyChange = () => {
    keyChangeTimeoutId = undefined
    const keys = new Set()
    for (const key in changedKeys){
      if (!isEqual(changedKeys[key], get(key))) keys.add(key)
      delete changedKeys[key]
    }
    if (keys.size > 0) pub(keys)
  }

  const set = (key, value) => {
    deferKeyChange(key)
    _set(key, value)
  }

  function useStore(keys){
    if (!keys) keys = []
    const arrayOfKeys = Array.isArray(keys)
    if (!arrayOfKeys) keys = [keys]
    const forceUpdate = useForceUpdate()
    useLayoutEffect(
      () => sub(keys, forceUpdate),
      [...keys],
    )
    let result = keys.map(get)
    if (!arrayOfKeys) result = result[0]
    return result
  }

  return { values, clear, get, set, sub, destroy, use: useStore }
}
