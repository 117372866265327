import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import TextInput from 'components/TextInput'

import './index.sass'

export default function TextInputWithPrefix({
  className, children, prefix, ...props
}){
  return <div className={classNames('TextInputWithPrefix', { className })}>
    <div onClick={focusInput}>{`${prefix}`}</div>
    <TextInput {...{type: 'text', ...props}}/>
    {children}
  </div>
}

TextInputWithPrefix.propTypes = {
  className: PropTypes.string,
  prefix: PropTypes.string.isRequired,
  children: PropTypes.node,
}

function focusInput(event){
  const input = event.target.parentElement.querySelector('input')
  if (input){
    input.focus()
    input.select()
  }
}
