import pathToRegexp from 'path-to-regexp'

export default function pathnameMatcher(pathname){
  function locationAt(pattern){
    const params = pathnameMatcher.match(pathname, pattern)
    if (params) {
      locationAt.params = params
      return true
    }else{
      delete locationAt.params
      return false
    }
  }
  return locationAt
}

pathnameMatcher.match = (pathname, pattern) => {
  const keys = []
  const regexp = pathToRegexp(pattern, keys)
  const matches = regexp.exec(pathname)
  if (!matches) return false
  const params = {}
  keys.forEach((key, index) => {
    params[key.name] = matches[index + 1]
  })
  return params
}
