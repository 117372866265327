import { h } from 'preact'
import Loading from 'components/Loading'
import { ChatChannel } from './ChatChannel'
import { NewDMChatChannel } from './NewDMChatChannel'
import { NoCoversationSelected } from './NoCoversationSelected'
import './index.sass'


export function CurrentChat({
  synopsis,
  currentChat,
  setForwarding,
  chatChip,
  openforwardWindow,
  setOpenforwardWindow,
  channelUidsToForwardTo,
  setSelectedChats,
  setChatChip,
  toggleChatSelection,
  ...props }) {
  if (!synopsis){
    return <Loading type="block" />
  }

  const chatChannelDetails = synopsis.find(channel => channel.uid === currentChat?.chatChannelUid)
  const chatChannelType = chatChannelDetails ? chatChannelDetails.type : null
  if (currentChat && currentChat.dm) {
    return (
      <NewDMChatChannel
        {...{
          ...props,
          synopsis,
          memberUserDids: [currentChat.dm],
        }}
      />
    )
  }


  if (currentChat && currentChat.chatChannelUid) {
    return (
      <ChatChannel
        {...{
          ...props,
          synopsis,
          unread: currentChat.unread,
          chatChannelSynopsis: synopsis.find(
            (s) => s.uid === currentChat.chatChannelUid
          ),
          chatChannelUid: currentChat.chatChannelUid,
          chatChannelType,
          setForwarding,
          chatChip,
          openforwardWindow,
          setOpenforwardWindow,
          channelUidsToForwardTo,
          setSelectedChats,
          setChatChip,
          toggleChatSelection
        }}
      />
    )
  }

  if (currentChat && currentChat.chatChannelUid && synopsis.find(s => s.uid
      === currentChat.chatChannelUid).isDeletedForUser) {
    return <div>This chat has been deleted.</div>
  }
  return <NoCoversationSelected {...props} />
}
