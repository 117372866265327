import { h } from 'preact'
import { useRef, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import IconButton from 'components/IconButton'
import TextInput from 'components/TextInput'
import InterfaceHelp from 'components/InterfaceHelp'
import './index.sass'

export default function SearchInput({
  className, interfaceHelp, ...props
}){
  const inputRef = useRef()
  const empty = typeof props.value !== 'string' || props.value === ""

  const focus = useCallback(
    () => { TextInput.focus(inputRef) },
    [],
  )

  const clearFilter = useCallback(
    () => {
      if (props.onChange) props.onChange("")
      else if (props.onInput) props.onInput("")
    },
    [props.onChange, props.onInput],
  )

  const clear = useCallback(
    () => {
      if (!props.onClear || props.onClear() !== false)
        clearFilter()
    },
    [props.onClear]
  )

  const onKeyDown = useCallback(
    event => {
      if (event.key === "Escape") clear()
      if (props.onKeyDown) props.onKeyDown(event)
    },
    [props.onKeyDown]
  )

  return <InterfaceHelp {...{
    ...interfaceHelp,
    Component: 'div',
    className: classNames('SearchInput', {empty, className}),
  }}>
    <TextInput
      {...props}
      ref={inputRef}
      onKeyDown={onKeyDown}
    />
    <IconButton
      className="SearchInput-button"
      type={empty ? 'search' : 'cancel-circled'}
      onClick={empty ? focus : clear}
      onFocus={focus}
      tabIndex={-1}
    />
  </InterfaceHelp>
}

SearchInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  interfaceHelp: PropTypes.object,
}
