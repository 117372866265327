import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Link from 'components/Link'
import Header from 'components/Header'
import TimeAgo from 'components/TimeAgo'
import './index.sass'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'

const MEDIA_ATTACHMENT_PREFIX = 'jlinc:dm:attachment:'

export default function ChatChannelListMember({
  className,
  avatar,
  preview,
  name,
  date,
  badge,
  isPersonalChatPage,
  unread,
  isSelected,
  forwarding,
  href,
  onToggleSelection,
  ...props
}) {


  const { is_unread: isUnread, is_notifications_muted: isNotificationsMuted, is_pinned: isPinned, uid } = props


  function preventDefault(e) {
    e.preventDefault()
  }

  function handleChatClick(e) {
    if (forwarding) {
      e.preventDefault()
      e.stopPropagation()
      onToggleSelection(uid)
      return
    }
  }

  return (
    <Link
      className={classNames('ChatChannelListMember', { className })}
      href={href}
      onClick={handleChatClick}
    >
      {avatar}
      <div className="ChatChannelListMember-details">
        <Header size="md">{name}</Header>
        {preview && (
          <div className="ChatChannelListMember-preview">
            <span>
              {preview.startsWith(MEDIA_ATTACHMENT_PREFIX)
                ? previewStringForMedia(preview).substring(0, 37)
                : stripHTML(preview).substring(0, 100) }
            </span>
            <div>
              {date && (
                <Fragment>
                  <TimeAgo time={date} tiny />
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
      {badge}
      {isPersonalChatPage && (
        <div onClick={preventDefault} className={"ChatChannelListMember-optionsMenu"}>
          {isUnread && !unread  && <Icon
            blue type="circle" size="sm" className="ChatChannelListMember-optionsMenu-Icons-unreadBadge" />}
          {isNotificationsMuted && <Icon
            blue type="mute" size="lg" className="ChatChannelListMember-optionsMenu-Icons-bell" />}
          {isPinned && <Icon
            blue type="pin" size="md" className="ChatChannelListMember-optionsMenu-Icons-pin" />}
        </div>
      )}
      <div style={{padding:'0.5rem'}}>
        {forwarding && !isSelected &&
        <IconButton type="unchecked" />
        }
        {forwarding && isSelected &&
       <IconButton type="selected" />
        }
      </div>
    </Link>
  )
}

ChatChannelListMember.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.node.isRequired,
  preview: PropTypes.string,
  isSelected: PropTypes.bool,
  forwarding: PropTypes.bool,
  onToggleSelection: PropTypes.func,
  name: PropTypes.string.isRequired,
  date: PropTypes.string,
  badge: PropTypes.node,
  isPersonalChatPage: PropTypes.bool,
  synopsis: PropTypes.array,
  is_unread: PropTypes.bool,
  is_notifications_muted: PropTypes.bool,
  is_pinned: PropTypes.bool,
  uid: PropTypes.string,
  unread: PropTypes.number,
  href: PropTypes.string,
}

const previewStringForMedia = preview =>
  preview.match(/"type":"([^"]+)"/)
    ? `sent ${RegExp.$1}`
    : 'sent media attachment'

function stripHTML(html){
  const div = global.document.createElement('div')
  div.innerHTML = html
  return div.textContent.trim()
}
