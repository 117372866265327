import { h } from "preact"
import { useState, useEffect, useRef } from "preact/hooks"
import PropTypes from "prop-types"
import IconButton from "components/IconButton"
import PeopleList from "components/PeopleList"
import { usePublicProfiles } from "lib/membershipAppStateHooks"
import { useMyPublicProfileDid } from "resources/auth"
import Icon from "components/Icon"
import {
  useRemoveMembersFromChatChannel,
  useAssignChatChannelAdminRole,
  useRemoveChatChannelAdminRole,
  useChatChannelMembershipDetails,
  useChatChannel,
} from "lib/chatHooks"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import StyleishModal from "components/StyleishModal"
import "./index.sass"

export default function ChatInfoPanel({
  open,
  onClose,
  handleOpenGroupEditModal: handleOpenGroupEditModal,
  chatChannel: chatChannelUid,
  handleOpenAddChatMembersModal,
  ...props
}) {
  const { chatChannel } = useChatChannel(chatChannelUid, "ChatInfoPanel")
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [membershipDetails, setMembershipDetails] = useState(null)
  const dropdownRef = useRef(null)
  const memberUserDids = chatChannel?.memberUserDids || []
  const myPublicProfileDid = useMyPublicProfileDid()

  const { publicProfiles } = usePublicProfiles(
    [...memberUserDids],
    "ManageChatMembersModal"
  )

  const assignChatChannelAdminRole =
    useAssignChatChannelAdminRole("ChatInfoPanel")
  const removeChatChannelAdminRole =
    useRemoveChatChannelAdminRole("ChatInfoPanel")
  const removeMembersFromChatChannel =
    useRemoveMembersFromChatChannel("ChatInfoPanel")
  const {
    loadingChatChannelMembershipDetails,
    errorLoadingChatChannelMembershipDetails,
    loadChatChannelMembershipDetails,
  } = useChatChannelMembershipDetails(chatChannelUid, "ManageMembersModal")

  useEffect(() => {
    if (chatChannelUid) {
      loadChatChannelMembershipDetails(chatChannelUid)
        .then((details) => {
          setMembershipDetails(details)
        })
        .catch((error) => {
          console.error("Error loading membership details:", error)
        })
    }
  }, [chatChannelUid, loadChatChannelMembershipDetails])

  const isUserAdmin = (memberDid) => {
    return membershipDetails?.memberships?.some(
      (membership) => membership.memberUserDid === memberDid && membership.isAdmin
    )
  }
  const isAdmin = membershipDetails && isUserAdmin(myPublicProfileDid)

  if (loadingChatChannelMembershipDetails) {
    return <div>Loading membership details...</div>
  }

  if (errorLoadingChatChannelMembershipDetails) {
    return (
      <div>
        Error loading membership details:{" "}
        {errorLoadingChatChannelMembershipDetails}
      </div>
    )
  }

  const updateMembershipDetails = () => {
    loadChatChannelMembershipDetails(chatChannelUid)
      .then((details) => {
        setMembershipDetails(details)
      })
      .catch((error) => {
        console.error("Error loading updated membership details:", error)
      })
  }

  const handleRemoveAdmin = (event, memberDid) => {
    event.stopPropagation()
    removeChatChannelAdminRole(chatChannelUid, memberDid)
      .then(() => {
        updateMembershipDetails(chatChannelUid)
      })
      .catch((error) => {
        console.error("Failed to remove admin role:", error)
      })
  }

  const handleRemoveFromGroup = (event, memberDid) => {
    event.stopPropagation()

    // eslint-disable-next-line no-alert
    const confirmed = confirm("You are removing this member from the group.")

    if (confirmed) {
      removeMembersFromChatChannel(chatChannelUid, [memberDid])
        .then(() => {
          setActiveDropdown(null)
        })
        .catch((error) => {
          console.error("Failed to remove member:", error)
        })
    }
  }

  const handleMakeAdmin = (event, memberDid) => {
    event.stopPropagation()
    assignChatChannelAdminRole(chatChannelUid, memberDid)
      .then(() => {
        updateMembershipDetails(chatChannelUid)
      })
      .catch((error) => {
        console.error("Failed to remove admin role:", error)
      })
  }

  const handleToggleDropdown = (did) => {
    setActiveDropdown(activeDropdown === did ? null : did)
  }

  const getChatMembersCount = chatChannel?.memberUserDids.length

  return (
    <TransitionGroup component={null}>
      {open && (
        <CSSTransition
          timeout={300}
          classNames="modal-transition"
          unmountOnExit
        >
          <div className="ChatInfoPanel">
            <div className="ChatInfoPanel-header">
              <IconButton
                type="return"
                onClick={onClose}
                className="ChatInfoPanel-header-returnButton"
              />
              <h2>Chat Info</h2>
            </div>

            <div className="ChatInfoPanel-content">
              <div className="ChatInfoPanel-chatName">
                {chatChannel &&
                          (chatChannel?.imageUrl.startsWith("#") ? (
                            <div
                              className="avatarImg EditChatInfoModal-preview"
                              style={{backgroundColor: chatChannel.imageUrl}}
                            >
                              <Icon type="chaty" />
                            </div>
                          ) : (
                            <img
                              src={chatChannel?.imageUrl}
                              alt="Chat Image"
                              className="avatarImg EditChatInfoModal-preview"
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "5px",
                              }}
                            />
                          ))}
                <div className="ChatInfoPanel-chatNameAndMemberCount">
                  {chatChannel?.chatName}
                  {getChatMembersCount && (
                    <span className="chatMembersCount">
                      {getChatMembersCount} Members
                    </span>
                  )}
                </div>

                {isAdmin && (
                  <IconButton
                    type="edit"
                    style={{ marginLeft: "8px" }}
                    onClick={handleOpenGroupEditModal}
                  />
                )}
              </div>

              <div>
                <hr className="sectionDivider" />
              </div>

              <div className="ChatInfoPanel-Description">
                <span>Description</span>
                <div>
                  {chatChannel?.description}
                  {isAdmin && (
                    <IconButton
                      type="edit"
                      style={{ marginLeft: "8px" }}
                      onClick={handleOpenGroupEditModal}
                    />
                  )}
                </div>
              </div>

              <span>
                <hr className="sectionDivider" />
              </span>

              <div
                style={{ paddingLeft: "10px" }}
                className="ChatInfoPanel-AddMembers"
              >
                Members
                {isAdmin && (
                  <IconButton
                    type="create"
                    style={{ margin: "10px" }}
                    onClick={handleOpenAddChatMembersModal}
                  />
                )}
              </div>

              <ChatInfoPanelForm
                {...{
                  ...props,
                  onClose,
                  handleMakeAdmin,
                  handleRemoveAdmin,
                  handleRemoveFromGroup,
                  activeDropdown,
                  setActiveDropdown,
                  chatChannel,
                  chatChannelMembershipDetails: membershipDetails,
                  memberUserDids,
                  publicProfiles: publicProfiles,
                  handleToggleDropdown: handleToggleDropdown,
                  dropdownRef,
                  isAdmin,
                }}
              />
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

ChatInfoPanel.propTypes = {
  open: StyleishModal.propTypes.open,
  onClose: StyleishModal.propTypes.onClose,
  handleOpenGroupEditModal: PropTypes.func.isRequired,
  chatChannel: PropTypes.string.isRequired,
  handleOpenAddChatMembersModal: PropTypes.func.isRequired,
}

function ChatInfoPanelForm({
  handleMakeAdmin,
  handleRemoveAdmin,
  handleRemoveFromGroup,
  activeDropdown,
  setActiveDropdown,
  chatChannelMembershipDetails,
  publicProfiles,
  handleToggleDropdown,
  isAdmin,
}) {
  const isUserAdmin = (memberDid) => {
    return chatChannelMembershipDetails?.memberships?.some(
      (membership) =>
        membership.memberUserDid === memberDid && membership.isAdmin
    )
  }

  const people = publicProfiles.map((publicProfile) => {
    const isAdminn =
      chatChannelMembershipDetails && isUserAdmin(publicProfile.did)

    return {
      ...publicProfile,
      className: "AssignAdminModal-recipient",
      isAdmin: isAdminn && true,
      buttons: isAdmin && (
        <div className="dropdown-container">
          <svg
            onClick={() => handleToggleDropdown(publicProfile.did)}
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-menu-vertical-dots"
          >
            <circle cx="12" cy="12" r="1" />
            <circle cx="12" cy="5" r="1" />
            <circle cx="12" cy="19" r="1" />
          </svg>
          {activeDropdown === publicProfile.did && (
            <ul className="dropdown-menu">
              <li
                onClick={(event) =>
                  handleRemoveFromGroup(event, publicProfile.did)
                }
              >
                Remove from Group
              </li>
              {isAdminn ? (
                <li
                  onClick={(event) =>
                    handleRemoveAdmin(event, publicProfile.did)
                  }
                >
                  Remove Admin
                </li>
              ) : (
                <li
                  onClick={(event) => handleMakeAdmin(event, publicProfile.did)}
                >
                  Make Admin
                </li>
              )}
            </ul>
          )}
        </div>
      ),
    }
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdowns = document.querySelectorAll('.dropdown-menu')
      let isClickInsideDropdown = false

      dropdowns.forEach(dropdown => {
        if (dropdown.contains(event.target)) {
          isClickInsideDropdown = true
        }
      })
      if (!isClickInsideDropdown) {
        setActiveDropdown(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setActiveDropdown])

  return (
    <div className="AssignAdminModal-form">
      <PeopleList {...{ people }} />

      {people.map((person) => (
        <div key={person.did}>{person.dropdown}</div>
      ))}
    </div>
  )
}
