export async function getFilePreview(file){
  return {
    name: file.name,
    size: file.size,
    type: file.type,
    ...(
      file.type.match('image') ? await loadImagePreviewFromFile(file) :
      file.type.match('video') ? await loadVideoPreviewFromFile(file) :
      file.type.match('audio') ? await loadAudioPreviewFromFile(file) :
      {}
    ),
  }
}

export function readFileAsDataURL(file){
  return new Promise((resolve, reject) => {
    const fileReader = new global.FileReader()
    fileReader.onerror = reject
    fileReader.onload = () => { resolve(fileReader.result) }
    fileReader.readAsDataURL(file)
  })
}

export function readFileAsArrayBuffer(file){
  return new Promise((resolve, reject) => {
    const fileReader = new global.FileReader()
    fileReader.onerror = reject
    fileReader.onload = () => { resolve(fileReader.result) }
    fileReader.readAsArrayBuffer(file)
  })
}

export function loadImage(src){
  return new Promise((resolve, reject) => {
    var img = global.document.createElement('img')
    img.onerror = reject
    img.onload = () => { resolve(img) }
    img.src = src
  })
}

export async function loadImagePreviewFromFile(file){
  const imageDataURI = await readFileAsDataURL(file)
  const img = await loadImage(imageDataURI)
  return {
    url: imageDataURI,
    height: img.naturalHeight,
    width: img.naturalWidth,
  }
}

export function loadVideoPreviewFromSrc(src){
  return new Promise((resolve, reject) => {
    const video = global.document.createElement('video')
    const tryToGetVideoPreview = () => {
      const previewImageDataURI = videoToPreviewImageDataURI(video)
      if (previewImageDataURI){
        video.pause()
        video.removeEventListener('loadeddata', tryToGetVideoPreview)
        video.removeEventListener('timeupdate', tryToGetVideoPreview)
        resolve({
          url: previewImageDataURI,
          height: video.videoHeight,
          width: video.videoWidth,
        })
      }
    }
    video.addEventListener('loadeddata', tryToGetVideoPreview)
    video.addEventListener('timeupdate', tryToGetVideoPreview)
    video.preload = 'metadata'
    video.onerror = (error) => {
      console.error(error)
      reject(new Error(`failed to load video file`))
    }
    video.setAttribute('crossorigin', 'anonymous')
    video.crossOrigin = 'anonymous'
    video.src = src
    // Load video in Safari / IE11
    video.muted = true
    video.playsInline = true
    video.play()
  })
}

export function videoToPreviewImageDataURI(video){
  const canvas = global.document.createElement('canvas')
  canvas.width = video.videoWidth
  canvas.height = video.videoHeight
  canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
  const dataURI = canvas.toDataURL()
  return dataURI.length > 100000 ? dataURI : false
}

export async function loadVideoPreviewFromFile(file){
  const videoData = await readFileAsArrayBuffer(file)
  const blob = new global.Blob([videoData], {type: file.type})
  const url = URL.createObjectURL(blob)
  try{
    const preview = await loadVideoPreviewFromSrc(url)
    preview.type = file.type
    return preview
  }catch(error){
    throw error
  }finally{
    URL.revokeObjectURL(url)
  }
}

export async function loadAudioPreviewFromFile(/*file*/){
  // TODO determin audio length
  return {}
}

