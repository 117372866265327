import { h } from 'preact'
import IconButton from 'components/IconButton'
import './index.sass'


export function ChatMessageFailed({ error, message, upload }) {
  return <IconButton
    className="OrganizationChatPage-ChatMessageFailed"
    onClick={() => {
      console.error(error)
      console.log({ message, upload })
    }}
  >!</IconButton>
}
