import { h } from 'preact'
import { useReducer, useRef } from 'preact/hooks'
import { memo } from 'preact/compat'

import { useAppState } from 'lib/appState'
import { usePortal } from 'lib/portal'
import useAutoFocus from 'lib/useAutoFocusHook'

import Link from 'components/Link'
import ButtonRow from 'components/ButtonRow'
import Button from 'components/Button'
import StyleishModal from 'components/StyleishModal'

const HARD_REFRESH_LINK = 'https://elod.in/how-to-do-a-hard-refresh-in-every-browser'

const NewAppVersionDetectedPrompt = memo(() => {
  const {
    appAction,
    newAppVersionDetected,
    updatingAppVersion,
  } = useAppState(
    [
      'newAppVersionDetected',
      'updatingAppVersion',
    ],
    'NewAppVersionDetectedPrompt'
  )

  const update = appAction('newVersion.update')

  const [dismissed, dismiss] = useReducer(() => true, false)

  const buttonRef = useRef()
  useAutoFocus(buttonRef, [])

  return usePortal()(
    <StyleishModal
      className="NewAppVersionDetectedPrompt"
      title="Please reload"
      open={newAppVersionDetected && !dismissed}
      onClose={updatingAppVersion ? undefined : dismiss}
      disabled={updatingAppVersion}
      scrollInternally
    >
      <p>
        We've detected a newer version of {APP_NAME}.
      </p>
      <p>
        Please save anything you might have been working
        on and reload this page.
      </p>
      <p>
        <span>If this message persists, please try a </span>
        <Link
          newWindow
          type="link"
          href={HARD_REFRESH_LINK}
          tabIndex={-1}
        >hard-refresh</Link>.
      </p>
      <ButtonRow>
        <Button
          ref={buttonRef}
          type="primary"
          onClick={update}
          disabled={updatingAppVersion}
          autoFocus
        >Reload</Button>
      </ButtonRow>
    </StyleishModal>
  )
})

export default NewAppVersionDetectedPrompt
