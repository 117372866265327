import { h } from 'preact'
import { useState } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'
import { setPageTitle } from 'lib/Page'

import ContentBox from 'components/ContentBox'
import HeaderedContentBox from 'components/HeaderedContentBox'
import SearchInput from 'components/SearchInput'

import PublicOrganizationsList from 'components/PublicOrganizationsList'
import './index.sass'


export default function SuperAdminPage() {

  const [textFilter, setTextFilter] = useState('')

  setPageTitle(`${PNFO.plural} - All`)

  return (
    <div className={`HubsPage`}>

      <SearchInput
        value={textFilter}
        onInput={setTextFilter}
        placeholder="Filter…"
        className="HubsPage-search"

      />
      <ContentBox className="HubsPage-Nav">
        <HeaderedContentBox header={`All ${PNFO.plural}`} icon="circle-thin">
          <PublicOrganizationsList textFilter={textFilter} showMembershipPills />
        </HeaderedContentBox>

      </ContentBox>
    </div >
  )
}
