
import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import { useConfirmationBefore } from 'lib/confirmationHooks'
import { usePublicProfile } from 'lib/membershipAppStateHooks'

import { setLocation } from 'resources/location'
import {
  useOrganizationWebhooks,
  useOrganizationWebhookCreator,
  useOrganizationWebhookDetroyer,
} from 'resources/organizationWebhooks'

import Button from 'components/Button'
import HeaderWithButtons from 'components/HeaderWithButtons'
import Link from 'components/Link'
import Header from 'components/Header'
import Form from 'components/Form'
import TextInput from 'components/TextInput'
import Loading from 'components/Loading'
import TimeAgo from 'components/TimeAgo'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import IconButton from 'components/IconButton'
import Subtext from 'components/Subtext'
import LinkToEmailSupport from 'components/LinkToEmailSupport'

export default function WebhooksPage(props){
  const { organization } = props

  props.isAvailable = organization.apikey.toLowerCase() === 'datayogi'
  props.rootPath = `/${organization.apikey}/admin/webhooks`
  props.newPath = props.rootPath + '/new'

  return <div className="OrganizationAdminPage-WebhooksPage">
    {h(
      props.location.pathname === props.newPath
        ? NewApiEndpoint
        : ApiEndpoints,
      props
    )}
  </div>
}

function ApiEndpoints(props){
  const { organizationApikey, isAvailable } = props

  const newLink = <Button {...{
    type: 'normal',
    value: 'New webhook',
    href: props.newPath,
    disabled: !isAvailable,
  }}/>

  return <div>
    <div className="OrganizationAdminPage-padded">
      <p>
        Webhooks allow you to manage how and where data that individual people
        share with your {PNFO.singular} under a SISA is transferred to your other
        data management services.
      </p>
      {isAvailable
        ? <Form.ButtonRow reverse>{newLink}</Form.ButtonRow>
        : <p>
          This is an advanced feature. If you are interested in it, please
          email <LinkToEmailSupport/>
        </p>
      }
    </div>
    {isAvailable && <OrganizationWebhooks {...{organizationApikey}}/>}
  </div>
}

function OrganizationWebhooks({ organizationApikey }){
  let organizationWebhooks = useOrganizationWebhooks(organizationApikey)

  if (organizationWebhooks) organizationWebhooks =
    Object.values(organizationWebhooks)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  return <div>
    {organizationWebhooks
      ? organizationWebhooks.length === 0
        ? <Header size="sm" centered italic spaced>You don't currently have any webhooks</Header>
        : organizationWebhooks.map(webhook =>
          <WebhookListMember key={webhook.id} {...webhook}/>
        )
      : <Loading type="fullPage" />
    }
  </div>
}

function WebhookListMember(props){
  const { url, createdAt, creatorUserDid } = props
  const { publicProfile = {} } = usePublicProfile(
    creatorUserDid,
    'OrganizationAdminPage/WebhooksPage',
  )
  return <div className="OrganizationAdminPage-WebhooksPage-listMember">
    <div>
      <Header size="lg"><Link type="link" disabled>{url}</Link></Header>
      <Header size="sm">
        Created <TimeAgo time={createdAt}/> by <LinkToPublicProfile {...{publicProfile}}/>
      </Header>
    </div>
    <div>
      <DestroyButton {...props}/>
    </div>
  </div>
}

function DestroyButton({ organizationApikey, id, url }){
  const destroyer = useOrganizationWebhookDetroyer(id)
  const onClick = useConfirmationBefore(
    () => { destroyer.destroy({ organizationApikey, id }) },
    `Are you sure you want to destroy the ` +
    `webhook that pots to "${url}"`
  )
  return <IconButton {...{
    disabled: destroyer.destroying,
    type: 'cancel-circled',
    onClick,
  }}/>
}

const generateSecret = () => Math.random().toString().split('.')[1]

function NewApiEndpoint(props){
  const { organizationApikey, isAvailable } = props
  const [url, setUrl] = useState('')
  const [secret, setSecret] = useState(generateSecret())
  const creator = useOrganizationWebhookCreator()
  const disabled = !isAvailable || !!creator.creating
  const backLink = <Button type="link" value="back" href={props.rootPath}/>

  return <Form {...{
    className: 'OrganizationAdminPage-padded',
    async onSubmit(){
      const organizationWebhook =
        await creator.create({ organizationApikey, url, secret })
      if (!organizationWebhook) return
      setLocation({
        pathname: props.rootPath,
        query: {webhook: organizationWebhook.id},
      })
    },
  }}>
    <HeaderWithButtons size="md" underlined buttons={backLink}>
      New API Endpoint
    </HeaderWithButtons>
    <NewApiEndpointCopy/>
    <Form.Row>
      <Form.Item>
        <Form.Label>URL</Form.Label>
        <TextInput {...{
          value: url,
          onInput: setUrl,
          disabled,
          type: 'url',
          name: 'url',
          autocomplete: false,
          required: true,
          lpignore: true,
          autoFocus: true,
          placeholder: 'http://example.com/receive-http-post-from-tru',
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Form.Item>
        <Form.Label>SECRET</Form.Label>
        <Subtext>
          We will always include this following secret in the POST requests
          we make to your app. For extra security; we recommend you copy the
          secret below and confirm it is a match before accepting data from a
          post.
        </Subtext>
        <TextInput {...{
          value: secret,
          onInput: setSecret,
          disabled,
          name: 'secret',
          autocomplete: false,
          required: true,
          lpignore: true,
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.ButtonRow>
      <Button {...{
        disabled,
        type: 'normal',
        href: props.rootPath,
        value: 'cancel'
      }}/>
      <Button submit {...{
        disabled,
        type: 'primary',
        value: creator.creating ? 'Creating…' : 'Create',
      }}/>
    </Form.ButtonRow>
  </Form>
}

const NewApiEndpointCopy = () => <Fragment>
  <p>
    We’ll send a POST request to the URL below with details of many {PNFO.singular} events.
  </p>
</Fragment>
