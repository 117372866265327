import PNFO from 'jlinc-shared/PNFO'
import { h, Fragment } from 'preact'
import { useCallback, useEffect, useState } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import useToggle from 'lib/useToggleHook'
import { useMyPublicProfile, useOrganization, useMutedUsers } from 'lib/membershipAppStateHooks'
import { useUpdateFeedPost, useCreateFeedPost } from 'lib/feedPostHooks'
import { publicProfileToDisplayName } from 'lib/publicProfiles'
import { PUBLISHABLE_TO_OPTIONS } from 'lib/feedPostHelpers'

import Subtext from 'components/Subtext'
import Header from 'components/Header'
import SingleLine from 'components/SingleLine'
import ContentBox from 'components/ContentBox'
import OrganizationIcon from 'components/OrganizationIcon'
import EndUserAvatar from 'components/EndUserAvatar'
import Button from 'components/Button'
import Icon from 'components/Icon'
import DropdownAsHeader from 'components/DropdownAsHeader'
import RichMediaOrWYSIWYGInput from 'components/RichMediaOrWYSIWYGInput'
import FeedPostAndCommentForm from 'components/FeedPostAndCommentForm'
import StyleishModal from 'components/StyleishModal'
import RadioInputGroup from 'components/RadioInputGroup'
import InterfaceHelp from 'components/InterfaceHelp'
import './index.sass'

export default function FeedPostForm(props){
  return h(props.feedPost ? UpdateForm : CreateForm, props)
}

FeedPostForm.propTypes = {
  className: PropTypes.string,
  feedPost: PropTypes.shape({
    feedUserDid: PropTypes.string.isRequired,
    feedOrganizationApikey: PropTypes.string.isRequired,
    visibleTo: PropTypes.number.isRequired,
    maxVisibleTo: PropTypes.number.isRequired,
    body: PropTypes.string.isRequired,
  }),
  value: PropTypes.shape({
    feedUserDid: PropTypes.string,
    feedOrganizationApikey: PropTypes.string,
    visibleTo: PropTypes.number,
    maxVisibleTo: PropTypes.number,
    body: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  canPostAsOrganization: PropTypes.bool,
}

function UpdateForm(props){
  const { updatePost, updating, updatingError }
    = useUpdateFeedPost(props.feedPost, 'FeedPostForm')
  return <EditForm {...{
    ...props,
    onSubmit: updatePost,
    submitting: updating,
    error: updatingError,
  }}/>
}

function CreateForm(props){
  const { createPost, creating, creatingError, createdFeedPost }
    = useCreateFeedPost(props.value, 'FeedPostForm')

  return <EditForm {...{
    ...props,
    onSubmit: createPost,
    submitting: creating,
    error: creatingError,
    onSuccess(){
      if (props.onSuccess)
        return props.onSuccess(createdFeedPost)
    },
  }}/>
}

function EditForm({
  className,
  disabled,
  feedPost,
  value,
  onChange,
  submitting,
  forForum,
  canPostAsOrganization,
  onCancel,
  minMaxVisibleTo,
  maxMaxVisibleTo,
  ...props
}){
  const { myPublicProfile } = useMyPublicProfile('FeedPostForm')
  const { listmuted, mutedUsers } = useMutedUsers("FeedPostForm")
  const { organization } = useOrganization(canPostAsOrganization, 'FeedPostForm')
  const [isMuted, setIsMuted] = useState(false)


  useEffect(() => {
    if(organization?.did) {
      listmuted({ organizationDid: organization.did })
    }
  }, [listmuted, organization?.did])


  useEffect(() => {
    const isUserMuted = mutedUsers?.some(user =>
      user.user_did === myPublicProfile?.did &&
      (user.mute_type === 'ALL' || user.mute_type === 'post')
    )
    setIsMuted(isUserMuted)
  }, [mutedUsers, myPublicProfile])


  return <FeedPostAndCommentForm {...{
    className: classNames('FeedPostForm', { className, forForum }),
    value,
    onChange,
    onCancel,
    submitting,
    disabled,
    persistedValues: feedPost,
    ...props
  }}>
    {
      ({ form, scheduleFocusInput }) =>
        <ContentBox className="FeedPostForm-Create">
          <div className="FeedPostForm-top">
            <div className="FeedPostForm-top-options">
              <OrganizationIconOrEndUserAvatar {...{
                isOrganization: form.value.posterOrganizationApikey, organization, myPublicProfile, form
              }}
              />
              <div className="FeedPostForm-top-options-selects">
                {form.bindInput({
                  valueProp: 'posterOrganizationApikey',
                  input: <PostAsDropdown {...{ myPublicProfile, organization, canPostAsOrganization }}/>
                })}
                {
                  minMaxVisibleTo !== maxMaxVisibleTo &&
                  <label>
                    <MaxVisibleOptionsModalButton {...{ form }}/>
                  </label>
                }
              </div>
            </div>
            <InterfaceHelp inside content={
              <div>
                <Header size="lg">Rich</Header>
                <p>
                  More deeply customize the content of your post. Such as changes to line
                  indentation, linking to media URLs, creating lists, and more.
                </p>
                <Header size="lg">Plain</Header>
                <p>
                  Keep it simple, with the plain editor you can write text and upload images
                  and videos to be posted. You can even create slideshows with multiple
                  images and videos. Switch to the rich editor for deeper customization.
                </p>
              </div>
            }>
              <label className="FeedPostForm-top-options-selects-richMediaToggle">
                {form.bindInput({
                  valueProp: 'body',
                  input: <RichMediaOrWYSIWYGInput.Toggle onChange={scheduleFocusInput} />,
                })}
              </label>
            </InterfaceHelp>
          </div>
          {form.bindInput({
            valueProp: 'body',
            bindTo: 'onInput',
            unsaved: false,
            disabled:isMuted,
            placeholder: isMuted ? `You are muted ` : "What's on your mind…",
            input: <RichMediaOrWYSIWYGInput />,
          })}
        </ContentBox>
    }
  </FeedPostAndCommentForm>
}

function OrganizationIconOrEndUserAvatar({ isOrganization, organization, myPublicProfile }) {
  const iconProps = { size: 'md', bordered: false }

  return isOrganization
    ? <OrganizationIcon {...{...iconProps, organization}}/>
    : <EndUserAvatar {...{...iconProps, publicProfile: myPublicProfile}}/>
}

function PostAsDropdown({
  disabled,
  value,
  onChange: _onChange,
  myPublicProfile,
  canPostAsOrganization,
  organization,
}){
  const onChange = useCallback(
    value => {
      _onChange(value ? organization.apikey : undefined)
    },
    [_onChange, organization],
  )

  return <label className="FeedPostForm-PostAsDropdown">
    {canPostAsOrganization
      ? <DropdownAsHeader {...{
        value: value ? 1 : 0,
        options: {
          [publicProfileToDisplayName(myPublicProfile)]: 0,
          [organization.name]: 1,
        },
        onChange,
        disabled,
      }} />
      : <Header size="lg" bold>
        <SingleLine>{publicProfileToDisplayName(myPublicProfile)}</SingleLine>
      </Header>
    }
  </label>
}

const MaxVisibleOptionsModalButton = ({form}) => {
  const [isOpen, open, close] = useToggle(false)
  return <Fragment>
    <Button type="none" onClick={open}>
      <Subtext>
        {PUBLISHABLE_TO_OPTIONS[form.value.maxVisibleTo][0]}<Icon type="down-dir" size="sm" />
      </Subtext>
    </Button>
    <StyleishModal {...{
      open: isOpen,
      onClose: close,
      title: `Visiblity when published`,
    }}>
      <p>
        This setting controls how visible this post will be when published.
      </p>
      <p>
        If this post is published by this {PNFO.singular}, its max visibility will be
        limited to the level you choose.
      </p>
      {form.bindInput({
        valueProp: 'maxVisibleTo',
        input: <RadioInputGroup
          large
          options={
            PUBLISHABLE_TO_OPTIONS.map(([, label], index) => ({
              label,
              disabled: index === 1,
            }))
          }
        />,
      })}
    </StyleishModal>
  </Fragment>
}

