import { h } from 'preact'
import PropTypes from 'prop-types'

import { useErrorBoundary } from 'preact/hooks'

import ErrorMessage from 'components/ErrorMessage'
import RawHTML from 'components/RawHTML'
import './index.sass'

export default function WYSIWYGContent({
  className = '',
  source = '',
  ...props
}){
  const [error, onDismiss] = useErrorBoundary()
  if (error) console.error('WYSIWYGContent error', {error, source})
  return <div
    {...props}
    className={`WYSIWYGContent ck-content ${className}`}
  >
    {error ? <ErrorMessage {...{error, onDismiss}}/> : <RawHTML source={source}/>}
  </div>
}

WYSIWYGContent.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string,
}

