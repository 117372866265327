import { h } from 'preact'
import { memo } from 'preact/compat'
import { useState, useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

const loadedImageSrcs = new Set()

export default function ImageWithLoader({src, defaultSrc, ...props}){
  const key = `${src} ${defaultSrc}`
  return <Image {...{...props, key, src, defaultSrc}}/>
}

ImageWithLoader.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
}

const Image = memo(({src, defaultSrc, className, ...props}) => {
  const empty = !src

  const loadedBefore = loadedImageSrcs.has(src)
  const [state, setState] = useState(
    empty ? 'empty' :
    loadedBefore ? 'loaded' :
    'loading'
  )

  const loading = state === 'loading'
  const loaded = state === 'loaded'
  const errored = state === 'errored'

  const unloadedRef = useRef(false)
  useEffect(
    () => {
      return () => {
        unloadedRef.current = true
      }
    },
    []
  )

  const ref = useRef()
  useEffect(
    () => {
      const image = ref.current
      if (!image) return
      const reset = () => {
        delete image.onload
        delete image.onerror
      }
      if (loading){
        image.onload = () => {
          if (unloadedRef.current) return
          setState('loaded')
          loadedImageSrcs.add(src)
        }
        image.onerror = error => {
          if (unloadedRef.current) return
          console.error('Error loading image src=', src, error)
          setState('errored')
        }
        image.src = src
        return reset
      }else{
        reset()
      }
    },
    [loading]
  )

  return <span {...{
    ...props,
    className: classNames('ImageWithLoader', {
      className, loading, loaded, errored, empty
    }),
  }}>
    {
      empty ? null :
      errored ? <img key="err" src={defaultSrc || BLANK_IMAGE}/> :
      <img key="main" {...{ref, src: loaded ? src : BLANK_IMAGE}}/>
    }
  </span>
})

const BLANK_IMAGE = `data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`
