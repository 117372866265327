import { h } from 'preact'
import { useCallback, useRef, useLayoutEffect } from 'preact/hooks'

import defer from 'lib/defer'
import { submitParentForm } from 'lib/DOMHelpers'
import classNames from 'lib/classNames'

import TextInput from 'components/TextInput'
import './index.sass'

export default function VerificationCodeInput({
  className,
  disabled,
  onInput,
  autoSubmit,
  ...props
}){
  props.onInput = useCallback(
    (value, event) => {
      value = value.replace(/\D/g, '')
      if (value.length > 6) value = value.slice(0, 6)
      event.target.value = value
      onInput(value)
      if (autoSubmit && VerificationCodeInput.validate(value))
        defer(() => { submitParentForm(event.target) })
    },
    [onInput, autoSubmit]
  )
  const ref = useRef()
  useLayoutEffect(
    () => {
      if (!disabled) TextInput.inputFromRef(ref).select()
    },
    [disabled]
  )
  return <TextInput {...{
    ...props,
    disabled,
    ref,
    className: classNames('VerificationCodeInput', { className }),
    type: 'number',
    max: 999999,
  }}/>
}


VerificationCodeInput.validate = code => /^\d{6}$/.test(code)
