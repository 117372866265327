import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import {
  usePublicProfile,
  useOrganization,
} from 'lib/membershipAppStateHooks'

import LinkToOrganization from 'components/LinkToOrganization'
import OrganizationIcon from 'components/OrganizationIcon'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import EndUserAvatar from 'components/EndUserAvatar'

export default function OrganizationFeedPostAuthorIcon({
  className,
  organizationApikey,
  publicProfileDid,
  size = 'md',
  bordered,
  ...props
}){
  className = classNames('OrganizationFeedPostAuthorIcon', { className })

  if (organizationApikey) return <OrgIcon {...{
    ...props,
    className,
    organizationApikey,
    size,
    bordered,
  }}/>

  if (publicProfileDid) return <UserIcon {...{
    ...props,
    className,
    publicProfileDid,
    size,
    bordered,
  }}/>
}

OrganizationFeedPostAuthorIcon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  organizationApikey: PropTypes.string,
  publicProfileDid: PropTypes.string,
  size: PropTypes.string,
  bordered: PropTypes.bool,
}


function OrgIcon({ organizationApikey, size, bordered, ...props }){
  const { organization = {} } = useOrganization(
    organizationApikey,
    'OrganizationFeedPostAuthorIcon',
  )
  return <LinkToOrganization {...{...props, organizationApikey}}>
    <OrganizationIcon {...{organization, size, bordered}}/>
  </LinkToOrganization>
}

function UserIcon({ publicProfileDid, size, bordered, ...props }){
  const { publicProfile = {} } = usePublicProfile(
    publicProfileDid,
    'OrganizationFeedPostAuthorIcon',
  )
  return <LinkToPublicProfile {...props} publicProfile={publicProfile}>
    <EndUserAvatar {...{publicProfile, size, bordered}}/>
  </LinkToPublicProfile>
}
