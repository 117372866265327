import { isLoggedIn } from '../resources/auth'
import { replaceLocation } from 'resources/location'
import api from '../api'
import { loadEntity } from 'lib/actionHelpers'
import { getBrowserNotificationSettings, createBrowserNotification } from 'lib/browserNotifications'

export function loadChatChannelsForPerson() {
  if (!isLoggedIn()) return
  return loadEntity.call(this, {
    reload: true,
    entityKey: 'chatChannels',
    request: () => api.getChatChannelsForUser(),
  })
}

export function loadChatChannelsForPublicProfile({ organizationApikey, publicProfileDid }) {
  if (!isLoggedIn()) return
  return loadEntity.call(this, {
    reload: true,
    entityKey: `chatChannels:${organizationApikey}:${publicProfileDid}`,
    request: () => api.getChatChannelsForPublicProfile(organizationApikey, publicProfileDid),
  })

}

export function loadSynopses() {
  if (isLoggedIn()) return loadEntity.call(this, {
    reload: true,
    entityKey: 'chatSynopsis',
    request: async () => {
      const newChatSynopsis = await api.getChatSynopsis()
      createBrowserNotificationsForNewChatMessages(
        newChatSynopsis,
        this.getState().chatSynopsis,
      )
      return newChatSynopsis
    },
  })
  this.setState({ chatSynopsis: undefined })
}

function createBrowserNotificationsForNewChatMessages(newChatSynopsis, oldChatSynopsis) {
  if (
    window.document.hasFocus() ||
    !oldChatSynopsis ||
    !getBrowserNotificationSettings().recieveChatMessageBrowser
  ) return
  for (const group of newChatSynopsis) {
    if (!group.unread) continue
    createBrowserNotification({
      uuid: `newChatMessages-${group.uid}-${group.latestAt}`,
      title: `new chat messages`,
      body: `new chat messages in ${group.organizationApikey} ${group.type}`,
      destinationPath: `/${group.organizationApikey}/chat/${group.uid}`,
    })
  }
}


export function loadChatChannel(chatChannelUid) {
  return loadEntity.call(this, {
    reload: true,
    entityKey: `chat:channel:${chatChannelUid}`,
    request: () => api.getChatChannel(chatChannelUid)
  })
}


export async function createChatChannel({
  organizationApikey, type, memberUserDids, name, chatImage
}) {
  if (type !== 'dm') throw new Error(`chatChannel.type can only be "dm"`)
  if (!Array.isArray(memberUserDids) || memberUserDids.length === 0)
    throw new TypeError(`memberUserDids must be a non-blank array of public profile dids`)

  const chatChannel = await api.createChatChannel({ organizationApikey, type, memberUserDids, name, chatImage})

  if (organizationApikey) {
    await replaceLocation(`/${organizationApikey}/chat/${chatChannel.uid}`)
  } else {
    await replaceLocation(`/chat/${chatChannel.uid}`)
  }
  await this.takeAction('chat.loadSynopses')
}


export function loadMessages(chatChannelUid) {
  return loadEntity.call(this, {
    reload: true,
    entityKey: `chat:channel:${chatChannelUid}:messages`,
    request: () => api.getChatMessages(chatChannelUid),
  })
}

let fasterChatPolling = false
let pollingForSynopsisTimeout
export function startPollingForSynopsis() {
  if (pollingForSynopsisTimeout) return
  const frequency = fasterChatPolling ? 1000 : 5000
  const reloadSynopses = async () => {
    if (localStorage.stopChat) return
    await loadSynopses.call(this)
    pollingForSynopsisTimeout = setTimeout(reloadSynopses, frequency)
  }
  setTimeout(reloadSynopses, 100)
}

export function pollingOnceForSynopsis() {
  loadSynopses.call(this)
}

export function setFasterChatPolling(value) { fasterChatPolling = !!value }

export function stopPollingForSynopsis() {
  if (pollingForSynopsisTimeout) {
    clearTimeout(pollingForSynopsisTimeout)
    pollingForSynopsisTimeout = undefined
  }
}

export async function createMessage(chatChannelUid, { message, file, caption,  urlPreview }) {
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  const pendingChatMessagesKey = `${chatMessagesKey}:pending`
  const pendingChatMessage = {
    createdAt: Date.now(),
    uid: `pending-${Date.now()}`,
    channelUid: chatChannelUid,
    message,
    urlPreview,
  }

  if (file) pendingChatMessage.uploadId = this.takeAction('assets.upload', file)

  this.addToSet(pendingChatMessagesKey, [pendingChatMessage])

  if (file) {
    let upload = this.getState()['assets:uploads'][pendingChatMessage.uploadId]
    upload = await upload.promise
    if (!upload) {
      this.removeFromSet(pendingChatMessagesKey, [pendingChatMessage])
      return
    }
    const { type, url } = upload

    const preview = upload.preview || {}
    delete preview.url
    message = `jlinc:dm:attachment:${JSON.stringify({ ...preview, url, type })}`
    console.log('NEW CHAT MESSAGe', message)
  }

  try {
    const chatMessage = await api.createChatMessage(chatChannelUid, message, caption, urlPreview?.id)
    const chatMessages = Array.from(this.getState()[chatMessagesKey] || [])
    this.removeFromSet(pendingChatMessagesKey, [pendingChatMessage])
    // TODO mamnnually update the digest latest, latestUid
    this.setState({
      [chatMessagesKey]: [...chatMessages, {...chatMessage, urlPreview}],
    })
  } catch (error) {
    this.removeFromSet(pendingChatMessagesKey, [pendingChatMessage])
    this.addToSet(pendingChatMessagesKey, [{ ...pendingChatMessage, error }])
  }
}

export function retry(pendingChatMessage) {
  const { channel, message } = pendingChatMessage
  this.removeFromSet(`chatMessages:${channel}:pending`, [pendingChatMessage])
  this.takeAction('chatMessages.create', channel, message)
}

export async function markAsRead(chatChannelUid) {
  const chatMessages = this.getState()[`chat:channel:${chatChannelUid}:messages`] || []
  const latestChatMessage = chatMessages.filter(cm => !cm.readAt).reverse()[0]
  if (!latestChatMessage) return
  try {
    await api.markChatMessagesAsRead(latestChatMessage.uid)
    this.takeAction('chat.pollingOnceForSynopsis')
  } catch (error) {
    console.error(`failed to mark direct message as read`, error)
  }
}

export async function changeChatChannelAttributes(channelUid, attribute) {
  try {
    const response = await api.changeChatChannelAttributes(channelUid, attribute)
    this.takeAction('chat.reflectNewAttributes', response.chatAttributes)
  } catch (error) {
    console.error(`failed to change chat channel attributes`, error)
  }
}

export async function getUrlPreview(url) {
  try {
    const response = await api.getUrlPreview(url)
    return response.preview
  } catch (error) {
    if (`${error}`.includes('Invalid URL')) return { url, invalidUrl: true }
    throw error
  }
}
// TODO: editing not working on the front end yet
export async function reflecteditedMessage(chatMessage) {
  const chatMessagesKey = `chat:channel:${chatMessage.channelUid}:messages`
  let chatMessages = this.getState()[chatMessagesKey] || []

  let urlPreview = null

  if (chatMessage.urlPreview) {
    urlPreview = await this.takeAction('chat.getUrlPreview', chatMessage.editedMessage)
  }


  if (chatMessage.replyToMessageUid) {
    const parentMessageIndex = chatMessages.findIndex(message => message.uid === chatMessage.replyToMessageUid)
    if (parentMessageIndex === -1)
      throw new Error(`Parent chat message with UID ${chatMessage.replyToMessageUid} not found.`)

    if (chatMessage.sendToMainChat) {
      const messageIndex = chatMessages.findIndex(message => message.uid === chatMessage.uid)

      if (messageIndex === -1) throw new Error(`Chat message with UID ${chatMessage.uid} not found.`)

      const updatedMessage = {
        ...chatMessages[messageIndex],
        editedMessage: chatMessage.editedMessage,
        message: chatMessage.editedMessage,
        urlPreview
      }

      this.setState({
        [chatMessagesKey]: [
          ...chatMessages.slice(0, messageIndex),
          updatedMessage,
          ...chatMessages.slice(messageIndex + 1),
        ],
      })
    }

    let updatedParentMessage = { ...chatMessages[parentMessageIndex] }

    const replyIndex = updatedParentMessage.replies.findIndex(reply => reply.uid === chatMessage.uid)
    if (replyIndex === -1) throw new Error(`Chat message reply with UID ${chatMessage.uid} not found.`)

    updatedParentMessage.replies[replyIndex] = {
      ...updatedParentMessage.replies[replyIndex],
      editedMessage: chatMessage.editedMessage,
      urlPreview
    }

    chatMessages = [
      ...chatMessages.slice(0, parentMessageIndex),
      updatedParentMessage,
      ...chatMessages.slice(parentMessageIndex + 1),
    ]

  } else {
    const messageIndex = chatMessages.findIndex(message => message.uid === chatMessage.uid)
    if (messageIndex === -1) throw new Error(`Chat message with UID ${chatMessage.uid} not found.`)

    const updatedMessage = {
      ...chatMessages[messageIndex],
      editedMessage: chatMessage.editedMessage,
      urlPreview
    }

    this.setState({
      [chatMessagesKey]: [
        ...chatMessages.slice(0, messageIndex),
        updatedMessage,
        ...chatMessages.slice(messageIndex + 1),
      ],
    })
  }
}

export async function reflectDeletedMessage(chatMessage) {
  const chatMessagesKey = `chat:channel:${chatMessage.channelUid}:messages`
  let chatMessages = this.getState()[chatMessagesKey] || []


  if (chatMessage.replyToMessageUid) {
    const parentMessageIndex = chatMessages.findIndex(message => message.uid === chatMessage.replyToMessageUid)
    if (parentMessageIndex === -1)
      throw new Error(`Parent chat message with UID ${chatMessage.replyToMessageUid} not found.`)

    if (chatMessage.sendToMainChat) {
      const messageIndex = chatMessages.findIndex(message => message.uid === chatMessage.uid)

      if (messageIndex === -1) throw new Error(`Chat message with UID ${chatMessage.uid} not found.`)

      const updatedMessage = {
        ...chatMessages[messageIndex],
        deletedAt: chatMessage.deletedAt,
        deletedByRole: chatMessage.deletedByRole,
        deletedByUserDid: chatMessage.deletedByUserDid,
        AdminComment: chatMessage.AdminComment
      }

      this.setState({
        [chatMessagesKey]: [
          ...chatMessages.slice(0, messageIndex),
          updatedMessage,
          ...chatMessages.slice(messageIndex + 1),
        ],
      })
    }

    let updatedParentMessage = { ...chatMessages[parentMessageIndex] }

    const replyIndex = updatedParentMessage.replies.findIndex(reply => reply.uid === chatMessage.uid)
    if (replyIndex === -1) throw new Error(`Chat message reply with UID ${chatMessage.uid} not found.`)

    updatedParentMessage.replies = [
      ...updatedParentMessage.replies.slice(0, replyIndex),
      { ...chatMessage },
      ...updatedParentMessage.replies.slice(replyIndex + 1),
    ]

    chatMessages = [
      ...chatMessages.slice(0, parentMessageIndex),
      updatedParentMessage,
      ...chatMessages.slice(parentMessageIndex + 1),
    ]
  } else {
    const messageIndex = chatMessages.findIndex(message => message.uid === chatMessage.uid)
    if (messageIndex === -1) throw new Error(`Chat message with UID ${chatMessage.uid} not found.`)

    const updatedMessage = { ...chatMessage }
    this.setState({
      [chatMessagesKey]: [
        ...chatMessages.slice(0, messageIndex),
        updatedMessage,
        ...chatMessages.slice(messageIndex + 1),
      ],
    })
  }
}

export async function reflectReadMessage(chatMessage) {
  const chatMessagesKey = `chat:channel:${chatMessage.channelUid}:messages`
  const chatMessages = this.getState()[chatMessagesKey] || []

  if (chatMessage.replyToMessageUid) {
    const parentMessageIndex = chatMessages.findIndex(message => message.uid === chatMessage.replyToMessageUid)
    if (parentMessageIndex === -1)
      throw new Error(`Parent chat message with UID ${chatMessage.replyToMessageUid} not found.`)

    let updatedParentMessage = { ...chatMessages[parentMessageIndex] }

    const replyIndex = updatedParentMessage.replies.findIndex(reply => reply.uid === chatMessage.uid)
    if (replyIndex === -1) throw new Error(`Chat message reply with UID ${chatMessage.uid} not found.`)

    updatedParentMessage.replies[replyIndex] = { ...updatedParentMessage.replies[replyIndex], ...chatMessage }

    this.setState({
      [chatMessagesKey]: [
        ...chatMessages.slice(0, parentMessageIndex),
        updatedParentMessage,
        ...chatMessages.slice(parentMessageIndex + 1),
      ],
    })
  } else {
    const messageIndex = chatMessages.findIndex(message => message.uid === chatMessage.uid)
    if (messageIndex === -1) throw new Error(`Chat message with UID ${chatMessage.uid} not found.`)

    const updatedMessage = { ...chatMessage }

    this.setState({
      [chatMessagesKey]: [
        ...chatMessages.slice(0, messageIndex),
        updatedMessage,
        ...chatMessages.slice(messageIndex + 1),
      ],
    })
  }
}

export async function reflectChatEvents(events) {
  const chatSynopsisKey = `chatSynopsis`
  let chatSynopsis = this.getState()[chatSynopsisKey] || []

  events.forEach(event => {
    const eventMessage = {
      uid: event.id,
      actorUserDid: event.actor_user_did,
      targetUserDid: event.target_user_did,
      eventType: event.event_type,
      additionalInfo: event.additional_info,
      createdAt: event.created_at,
    }

    const chatChannelIndex = chatSynopsis.findIndex(channel => channel.uid === event.channel_uid)
    if (chatChannelIndex !== -1) {
      let updatedChatChannel = { ...chatSynopsis[chatChannelIndex] }

      if (!Array.isArray(updatedChatChannel.events)) {
        updatedChatChannel.events = []
      }

      updatedChatChannel.events.push(eventMessage)

      chatSynopsis = [
        ...chatSynopsis.slice(0, chatChannelIndex),
        updatedChatChannel,
        ...chatSynopsis.slice(chatChannelIndex + 1),
      ]
    }
  })

  this.setState({
    [chatSynopsisKey]: chatSynopsis,
  })
}

export async function reflectChatMembers({ events }) {
  console.log('reflectChatMembers', events)
  const { chatSynopsis } = this.getState()
  const chatSynopsisKey = `chatSynopsis`
  let updatedChatSynopsis = [...chatSynopsis]

  events?.forEach(event => {
    const { channel_uid: channelUid, member_user_did: memberUserDid, is_admin: isAdmin, left_at: leftAt } = event
    const chatChannel = updatedChatSynopsis.find(channel => channel.uid === channelUid)
    if (!chatChannel) return

    const memberIndex = chatChannel.members.findIndex(member => member.memberUserDid === memberUserDid)
    if (memberIndex === -1) return

    if (leftAt) {
      chatChannel.members.splice(memberIndex, 1)
    } else {
      chatChannel.members[memberIndex].isAdmin = isAdmin
    }

    const chatChannelIndex = updatedChatSynopsis.findIndex(channel => channel.uid === channelUid)
    if (chatChannelIndex !== -1) {
      updatedChatSynopsis[chatChannelIndex] = chatChannel
    }
  })

  this.setState({ [chatSynopsisKey]: updatedChatSynopsis })

}


export function reflectNewReaction(Reaction) {
  const { channelUid, messageUid, result } = Reaction
  const chatMessagesKey = `chat:channel:${channelUid}:messages`
  console.log(channelUid, 'Channel UID')

  let chatMessages = this.getState()[chatMessagesKey] || []

  let updatedChatMessages = chatMessages.map(message => {

    if (message.uid === messageUid) {
      if(result.deleted) {
        const updatedReactions = message.reactions.filter(reaction => reaction.reaction !== result.reaction.reaction)
        return { ...message, reactions: updatedReactions }
      } else {
        const updatedReactions = [...(message.reactions || []), result.reaction]
        return { ...message, reactions: updatedReactions }
      }
    }
    return message
  })
  this.setState({ [chatMessagesKey]: updatedChatMessages })
}

export function reflectNewAttributes(chatAttributes) {
  const { channel_uid:channelUid, is_pinned, is_unread, is_notifications_muted, is_archived } = chatAttributes

  const chatSynopsisKey = `chatSynopsis`

  let chatSynopsis = this.getState()[chatSynopsisKey]

  chatSynopsis.map((chatChannel) => {
    if (chatChannel.uid === channelUid) {
      if(is_pinned) {
        chatChannel.is_pinned = true
      } else {
        chatChannel.is_pinned = false
      }

      if(is_unread) {
        chatChannel.is_unread = true
      } else {
        chatChannel.is_unread = false
      }

      if(is_notifications_muted) {
        chatChannel.is_notifications_muted = true
      } else {
        chatChannel.is_notifications_muted = false
      }

      if(is_archived) {
        chatChannel.is_archived = true
      } else {
        chatChannel.is_archived = false
      }
      return chatChannel
    }

    return chatChannel
  })

  let updatedChatSynopsis = [...chatSynopsis]
  this.setState({ [chatSynopsisKey]: updatedChatSynopsis })

}


export async function editMessage(chatChannelUid, messageUid, { message, urlPreview }) {


  try {
    const response = await api.editChatMessage(chatChannelUid, messageUid, message, urlPreview?.id)

    return response
  } catch (error) {

    throw error
  }
}


export async function replyToMessage(chatChannelUid, messageUid, { replyMessage, urlPreviewId, sendToMainChat }) {

  const initialState = this.getState()
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  const chatMessages = initialState[chatMessagesKey] || []

  const originalMessageIndex = chatMessages.findIndex(message => message.uid === messageUid)
  if (originalMessageIndex === -1) {
    throw new Error(`Chat message with UID ${messageUid} not found.`)
  }

  const originalMessage = chatMessages[originalMessageIndex]
  const reply = {
    createdAt: Date.now(),
    uid: `reply-${Date.now()}`,
    channelUid: chatChannelUid,
    message: replyMessage,
    originalMessage,
    sendToMainChat: sendToMainChat || false,
    urlPreviewId
  }

  const updatedChatMessages = [...chatMessages, reply]

  try {
    const replyContent = {
      originalMessage: originalMessage.message,
      reply: replyMessage,
      urlPreviewId
    }
    const replyResponse = await api.replyToChatMessage(
      chatChannelUid, messageUid, replyContent, urlPreviewId, sendToMainChat
    )

    if (!replyResponse || !replyResponse.uid) {
      throw new Error("API did not return a valid message object")
    }

    const index = updatedChatMessages.findIndex(message => message.uid === reply.uid)
    if (index !== -1) {
      updatedChatMessages.splice(index, 1, replyResponse)
    }

    return replyResponse

  } catch (error) {

    const index = updatedChatMessages.findIndex(message => message.uid === reply.uid)
    if (index !== -1) {
      updatedChatMessages.splice(index, 1)
      this.setState({ [chatMessagesKey]: updatedChatMessages })
    }
    throw error
  }
}

export function setReplyingToMessage(channelUid, messageUid){
  const chatMessagesKey = `chat:channel:${channelUid}:replying`
  this.setState({
    [chatMessagesKey]: messageUid,
  })
}

export async function forwardChatMessage(chatChannelUids, messageUid, message) {

  try {
    const forwardedMessage = await api.forwardChatMessage({ chatChannelUids, messageUid, message })
    return forwardedMessage
  } catch (error) {
    throw error
  }
}

export async function starMessage(chatChannelUid, messageUid) {
  try {
    await api.starChatMessage(chatChannelUid, messageUid)
    this.takeAction('chat.loadStarredMessages', chatChannelUid)
  } catch (error) {
    console.error("Failed to star the message:", error)
    throw error // Rethrow the error to be handled by the caller
  }
}


export async function deleteMessage(
  chatChannelUid,
  messageUid,
  { deletionReason = "", AdminComment = "" }
) {


  try {
    await api.deleteChatMessage(chatChannelUid, messageUid, deletionReason, AdminComment)

    // no need to update the state, the message will be updated when the server emits the event
  } catch (error) {
    console.error(`Failed to delete chat message`, error)
    throw error
  }
}

export async function unstarMessage(chatChannelUid, messageUid) {

  try {
    const response = await api.unstarChatMessage(chatChannelUid, messageUid)
    this.takeAction('chat.loadStarredMessages', chatChannelUid)
    return response
  } catch (error) {
    throw error
  }
}


export async function loadStarredMessages(chatChannelUid) {
  return loadEntity.call(this, {
    reload: true,
    entityKey: `chat:channel:${chatChannelUid}:starredMessages`,
    request: () => api.getStarredChatMessages(chatChannelUid),
  })
}


export async function openThread(chatChannelUid, messageUid) {
  this.setState({
    [`chat:channel:${chatChannelUid}:thread:open`]: messageUid
  })
}

export async function sendReplyToMainChat(chatChannelUid, messageUid, urlPreviewId, sendToMainChat) {
  const replyContent = undefined
  try {
    await api.replyToChatMessage(chatChannelUid, messageUid, replyContent, urlPreviewId, sendToMainChat)
  } catch (error) {
    throw error
  }
}
export async function searchInChat(channelUid, keyword) {
  if (isLoggedIn()) {
    return loadEntity.call(this, {
      reload: true,
      entityKey: `chatSearchResults`,
      request: () => api.searchInChat(channelUid, keyword),
    })
  }
}

export async function undoDeleteMessage(chatChannelUid, messageUid, action) {
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  let chatMessages = this.getState()[chatMessagesKey] || []

  try {
    const undeletedMessage = await api.undoDeleteChatMessage(chatChannelUid, messageUid, action)
    if (action === 'approve') {
      const messageIndex = chatMessages.findIndex(m => m.uid === messageUid)
      if (messageIndex !== -1) {
        chatMessages[messageIndex] = undeletedMessage
      } else {
        chatMessages = [...chatMessages, undeletedMessage]
      }
      this.setState({ [chatMessagesKey]: undeletedMessage })
    }

  } catch (error) {
    console.error(`Failed to process undelete request`, error)
    throw error
  }
}

export async function createChatMessageReaction(chatChannelUid, messageUid, reaction) {
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  let chatMessages = this.getState()[chatMessagesKey] || []

  try {
    const reactionResult = await api.createChatMessageReaction(chatChannelUid, messageUid, reaction)

    const updateMessageReactions = (message, reaction) => {
      return { ...message, reactions: [...(message.reactions || []), reaction] }
    }

    const findAndUpdateMessage = (messages, messageUid, reaction) => {
      const index = messages.findIndex(m => m.uid === messageUid)
      if (index !== -1) {
        const updatedMessage = updateMessageReactions(messages[index], reaction)
        return [
          ...messages.slice(0, index),
          updatedMessage,
          ...messages.slice(index + 1),
        ]
      }
      return messages
    }

    let updatedChatMessages = chatMessages
    const message = chatMessages.find(m => m.uid === messageUid)
    if (message && message.replyToMessageUid) {
      const parentMessageIndex = chatMessages.findIndex(m => m.uid === message.replyToMessageUid)
      if (parentMessageIndex !== -1) {
        let updatedParentMessage = { ...chatMessages[parentMessageIndex] }
        updatedParentMessage.replies = findAndUpdateMessage(updatedParentMessage.replies, messageUid, reactionResult)
        updatedChatMessages = [
          ...chatMessages.slice(0, parentMessageIndex),
          updatedParentMessage,
          ...chatMessages.slice(parentMessageIndex + 1),
        ]
      }
    } else {
      updatedChatMessages = findAndUpdateMessage(chatMessages, messageUid, reactionResult)
    }

    this.setState({ [chatMessagesKey]: updatedChatMessages })

    return reactionResult
  } catch (error) {
    console.error(`Failed to create chat message reaction`, error)
    throw error
  }
}


export async function removeChatMessageReaction(chatChannelUid, messageUid, reaction) {
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  try {
    const reactionRemovalResult = await api.removeChatMessageReaction(chatChannelUid, messageUid, reaction)

    this.setState({ [chatMessagesKey]: reactionRemovalResult })

  } catch (error) {
    console.error(`Failed to remove chat message reaction`, error)
    throw error
  }
}


export async function leaveChatChannel(chatChannelUid) {
  if (!isLoggedIn()) throw new Error('User must be logged in to leave a chat channel.')

  try {
    await api.leaveChatChannel(chatChannelUid)

    return { success: true }
  } catch (error) {
    console.error('Failed to leave chat channel:', error)
    throw error
  }
}

export async function deleteChatChannel(chatChannelUid) {
  if (!isLoggedIn()) throw new Error('User must be logged in to delete a chat channel.')

  try {
    await api.deleteChatChannel(chatChannelUid)

    const { chatSynopsis = [] } = this.getState()
    const updatedChatSynopsis = chatSynopsis.filter(channel => channel.uid !== chatChannelUid)
    this.setState({ chatSynopsis: updatedChatSynopsis })
    await this.takeAction('chat.loadSynopses')

    return { success: true }
  } catch (error) {
    console.error('Failed to delete chat channel:', error)
    throw error
  }
}

export async function addMembersToChatChannel(channelUid, memberUserDids) {
  if (!channelUid) throw new Error('channelUid is required')
  if (!Array.isArray(memberUserDids) || memberUserDids.length === 0)
    throw new Error('memberUserDids must be a non-empty array')

  try {
    await api.addMembersToChatChannel(channelUid, memberUserDids)
    return { success: true }
  } catch (error) {
    console.error('Failed to add members to chat channel:', error)
    throw error
  }
}

export async function removeMembersFromChatChannel(channelUid, memberUserDids) {
  if (!channelUid) throw new Error('channelUid is required')
  if (!Array.isArray(memberUserDids) || memberUserDids.length === 0)
    throw new Error('memberUserDids must be a non-empty array')

  try {
    await api.removeMembersFromChatChannel(channelUid, memberUserDids)
    return { success: true }
  } catch (error) {
    console.error('Failed to remove members from chat channel:', error)
    throw error
  }
}

export async function assignChatChannelAdminRole(channelUid, adminUserDid) {
  if (!channelUid) throw new Error('channelUid is required')
  if (!adminUserDid) throw new Error('adminUserDid is required')

  try {
    await api.assignChatChannelAdminRole(channelUid, adminUserDid)
    return { success: true }
  } catch (error) {
    console.error('Failed to assign admin role:', error)
    throw error
  }
}

export async function removeChatChannelAdminRole(channelUid, adminUserDid) {
  if (!channelUid) throw new Error('channelUid is required')
  if (!adminUserDid) throw new Error('adminUserDid is required')

  try {
    await api.removeChatChannelAdminRole(channelUid, adminUserDid)
    return { success: true }
  } catch (error) {
    console.error('Failed to remove admin role:', error)
    throw error
  }
}

export async function getChatChannelMembershipDetails(chatChannelUid) {
  try {
    if (!chatChannelUid) throw new Error('chatChannelUid is required')
    if (this.getState()[`chat:channel:${chatChannelUid}:membershipDetails:loading`]) return
    this.setState({ [`chat:channel:${chatChannelUid}:membershipDetails:loading`]: true })
    const membershipDetails = await api.getChatChannelMembershipDetails(chatChannelUid)
    this.setState({ [`chat:channel:${chatChannelUid}:membershipDetails`]: membershipDetails })
    this.setState({ [`chat:channel:${chatChannelUid}:membershipDetails:loading`]: false })
    return membershipDetails
  } catch (error) {
    this.setState({ [`chat:channel:${chatChannelUid}:membershipDetails:loading`]: false })
    this.setState({ [`chat:channel:${chatChannelUid}:membershipDetails:error`]: error })
    console.error('Failed to fetch chat channel membership details:', error)
    throw error
  }
}

export async function addChatChannelDetails(ChatChannelUid, chatName, imageFile, description) {
  let imageUrl = null
  if (imageFile) {
    try {
      const uploadId = this.takeAction('assets.upload', imageFile)
      const upload = this.getState()['assets:uploads'][uploadId]

      if (!upload) {
        throw new Error('Image upload initialization failed')
      }

      const uploadResult = await upload.promise
      if (!uploadResult || !uploadResult.url) {
        throw new Error('Image upload failed')
      }

      imageUrl = uploadResult.url
    } catch (error) {
      console.error('addChatChannelDetails: Failed to upload image', error)
      throw new Error('Image upload failed')
    }
  }

  try {
    const response = await api.addChatChannelDetails(ChatChannelUid, chatName, imageUrl, description)

    if (response) {
      this.setState(prevState => {
        return {
          chatChannels: prevState.chatChannels.map(channel =>
            channel.uid === ChatChannelUid ? { ...channel, chatName, imageUrl, description } : channel
          )
        }
      })
    }

    await this.takeAction('chat.loadSynopses')

  } catch (error) {
    console.error('Failed to update chat channel details', error)
    throw error
  }
}
