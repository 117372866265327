import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import ContentBox from 'components/ContentBox'
import HeaderedContent from 'components/HeaderedContent'
import './index.sass'

export default function HeaderedContentBox({
  className,
  ...props
}){
  delete props.class // preact!
  return <ContentBox className={classNames('HeaderedContentBox', { className })}>
    <HeaderedContent {...props}/>
  </ContentBox>
}

HeaderedContentBox.propTypes = {
  className: PropTypes.string,
  ...HeaderedContent.propTypes,
}
