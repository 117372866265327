import tlds from 'tlds'
import urlRegex from 'url-regex'

if (process.env.NODE_ENV === 'development'){
  // enable linkification of .test urls in development
  if (window.location.host.endsWith('.test')) tlds.push('test')
}

export function toExternalUrl(url){
  return url.match(/^https?:\/\//) ? url : `https://${url}`
}

export function splitByUrls(text){
  // make a new RegExp every time because they maintain cursor state
  const URL_REGEX = urlRegex({ strict: false })
  const parts = []
  let match
  let cursor = 0
  while (match = URL_REGEX.exec(text)) { // eslint-disable-line
    const start = match.index
    const length = match[0].length
    const part = text.slice(cursor, start)
    cursor = start + length
    const url = text.slice(start, cursor)
    parts.push(part)
    parts.push({url})
  }
  parts.push(text.slice(cursor))
  return parts
}

export function findUrls(text){
  return splitByUrls(text)
    .filter(part =>
      typeof part === 'object' &&
      typeof part.url === 'string'
    )
    .map(part => normalizeUrl(part.url))
}


export function normalizeUrl(url){
  return /^https?:\/\//.test(url) ? url : `http://${url}`
}
