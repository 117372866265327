import { h } from 'preact'

import './index.sass'

export default function SingleLine({className = '', children, ...props}){
  if (typeof children === 'string') children = <SingleLine.Shrink>{children}</SingleLine.Shrink>
  return <span className={`SingleLine ${className}`} {...props}>{children}</span>
}

SingleLine.Shrink = function Shrink({className = '', children, ...props}){
  return <span className={`SingleLine-Shrink ${className}`} {...props}>{children}</span>
}
