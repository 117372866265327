import { h } from 'preact'
import { useEffect, useCallback } from 'preact/hooks'
import { useAppState } from 'lib/appState'

export function useOrganizationFeedSubscriptions(organizationApikey, componentName) {
  const organizationFeedSubscriptionsKey = `organization:${organizationApikey}:feedSubscriptions`
  const {
    takeAction,
    organizationFeedSubscriptionUids,
    organizationFeedSubscriptionsLoaded,
    errorLoadingOrganizationFeedSubscriptions,
    subscribingToOrganizationFeed,
    loadingOrganizationFeedSubscriptions,
    errorSubscribingToOrganizationFeed,
    unsubscribingFromOrganizationFeed,
    errorUnsubscribingFromOrganizationFeed,
  } = useAppState(
    {
      [organizationFeedSubscriptionsKey]: 'organizationFeedSubscriptionUids',
      [`${organizationFeedSubscriptionsKey}:loaded`]: 'organizationFeedSubscriptionsLoaded',
      [`${organizationFeedSubscriptionsKey}:loading`]: 'loadingOrganizationFeedSubscriptions',
      [`${organizationFeedSubscriptionsKey}:loading:error`]: 'errorLoadingOrganizationFeedSubscriptions',
      [`feedSubscriptions:subscribing`]: 'subscribingToOrganizationFeed',
      [`feedSubscriptions:subscribing:error`]: 'errorSubscribingToOrganizationFeed',
      [`feedSubscriptions:unsubscribing`]: 'unsubscribingFromOrganizationFeed',
      [`feedSubscriptions:unsubscribing:error`]: 'errorUnsubscribingFromOrganizationFeed',
    },
    componentName,
  )

  const { organizationFeedSubscriptions } = useFeedSubscriptions(
    organizationFeedSubscriptionUids || [], componentName,
  )

  const needsLoading = (
    !errorLoadingOrganizationFeedSubscriptions &&
    !loadingOrganizationFeedSubscriptions &&
    !organizationFeedSubscriptionsLoaded
  )

  useEffect(
    () => {
      if (needsLoading){
        takeAction('feedSubscriptions.load', organizationApikey)
      }
    },
    [needsLoading, organizationApikey]
  )

  return {
    subscribeToOrganizationFeed: useCallback(
      subscriberOrganizationApikey => {
        takeAction('feedSubscriptions.subscribe', { organizationApikey, subscriberOrganizationApikey })
      },
      [],
    ),
    unsubscribeFromOrganizationFeed: useCallback(
      organizationFeedSubscription => {
        takeAction('feedSubscriptions.unsubscribe', { organizationFeedSubscription })
      },
      [],
    ),
    organizationFeedSubscriptionUids,
    organizationFeedSubscriptions,
    errorLoadingOrganizationFeedSubscriptions,
    subscribingToOrganizationFeed,
    errorSubscribingToOrganizationFeed,
    unsubscribingFromOrganizationFeed,
    errorUnsubscribingFromOrganizationFeed,
    loadingOrganizationFeedSubscriptions,
  }
}

export function useOrganizationFeedSubscription(
  {
    organizationApikey,
    subscriberOrganizationApikey,
  },
  componentName
) {
  const {
    takeAction,
    subscribeeFeedSubscriptionUids = new Set(),
    subscriberFeedSubscriptionUids = new Set(),
    subscribingToOrganizationFeed,
    errorSubscribingToOrganizationFeed,
    unsubscribingFromOrganizationFeed,
    errorUnsubscribingFromOrganizationFeed,
  } = useAppState(
    {
      [`organization:${organizationApikey}:feedSubscriptions`]: 'subscribeeFeedSubscriptionUids',
      [`organization:${subscriberOrganizationApikey}:feedSubscriptions`]: 'subscriberFeedSubscriptionUids',
      [`feedSubscriptions:subscribing`]: 'subscribingToOrganizationFeed',
      [`feedSubscriptions:subscribing:error`]: 'errorSubscribingToOrganizationFeed',
      [`feedSubscriptions:unsubscribing`]: 'unsubscribingFromOrganizationFeed',
      [`feedSubscriptions:unsubscribing:error`]: 'errorUnsubscribingFromOrganizationFeed',
    },
    componentName,
  )

  const { organizationFeedSubscriptions } = useFeedSubscriptions(
    [
      ...subscribeeFeedSubscriptionUids,
      ...subscriberFeedSubscriptionUids,
    ],
    componentName,
  )
  const organizationFeedSubscription = organizationFeedSubscriptions.find(s =>
    s.organizationApikey === organizationApikey &&
    s.subscriberOrganizationApikey === subscriberOrganizationApikey
  )

  const subscribing = (
    subscribingToOrganizationFeed &&
    subscribingToOrganizationFeed.organizationApikey === organizationApikey &&
    subscribingToOrganizationFeed.subscriberOrganizationApikey === subscriberOrganizationApikey
  )

  const unsubscribing = (
    unsubscribingFromOrganizationFeed &&
    unsubscribingFromOrganizationFeed === organizationFeedSubscription
  )

  return {
    subscribe: useCallback(
      () => {
        takeAction('feedSubscriptions.subscribe', { organizationApikey, subscriberOrganizationApikey })
      },
      [organizationApikey, subscriberOrganizationApikey]
    ),
    unsubscribe: useCallback(
      () => {
        takeAction('feedSubscriptions.unsubscribe', { organizationFeedSubscription })
      },
      [organizationFeedSubscription]
    ),
    organizationFeedSubscription,
    subscribing,
    errorSubscribing: errorSubscribingToOrganizationFeed,
    unsubscribing,
    errorUnsubscribing: errorUnsubscribingFromOrganizationFeed,
  }
}

export function useMyOrganizationFeedSubscriptions(componentName) {
  const {
    takeAction,
    subscribingToOrganizationFeed,
    errorSubscribingToOrganizationFeed,
    unsubscribingFromOrganizationFeed,
    errorUnsubscribingFromOrganizationFeed,
    myOrganizationFeedSubscriptionUids,
    myOrganizationFeedSubscriptionUidsLoaded,
    myOrganizationFeedSubscriptionUidsLoading,
    myOrganizationFeedSubscriptionUidsLoadingError,
  } = useAppState(
    {
      'feedSubscriptions:subscribing': 'subscribingToOrganizationFeed',
      'feedSubscriptions:subscribing:error': 'errorSubscribingToOrganizationFeed',
      'feedSubscriptions:unsubscribing': 'unsubscribingFromOrganizationFeed',
      'feedSubscriptions:unsubscribing:error': 'errorUnsubscribingFromOrganizationFeed',
      'myOrganizationFeedSubscriptionUids': 'myOrganizationFeedSubscriptionUids',
      'myOrganizationFeedSubscriptionUids:loaded': 'myOrganizationFeedSubscriptionUidsLoaded',
      'myOrganizationFeedSubscriptionUids:loading': 'myOrganizationFeedSubscriptionUidsLoading',
      'myOrganizationFeedSubscriptionUids:loading:error': 'myOrganizationFeedSubscriptionUidsLoadingError',
    },
    componentName
  )

  let {
    organizationFeedSubscriptions: myOrganizationFeedSubscriptions
  } = useFeedSubscriptions(
    myOrganizationFeedSubscriptionUids || [],
    componentName,
  )
  if (!myOrganizationFeedSubscriptionUids) myOrganizationFeedSubscriptions = undefined

  useEffect(
    () => {
      if (!myOrganizationFeedSubscriptionUidsLoaded)
        takeAction('feedSubscriptions.loadMine')
    },
    [myOrganizationFeedSubscriptionUidsLoaded]
  )

  return {
    subscribeToOrganizationFeed: useCallback(
      organizationApikey => {
        takeAction('feedSubscriptions.subscribe', { organizationApikey })
      },
      [],
    ),
    unsubscribeFromOrganizationFeed: useCallback(
      organizationFeedSubscription => {
        takeAction('feedSubscriptions.unsubscribe', { organizationFeedSubscription })
      },
      [],
    ),
    subscribingToOrganizationFeed,
    errorSubscribingToOrganizationFeed,
    unsubscribingFromOrganizationFeed,
    errorUnsubscribingFromOrganizationFeed,
    myOrganizationFeedSubscriptionUids,
    myOrganizationFeedSubscriptionUidsLoaded,
    myOrganizationFeedSubscriptionUidsLoading,
    myOrganizationFeedSubscriptionUidsLoadingError,
    myOrganizationFeedSubscriptions,
    myOrganizationFeedSubscriptionLoaded: myOrganizationFeedSubscriptionUidsLoaded,
    myOrganizationFeedSubscriptionLoading: myOrganizationFeedSubscriptionUidsLoading,
    myOrganizationFeedSubscriptionLoadingError: myOrganizationFeedSubscriptionUidsLoadingError,
  }
}

function useFeedSubscriptions(organizationFeedSubscriptionUids, componentName) {
  const appState = useAppState(
    Array.from(organizationFeedSubscriptionUids).map(uid => `organizationFeedSubscription:${uid}`),
    componentName,
  )

  const organizationFeedSubscriptions = []
  organizationFeedSubscriptionUids.forEach(uid => {
    const organizationFeedSubscription = appState[`organizationFeedSubscription:${uid}`]
    if (!organizationFeedSubscription) {
      console.warn(`organizationFeedSubscription uid="${uid}" not found in appState`)
      return
    }
    organizationFeedSubscriptions.push(organizationFeedSubscription)
  })
  organizationFeedSubscriptions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  return { organizationFeedSubscriptions }
}
