export default function inspect(object, indentation = 2){
  return JSON.stringify(
    object,
    replaceUndefinedWithUndefinedString,
    indentation
  ).replace(/"UNDEFINEDPLACEHOLDER"/g, 'undefined')
}

const replaceUndefinedWithUndefinedString = (k, v) => {
  if (v === undefined) return 'UNDEFINEDPLACEHOLDER'
  if (v instanceof Error) return { message: v.message, stack: v.stack }
  return v
}
