import api from '../api'

export async function load() {
  if (this.getState().gettingRequire2FA) return

  this.setState({
    gettingRequire2FA: true,
    gettingRequire2FAError: undefined,
    updatingRequire2FAError: undefined,
  })

  try {
    const { require2FA } = await api.getRequire2FA()

    this.setState({ require2FA })
  } catch (gettingRequire2FAError) {
    this.setState({ gettingRequire2FAError })
  } finally {
    this.setState({ gettingRequire2FA: undefined })
  }
}

export async function update(value) {
  if (this.getState().updatingRequire2FA) return

  this.setState({
    updatingRequire2FA: true,
    gettingRequire2FAError: undefined,
    updatingRequire2FAError: undefined,
  })

  try {
    const { require2FA } = await api.updateRequire2FA(value)
    this.setState({require2FA })

  } catch (updatingRequire2FAError) {
    this.setState({ updatingRequire2FAError })
  } finally {
    this.setState({ updatingRequire2FA: undefined })
  }
}


