import { h, Fragment } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import Unauthorized from 'components/Unauthorized'
import ContentBox from 'components/ContentBox'
import HeaderedContentBox from 'components/HeaderedContentBox'
import EditPublicProfileForm from 'components/EditPublicProfileForm'

export default function UserProfileEditPage({isMe}){
  if (!isMe) return <Unauthorized className="UserProfileEditPage"/>
  return <div className="UserProfileEditPage">
    <ContentBox padded>
      <EditPublicProfileForm />
    </ContentBox>
  </div>
}

const PageExplanation = () =>
  <HeaderedContentBox padded header="Profile">
    {isDataYogi ? <DataYogiExplanation /> : <Explanation />}
  </HeaderedContentBox>

UserProfileEditPage.pageTitle = 'Edit Public Profile'
UserProfileEditPage.rightColumn = PageExplanation

const Explanation = () => <Fragment>
  <p>
    You can use your real name, or a handle, for your profile, but you have to choose one or the
    other in beta. In the future we plan to allow each person to use both and keep them separate.
  </p>
  <p>
    Your reputation is based on a digital key that signs all activity on {APP_NAME}.
    This digitally signed chain, called the "{APP_NAME} line", establishes the author
    of each post, where it was published, and republished, on which {PNFO.plural}.
  </p>
</Fragment>

const DataYogiExplanation = () => <Fragment>
  <p>
    My Profile has limited functionality in place at present but this will evolve over time to enable you to pull in
    content shaped by the data you wish to make available to shape the content you see.
  </p>
</ Fragment>
