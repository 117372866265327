import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import ContentBox from 'components/ContentBox'
import Header from 'components/Header'
import Navbar from 'components/Navbar'
import Icon from 'components/Icon'
import './index.sass'

export default function OrganizationPageSubnav({
  className,
  children,
  icon,
  name,
  belowNav,
  ...props
}){
  delete props.currentUser
  delete props.isMember

  return <ContentBox
    {...props}
    className={classNames('OrganizationPageSubnav', { className })}
  >
    <Navbar>
      <Header size="lg">
        {icon && <Icon type={icon} size="lg" blue/>}
        <span>{name}</span>
      </Header>
      {children}
    </Navbar>
    {belowNav}
  </ContentBox>
}

OrganizationPageSubnav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  currentUser: PropTypes.object,
  isMember: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  belowNav: PropTypes.component,
}
