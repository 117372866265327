import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { createClassComponent } from 'lib/preactHelpers'

import ContentBox from 'components/ContentBox'
import IconButton from 'components/IconButton'
import './index.sass'

// TODO rename to ContentMenu?
export default function LeftDrawer({
  className,
  menu,
  content,
  open,
  ...props
}){
  return <ContentBox
    {...props}
    className={classNames('LeftDrawer', { className, open })}
  >
    <div className="LeftDrawer-menu">{menu}</div>
    <div className="LeftDrawer-content">{content}</div>
  </ContentBox>
}

LeftDrawer.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.node,
  content: PropTypes.node,
  open: PropTypes.bool,
}

LeftDrawer.BackButton = createClassComponent('LeftDrawer-BackButton', IconButton)
LeftDrawer.BackButton.defaultProps = { type: 'back' }
LeftDrawer.Header = createClassComponent('LeftDrawer-Header')

LeftDrawer.Body = createClassComponent('LeftDrawer-Body')
