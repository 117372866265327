import { h } from 'preact'
import { Fragment } from 'preact'
import { useRef, useEffect } from 'preact/hooks'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import useFirstRender from 'lib/useFirstRender'
import { DMChatListMember } from './DMChatListMember'
import { GeneralChatListMember } from './GeneralChatListMember'
import './index.sass'
import { useMyOrganizations } from 'lib/membershipAppStateHooks'


export function ChatsList({
  chatHref, myPublicProfile, organization, synopsis = [], currentChat, isPersonalChatPage, sortOption,
  searchParam, typeFilter, forwarding, setChatChip, selectedChats, toggleChatSelection
}) {
  const firstRender = useFirstRender()

  const { myOrganizations } = useMyOrganizations('OrganizationChatPage')

  const chatsSelected = synopsis.filter(chatChannel => selectedChats?.has(chatChannel.uid))
    .map(chatChannel => ({
      ...chatChannel,
      key: chatChannel.uid,
      href: chatHref(chatChannel.uid),
      selected: (currentChat && currentChat.chatChannelUid === chatChannel.uid) || selectedChats.has(chatChannel.uid),
      orgName: chatChannel.type === 'general' &&
      myOrganizations.find(org => org.apikey === chatChannel.organizationApikey)?.name,
    }))


  useEffect(() => {
    setChatChip?.(chatsSelected)
  }, [selectedChats])

  const chats = synopsis
    .filter(chatChannel => !chatChannel.isDeletedForUser)

    .map(chatChannel => ({
      ...chatChannel,
      key: chatChannel.uid,
      href: chatHref(chatChannel.uid),
      // selected: currentChat && currentChat.chatChannelUid === chatChannel.uid,
      selected: (currentChat && currentChat.chatChannelUid === chatChannel.uid) || selectedChats?.has(chatChannel.uid),
      orgName: chatChannel.type === 'general' &&
      myOrganizations.find(org => org.apikey === chatChannel.organizationApikey)?.name,
      displayName: chatChannel.type === 'dm'
        ? chatChannel.name
        : myOrganizations.find(org => org.apikey === chatChannel.organizationApikey)?.name,
    }))
    .sort((a, b) => {
      if (a.is_pinned && !b.is_pinned) {
        return -1
      } else if (!a.is_pinned && b.is_pinned) {
        return 1
      } else {
        let aDate = new Date(a.latestAt || a.createdAt).getTime()
        let bDate = new Date(b.latestAt || b.createdAt).getTime()

        switch (sortOption) {
        case "AToZ":
          return a.displayName.localeCompare(b.displayName)
        case "ZToA":
          return b.displayName.localeCompare(a.displayName)
        case "OldestToNewest":
          return aDate - bDate
        case "NewestToOldest":
        default:
          return bDate - aDate
        }
      }
    }).filter(chat => {
      const isGeneral = chat.type === 'general'

      if (searchParam) {
        if (isGeneral) {
          return chat.orgName?.toLowerCase().includes(searchParam?.toLowerCase())
        }
        if (chat.type === 'dm') { //* DM chats that have no name in the DB will not be searchable
          return chat.name?.toLowerCase().includes(searchParam?.toLowerCase())
        }
      }
      return true
    })

  if (currentChat && currentChat.dm) {
    chats.unshift({
      key: 'newDMChatChannel',
      type: 'dm',
      unread: 0,
      createdAt: new Date(),
      selected: true,
      href: `${global.location}`,
      memberUserDids: [currentChat.dm],
    })
  }

  const selectedIndex = chats.findIndex(chat => chat.selected)
  const rootRef = useRef()
  useEffect(
    () => {
      const node = rootRef.current.base
      const scrollTo = top => node.scrollTo({ top, behavior: firstRender ? undefined : 'smooth' })
      const selected = node.querySelector('.OrganizationChatPage-ChatListMember-selected')
      if (selected) {
        const { offsetTop, offsetHeight } = selected
        const { clientHeight, scrollTop } = node
        if (offsetTop < scrollTop || offsetTop > (scrollTop + clientHeight)) {
          scrollTo(offsetTop - (clientHeight / 3) + (offsetHeight / 2))
        }
      } else {
        scrollTo(0)
      }
    },
    [selectedIndex]
  )

  const filteredChats = chats.filter(chat => {
    if (typeFilter === 'dm') {
      return chat.type === 'dm' && chat.organizationApikey === null
    } else if (typeFilter === 'general') {
      return (chat.type === 'dm' || chat.type === 'general') && chat.organizationApikey
    } else {
      return true
    }
  })
  return<> <TransitionGroup ref={rootRef} className="OrganizationChatPage-ChatsList">
    {filteredChats.map(chatChannel => <CSSTransition
      key={chatChannel.key}
      timeout={200}
      classNames="OrganizationChatPage-ChatsList-transition"
    >
      {h(
        chatChannel.type === 'general' ? GeneralChatListMember : DMChatListMember,
        { ...chatChannel,
          myPublicProfile,
          organization,
          isPersonalChatPage,
          forwarding,
          isSelected: selectedChats?.has(chatChannel.uid),
          onToggleSelection: () => toggleChatSelection(chatChannel.uid),
        }
      )}
    </CSSTransition>
    )}
  </TransitionGroup>
  </>
}
