import moment from 'moment'
import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import useUpdateEverySecond from 'lib/useUpdateEverySecond'
import './index.sass'

export default function CountdownToTime({
  className, to, format, ...props
}){
  useUpdateEverySecond()
  to = moment(to)
  const distance = Math.max(0, to.diff(moment()))
  const done = distance === 0
  const title = to.toDate().toLocaleTimeString()
  const value = moment.utc(distance).format(format)
  className = classNames('CountdownToTime', { className, done })
  return <span {...{ ...props, className, title}}>{value}</span>
}

CountdownToTime.defaultProps = {
  format: 'HH:mm:ss',
}

CountdownToTime.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment().constructor),
  ]).isRequired,
}
