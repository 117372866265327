import { useState, useMemo } from 'preact/hooks'

export default function useAsyncAction(func, args = []){
  const [
    {
      acting,
      result,
      error,
    },
    setState
  ] = useState({})

  const act = useMemo(
    () => (
      acting
        ? () => {}
        : () => {
          const acting = func()
          console.log('???? acting', acting)
          setState({ acting })
          acting.then(
            result => { setState({ result }) },
            error => { setState({ error }) },
          )
        }
    ),
    [acting, func, ...args]
  )

  return {
    act,
    acting,
    result,
    error,
  }
}
