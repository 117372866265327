import api from '../api'

export default function(preferences){
  this.setState({
    updatingPreferences: true,
    updatingPreferencesError: undefined,
  })

  api.updatePreferences({preferences}).then(
    ({preferences}) => {
      this.setState({
        preferences,
        updatingPreferences: undefined,
      })
    },
    updatingPreferencesError => {
      this.setState({
        updatingPreferences: undefined,
        updatingPreferencesError,
      })
    }
  )
}
