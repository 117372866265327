import api from '../api'

export async function all(searchQuery, page){
  if (!searchQuery || !page) return
  const searchingKey = `search:${searchQuery}:${page}:searching`
  const searchingErrorKey = `search:${searchQuery}:${page}:error`
  const searchResultsKey = `search:${searchQuery}:${page}:results`

  if (this.getState()[searchingKey]) return

  const searchedAt = Date.now()
  this.setState({
    [searchingKey]: searchedAt,
    [searchingErrorKey]: undefined,
  })

  try{
    const searchResults = await api.search(searchQuery, page - 1)
    searchResults.searchedAt = searchedAt
    this.setState({[searchResultsKey]: searchResults})
  }catch(error){
    this.setState({[searchingErrorKey]: error})
  }finally{
    this.setState({[searchingKey]: undefined})
  }
}

export async function organizations(searchQuery, page){
  const {
    organizations, lastPage
  } = await api.searchForOrganizations(searchQuery, page)
  this.takeAction('organization.addOrganizationsToAppState', organizations)
  return { organizations, lastPage }
}
