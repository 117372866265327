
export function getAssetUrls(){
  const $ = s => Array.from(window.document.querySelectorAll(s))
  const stylehseets = $('link[rel=stylesheet]').map(l => l.href)
  const scripts = $('script').map(l => l.src)
  return [...stylehseets, ...scripts]
}

export function getAppVersion(){
  return {
    assets: getAssetUrls(),
  }
}
