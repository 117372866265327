
import { h } from 'preact'
import classNames from 'lib/classNames'
import FileAttachment from 'components/FileAttatchement'
import './index.sass'

export function UploadingFile({ loading, progress, error, preview }) {
  const failed = !!error
  const className = classNames('OrganizationChatPage-UploadingFile', {
    failed,
  })
  return <div {...{ className }}>
    <FileAttachment {...preview} />
    {loading &&
      <div
        className="OrganizationChatPage-UploadingFile-progress"
        style={{ right: `${100 - (progress || 95)}%` }} />}
  </div>
}
