import { h } from 'preact'
import PropTypes from 'prop-types'
import moment from 'moment'

import classNames from 'lib/classNames'
import PERSONAL_DATA_KEYS from 'jlinc-shared/personal_data_keys'
import CONSENTS from 'jlinc-shared/consents'
import COMMUNICATION_CHANNELS from 'jlinc-shared/communication_channels'
import COMMUNICATION_CHANNEL_TITLES from 'jlinc-shared/communication_channel_titles'
import { getLabelForPersonalDataField } from 'lib/accountDataSpec'

import Link from 'components/Link'
import HelpButton from 'components/HelpButton'
import Header from 'components/Header'
import './index.sass'

export default function SisaEventDescription({
  className,
  organization,
  sisaEvent,
  ...props
}){
  const sisaEventId = sisaEvent.audit.eventId

  return <div
    {...props}
    className={classNames('SisaEventDescription', { className })}
  >
    <div className="SisaEventDescription-row">
      <Header size="sm">Event ID</Header>
      <div className="SisaEventDescription-row-text">
        {sisaEventId}
      </div>
    </div>

    {/* <div className="SisaEventDescription-row">
      <Header size="sm">Organization</Header>
      <div className="SisaEventDescription-row-text">
        {organization.name}
      </div>
    </div> */}

    <div className="SisaEventDescription-row">
      <Header size="sm">Date/Time Signed</Header>
      <div className="SisaEventDescription-row-text">
        {moment(new Date(sisaEvent.audit.createdAt)).format('LLLL')}
      </div>
    </div>


    <div className="SisaEventDescription-row">
      <Header size="sm">Event Type</Header>
      <div className="SisaEventDescription-row-text">
        {sisaEvent.audit.eventType.replace(/Event$/, '')}
      </div>
    </div>

    <div className="SisaEventDescription-row">
      <Header size="sm" className="SisaEventDescription-row-headerWithHelpButton">
        <span>Rights Holder DID</span>
        <HelpButton>
          The Rights Holder is you and this DID <DidDocsLink /> may be the only way that
          this Data Custodian can identify you if you have not given them your name or
          other identifying info.
        </HelpButton>
      </Header>
      <div className="SisaEventDescription-row-text">
        {sisaEvent.audit.rightsHolderDid}
      </div>
    </div>

    <div className="SisaEventDescription-row">
      <Header size="sm" className="SisaEventDescription-row-headerWithHelpButton">
        <span>Data Custodian DID</span>
        <HelpButton>
          The DID <DidDocsLink /> for the Data Custodian "{organization.name}"
          the party that has custody of your data.
        </HelpButton>
      </Header>
      <div className="SisaEventDescription-row-text">
        {sisaEvent.audit.dataCustodianDid}
      </div>
    </div>


    <div className="SisaEventDescription-row">
      <Header size="sm">Previous Sisa Event</Header>
      <div className="SisaEventDescription-row-text">
        { sisaEvent.audit.previousId
          ? <Link href={`/${organization.apikey}/sisa-events/${sisaEvent.audit.previousId}`}>
            {sisaEvent.audit.previousId}
          </Link>
          : null
        }
      </div>
    </div>

    <div className="SisaEventDescription-row">
      <Header size="sm">Changes</Header>
      <EventDetails event={sisaEvent.event} />
    </div>
  </div>
}

SisaEventDescription.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  sisaEvent: PropTypes.shape({
    audit: {
      eventId: PropTypes.string,
      createdAt: PropTypes.string,
      eventType: PropTypes.string,
      rightsHolderDid: PropTypes.string,
      dataCustodianDid: PropTypes.string,
      previousId: PropTypes.string,
    },
    event: {
      shared_personal_data: PropTypes.object,
      personal_data: PropTypes.object,
      consents: PropTypes.object,
      communication_channels: PropTypes.object,
    },
  }),
  organization: PropTypes.shape({
    apikey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}


export const EventDetails = ({ event }) => {
  const changes = []

  if (event.shared_personal_data) {
    Object.keys(event.shared_personal_data)
      .sort(sortPersonalData)
      .forEach(key => {
        const value = event.shared_personal_data[key]
        const label = getLabelForPersonalDataField(key)
        changes.push(
          <div key={`shared_personal_data.${key}`} className="SisaEventDescription-change">
            <span>{value ? 'Started' : 'Stopped'} sharing</span>&nbsp;
            <Field>{label}</Field>
          </div>
        )
      })
  }

  if (event.personal_data){
    Object.keys(event.personal_data)
      .sort(sortPersonalData)
      .forEach(personalDataKey => {
        const value = event.personal_data[personalDataKey]
        const label = getLabelForPersonalDataField(personalDataKey)
        if (event.shared_personal_data && event.shared_personal_data[personalDataKey] === false) {
          return
        }else if (value === null) {
          changes.push(
            <div key={`personal_data.${personalDataKey}`} className="SisaEventDescription-change">
              <span>Stopped sharing</span>&nbsp;
              <Field>{label}</Field>
            </div>
          )
        }else{
          changes.push(
            <div key={`personal_data.${personalDataKey}`} className="SisaEventDescription-change">
              <Field>{label}:</Field>&nbsp;
              <div className="SisaEventDescription-change-changedTo">
                {value}
              </div>
            </div>
          )
        }
      })
  }

  if (event.consents){
    Object.keys(event.consents)
      .sort(sortConsents)
      .forEach(consent => {
        const enabled = event.consents[consent]
        changes.push(
          <div key={`consents.${consent}`} className="SisaEventDescription-change">
            <span>Permission:</span>&nbsp;
            <Field>{consent}</Field>&nbsp;
            <span>{enabled ? 'enabled' : 'disabled'}</span>
          </div>
        )
      })
  }

  if (event.communication_channels){
    Object.keys(event.communication_channels)
      .sort(sortCommunicationChannels)
      .forEach(communicationChannel => {
        const title = COMMUNICATION_CHANNEL_TITLES[communicationChannel] || communicationChannel
        const enabled = event.communication_channels[communicationChannel]
        changes.push(
          <div key={`communication_channels.${communicationChannel}`} className="SisaEventDescription-change">
            <span>Communication Channel:</span>&nbsp;
            <Field>{title}</Field>&nbsp;
            <span>{enabled ? 'enabled' : 'disabled'}</span>
          </div>
        )
      })
  }

  return <div className="SisaEventDescription-changesWrapper">{changes}</div>
}

const Field = ({children}) =>
  <span className="SisaEventDescription-Field">{children}</span>


const sortPersonalData = (a, b) => {
  a = PERSONAL_DATA_KEYS.indexOf(a)
  b = PERSONAL_DATA_KEYS.indexOf(b)
  return a < b ? -1 : a > b ? 1 : 0
}

const sortConsents = (a, b) => {
  a = CONSENTS.indexOf(a)
  b = CONSENTS.indexOf(b)
  return a < b ? -1 : a > b ? 1 : 0
}

const sortCommunicationChannels = (a, b) => {
  a = COMMUNICATION_CHANNELS.indexOf(a)
  b = COMMUNICATION_CHANNELS.indexOf(b)
  return a < b ? -1 : a > b ? 1 : 0
}


const DidDocsLink = () =>
  <Link type="link" href="https://w3c-ccg.github.io/did-spec/" newWindow><i>Decentralized Identifier</i></Link>
