import { useEffect } from 'preact/hooks'
import history from 'lib/history'

export default function useScrollUpOnNaturalPageChange(
  args = [], getScrollTop = () => 0
){
  useEffect(
    () => {
      if (history.natural || history.justLoaded){
        const scrollTop = getScrollTop()
        window.document.documentElement.scrollTo(0, scrollTop - 1)
        requestAnimationFrame(() => {
          window.document.documentElement.scrollTo(0, scrollTop)
        })
      }
    },
    args,
  )
}
