const WYSIWYG_IMAGE_PLACEHOLDER = '<figure class="image"><img>'

export const EMPTY_WYSIWYG = `<p></p>`
export const WYSIWYG_IMAGE_FAILED = 'Image%20failed%20to%20upload'

export function wysiwygContainsUploadingPlaceholder(value) {
  return value && value.includes(WYSIWYG_IMAGE_PLACEHOLDER)
}

export const isEmptyWYSIWYG = value => !value || value.trim() === EMPTY_WYSIWYG

export function analyze(value){
  if (!value || typeof value !== 'string') return { isEmpty: true }
  return {
    uploading: value.includes(WYSIWYG_IMAGE_PLACEHOLDER),
    failedUploads: value.includes(WYSIWYG_IMAGE_FAILED),
    isEmpty: isEmptyWYSIWYG(value),
  }
}

const LAST_USED_WYSIWYG_KEY = 'FeedPostForm-lastUsedWYSIWYG'
export const isPreferred = () =>
  localStorage[LAST_USED_WYSIWYG_KEY] === '1'
export const setIsPreferred = usedWYSIWYG => {
  localStorage[LAST_USED_WYSIWYG_KEY] =
    usedWYSIWYG ? '1' : undefined
}

export const is = text => typeof text === 'string' && /^\s*<[a-z]/.test(text)
