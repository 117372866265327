import { h } from 'preact'
import { useRef, useMemo, useState } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Icon from 'components/Icon'
import Header from 'components/Header'
import './index.sass'

export default function FilesUploadDroparea({
  className,
  children,
  disabled,
  onFiles,
  accept,
  multiple,
  label,
  ...props
}){
  const [dropping, setDropping] = useState(false)

  const propsRef = useRef()
  Object.assign(propsRef, { disabled, onFiles })

  const acceptRegExp = acceptToRegExp(accept)

  const events = useMemo(() => ({
    onDragOver(event){
      event.preventDefault()
      setDropping(true)
    },
    onDragLeave(event){
      event.preventDefault()
      setDropping(false)
    },
    onDrop(event){
      event.preventDefault()
      setDropping(false)
      if (propsRef.disabled) return
      const files = [...event.dataTransfer.files]
        .filter(file => file.type.match(acceptRegExp))
      if (!multiple && files.length > 1) files.length = 1
      propsRef.onFiles(files, event)
    },
  }), [])

  return <div {...{
    ...props,
    ...events,
    className: classNames('FilesUploadDroparea', { className }),
    'data-disabled': disabled,
    'data-dropping': dropping,
  }}>
    <div className="FilesUploadDroparea-overlay">
      <Icon type="upload"/>
      {label && <Header size="xxl">{label}</Header>}
    </div>
    {typeof children === 'function' ? children(dropping) : children}
  </div>
}

FilesUploadDroparea.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onFiles: PropTypes.func.isRequired,
  accept: PropTypes.string,
  label: PropTypes.node,
}
FilesUploadDroparea.defaultProps = {
  label: 'drop files here',
  accept: '*',
  multiple: true,
}


const acceptToRegExp = accept =>
  accept === '*'
    ? /.*/
    : new RegExp(
      '^(' + (
        accept
          .split(',')
          .map(p => p.replace('/*', '\\/.+'))
          .join('|')
      ) + ')$'
    )
