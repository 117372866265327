import { h, Fragment } from 'preact'
import { useRef, useMemo, useErrorBoundary } from 'preact/hooks'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { usePortal } from 'lib/portal'
import classNames from 'lib/classNames'
import useOnWindowBlur from 'lib/useOnWindowBlurHook'

import ErrorMessage from 'components/ErrorMessage'
import './index.sass'

export default function ContextPortal({
  className,
  isOpen,
  onClose,
  closeOnWindowBlur = true,
  ...props
}){
  let shroudRef = useRef()
  if (props.shroudRef) {
    shroudRef = props.shroudRef
    delete props.shroudRef
  }
  useOnWindowBlur(closeOnWindowBlur && onClose)

  return usePortal()(
    <TransitionGroup className={classNames('ContextPortal', { className })}>
      {isOpen &&
        <CSSTransition classNames="ContextPortal-animation" timeout={200}>
          <Shroud {...{
            ...props,
            ref: shroudRef,
            onClose,
          }}/>
        </CSSTransition>
      }
    </TransitionGroup>
  )
}

ContextPortal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.node,
}

function Shroud({
  onClose,
  renderInsideShroud = true,
  children,
  ...props
}){
  const ref = useRef()
  const [error, onDismiss] = useErrorBoundary(error =>
    console.error('ContextPortal render error', error)
  )
  const onMouseDown = useMemo(
    () => onClose && (event => {
      if (props.onMouseDown && props.onMouseDown(event) === false) return
      if (event.target === ref.current && onClose) onClose()
    }),
    [onClose, props.onMouseDown]
  )
  return <Fragment>
    <div {...{
      ...props,
      tabIndex: 0,
      ref,
      className: 'ContextPortal-shroud',
      onMouseDown,
    }}>
      {error
        ? <ErrorMessage {...{error, onDismiss}}/>
        : (renderInsideShroud ? children : undefined)
      }
    </div>
    {!error && (renderInsideShroud ? undefined : children)}
  </Fragment>
}
