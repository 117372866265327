import api from '../api'

export function create({ organizationApikey, maxUsage, validUntil }) {
  if (!organizationApikey) throw new Error('organizationApikey is required')

  const createdKey        = `organizationMembershipInvite:${organizationApikey}:created`
  const creatingKey       = `organizationMembershipInvite:${organizationApikey}:creating`
  const creatingErrorKey  = `organizationMembershipInvite:${organizationApikey}:creating:error`

  if (this.getState()[creatingKey]) return

  this.setState({
    [creatingKey]: true,
    [createdKey]: undefined,
    [creatingErrorKey]: undefined,
  })

  const invitePayload = {
    organizationApikey,
    maxUsage,
    validUntil,
  }

  api.createOrganizationMembershipInvite(invitePayload).then(
    ({ organizationMembershipInvite: invite }) => {
      const invitesKey = `organizationMembershipInvites:${organizationApikey}`
      const { [invitesKey]: invites = [] } = this.getState()
      this.setState({
        [creatingKey]: undefined,
        [createdKey]: invite.token,
        [invitesKey]: [invite, ...invites],
      })
    },
    error => {
      this.setState({
        [creatingKey]: undefined,
        [creatingErrorKey]: error,
      })
    }
  )
}

export function loadInvites(organizationApikey){
  if (!organizationApikey) throw new Error('organizationApikey is required')

  const invitesKey      = `organizationMembershipInvites:${organizationApikey}`
  const loadingKey      = `organizationMembershipInvites:${organizationApikey}:loading`
  const loadingErrorKey = `organizationMembershipInvites:${organizationApikey}:loading:error`

  const {
    [loadingKey]: loading,
    [invitesKey]: invites,
  } = this.getState()
  if (loading || invites) return

  this.setState({
    [loadingKey]: true,
    [invitesKey]: undefined,
    [loadingErrorKey]: undefined,
  })

  api.getOrganizationMembershipInvites({ organizationApikey }).then(
    ({ organizationMembershipInvites }) => {
      this.setState({
        [loadingKey]: undefined,
        [invitesKey]: organizationMembershipInvites,
      })
    },
    error => {
      this.setState({
        [loadingKey]: undefined,
        [loadingErrorKey]: error,
      })
    }
  )
}

export function loadInvite(token) {
  if (!token) throw new Error('token is required')

  const inviteKey  = `organizationMembershipInvite:${token}`
  const loadingKey = `organizationMembershipInvite:${token}:loading`
  const errorKey   = `organizationMembershipInvite:${token}:loading:error`

  const {
    [loadingKey]: loading,
    [inviteKey]: invite,
  } = this.getState()
  if (loading || invite) return

  this.setState({
    [loadingKey]: true,
    [inviteKey]: undefined,
    [errorKey]: undefined,
  })

  api.getOrganizationMembershipInvite({ token }).then(
    ({ organizationMembershipInvite: invite }) => {
      invite.token = token
      this.setState({
        [loadingKey]: undefined,
        [inviteKey]: invite,
        [`organizationMembershipInviteTo:${invite.organizationApikey}`]: invite,
      })
    },
    error => {
      if (error.message.includes('invalid invite token'))
        error = `This invite token is invalid.`
      this.setState({
        [loadingKey]: undefined,
        [errorKey]: error,
      })
    }
  )
}
