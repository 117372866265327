import logger from 'lib/logger'
import api from '../api'

export default function(organizationApikey) {
  return api.getOrganizationAccountdata({ organizationApikey })
    .then(
      organizationAccountData => {
        this.setState({
          [`organizationAccountData:${organizationApikey}`]: organizationAccountData,
        })
      },
      error => {
        if (error.message.includes('organization account data not found')) return
        logger.error(`error loading organization account data for ${organizationApikey}`)
        logger.error(error)
      }
    )
}
