import { h } from 'preact'
import { useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Icon from 'components/Icon'
import './index.sass'

export default function BounceArrow({
  className,
  disabled,
  down,
  up = !down,
  active,
}){
  const iconRef = useRef()
  const firstRenderRef = useRef(true)
  useEffect(
    () => {
      if (firstRenderRef.current){
        firstRenderRef.current = false
        return
      }
      if (!active) return
      const className = `BounceArrow-${up ? 'up' : 'down'}`
      const element = iconRef.current.base
      element.classList.add(className)
      const removeClassName = () => {
        element.classList.remove(className)
      }
      const timeout = setTimeout(removeClassName, 1000)
      return () => { clearTimeout(timeout); removeClassName() }
    },
    [disabled, up, active]
  )
  return <Icon {...{
    className: classNames('BounceArrow', { className, active }),
    ref: iconRef,
    type: up ? 'up-bold' : 'down-bold',
  }}/>
}

BounceArrow.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  up: PropTypes.bool,
  down: PropTypes.bool,
  active: PropTypes.bool.isRequired,
}
