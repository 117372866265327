import api from '../api'

export default function({ organizationApikeys, changes }){
  return api.updateOrganizationsAccountData({ organizationApikeys, changes })
    .then(({ updates, notifications }) => {
      const newState = {}
      for (const organizationApikey in updates){
        const {organizationAccountData, error} = updates[organizationApikey]
        newState[`organizationAccountData:${organizationApikey}`] = organizationAccountData
        newState[`updatingOrganizationAccountDataError:${organizationApikey}`] = error
      }
      this.setState(newState)
      this.takeAction('notifications.addNotificationsToAppState', notifications)
    })
}
