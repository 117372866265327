import moment from 'moment'
import { h, Fragment } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import classNames from 'lib/classNames'
import { useLocalStorageState } from 'lib/storageHooks'
import { usePublicProfile } from 'lib/membershipAppStateHooks'
import useToggle from 'lib/useToggleHook'

import {
  useOrganizationAnnouncements,
  reloadOrganizationAnnouncements,
  createOrganizationAnnouncement,
  updateOrganizationAnnouncement,
  destroyOrganizationAnnouncement,
  markOrganizationAnnouncementAsSeen,
} from 'resources/OrganizationAnnouncements'

import Alert from 'components/Alert'
import Link from 'components/Link'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import EditButton from 'components/EditButton'
import Header from 'components/Header'
import Loading from 'components/Loading'
import InterfaceHelp from 'components/InterfaceHelp'
import ErrorMessage from 'components/ErrorMessage'
import Form from 'components/Form'
import FormBuilder from 'components/FormBuilder'
import StyleishModal from 'components/StyleishModal'
import WYSIWYGInput from 'components/WYSIWYGInput'
import WYSIWYGContent from 'components/WYSIWYGContent'
import EndUserAvatar from 'components/EndUserAvatar'
import TimeAgo from 'components/TimeAgo'
import ActionButton from 'components/ActionButton'
import ContentBox from 'components/ContentBox'
import CountdownToTime from 'components/CountdownToTime'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import NotificationBadge from 'components/NotificationBadge'
import './index.sass'

const MAKE_TEXT = 'Make Announcement'

export default function OrganizationAnnouncements({ className, ...props }){
  return <div className={classNames('OrganizationAnnouncements', { className })}>
    <ErrorMessage error={props.loadingError}/>
    {props.loaded
      ? <List {...props}/>
      : <Loading type="block" />
    }
  </div>
}

OrganizationAnnouncements.use = useSharedState
function useSharedState({
  organizationApikey, isAdmin, isCurator, isMember,
  modalOpen, onModalClose
}){
  const editable = !!(isAdmin || isCurator)
  const announcements = useOrganizationAnnouncements(organizationApikey)
  const lastLoud = announcements.lastSentSMSAndEmailNotificationsAt
  const loadingError = announcements._loadingError
  const loaded = !loadingError && Array.isArray(announcements.all)
  const empty = loaded && announcements.all.length === 0
  const count = loaded ? announcements.all.length : 0
  const unseenCount = loaded ? announcements.all.filter(a => !a.seenAt).length : 0

  const [_modalOpen, openModal, closeModal] = useToggle(unseenCount > 0)
  modalOpen = modalOpen || _modalOpen
  const [draft, setDraft] = useLocalStorageState(
    `OrganizationAnnouncements:draft:${organizationApikey}`
  )
  const [creating, startCreating, stopCreating] = useToggle(draft && draft.value)

  useEffect(
    () => { if (loaded && unseenCount > 0) openModal() },
    [loaded, unseenCount]
  )

  useEffect(
    () => { if (isMember) reloadOrganizationAnnouncements(organizationApikey) },
    [isMember]
  )

  const props = {
    organizationApikey, editable,
    announcements: announcements.all,
    loadingError, loaded, empty,
    modalOpen, openModal,
    closeModal(){
      if (onModalClose) onModalClose()
      closeModal()
    },
    creating, startCreating, stopCreating,
    draft, setDraft,
    count, unseenCount,
    lastLoud
  }
  const renderModal = () => <OrganizationAnnouncements.Modal {...props}/>
  const renderButton = () => <OrganizationAnnouncements.Button {...props}/>
  return [renderModal, renderButton]
}

function List(props){
  const { organizationApikey, lastLoud, editable, announcements, empty, selectedUid } = props

  const ref = useRef()
  useEffect(
    () => {
      ref.current.closest('.StyleishModal-body').scrollTop = 0
    },
    [selectedUid]
  )

  return <div className="OrganizationAnnouncements-List" ref={ref}>
    {editable &&
      <ContentBox noBorder>
        {props.creating
          ? <Editor {...{
            organizationApikey,
            lastLoud,
            value: props.draft,
            onChange: props.setDraft,
            onClose: props.stopCreating,
          }}/>
          : <Header centered> <Button type="primary" onClick={props.startCreating}>{MAKE_TEXT}</Button> </Header>
        }
      </ContentBox>
    }
    {empty
      ? <Header size="md" centered className="OrganizationAnnouncements-emptyHeader">
        There are currently no announcements
      </Header>
      : announcements.map(announcement =>
        <Announcement {...{organizationApikey, editable, announcement}} />
      )
    }
    <ButtonRow className="OrganizationAnnouncements-navButtons">
      <Button type="normal" onClick={props.closeModal}>close</Button>
    </ButtonRow>
  </div>
}

function Announcement({ organizationApikey, editable, announcement }){
  const unSeen = !announcement.seenAt
  const { publicProfile } = usePublicProfile(announcement.createdBy, 'OrganizationAnnouncements')
  useEffect(
    () => {
      if (unSeen) markOrganizationAnnouncementAsSeen(announcement)
    },
    [announcement.uid, unSeen]
  )
  const [draft, setDraft] = useLocalStorageState(
    `OrganizationAnnouncements:${announcement.uid}:edits`
  )
  const [isEditing, startEditing, stopEditing] = useToggle(draft && draft.value)
  return <ContentBox padded className={classNames('OrganizationAnnouncements-Announcement', { unSeen })}>
    {isEditing
      ? <Editor {...{
        organizationApikey,
        announcement,
        value: draft,
        onChange: setDraft,
        onClose: stopEditing,
      }}/>
      : <Fragment>
        {editable &&
          <ButtonRow>
            {announcement.sentSmsAndEmailNotifications &&
              <InterfaceHelp content="SMS and Email notifications sent">
                <Icon type="megaphone" title="SMS and Email notifications sent" />
              </InterfaceHelp>
            }
            <EditButton {...{onClick: startEditing, bordered: false}}/>
          </ButtonRow>}
        <WYSIWYGContent source={announcement.value}/>
        <Header size="md">
          {editable && <span>(seen by {announcement.seenCount} members)&nbsp;</span>}
          <span>Created&nbsp;by&nbsp;</span>
          <LinkToPublicProfile {...{publicProfile, tabIndex: -1}}>
            <EndUserAvatar {...{publicProfile, size: 'tn'}}/>
          </LinkToPublicProfile>&nbsp;
          <TimeAgo time={announcement.createdAt}/>
          {announcement.updatedAt && announcement.updatedAt !== announcement.createdAt &&
            <Fragment>
              &nbsp;and&nbsp;edited&nbsp;<TimeAgo time={announcement.updatedAt}/>
            </Fragment>
          }
        </Header>
      </Fragment>
    }
  </ContentBox>
}


function Editor({ organizationApikey, lastLoud: lastLoud, announcement, value, onChange, onClose }){
  const [uploading, onUploading] = useState(false)
  const onReset = () => { onChange(undefined, true) }
  const onCancel = () => { onReset(); onClose() }

  if (lastLoud) lastLoud = moment(lastLoud)

  return <FormBuilder {...{
    className: 'OrganizationAnnouncements-Editor',
    persistedValues: announcement,
    value,
    onChange,
    onReset,
    submittable: !uploading && value,
    async onSubmit(draft){
      if (!draft || !draft.value) return
      let uid
      if (announcement){
        uid = announcement.uid
        await updateOrganizationAnnouncement(organizationApikey, uid, draft.value)
      }else{
        uid = await createOrganizationAnnouncement(organizationApikey, draft.value)
      }
      onChange(undefined, true)
      onClose()
    },
    render: form => (
      <Fragment>
        <Header size="lg" centered>
          {announcement ? `Edit` : `Create New`} {PNFO.singular} Announcement
        </Header>
        {(!announcement && lastLoud && moment().diff(lastLoud, 'days') < 1) &&
          <Alert type="warning">
            <div>
              <b>WARNING</b> if you create this {PNFO.singular} announcement now,
              your members will <b>not</b> be notified via SMS or Email.<br/>
              Please wait <CountdownToTime
                to={lastLoud.clone().add(1, 'day')}
                format="H [hours and] m [minutes]"
              /> before creating another announcement
              if you want to notify your members via SMS or Email.
            </div>
          </Alert>
        }
        <Form.Row>
          {form.item({
            valueProp: 'value',
            bindTo: 'onInput',
            input: <WYSIWYGInput {...{onUploading}}/>,
            required: true,
          })}
        </Form.Row>
        <Form.ButtonRow reverse>
          {form.submitButton(
            uploading
              ? { value: 'Uploading…', disabled: true }
              : announcement
                ? { value: 'Update', submittingValue: 'Update…' }
                : { value: 'Announce', submittingValue: 'Announcing…' }
          )}
          {announcement && <DestroyButton {...{ announcement, onSuccess: onClose }}/> }
          {form.resetButton()}
          {onClose && <Button type="normal" onClick={onCancel}>cancel</Button>}
        </Form.ButtonRow>
      </Fragment>
    )
  }}/>
}

function DestroyButton({ announcement, ...props}){
  return <ActionButton {...{
    ...props,
    type: 'normal',
    confirmation: `Are you sure want to destroy this announcement?`,
    async action(){
      await destroyOrganizationAnnouncement(announcement)
    },
    value: 'destroy',
    busyValue: 'destroying…',
  }}/>
}


OrganizationAnnouncements.Modal = function(props){
  return <StyleishModal scrollInternally {...{
    className: 'OrganizationAnnouncements-Modal',
    title: `${PNFO.singular} Announcements`,
    open: props.modalOpen,
    onClose: props.closeModal,
    padded: false,
    scrollInternally: true,
  }}>
    <input type="text" className=""/>
    <OrganizationAnnouncements {...props}/>
  </StyleishModal>
}

OrganizationAnnouncements.Button = function(props){
  return <div className="OrganizationAnnouncements-Button">
    {props.editable && <Fragment>
      <Link {...{
        className: 'OrganizationAnnouncements-newLink',
        type: 'link',
        onClick(){ props.openModal(); props.startCreating() },
      }}>{MAKE_TEXT}</Link>
      &nbsp;
    </Fragment>}
    <IconButton {...{
      className: 'OrganizationAnnouncements-openButton',
      type: 'megaphone',
      onClick(){ props.openModal() },
    }}/>
    <NotificationBadge total={props.unseenCount}/>
  </div>
}
