import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Button from 'components/Button'
import Header from 'components/Header'
import StyleishModal from 'components/StyleishModal'
import './index.sass'

const title = `Welcome to ${APP_NAME}`

export default function APortalWelcomeModal({ className, onClose, ...props }){
  return <StyleishModal {...{
    className: classNames('APortalWelcomeModal', { className }),
    title,
    onClose,
    ...props,
  }}>
    <APortalWelcomeModal.Content {...{ onClose }}/>
  </StyleishModal>
}

APortalWelcomeModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
}

function Content({ className, onClose, ...props }){
  return <div
    className={classNames('APortalWelcomeModal-Content', { className })}
    {...props}
  >
    <Header size="xl" centered>{title}</Header>
    {isDataYogi ? <DataYogiWelcomeText /> : <WelcomeText />}
    <div className="flex-horizontal-center">
      <Button
        autoFocus
        type="primary"
        onClick={onClose}
        value="Lets go!"
      />
    </div>
  </div>
}
APortalWelcomeModal.Content = Content

const DataYogiWelcomeText = () => <Fragment>
  <p>
    Welcome to {APP_NAME} – putting you in control of your buying related data.
  </p>
  <p>
    {APP_NAME} uses ultra-modern data management tools and protocols to give you a place to build up your own data
    assets and leverage them to good effect in managing your supply relationships. Over time, DataYogi will help
    you save time, save money and make managing your many products and services that little bit easier.
  </p>
</Fragment>

const WelcomeText = () => <Fragment>
  <p>
    {APP_NAME} uses reputation to help re-establish "truth" on the Internet. It offers a clean, safe, space
    with no ads, bots, or trolls. Collaboratively-curated forums give you control over what you see
    in your feed without algorithms or censorship.
  </p>
  <WalkthroughVideo/>
</Fragment>

function WalkthroughVideo(){
  return <div className="APortalWelcomeModal-video">
    <video src="https://assets.jlinc.io/3b50db80-5232-11ec-ac02-ff294d0be4d9.mp4" autoPlay controls />
  </div>
}
