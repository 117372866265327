import capitalize from 'lib/capitalize'

export function entityKeys(entityKey, actions = ['loading', 'updating']){
  const keys = {
    entityKey,
    entityNotFoundKey: `${entityKey}:notFound`,
  }
  for (const action of actions){
    keys[`entity${capitalize(action)}Key`] = `${entityKey}:${action}`
    keys[`entity${capitalize(action)}ErrorKey`] = `${entityKey}:${action}:error`
  }
  // console.log(JSON.stringify(keys, null, 2))
  return keys
}

export async function tx(lockKey, errorKey, work){
  if (typeof errorKey === 'function'){
    work = errorKey
    errorKey = lockKey + ':error'
  }
  if (this.getState()[lockKey]) return
  this.setState({
    [lockKey]: true,
    [errorKey]: undefined,
  })
  try{
    await work(lockKey, errorKey)
  }catch(error){
    console.error(error)
    this.setState({ [errorKey]: error })
    return error
  }finally{
    this.setState({ [lockKey]: undefined })
  }
}

export async function loadEntity(options){
  const {
    reload = false,
    request,
    entityKey,
  } = options

  const {
    entityLoadingKey,
    entityLoadingErrorKey,
    entityNotFoundKey,
  } = entityKeys(entityKey)

  const appState = this.getState()
  if (!reload && (appState[entityKey] || appState[entityNotFoundKey])) return
  if (appState[entityLoadingKey]) return

  this.setState({
    [entityLoadingKey]: true,
    [entityLoadingErrorKey]: undefined,
  })

  try{
    const entity = await request()
    if (entity){
      this.setState({[entityKey]: entity})
    }else{
      this.setState({[entityNotFoundKey]: true})
    }
  }catch(error){
    this.setState({[entityLoadingErrorKey]: error})
    return error
  }finally{
    this.setState({ [entityLoadingKey]: undefined })
  }
}

function _addToCollection({
  collection,
  idProp = 'uid',
  memberToId = member => member[idProp],
  memberKey,
}){
  const newState = {}
  collection.forEach(member => {
    const appStateKey = memberKey(memberToId(member))
    newState[appStateKey] = member
  })
  this.setState(newState)
  return collection.map(memberToId)
}

export async function addToCollection({
  collection,
  entityKey,
  idProp = 'uid',
  memberToId = member => member[idProp],
  memberKey,
  replace = false,
}){
  let ids = _addToCollection.call(this, {
    collection,
    idProp,
    memberToId,
    memberKey,
  })

  if (!replace){
    const currentIds = (this.getState()[entityKey] || [])
    ids = Array.from(new Set([...currentIds, ...ids]))
  }
  this.setState({[entityKey]: ids})
}

export async function replaceCollection(options){
  return addToCollection.call(this, {
    ...options,
    replace: true,
  })
}

export async function loadCollection({
  request,
  idProp,
  memberToId,
  memberKey,
  ...options
}){
  return await loadEntity.call(this, {
    ...options,
    request: async () => {
      return _addToCollection.call(this, {
        collection: await request(),
        idProp,
        memberToId,
        memberKey,
      })
    },
  })
}

export async function updateEntity(options){
  const { request, entityKey } = options
  const { entityUpdatingKey, entityUpdatingErrorKey } = entityKeys(entityKey)
  return tx.call(this, entityUpdatingKey, entityUpdatingErrorKey, async () => {
    const entity = await request()
    this.setState({[entityKey]: entity})
  })
}

export function batchAction({ setKey, addToSet, handleBatch, batchDelayWindow = 1000 }){
  let batchTimeout = null

  return function(...args) {
    const member = addToSet.call(this, ...args)
    if (typeof member === 'undefined') return

    this.addToSet(setKey, [member])
    if (batchTimeout) return
    batchTimeout = setTimeout(
      () => {
        batchTimeout = null
        const batch = this.getState()[setKey]
        this.setState({ [setKey]: undefined })
        if(!batch || batch.size === 0) return
        handleBatch.call(this, batch)
      },
      batchDelayWindow
    )
  }
}
