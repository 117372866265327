import { h } from 'preact'

import PublicProfileMembershipsList from 'components/PublicProfileMembershipsList'

export default function UserProfileMembershipsPage({publicProfile}){
  return <div className="UserProfileMembershipsPage">
    <PublicProfileMembershipsList {...{ publicProfile }}/>
  </div>
}


UserProfileMembershipsPage.pageTitle = 'Memberships'
