import { h, Fragment } from 'preact'
import { useRef, useEffect, useCallback } from 'preact/hooks'

import classNames from 'lib/classNames'
import { useLocalStorage } from 'lib/storageHooks'
import useToggle from 'lib/useToggleHook'
import { useEditOrganization } from 'lib/organizationAdminHooks'
import { wysiwygContainsUploadingPlaceholder } from 'lib/WYSIWYG'

import ContentBox from 'components/ContentBox'
import Form from 'components/Form'
import FormBuilder from 'components/FormBuilder'
import Button from 'components/Button'
import EditButton from 'components/EditButton'
import WYSIWYGContent from 'components/WYSIWYGContent'
import WYSIWYGInput from 'components/WYSIWYGInput'
import './index.sass'

export default function OrganizationDescription({editable, ...props}){
  return h(editable ? Editable : Fixed, props)
}

function Content({ organization }){
  return <WYSIWYGContent source={organization.consumer_description} />
}

function Fixed({ className, organization, ...props }){
  return <ContentBox
    {...props}
    padded
    className={classNames('OrganizationDescription', { className })}
  >
    <Content {...{ organization }}/>
  </ContentBox>
}

function Editable({ className, organization }){
  const value = organization.consumer_description || undefined
  const [draft, setDraft] =
    useLocalStorage(`OrganizationDescription:${organization.apikey}:draft`)
  const [editing, startEditing, stopEditing] = useToggle(
    draft || isBlank(organization.consumer_description)
  )
  return <div
    className={classNames('OrganizationDescription', { className, editing })}
  >
    {editing
      ? <EditForm {...{
        organization,
        persistedValue: value,
        value: draft,
        onChange: setDraft,
        stopEditing,
      }}/>
      : <Fragment>
        <EditButton onClick={startEditing}/>
        <ContentBox padded>
          <Content {...{ organization }}/>
        </ContentBox>
      </Fragment>
    }
  </div>
}

function EditForm({
  organization,
  persistedValue,
  value,
  onChange,
  stopEditing,
}){
  const inputRef = useRef()
  const focusInput = useCallback(() => { tryToFocusInput(inputRef) }, [])
  useEffect(focusInput, [])
  const {
    updateOrganization,
    updatingOrganization,
    updatingOrganizationError,
    clearUpdatingOrganizationError,
  } = useEditOrganization(organization.apikey, 'OrganizationDescription')

  return <FormBuilder {...{
    persistedValues: { value: persistedValue },
    value: typeof value === 'string' && value !== persistedValue ? { value } : {},
    onChange: change => {
      onChange(change && change.value)
    },
    onSubmit: change => {
      updateOrganization({ consumer_description: change.value }).then(() => {
        onChange()
      })
    },
    submitting: updatingOrganization,
    error: updatingOrganizationError,
    onSuccess: stopEditing,
    clearError: clearUpdatingOrganizationError,
    render: form =>
      <Fragment>
        <ContentBox className="OrganizationDescription-input">
          {form.bindInput({
            ref: inputRef,
            valueProp: 'value',
            bindTo: 'onInput',
            unsaved: false,
            input: <WYSIWYGInput autoFocus />,
            required: organization.purpose === 'Organizational' || organization.purpose === 'editorial_board',
          })}
        </ContentBox>
        <Form.ButtonRow reverse className="OrganizationDescription-buttons">
          {form.submitButton({
            value: 'Update',
            submittingValue: 'Updating…',
            disabled: wysiwygContainsUploadingPlaceholder(value),
          })}
          {form.hasChanges
            ? form.resetButton({
              confirm: resetConfirmation,
              onClick: focusInput,
            })
            : persistedValue
              ? <Button type="normal" onClick={stopEditing}>cancel</Button>
              : null
          }
        </Form.ButtonRow>
      </Fragment>
  }}/>
}

const resetConfirmation = (
  `Are you sure you want to throw these changes away?`
)

function tryToFocusInput(inputRef){
  let start = Date.now()
  const tryToFocus = () => {
    const input = inputRef.current
    if (input && input.base){
      const focusable = input.base.querySelector('[contenteditable]')
      if (focusable) {
        focusable.focus()
        return
      }
    }
    const elapsed = Date.now() - start
    if (elapsed < 1000) setTimeout(tryToFocus, 50)
  }
  tryToFocus()
}

const isBlank = x => typeof x !== 'string' || !x.trim()
