import { Fragment, h } from 'preact'
import PropTypes from 'prop-types'
import StyleishModal from 'components/StyleishModal'
import classNames from 'lib/classNames'
import { useMyPublicProfile, usePublicProfiles } from 'lib/membershipAppStateHooks'
import { useCallback, useEffect, useRef, useState } from 'preact/hooks'
import EndUserAvatar from 'components/EndUserAvatar'
import Timestamp from 'components/Timestamp'
import Subtext from 'components/Subtext'
import PlainText from 'components/PlainText'
import WYSIWYGContent from 'components/WYSIWYGContent'
import { navigateToMessage } from 'components/NotificationsList'
import { useChatChannel } from 'lib/chatHooks'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import IconButton from 'components/IconButton'
import SearchInput from 'components/SearchInput'
import { useAppActions, useAppState } from 'lib/appState'
import Icon from 'components/Icon'
import FileAttachment from 'components/FileAttatchement'
import moment from 'moment'
import './index.sass'


export default function StarredMessagesModal({
  open,
  chatChannel: chatChannelUid,
  closeStarredMessagesModal,
  ...props
}){

  const {
    chatChannel,
    deleteChatMessage,
    unstarChatMessage,
  } = useChatChannel(chatChannelUid, 'StarredMessagesModal')

  const { starredChatMessages } = useAppState(
    {
      [`chat:channel:${chatChannelUid}:starredMessages`] : 'starredChatMessages'
    },
    'StarredMessagesModal'
  )

  const [starredMessagesIds, setStarredMessagesIds] = useState(new Set())

  useEffect(() => {
    setStarredMessagesIds(new Set(starredChatMessages?.map(({ uid }) => uid) || []))
  }, [starredChatMessages, starredMessagesIds])

  const { takeAction } = useAppActions('StarredMessagesModal')


  const handleUnStarChatMessage = useCallback((uid) => {
    const newIds = new Set([...starredMessagesIds])
    newIds.delete(uid)
    setStarredMessagesIds(newIds)
    unstarChatMessage(chatChannelUid, uid)
    takeAction('chat.loadStarredMessages', chatChannelUid)
    closeStarredMessagesModal()
  }, [starredMessagesIds, unstarChatMessage, chatChannelUid])

  const header = <Fragment>
    <div className={'StarredMessagesModal-header'}>
      <IconButton
        {...{
          type: 'return',
          onClick: closeStarredMessagesModal,
          className: 'StarredMessagesModal-header-returnButton',
        }}
      />
      <span className={'StarredMessagesModal-header-title'}>
        Starred Messages
      </span>
    </div>
  </Fragment>

  if (!chatChannel) {
    return <div className="StarredMessagesModal">
      {header}
      <div className="StarredMessagesList-noResults">
        <span className="StarredMessagesList-noResults-text">
          No starred messages
        </span>
      </div>
    </div>
  }

  let modal

  if (open) modal = <CSSTransition
    timeout={300}
    classNames="StarredMessagesModal-main"
    key={'StarredMessagesModal'}
  >
    <Fragment>
      {header}
      <div className={'StarredMessagesModal-container'}>
        <StarredMessagesList {...{
          chatChannel,
          starredMessages: starredChatMessages,
          starredMessagesIds,
          handleUnStarChatMessage,
          closeStarredMessagesModal,
          deleteChatMessage,
          ...props
        }}/>
      </div>
    </Fragment>
  </CSSTransition>

  return <TransitionGroup component={"div"} className={"StarredMessagesModal"}>{modal}</TransitionGroup>
}

StarredMessagesModal.propTypes = {
  className: PropTypes.string,
  open: StyleishModal.propTypes.open,
  onClose: StyleishModal.propTypes.onClose,
  chatChannel: PropTypes.string.isRequired,
  closeStarredMessagesModal: PropTypes.func.isRequired,
}
function StarredMessagesList({
  starredMessages,
  ...props
}){

  const publicProfileDids = starredMessages.map(starredMessage => starredMessage?.creatorUserDid)

  const { publicProfiles } = usePublicProfiles([...publicProfileDids], 'StarredMessagesModal')

  const { myPublicProfile } = useMyPublicProfile('StarredMessagesModal')

  const [searchTerm, setSearchTerm] = useState('')

  const [filteredStarredMessages, setFilteredStarredMessages] = useState(starredMessages)

  useEffect(() => {
    const filteredStarredMessages = starredMessages.filter(starredMessage => {
      return starredMessage?.message?.toLowerCase().includes(searchTerm.toLowerCase())
    })
    setFilteredStarredMessages(filteredStarredMessages)
  }
  , [searchTerm])


  return <div className="StarredMessagesList">
    <SearchInput {...{
      placeholder: 'Search…',
      className: 'StarredMessagesList-SearchBar',
      interfaceHelp: {
        position: 'tl',
        inside: true,
        title: 'Search',
        content: `Search all starred messages`,
      },
      value: searchTerm,
      onChange: setSearchTerm,
    }
    }
    />

    {filteredStarredMessages?.map(starredMessage => {
      const fromMe = starredMessage?.creatorUserDid === myPublicProfile?.did
      return <StarredMessage {...{
        uid: starredMessage?.uid,
        message: starredMessage?.message,
        finalMessage: starredMessage?.editedMessage,
        createdAt: starredMessage?.createdAt,
        publicProfile: publicProfiles.find(pp => pp.did === starredMessage?.creatorUserDid),
        fromMe,
        myPublicProfile,
        isStarredMessagesModalOpen: true,
        publicProfiles,
        repliedMessage: starredMessage?.repliedMessage,
        deletedAt: starredMessage?.deletedAt,
        readAt: !fromMe && starredMessage?.readAt,
        replyToMessageUid: starredMessage?.replyToMessageUid,
        ...props
      }}/>
    })}
    {filteredStarredMessages?.length === 0 && (
      <div className="StarredMessagesList-noResults">
        {searchTerm ? (
          <div className="StarredMessagesList-noResults-text">
            No results found for "{searchTerm}"
          </div>
        ) : (
          <div className="StarredMessagesList-noResults-text">
            No starred messages
          </div>
        )}
      </div>
    )}
  </div>
}

StarredMessagesList.propTypes = {
  chatChannel: PropTypes.string.isRequired,
  starredMessages: PropTypes.array.isRequired,
}


export function StarredMessage({
  uid,
  message,
  replyToMessageUid,
  createdAt,
  publicProfile,
  fromMe,
  handleUnStarChatMessage,
  deletedAt,
  chatChannel,
  setReplyingTo,
  setIsEditing,
  setEditedMessage,
  setEditMessageUid,
  closeStarredMessagesModal,
  deleteChatMessage,
  setIsMessageDeletedByAdmin,
  setIsEditingThread,
  finalMessage,
  openThreadRepliesModal,
  // readAt // TODO will be added when fix readAt
}) {

  const isReply = !!replyToMessageUid

  const isEdited = !!finalMessage

  const messageContent = finalMessage || message

  const chatChannelUid = chatChannel.uid

  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)
  const className = classNames(
    'StarredMessage',
    { 'from-me': fromMe, 'from-others': !fromMe },
  )

  const { takeAction } = useAppActions('StarredMessage')

  const FILE_ATTACHMENT_PREFIX = 'jlinc:dm:attachment:'

  const fileAttachment = (
    messageContent &&
    messageContent.startsWith(FILE_ATTACHMENT_PREFIX) &&
    JSON.parse(messageContent.split(FILE_ATTACHMENT_PREFIX)[1])
  )


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  const handleShowInChat = () => {
    navigateToMessage(uid)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const messageIsWYSIWYG = /^<(h\d|p|ul|figure)/.test(message)

  const handleReplyToMessage = useCallback(() => {
    takeAction('chat.setReplyingToMessage', chatChannelUid, uid)
    setReplyingTo({
      message: messageContent,
      uid: uid,
      username: fromMe ? 'You' : publicProfile.username,
    })
    closeStarredMessagesModal()
    openThreadRepliesModal()
    takeAction('chat.openThread', chatChannelUid, uid)
  }, [chatChannelUid, uid])

  const handleEditMessage = useCallback((uid, message, isDeletedByAdmin, editedMessage) => {
    setIsEditingThread(true)
    setIsEditing(false)
    setEditedMessage(editedMessage || message)
    setEditMessageUid(uid)
    setIsMessageDeletedByAdmin(isDeletedByAdmin) // Store this state
  }, [])


  const handleDeleteMessage = useCallback(async (messageUid, deletionReason = "",  AdminComment = "",) => {
    try {
      await deleteChatMessage(chatChannelUid, messageUid, deletionReason, AdminComment)
    } catch (error) {
      console.error('Error deleting chat message:', error)
    }
  }, [deleteChatMessage, chatChannelUid])


  const enableEdit = (message) => {
    const now = moment()
    const diffInMinutes = now.diff(message.createdAt, 'minutes')
    if (message.createdAt && diffInMinutes < 60) {
      return true
    }
    return false
  }

  const now = new Date()
  const readTimestamp = new Date(createdAt) // TODO: use readAt instead of createdAt
  const timeElapsed = now - readTimestamp
  const isDeleted = !!deletedAt


  const generateMenuItems = () => {
    const menuItems = [
      { label: 'Show in chat', onClick: handleShowInChat, condition: true, icon: 'showInChat'},
      {
        label: 'Remove star', onClick: () => {
          handleUnStarChatMessage(uid)
          setShowDropdown(false)
        }, condition: true, icon: 'starred'
      },
      { label: 'Reply message', onClick: handleReplyToMessage,
        condition: !isReply, icon: 'back'},
      { label: 'Edit message', onClick: handleEditMessage,
        condition: fromMe && enableEdit({ createdAt }), icon: 'edit'},
      { label: 'Delete message', onClick: () => {
        handleDeleteMessage(uid)
        closeStarredMessagesModal()
      }, condition: !isDeleted && fromMe && (
        // TODO will be added when fix readAt !readAt ||
        timeElapsed < 3600000), icon: 'delete'},
    ]

    return menuItems.filter(item => item.condition && item.label)
  }


  if(deletedAt){
    return <div className={`StarredMessage StarredMessage-${fromMe ? 'from-me' : 'from-others'} deleted`}>
      {!fromMe && (
        <EndUserAvatar
          {...{
            size: "md",
            publicProfile,
          }} />
      )}
      <div className="StarredMessage-Bubble">
        <div className="StarredMessage-Bubble-deleted">
          This message has been deleted
        </div>
        <Subtext className="StarredMessage-Bubble-time">
          {createdAt && (
            <Timestamp time={createdAt} format="date" />
          )} &nbsp;
          <Timestamp time={createdAt} format="time" />
        </Subtext>
      </div>
    </div>
  }

  return <div {...{ className }}>
    {!fromMe && (
      <EndUserAvatar
        {...{
          size: "md",
          publicProfile,
        }} />
    )}
    <div className={'StarredMessage-Bubble-dropdown'} onClick={toggleDropdown} ref={dropdownRef}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-menu-vertical-dots"
      >
        <circle cx="12" cy="12" r="1" />
        <circle cx="12" cy="5" r="1" />
        <circle cx="12" cy="19" r="1" />
      </svg>
      {showDropdown && (
        <ul className="dropdown-menu starred">
          {generateMenuItems().map((item, index) => item.condition && (
            <li key={index} onClick={item.onClick} className={"dropdown-option"}>
              <span>
                <Icon type={item.icon} size="lg" />&nbsp;
              </span>
              <span>
                {item.label}
              </span>
            </li>
          ))}
        </ul>
      )
      }
    </div>
    <div className={`StarredMessage-Bubble`}>
      {
        fileAttachment ? (
          <FileAttachment {...fileAttachment} />
        ) : messageIsWYSIWYG ? (
          <WYSIWYGContent source={messageContent} />
        ) : (
          <PlainText text={messageContent} />
        )}
      {isEdited && <span className="edited-label">edited</span>}
      <Subtext className="StarredMessage-Bubble-time">
        {createdAt && (
          <Timestamp time={createdAt} format="date" />
        )} &nbsp;
        <Timestamp time={createdAt} format="time" />
      </Subtext>
    </div>
  </div>

}
