import { useEffect } from 'preact/hooks'
import { createLogger } from 'lib/logger'
import { tx, createInMemoryStore } from 'lib/stores'

import aServer from './aServer'

const logger = createLogger('captcha', 'color: purple')

const state = createInMemoryStore({
  // 'captcha',
  // 'captchaLoading',
  // 'captchaLoadingError',
})

export function getCaptcha(){
  return state.get().captcha
}

export function clearCaptcha(){
  state.set({ captcha: undefined })
}

export function consumeCaptcha(){
  const captcha = getCaptcha()
  if (captcha.consumed)
    throw new Error(`captcha already consumed!`)
  captcha.consumed = true
  state.set({ captcha })
  return captcha
}

// ACTIONS

export function reloadCaptcha(){
  logger.info('reloadCaptcha')
  state.set({ captcha: undefined })
  return tx(state, 'loadingCaptcha', async () => {
    const { captcha } = await aServer.getJSON(`/captcha`)
    state.set({ captcha })
  })
}

DEBUG.reloadCaptcha = reloadCaptcha

export function loadCaptcha(){
  const { captcha, loadingCaptcha } = state.get()
  if (captcha || loadingCaptcha) return
  return reloadCaptcha()
}

// HOOKS

export function useCaptcha(){
  const {
    captcha,
    loadingCaptcha: loading,
    loadingCaptchaError: error,
  } = state.useStore([
    'captcha',
    'loadingCaptcha',
    'loadingCaptchaError',
  ])
  useEffect(
    () => { loadCaptcha() },
    [captcha]
  )
  return { loading, error, ...captcha }
}
