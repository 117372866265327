import PNFO from 'jlinc-shared/PNFO'
import { h, Fragment } from 'preact'

import Form from 'components/Form'
import CountryDropdownPicker from 'components/CountryDropdownPicker'
import PhoneAndCountryInput from 'components/PhoneAndCountryInput'
import OrganizationEditForm from 'components/OrganizationEditForm'

export default function ContactAndBasicInfoPage({
  organization, changes, setChanges
}){
  return <OrganizationEditForm {...{
    organization,
    className: 'OrganizationAdminPage-padded',
    value: changes,
    onChange: setChanges,
    render: ({
      form,
      legalEntity,
      isPersonalOrTopical,
      formControls,
    }) =>
      <Fragment>
        <p>
          This is the information we will use to contact that admin of this {PNFO.singular}
        </p>
        <Form.Row>
          {form.textItem({
            valueProp: 'dpo_email',
            label: 'EMAIL',
            name: 'email',
          })}
          {form.item({
            valueProp: 'contact_phone',
            label: 'CONTACT PHONE',
            input: <PhoneAndCountryInput />
          })}
        </Form.Row>
        <p>
          This is the basic info that will be displayed about your entity – to real human users who have
          created an account using a mobile phone. This info is NOT published on the open Internet.
        </p>
        <Form.Row>
          {form.textItem({
            valueProp: 'legal_name',
            label: (
              legalEntity
                ? 'LEGAL NAME OF ENTITY'
                : 'YOUR LEGAL NAME'
            ),
            name: 'legal-entit-name',
            required: !isPersonalOrTopical,
          })}
          {form.textItem({
            valueProp: 'domain',
            label: 'WEBSITE',
            type: 'text',
            autocomplete: 'website',
            placeholder: 'https://example.com',
          })}
        </Form.Row>
        <Form.Row>
          {form.textItem({
            valueProp: 'address',
            label: 'STREET',
            name: 'street-address',
            autocomplete: 'street-address',
          })}
          {form.textItem({
            valueProp: 'city',
            label: 'TOWN / CITY',
            name: 'city',
            autocomplete: 'address-level1 locality city',
          })}
        </Form.Row>
        <Form.Row>
          {form.textItem({
            valueProp: 'state',
            label: 'STATE / PROVINCE / COUNTY',
            name: 'mailingstate',
            autocomplete: 'administrative-area state province region',
            required: !isPersonalOrTopical,
          })}
          {form.textItem({
            valueProp: 'post_code',
            label: 'POSTAL CODE',
            name: 'postal-code',
            autocomplete: 'postal-code',
          })}
        </Form.Row>
        <Form.Row>
          {form.item({
            valueProp: 'country',
            label: 'COUNTRY',
            required: !isPersonalOrTopical,
            input: <CountryDropdownPicker />
          })}
          <Form.Item/>
        </Form.Row>
        {formControls()}
      </Fragment>
  }}/>
}
