import { h } from 'preact'

import { useOrganizationFeedPost } from 'lib/feedPostHooks'
import useScrollUpOnNaturalPageChange from 'lib/useScrollUpOnNaturalPageChange'
import { useOrganization } from 'lib/membershipAppStateHooks'

import Loading from 'components/Loading'
import SingleColumn from 'components/SingleColumn'
import PageNotFound from 'components/PageNotFound'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationFeedPost from 'components/OrganizationFeedPost'
import Navbar from 'components/Navbar'
import Header from 'components/Header'
import OrganizationMembershipButton from 'components/OrganizationMembershipButton'
import LinkToOrganization from 'components/LinkToOrganization'
import OrganizationIcon from 'components/OrganizationIcon'

import './index.sass'

export default function OrganizationFeedPostPage(props){
  const { params: { organizationApikey, feedPostUid } } = props

  useScrollUpOnNaturalPageChange([])

  const {
    organization,
    organizationLoading,
    organizationLoadingError,
    organizationNotFound,
  } =
    useOrganization(organizationApikey, 'OrganizationFeedPostPage')

  const {
    feedPost,
    feedPostLoading,
    feedPostLoadingError,
    feedPostNotFound,
  } = useOrganizationFeedPost(
    feedPostUid,
    'OrganizationFeedPostPage',
  )

  if (organizationNotFound || feedPostNotFound) return <PageNotFound {...props}/>
  if (organizationLoading || feedPostLoading) return <Loading />

  return <SingleColumn key={feedPostUid} className="OrganizationFeedPostPage">
    <ErrorMessage error={organizationLoadingError || feedPostLoadingError}/>
    {organization && <OrganizationBranding {...{ organization }}/>}
    {feedPost && <OrganizationFeedPost {...{ post: feedPost, withinFeed: false }}/>}
  </SingleColumn>
}

function OrganizationBranding({ organization }){
  return <Navbar className="OrganizationFeedPostPage-OrganizationBranding">
    <LinkToOrganization {...{organization}}>
      <OrganizationIcon size="md" bordered {...{organization}}/>
    </LinkToOrganization>
    <Navbar.Space/>
    <LinkToOrganization {...{organization}}>
      <Header size="xl">{organization.name}</Header>
    </LinkToOrganization>
    <Navbar.Grow/>
    <OrganizationMembershipButton {...{
      organization,
      includeOrgName: true,
      hideIfMember: false,
    }}/>
  </Navbar>
}
