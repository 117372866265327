import { h, Fragment } from 'preact'

import Link from 'components/Link'

const JLINC_TERMS = isDataYogi
  ? <Fragment>
    <p>
      {APP_NAME} provides a personal data management service where you can build up your own data assets for your own
      purposes. You can then share them with organisations should you wish but retain control of that data sharing
      relationship.
    </p>
    <p>
      {APP_NAME} is an accredited MyData Operator, which is a new category of service provider emerging as the
      Internet matures. MyData Operators sign up to an accreditation process that renews and extends annually.
      This process dictates that a MyData Operators acts on your behalf around your data, and is at worst a
      neutral facilitator of data exchange.
    </p>
    <p>
      {APP_NAME} is structurally on your side, acting as your agent; the service will do nothing with your
      data without you taking a proactive step to make something happen.
    </p>
    <p>
      I authorise {APP_NAME} to operate a data service for me, as my agent, where I control my own data,
      relationships and intent
    </p>
  </Fragment>
  : <Fragment>
    <p>
      {LEGAL_APP_NAME} provides web software under the Terms below.
    </p>
    <p>
      Upon signing up to create an account at {location.host}, {LEGAL_APP_NAME} shall serve as a personal
      "Data Custodian" acting on your behalf.
    </p>
    <p>
      {APP_NAME} provides you, the account holder, with an automated interface to control and manage digitally
      signed data sharing agreements with Data Custodians representing businesses and other entities using
      the <Link href="https://www.jlinc.com/" type="link">JLINC protocol</Link>.
    </p>
    <p>
      {APP_NAME} shall take all reasonable precautions to keep your data secure and to only transfer your data,
      permissions, consents and preferences, to other parties as directly instructed by you.
    </p>
    <p>
      Signing up on {APP_NAME} represents your affirmative agreement to these Terms. The exact language of these Terms
      may be modified. However, the substance of the intent shall not be changed without obtaining affirmative
      permission from you in a clear, simple and understandable manner.
    </p>
    <p>
      {LEGAL_APP_NAME} is a California benefit corporation with both data integrity and environmental protection
      written into its founding articles.
    </p>
  </Fragment>

export default function JlincTerms() { return JLINC_TERMS }
