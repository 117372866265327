import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Button from 'components/Button'
import './index.sass'

export default function NavDots({ className, dots, ...props }){
  return <div
    {...props}
    className={classNames('NavDots', { className })}
  >
    {dots.map(({ selected, ...props }) =>
      <Button
        type="none"
        {...props}
        data-selected={selected}
      ><div/></Button>
    )}
  </div>
}

NavDots.propTypes = {
  className: PropTypes.string,
  dots: PropTypes.arrayOf({
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    href: PropTypes.string,
  }),
}


