export default function(){
  const { defaultAccountDataStagedChanges } = this.getState()
  if (!defaultAccountDataStagedChanges) return

  this.setState({
    committingDefaultAccountDataStagedChangesToAllOrgs: true,
  })

  return this.takeAction('updateAllOrganizationAccountData', { changes: defaultAccountDataStagedChanges })
    .catch(error => {
      this.setState({
        errorCommittingDefaultAccountDataStagedChangesToAllOrgs: error,
      })
    })
    .then(() => {
      this.setState({
        committingDefaultAccountDataStagedChangesToAllOrgs: undefined,
      })
    })
}
