import PNFO from 'jlinc-shared/PNFO'
import { h, Fragment } from 'preact'
import { useCallback } from 'preact/hooks'

import useToggle from 'lib/useToggleHook'
import { useLocationQueryState } from 'resources/location'
import { useLocalStorage } from 'lib/storageHooks'
import { useOrganizationFeedSubscriptions } from 'lib/feedSubscriptionHooks'
import { useOrganizations } from 'lib/membershipAppStateHooks'

import { useCreatePageErrorIf } from 'resources/pageAlerts'

import Loading from 'components/Loading'
import Link from 'components/Link'
import Button from 'components/Button'
import Header from 'components/Header'
import PageTabs from 'components/PageTabs'
import ErrorMessage from 'components/ErrorMessage'
import Form from 'components/Form'
import { hrefToOrganization } from 'components/LinkToOrganization'
import OrganizationEditForm from 'components/OrganizationEditForm'
import OrganizationSubscribeToFeedButton from 'components/OrganizationSubscribeToFeedButton'
import OrganizationSearch from 'components/OrganizationSearch'
import SortableOrganizationList from 'components/SortableOrganizationList'
import MyOrganizationsList from 'components/MyOrganizationsList'
import { sortOrganizationsBySubscribedToOrder } from 'components/SortableOrganizationList'

export default function Subscriptions(props){
  const { organization } = props

  const {
    organizationFeedSubscriptions,
    errorLoadingOrganizationFeedSubscriptions,
    loadingOrganizationFeedSubscriptions,
    errorSubscribingToOrganizationFeed: errorSubscribing,
    errorUnsubscribingFromOrganizationFeed: errorUnsubscribing,
  } = useOrganizationFeedSubscriptions(
    organization.apikey,
    'OrganizationAdminPage/SubscriptionsPage'
  )

  useCreatePageErrorIf(
    errorSubscribing,
    `Failed to subscribe. Please try again later.`,
  )
  useCreatePageErrorIf(
    errorUnsubscribing,
    `Failed to unsubscribe. Please try again later.`,
  )

  const subscriptions = organizationFeedSubscriptions
    .filter(s => s.subscriberOrganizationApikey === organization.apikey)

  return <div className="OrganizationAdminPage-Subscriptions">
    <ErrorMessage error={errorLoadingOrganizationFeedSubscriptions}/>
    {loadingOrganizationFeedSubscriptions
      ? <Loading type="block" />
      : <PageContent {...{organization, subscriptions}}/>
    }
  </div>
}

function PageContent({ organization, subscriptions }){
  const subsByApikey = {}
  subscriptions.forEach(s => {
    subsByApikey[s.organizationApikey] = s
  })

  const listPagePathname = `${hrefToOrganization(organization.apikey)}/admin/subscriptions`
  const subscriberOrganizationApikey = organization.apikey
  const props = {
    organization,
    subscriptions,
    subsByApikey,
    listPagePathname,
    findPagePathname: `${listPagePathname}/find`,
    organizationListProps: {
      animateChanges: false,
      showMembershipPills: false,
      map(otherOrganization){
        return {
          ...otherOrganization,
          buttons: [
            <OrganizationSubscribeToFeedButton {...{
              organizationApikey: otherOrganization.apikey,
              subscriberOrganizationApikey,
            }}/>
          ],
        }
      },
    },
  }

  return <PageTabs {...{
    size: 'lg',
    padded: true,
    tabs: [
      {
        title: 'Subscribed to',
        href: props.listPagePathname,
        render: () => <SubscribedTo {...props}/>,
      },
      {
        title: `Find ${PNFO.plural}`,
        href: props.findPagePathname,
        render: () => <FindMore {...props}/>,
      },
    ],
  }}/>
}

function SubscribedTo({ organization, subsByApikey, findPagePathname, organizationListProps }){
  const forumLink = text => <Link type="text" href={`/${organization.apikey}/forum`}>{text}</Link>

  return <Fragment>
    <div className="OrganizationAdminPage-padded">
      <Header size="lg">{PNFO.plural} that feed {organization.name}'s Forum.</Header>
      <p>
        Feed posts from these {PNFO.plural} will be automatically ingested
        into {forumLink('the forum')}
      </p>
    </div>
    <OrganizationsSubscribedToList {...{
      organization, subsByApikey, ...organizationListProps
    }}/>
    <Header size="lg" centered>
      <Button type="primary" href={findPagePathname}>
        Feed {organization.name} with more {PNFO.plural}!
      </Button>
    </Header>
  </Fragment>
}

function FindMore({ organization, organizationListProps }){
  const [query, setQuery] = useLocationQueryState(
    {query: 'q', defaultValue: '', replace: true},
    'OrganizationAdminPage/SubscriptionsPage'
  )
  const searching = !!(query && query.trim())
  const filter = useCallback(o => o.apikey !== organization.apikey, [organization.apikey])
  return <Fragment>
    <div className="OrganizationAdminPage-padded">
      <Header subtle size="sm">
        Search for {PNFO.plural} to feed {organization.name}'s Forum
      </Header>
    </div>
    <OrganizationSearch {...{
      ...organizationListProps,
      query,
      onChange: setQuery,
      placeholder: 'Search…',
      autoFocus: true,
      filter,
    }}/>
    {searching || <MyOrganizationsList {...{filter, ...organizationListProps}} />}
  </Fragment>
}

function OrganizationsSubscribedToList({
  organization, subsByApikey, map
}){
  const {
    organizations,
    organizationsLoading,
    organizationsLoadingError
  } = useOrganizations(
    Object.keys(subsByApikey),
    'OrganizationAdminPage/SubscriptionsPage'
  )
  const sortable = organizations && organizations.length > 1
  const [changes, setChanges]
    = useLocalStorage(`organization:${organization.apikey}:draft_orgs_subscribed_to_order`)
  const hasChanges = !!changes
  const [sorting, startSorting, stopSorting] = useToggle(hasChanges)
  const sortOrder = changes || organization.orgs_subscribed_to_order || []

  return <OrganizationEditForm {...{
    error: organizationsLoadingError,
    disabled: !!organizationsLoading,
    organization,
    value: {
      orgs_subscribed_to_order: changes
    },
    onChange: c => {
      setChanges(c && c.orgs_subscribed_to_order)
    },
    onSuccess: stopSorting,
    render: ({ form }) =>
      <Fragment>
        {form.bindInput({
          valueProp: 'orgs_subscribed_to_order',
          input: <SortableOrganizationList {...{
            organizationsLoading,
            organizations: sortOrganizationsBySubscribedToOrder(sortOrder, organizations.map(map)),
            editing: sortable && sorting,
            onEmpty: <NoSubscriptions/>,
          }}/>
        })}
        {
          sorting ? <Form.ButtonRow className="OrganizationAdminPage-padded">
            {form.resetButton({ onClick: stopSorting })}
            {form.submitButton({
              value: 'Save',
              submittingValue: 'Saving…',
            })}
          </Form.ButtonRow> :
          sortable ? <Form.ButtonRow className="OrganizationAdminPage-padded">
            <Button {...{
              type: 'link',
              value: 'edit sort order',
              onClick: startSorting,
            }}/>
          </Form.ButtonRow> :
          null
        }
      </Fragment>
  }}/>
}


const NoSubscriptions = () =>
  <span>Use the search field below to subscribe to other {PNFO.plural}</span>
