import api from '../api'

export function load(organizationApikeys) {
  const notLoadedOrganizations = new Set()

  const appState = this.getState()
  Array.from(organizationApikeys).forEach(organizationApikey => {
    const { [`organization:${organizationApikey}:loaded`]: loaded } = appState
    if (!loaded) notLoadedOrganizations.add(organizationApikey)
  })

  if (notLoadedOrganizations.size > 0) this.takeAction('organizations.reload', [...notLoadedOrganizations])
}

export async function reload(organizationApikeys) {
  if (!Array.isArray(organizationApikeys)) throw new Error('organizationApikeys is required')
  if (organizationApikeys.length === 0) return

  const loadingErrorKey = `organizations:loading:error`
  const appState = this.getState()
  const organizationsNotLoading = organizationApikeys.filter(organizationApikey => {
    return !appState[`organization:${organizationApikey}:loading`]
  })

  if (organizationsNotLoading.length === 0) return

  setLoadingStateForOrganizations.call(this, organizationsNotLoading, true)
  this.setState({ [loadingErrorKey]: undefined })

  try {
    const { organizations, memberships, publicProfiles } = await api.getOrganizations({ organizationApikeys })
    const newState = {}

    organizationApikeys.forEach(organizationApikey => {
      const organization = organizations
        .find(o =>
          o.apikey.toLowerCase() ===
          organizationApikey.toLowerCase()
        )
      if (
        organization &&
        organization.apikey !== organizationApikey
      ){
        newState[
          `organization:${organizationApikey}:canonical`
        ] = organization.apikey
        organizationApikey = organization.apikey
      }
      newState[
        `organization:${organizationApikey}` +
        (organization ? ':loaded' : ':notFound')
      ] = true
    })
    this.setState(newState)
    this.takeAction('publicProfiles.addPublicProfilesToAppState', publicProfiles)
    this.takeAction('organization.addOrganizationsToAppState', organizations)
    this.takeAction('organizationMemberships.addMembershipsToAppState', memberships)
  } catch(error) {
    this.setState({ [loadingErrorKey]: error })
  } finally {
    setLoadingStateForOrganizations.call(this, organizationsNotLoading, undefined)
  }
}

function setLoadingStateForOrganizations(organizationApikeys, loadingState) {
  const newState = {}
  organizationApikeys.forEach(organizationApikey => {
    newState[`organization:${organizationApikey}:loading`] = loadingState
  })
  this.setState(newState)
}
