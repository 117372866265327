import { h, Fragment } from 'preact'
import { useMemo } from 'preact/hooks'
import { forwardRef } from 'preact/compat'

import classNames from 'lib/classNames'
import { createClassComponent } from 'lib/preactHelpers'
import { isInvalidPassword } from 'lib/validators'

import OrganizationIcon from 'components/OrganizationIcon'
import LinkToOrganization from 'components/LinkToOrganization'
import TruT from 'components/TruT'
import Form from 'components/Form'
import TextInput from 'components/TextInput'
import Checkbox from 'components/Checkbox'
import './index.sass'

const APortalAuthForm = createClassComponent('APortalAuthForm')
export default APortalAuthForm

APortalAuthForm.Logo = ({ organization }) =>
  <div className="APortalAuthForm-Logo">
    {organization
      ? <LinkToOrganization {...{organization}}>
        <OrganizationIcon size="lg" {...{organization}}/>
      </LinkToOrganization>
      : <TruT />
    }
  </div>
APortalAuthForm.Intro = createClassComponent('APortalAuthForm-Intro')
APortalAuthForm.Footer = createClassComponent('APortalAuthForm-Footer')
APortalAuthForm.LoginInputRow = forwardRef((props, ref) =>
  <Form.Row>
    <Form.Item>
      <Form.Label>EMAIL OR MOBILE</Form.Label>
      <TextInput {...{
        ...props,
        ref,
        name: 'login',
        autocomplete: 'username webauthn',
        required: true,
        lpignore: false,
      }}/>
    </Form.Item>
  </Form.Row>
)

APortalAuthForm.Passwords = Passwords
function Passwords({
  disabled,
  password,
  setPassword,
  passwordConfirmation,
  setPasswordConfirmation,
  showPassword,
  setShowPassword,
}){
  const passwordValidationError = useMemo(
    () => password
      ? isInvalidPassword(password)
      : null,
    [password]
  )

  const passwordsMatch = useMemo(
    () =>
      password && passwordConfirmation
        ? password === passwordConfirmation
        : null
    ,
    [password, passwordConfirmation]
  )

  const error = (
    passwordValidationError ||
    (passwordsMatch === false && 'passwords dont match')
  )
  return <Fragment>
    <Form.Row>
      <Form.Item>
        <small className="APortalAuthForm-passwordError">{error}</small>
        <Form.Label>NEW PASSWORD</Form.Label>
        <TextInput {...{
          className: classNames(
            'APortalAuthForm-password',
            { invalid: passwordValidationError, valid: passwordValidationError === false },
          ),
          value: password,
          onInput: setPassword,
          type: showPassword ? 'text' : 'password',
          name: 'password',
          autocomplete: 'new-password',
          required: true,
          label: false,
          placeholder: 'Password',
          lpignore: false,
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Form.Item>
        <TextInput {...{
          className: classNames(
            'APortalAuthForm-passwordConfirmation',
            { invalid: passwordsMatch === false, valid: passwordsMatch === true },
          ),
          value: passwordConfirmation,
          onInput: setPasswordConfirmation,
          type: showPassword ? 'text' : 'password',
          name: 'passwordConfirmation',
          autocomplete: 'new-password',
          required: true,
          label: false,
          placeholder: 'Password Confirmation',
          lpignore: false,
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Form.Item>
        <Checkbox {...{
          disabled,
          checked: showPassword,
          onChange: setShowPassword,
          label: 'Show password',
        }}/>
      </Form.Item>
    </Form.Row>
  </Fragment>
}
