import { h } from 'preact'

import classNames from 'lib/classNames'

import PageError from 'components/PageError'
import JlinxLooking from 'components/Jlinx/looking.svg'

export default function PageNotFound({ className, ...props }){
  return <PageError {...{
    header: 'Page Not Found',
    img: JlinxLooking,
    subtext: (
      `Whooops! I've looked everywhere, ` +
      `but unfortunately I can't find that page`
    ),
    ...props,
    className: classNames('PageNotFound', { className }),
  }}/>
}
