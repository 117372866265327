import { h, Fragment } from 'preact'

import { useConfirmationBefore } from 'lib/confirmationHooks'
import {
  useOrganizationNetworkMembershipsWithOrganizations,
  useOrganizationNetworkMembership,
} from 'lib/organizationNetworkMembershipHooks'

import Button from 'components/Button'
import Header from 'components/Header'
import PageTabs from 'components/PageTabs'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationList from 'components/OrganizationList'

export default function OtherNetworksPage(props){
  const { organization } = props
  const {
    organizationNetworkMemberships,
    organizationNetworkMembershipsLoadingError,
  } = useOrganizationNetworkMembershipsWithOrganizations(
    organization.apikey, 'OrganizationAdminPage-OtherNetworksPage'
  )

  if (organizationNetworkMembershipsLoadingError)
    return <ErrorMessage error={organizationNetworkMembershipsLoadingError} />

  props.organizations = organizationNetworkMemberships
    .filter(m => m.organization && m.memberOrganizationApikey === organization.apikey)
    .map(membership => ({
      ...membership.organization,
      membership,
    }))

  const pathPrefix = `/${organization.apikey}/admin/networks`
  return <PageTabs {...{
    className: 'OrganizationAdminPage-OtherNetworksPage',
    size: 'lg',
    padded: true,
    tabs: [
      {
        title: 'Networks',
        href: pathPrefix,
        render: () => <Networks {...props}/>
      },
      {
        title: 'Invited',
        href: pathPrefix + '/invites',
        render: () => <Invites {...props}/>
      },
    ],
  }}/>
}

function Invites({ organization, organizations }){
  return <HeaderedOrganizationList {...{
    header: `${organization.name} is invited to join these Networks`,
    onEmpty: `${organization.name} is not invited to any Networks.`,
    organizations: organizations
      .filter(o => !o.membership.accepted)
      .map(organization => ({
        ...organization,
        href: `/${organization.apikey}/network`,
        buttons: <InviteControls {...{organization}}/>,
      })),
  }}/>
}

function Networks({ organization, organizations }){
  const organizationApikey = organization.apikey
  return <HeaderedOrganizationList {...{
    header: `${organization.name} is in these Networks`,
    onEmpty: `${organization.name} is not in any Networks.`,
    organizations: organizations
      .filter(o => o.membership.accepted)
      .map(organization => ({
        ...organization,
        href: `/${organization.apikey}/network/${organizationApikey}`,
        buttons: <NetworkControls {...{organization}}/>,
      })),
  }}/>
}

const HeaderedOrganizationList = ({ header, organizations, onEmpty }) =>
  <Fragment>
    <Header size="lg">{header}</Header>
    <OrganizationList {...{
      organizations,
      filter: null,
      map: null,
      onEmpty: <Header size="md" centered italic>{onEmpty}</Header>,
    }}/>
  </Fragment>

function InviteControls({organization}){
  const { membership } = organization
  const {
    acceptOrganizationNetworkMembership: accept,
    removeOrganizationNetworkMembership: decline,
    updatingOrganizationNetworkMembership: updating,
  } = useOrganizationNetworkMembership(
    membership.organizationApikey,
    membership.memberOrganizationApikey,
    'OrganizationAdminPage-OtherNetworksPage',
  )
  const disabled = !!updating
  return <Fragment>
    <Button {...{disabled, type: 'normal', onClick: decline}}>decline</Button>
    <Button {...{disabled, type: 'primary', onClick: accept}}>accept</Button>
  </Fragment>
}

function NetworkControls({organization}){
  const { membership } = organization
  const {
    removeOrganizationNetworkMembership: remove,
    updatingOrganizationNetworkMembership: removing,
  } = useOrganizationNetworkMembership(
    membership.organizationApikey,
    membership.memberOrganizationApikey,
    'OrganizationAdminPage-OtherNetworksPage',
  )
  const confirmRemove = useConfirmationBefore(remove,
    `Are you sure you want to unlist "` +
    (membership.memberOrganization && membership.memberOrganization.name) +
    `" from "` + organization.name + `"?`
  )
  return <Button {...{
    type: 'normal',
    onClick: removing ? null : confirmRemove,
    disabled: !!removing,
  }}>unlist</Button>
}
