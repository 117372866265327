import { useEffect } from 'preact/hooks'
import { createInMemoryStore } from 'lib/stores'

const state = createInMemoryStore()

const DEFAULT_TIMEOUT = 5000

let idSequence = 1

export function createPageAlert({
  type,
  name,
  message,
  error,
  id, // optional
  timeout = DEFAULT_TIMEOUT,
}){
  const key = idSequence++
  if (error) {
    console.error(error)
    message = toString(error)
    type = 'error'
  }
  const pageAlert = { key, id, type, name, message, timeout }
  pageAlert.dismiss = () => {
    clearTimeout(pageAlert._timeoutId)
    state.set({ [key]: undefined })
  }
  if (timeout) pageAlert._timeoutId = setTimeout(pageAlert.dismiss, timeout)
  if (id) clearPageError(id)
  state.set({ [key]: pageAlert })
}

for (const type of ['success', 'info', 'warning', 'error'])
  createPageAlert[type] = (message, opts) =>
    createPageAlert({ ...opts, type, message })

export function createPageError(...error){
  const message = error.map(toString).join(' ')
  return createPageAlert({type: 'error', message})
}

export function setPageError(id, error){
  if (error) createPageAlert({id, name: id, error })
  else clearPageError(id)
}

export function dismissPageAlert(pageAlert){
  pageAlert.dismiss()
}

export function usePageAlerts(){
  const pageAlerts = state.useStore()
  return Object.values(pageAlerts)
}

export function clearPageError(id){
  Object.values(state.get()).forEach(pageAlert => {
    if (pageAlert && pageAlert.id === id) pageAlert.dismiss()
  })
}


const toString = e => `${e instanceof Error ? e.message : e}`


// HOOKS

export function useCreatePageAlertIf(condition, alert){
  useEffect(
    () => { if (condition) createPageAlert(alert) },
    [condition],
  )
}

export function useCreatePageErrorIf(error, message){
  useCreatePageAlertIf(!!error, {type: 'error', message})
}


// DEBUG

Object.assign(DEBUG, {
  createPageAlert,
  setPageError,
  clearPageError,
})
