import api from '../api'
import { isDID } from 'lib/dids'

export function addPublicProfilesToAppState(publicProfiles){
  const newState = {}
  publicProfiles.forEach(publicProfile => {
    if (!publicProfile.did){
      console.trace('publicProfile has no did', publicProfile)
      return
    }
    newState[`publicProfile:${publicProfile.did}`] = {...publicProfile,
      displayName: publicProfile.displayName || publicProfile.username}
    if (publicProfile.username){
      newState[`@${publicProfile.username}`] = publicProfile.did
    }
  })
  this.setState(newState)
}

export function update(changes) {
  if (this.getState().updatingMyPublicProfile) return Promise.resolve()

  this.setState({
    updatingMyPublicProfile: true,
    errorUpdatingMyPublicProfile: undefined
  })

  api.updatePublicProfile({ publicProfile: changes }).then(
    ({publicProfile}) => {
      this.takeAction('publicProfiles.addPublicProfilesToAppState', [publicProfile])
      this.setState({updatingMyPublicProfile: undefined})
    },
    errorUpdatingMyPublicProfile => {
      this.setState({
        updatingMyPublicProfile: undefined,
        errorUpdatingMyPublicProfile,
      })
    }
  )
}

export function clearError() {
  this.setState({
    errorUpdatingMyPublicProfile: undefined
  })
}

export async function get({dids = [], usernames = []}){
  const appState = this.getState()
  const loadingPublicProfiles = appState.loadingPublicProfiles || new Set()
  const invalidDids = dids.filter(did => !isDID(did))
  if (invalidDids.length > 0)
    console.trace('invalid DIDs given to publicProfiles.get', { dids })
  dids = dids.filter(did =>
    !invalidDids.includes(did) &&
    !loadingPublicProfiles.has(did) &&
    !(`publicProfile:${did}` in appState)
  )
  usernames = usernames.filter(username =>
    !loadingPublicProfiles.has(username) &&
    !(`@${username}` in appState)
  )
  if (dids.length === 0 && usernames.length === 0) return
  this.addToSet('loadingPublicProfiles', dids)
  this.addToSet('loadingPublicProfiles', usernames)
  try{
    const { publicProfiles } = await api.getPublicProfiles({dids, usernames})
    this.takeAction('publicProfiles.addPublicProfilesToAppState', publicProfiles)
    addNotFoundPublicProfilesToAppState.call(this, {publicProfiles, dids, usernames})
  }catch(error){
    console.warn('ERROR loading public profiles:', {dids, usernames})
    console.error(error)
  }finally{
    this.removeFromSet('loadingPublicProfiles', dids)
    this.removeFromSet('loadingPublicProfiles', usernames)
  }
}


function addNotFoundPublicProfilesToAppState({publicProfiles, dids, usernames}){
  const newState = {}
  dids.forEach(did => {
    if (publicProfiles.some(publicProfile => publicProfile.did === did)) return
    newState[`publicProfile:${did}`] = null
  })
  usernames.forEach(username => {
    if (publicProfiles.some(publicProfile => publicProfile.username === username)) return
    newState[`@${username}`] = null
  })
  this.setState(newState)
}
