import stripNonRequestedAccountData from 'jlinc-shared/stripNonRequestedAccountData'
import diffAccountData from 'jlinc-shared/diffAccountData'
import mergeAccountData from 'jlinc-shared/mergeAccountData'
import logger from 'lib/logger'
import getRequestedDataForOrganization from 'jlinc-shared/getRequestedDataForOrganization'

export default function(organizationApikey, changes) {
  const organizationKey                         = `organization:${organizationApikey}`
  const organizationAccountDataKey              = `organizationAccountData:${organizationApikey}`
  const organizationAccountDataStagedChangesKey = `organizationAccountDataStagedChanges:${organizationApikey}`
  const updatingErrorKey                        = `updatingOrganizationAccountDataError:${organizationApikey}`

  const {
    [organizationKey]: organization,
    [organizationAccountDataKey]: organizationAccountData,
    [organizationAccountDataStagedChangesKey]: organizationAccountDataStagedChanges,
  } = this.getState()

  let newOrganizationAccountDataStagedChanges = mergeAccountData(organizationAccountDataStagedChanges, changes)
  newOrganizationAccountDataStagedChanges = diffAccountData(
    organizationAccountData,
    mergeAccountData(organizationAccountData, newOrganizationAccountDataStagedChanges),
  )

  if (newOrganizationAccountDataStagedChanges){
    const requestedData = getRequestedDataForOrganization(organization)
    newOrganizationAccountDataStagedChanges = stripNonRequestedAccountData(
      newOrganizationAccountDataStagedChanges,
      requestedData,
    )
  }

  logger.debug({ newOrganizationAccountDataStagedChanges })

  this.setState({
    [organizationAccountDataStagedChangesKey]: newOrganizationAccountDataStagedChanges,
    [`importDefaultAccountDataError:${organizationApikey}`]: undefined,
    [updatingErrorKey]: undefined,
  })

}
