import { h, cloneElement } from 'preact'
import { useRef, useCallback, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { childrenOrFragmentToChildArray } from 'lib/preactHelpers'
import useOnScroll from 'lib/useOnScrollHook'

import Header from 'components/Header'
import './index.sass'

export default function IconRowAccordion({
  className,
  children,
  expandedIndex,
  onChange,
  onEmpty,
  ...props
}){
  children = childrenOrFragmentToChildArray(children)
  props.className = classNames('IconRowAccordion', { className })
  if (onEmpty && children.length === 0)
    return <Header {...props} size="sm" centered italic>{onEmpty}</Header>
  return <div {...props}>
    {children.map((child, index) =>
      <Child {...{
        key: index,
        index,
        onChange,
        iconRow: child.props.children[0],
        content: child.props.children[1],
        expanded: index === expandedIndex,
      }}/>
    )}
  </div>
}

IconRowAccordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  expandedIndex: PropTypes.number.inRequired,
  onChange: PropTypes.func.inRequired,
  onEmpty: PropTypes.node,
}

function Child({ index, onChange, iconRow, content, expanded }){
  const contentRef = useRef()
  const setHeight = useCallback(() => {
    if (!contentRef.current) return
    const style = global.getComputedStyle(contentRef.current.firstElementChild)
    const height = Math.ceil(parseFloat(style.height, 10))
    contentRef.current.style.setProperty('--height', `${height}px`)
  }, [])
  useOnScroll(contentRef, setHeight)
  useEffect(setHeight)

  if (onChange) iconRow = cloneElement(iconRow, {
    onClick: () => {
      onChange(expanded ? undefined : index)
    },
  })

  return <div data-expanded={expanded}>
    {iconRow}
    <div
      ref={contentRef}
      style={{height: expanded ? 'var(--height)' : 0}}
    >{content}</div>
  </div>
}
