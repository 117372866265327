import { h } from "preact"
import { useState, useEffect, useMemo } from "preact/hooks"
import Button from "components/Button"
import ButtonRow from "components/ButtonRow"
import PeopleList from "components/PeopleList"
import { usePublicProfiles } from "lib/membershipAppStateHooks"
import { useAddMembersToChatChannel, useChatSynopsis } from "lib/chatHooks"
import { useMyPublicProfileDid } from "resources/auth"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import IconButton from "components/IconButton"
import SearchInput from "components/SearchInput"
import PropTypes from "prop-types"
import "./index.sass"

export default function AddChatMembersModal({ open, onClose, chatChannelUid }) {
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedMembers, setSelectedMembers] = useState(new Set())
  const addMembersToChatChannel = useAddMembersToChatChannel(
    "AddChatMembersModal"
  )
  const myPublicProfileDid = useMyPublicProfileDid()
  const [suggestedMembersDids, setSuggestedMembersDids] = useState([])

  const { chatSynopsis: synopsis } = useChatSynopsis("AddChatMembersModal")

  const matchedChannelSynopsis = useMemo(
    () => synopsis.find((channel) => channel.uid === chatChannelUid),
    [synopsis, chatChannelUid]
  )

  useEffect(() => {
    if (
      !matchedChannelSynopsis ||
      !matchedChannelSynopsis.memberUserDids ||
      matchedChannelSynopsis.memberUserDids.length === 0
    ) {
      return
    }

    const currentChatMemberDidsSet = new Set(
      matchedChannelSynopsis.memberUserDids
    )

    const suggestedMemberDids = synopsis
      .flatMap((chat) => chat.memberUserDids || [])
      .filter(
        (did) =>
          !currentChatMemberDidsSet.has(did) && did !== myPublicProfileDid
      )

    const uniqueSuggestedMemberDids = [...new Set(suggestedMemberDids)]

    setSuggestedMembersDids(uniqueSuggestedMemberDids)
  }, [synopsis, myPublicProfileDid, matchedChannelSynopsis])

  const { publicProfiles } = usePublicProfiles(
    [...suggestedMembersDids],
    "AddChatMembersModal"
  )

  const toggleMemberSelection = (memberUserDid) => {
    setSelectedMembers((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(memberUserDid)) {
        newSet.delete(memberUserDid)
      } else {
        newSet.add(memberUserDid)
      }
      return newSet
    })
  }

  const handleAddMembers = async () => {
    const memberUserDids = Array.from(selectedMembers)
    try {
      await addMembersToChatChannel(chatChannelUid, memberUserDids)
      setSelectedMembers(new Set())
      onClose()
    } catch (error) {
      console.error("Failed to add members:", error)
    }
  }

  const filteredProfiles = useMemo(
    () =>
      publicProfiles.filter((profile) =>
        profile.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [publicProfiles, searchTerm]
  )

  const people = filteredProfiles.map((publicProfile) => {
    const selected = selectedMembers.has(publicProfile.did)
    const onClick = () => toggleMemberSelection(publicProfile.did)

    return {
      ...publicProfile,
      selected: selected,
      buttons: (
        <IconButton
          type={selected ? "selected" : "create"}
          onClick={onClick}
        />
      ),
    }
  })

  return (
    <TransitionGroup component={null}>
      {open && (
        <CSSTransition
          timeout={300}
          classNames="modal-transition"
          unmountOnExit
        >
          <div className="AddChatMembersModal">
            <div className="AddChatMembersModal-header">
              <IconButton
                type="return"
                onClick={onClose}
                className="AddChatMembersModal-header-returnButton"
              />
              <h2>Add Members</h2>
            </div>

            <SearchInput
              {...{
                placeholder: "Search…",
                className: "AddChatMembersModal-SearchBar",
                interfaceHelp: {
                  position: "tl",
                  inside: true,
                  title: "Search",
                  content: `Search Members`,
                },
                value: searchTerm,
                onChange: setSearchTerm,
              }}
            />
            <span style={{paddingLeft: "10px"}}>Suggestions</span>
            <div className="AddChatMembersModal-content">
              <PeopleList people={people} style={{paddingRight: "10px"}}/>
            </div>

            <div className="AddChatMembersModal-actions">
              <ButtonRow>
                <Button type="normal" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={handleAddMembers}
                  disabled={selectedMembers.size === 0}
                >
                  Add
                </Button>
              </ButtonRow>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

AddChatMembersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  chatChannelUid: PropTypes.string.isRequired,
}
