import { h } from 'preact'

import classNames from 'lib/classNames'

import EntityInfo, { Detail, Address } from 'components/EntityInfo'

export default function PublicProfileInfo({
  className,
  publicProfile,
  extraDetails,
  ...props
}){

  return <EntityInfo
    {...props}
    className={classNames('PublicProfileInfo', { className })}
  >
    {extraDetails && extraDetails.map(([label, value]) =>
      <Detail {...{label, value}}/>
    )}
    <Detail label="BIO" value={publicProfile.bio}/>
    {hasOnlyCountry(publicProfile)
      ? <Detail label="COUNTRY" value={publicProfile.country}/>
      : <Address {...publicProfile} />
    }
  </EntityInfo>
}

const hasOnlyCountry = ({address, city, province, state, postalCode, country}) =>
  country && !address && !city && !province && !state && !postalCode

