import { h } from 'preact'
import PropTypes from 'prop-types'
import classNames from 'lib/classNames'

import Button from 'components/Button'
import './index.sass'

export default function HugeChoiceInput({
  disabled,
  unsaved,
  value,
  defaultValue,
  onChange,
  options,
  fat,
  ...props
}){
  if (value === undefined) value = defaultValue
  const noDescriptions = options.every(option => !option.description)
  const className = classNames(
    'HugeChoiceInput',
    { unsaved, className: props.className, noDescriptions }
  )
  return <div {...{...props, className}}>
    {options.map(option => {
      let className = 'HugeChoiceInput-option'
      if (disabled || option.disabled) className += ' HugeChoiceInput-option-disabled'
      return <div {...{className}}>
        <div className="HugeChoiceInput-option-button">
          <Button block {...{
            disabled: disabled || option.disabled,
            fat,
            type: option.value === value
              ? unsaved ? 'success' : 'primary'
              : 'normal',
            onClick: () => { onChange(option.value) }
          }}>{option.buttonValue}</Button>
        </div>
        <div className="HugeChoiceInput-option-description">
          {option.description}
        </div>
      </div>
    })}
  </div>
}

HugeChoiceInput.propTypes = {
  className: PropTypes.string,
  disabled: Button.propTypes.disabled,
  unsaved: PropTypes.bool,
  fat: PropTypes.bool,
  value: PropTypes.node,
  defaultValue: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      buttonValue: PropTypes.node,
      description: PropTypes.node,
      disabled: PropTypes.bool,
    }),
  ),
  onChange: PropTypes.func,
}
