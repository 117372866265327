import { h } from 'preact'
import { useRef, useEffect, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import useToggle from 'lib/useToggleHook'
import classNames from 'lib/classNames'
import { usePortal } from 'lib/portal'
import {
  getDocumentHeight,
  preventInteractionsOutsideOf,
  isTargetFixed,
} from 'lib/DOMHelpers'
import usePrevious from 'lib/usePreviousHook'

import Header from 'components/Header'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import Spotlight from 'components/Spotlight'
import StepDots from 'components/StepDots'
import './index.sass'

const TRANSITION_DURATION = 200

export default function InterfaceWalkthrough({className, stepDots, step}){
  const [justHitBack, setJustHitBack, unsetJustHitBack] = useToggle(false)
  useEffect(
    () => {
      const focus = () => { focusNextOrBackButton(justHitBack) }
      focus()
      unsetJustHitBack(false)
      const timeout = setTimeout(focus, TRANSITION_DURATION)
      return () => { clearTimeout(timeout) }
    },
    [step]
  )
  return usePortal()(
    <TransitionGroup className={classNames('InterfaceWalkthrough', { className })}>
      {step &&
        <CSSTransition classNames="InterfaceWalkthrough-openTransition" timeout={TRANSITION_DURATION}>
          <Content {...{stepDots, step, setJustHitBack}}/>
        </CSSTransition>
      }
    </TransitionGroup>
  )
}

InterfaceWalkthrough.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

function focusNextOrBackButton(justHitBack){
  const button = document.body.querySelector(
    `.InterfaceWalkthrough-Step-${justHitBack ? 'prev' : 'next'}Button`
  )
  if (button && document.activeElement !== button) button.focus()
}

function Content({stepDots, step, setJustHitBack}){
  const previousStep = usePrevious(step)
  const rootRef = useRef()
  const spotlightRef = useRef()
  const stepRef = useRef()
  const firstFocusRef = useRef()

  useEffect(
    () => preventInteractionsOutsideOf(
      rootRef.current,
      {
        onFocus: () => {
          firstFocusRef.current.focus()
        }
      },
    ),
    [],
  )

  useEffect(
    () => {
      const root = rootRef.current
      function changeSteps(event, forward){
        const selector = `.InterfaceWalkthrough-Step-${forward ? 'next' : 'prev'}Button`
        root.querySelector(selector).click()
        event.preventDefault()
      }
      function onKeyDown(event){
        if (event.key === 'ArrowLeft') changeSteps(event, false)
        if (event.key === 'ArrowRight') changeSteps(event, true)
      }
      root.addEventListener('keydown', onKeyDown, {passive:false})
      return () => {
        root.addEventListener('keydown', onKeyDown)
      }
    },
    [],
  )

  useEffect(
    () => {
      let timeout
      const _reposition = (_, animate = false) => {
        clearTimeout(timeout)
        const target = getStepTarget(step.selector)
        if (target) {
          reposition({
            root: rootRef.current,
            spotlight: spotlightRef.current.base,
            step: stepRef.current.base,
            target,
            animate,
            spotlightOptions: step.spotlightOptions,
          })
        }else{
          timeout = setTimeout(
            () => { _reposition(undefined, animate) },
            10
          )
        }
      }
      _reposition(undefined, !!previousStep)
      window.addEventListener('resize', _reposition)
      const interval = setInterval(_reposition, 1000)
      return () => {
        clearInterval(interval)
        clearTimeout(timeout)
        window.removeEventListener('resize', _reposition)
      }
    },
    [step]
  )

  const focus = useCallback(
    (event) => {
      if (
        rootRef.current &&
        (
          rootRef.current === event.target ||
          !rootRef.current.contains(event.target)
        )
      ){
        firstFocusRef.current.focus()
        event.preventDefault()
        return false
      }
    },
    []
  )

  return <div {...{
    ref: rootRef,
    className: 'InterfaceWalkthrough-Content',
    onClick: focus,
  }}>
    <input type="text" ref={firstFocusRef} />
    <Spotlight ref={spotlightRef}/>
    <Step {...{ref: stepRef, stepDots, step, setJustHitBack}}/>
  </div>
}

function getStepTarget(selector){
  return Array.from(document.querySelectorAll(selector)).find(node => {
    const rect = node.getBoundingClientRect()
    return rect.height > 0 && rect.width > 0
  })
}

function Step({stepDots, step, setJustHitBack}){
  const {
    position = 'bl',
    title,
    content,
    closeButton,
    prevButton,
    nextButton,
  } = step

  return <div {...{
    className: `InterfaceWalkthrough-Step`,
    'data-position': position,
  }}>
    <div>
      <div className="InterfaceWalkthrough-Step-content">
        <Header size="xl">{title}</Header>
        {content}
      </div>
      <div className="InterfaceWalkthrough-Step-buttons">
        <Button {...{
          ...(nextButton ? nextButton : closeButton),
          key: 'nextButton',
          className: 'InterfaceWalkthrough-Step-nextButton',
        }}>
          <span>{nextButton ? 'next' : 'done'}</span>
        </Button>
        <Button {...{
          ...(prevButton ? prevButton : {disabled: true}),
          key: 'prevButton',
          className: 'InterfaceWalkthrough-Step-prevButton',
          onClick: setJustHitBack,
        }}>
          <span>back</span>
        </Button>
        <div className="InterfaceWalkthrough-Step-middle">
          <StepDots dots={stepDots} />
        </div>
      </div>
      <IconButton {...{
        ...closeButton,
        key: 'closeButton',
        className: 'InterfaceWalkthrough-Step-closeButton',
        type: 'cancel-circled',
      }}/>
    </div>
  </div>
}

function reposition({
  root, spotlight, step, target, animate, spotlightOptions = {}
}){
  const document = root.ownerDocument
  const scroller = document.documentElement
  const { scrollTop, clientHeight } = scroller
  const targetIsFixed = isTargetFixed(target)
  const targetRect = target.getBoundingClientRect()

  const viewportHeight = clientHeight
  const targetTop = targetRect.top + scrollTop

  let newScrollTop = targetIsFixed
    ? scrollTop > 0 ? scrollTop - 1 : 0
    : calcNewScrollTop({
      scrollTop, targetTop, viewportHeight, document
    })

  const offsetY = scrollTop - newScrollTop

  Spotlight.pointAt({
    minHeight: 120,
    minWidth: 120,
    ...spotlightOptions,
    spotlight,
    targetRect,
    offsetY,
    animate,
  })

  positionStep({
    targetRect,
    step,
    scroller,
    offsetY,
  })
  step.style.opacity = 1

  if (newScrollTop !== scrollTop)
    scroller.scrollTo({top: newScrollTop, behavior: 'smooth'})
}

function calcNewScrollTop({
  targetTop, viewportHeight, document
}){
  // find the centerpoint and then adjust for edges
  let newScrollTop = targetTop - (viewportHeight / 2)
  // underscroll
  if (newScrollTop < 0) {
    newScrollTop = 0
  // overscroll
  }else{
    const maxScrollTop = getDocumentHeight(document) - viewportHeight
    if (newScrollTop > maxScrollTop) newScrollTop = maxScrollTop
  }
  return Math.round(newScrollTop)
}
const zeroIfLess = n => n < 0 ? 0 : n
function positionStep({
  targetRect,
  step,
  scroller,
  offsetY = 0,
  offsetX = 0,
  minHeight = 100,
  minWidth = 300,
}){
  const position = step.dataset.position || 'bl'
  const viewportHeight = scroller.clientHeight
  const viewportWidth = scroller.clientWidth

  const positionInside = position[2] === 'i'

  let maxHeight, maxWidth, top, left, bottom, right
  if (position[0] === 't'){
    maxHeight = targetRect.top
    bottom = zeroIfLess(viewportHeight - targetRect.top - offsetY)
  }else if (position[0] === 'b'){
    maxHeight = viewportHeight - targetRect.bottom
    top = zeroIfLess(targetRect.bottom + offsetY)
  }

  if (position[1] === 'l'){
    if (positionInside){
      maxWidth = targetRect.width
      left = zeroIfLess(targetRect.left + offsetX)
    }else{
      maxWidth = targetRect.left
      right = zeroIfLess(viewportWidth - targetRect.left - offsetX)
    }
  }else if (position[1] === 'r'){
    maxWidth = viewportWidth - targetRect.right
    left = zeroIfLess(targetRect.right + offsetX)
  }

  if (maxHeight < minHeight) maxHeight = minHeight
  if (maxWidth < minWidth) maxWidth = minWidth
  step.style.top = pxOrAuto(top)
  step.style.left = pxOrAuto(left)
  step.style.bottom = pxOrAuto(bottom)
  step.style.right = pxOrAuto(right)
  step.style.maxHeight = `${maxHeight}px`
  step.style.maxWidth = `${maxWidth}px`
}

const pxOrAuto = px => typeof px === 'number' ? `${px}px` : 'auto'

