import { h } from 'preact'
import PropTypes from 'prop-types'

import { useFeed } from 'lib/feedPostHooks'

import InfiniteScrollDown from 'components/InfiniteScrollDown'
import OrganizationFeedPost from 'components/OrganizationFeedPost'
import LoggedOutFeedPostsMessage from 'components/LoggedOutFeedPostsMessage'
import './index.sass'

export default function OrganizationFeedPosts({
  className = '',
  feed,
  emptyMessage,
  fullyLoadedMessage,
  hideConsumedPosts,
  loggedOut,
  organizationApikey,
  dedupe = false,
}){
  let {
    loading,
    loadingError,
    posts,
    fullyLoaded,
    loadMore,
  } = useFeed({feed, pageSize: 100}, 'OrganizationFeedPosts')

  const seenPosts = new Set()
  posts = posts.filter(post => {
    if (dedupe) {
      if (seenPosts.has(post.feedPostContentUid)) return false
      seenPosts.add(post.feedPostContentUid)
    }
    return !hideConsumedPosts || !post.parentUid
  })

  if (loggedOut && fullyLoaded && posts.length === 0)
    return <LoggedOutFeedPostsMessage destinationOrganizationApikey={organizationApikey} />

  return <InfiniteScrollDown {...{
    className: `OrganizationFeedPosts ${className}`,
    name: 'posts',
    loading,
    loadingError,
    fullyLoaded,
    loadMore,
    emptyMessage,
    fullyLoadedMessage,
  }}>
    {posts.map(post =>
      <OrganizationFeedPost
        key={post.uid}
        sortBy={post.lastPublishedAt || post.createdAt}
        {...{
          post,
        }}
        withinFeed
        feed={feed}
      />
    )
    }
  </InfiniteScrollDown>
}

OrganizationFeedPosts.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string,
  feed: PropTypes.string.isRequired,
  emptyMessage: InfiniteScrollDown.propTypes.emptyMessage,
  fullyLoadedMessage: InfiniteScrollDown.propTypes.fullyLoadedMessage,
  usersFeed: PropTypes.bool,
  hideConsumedPosts: PropTypes.bool,
  loggedOut: PropTypes.bool,
  dedupe: PropTypes.bool,
}
