
export function normalizeVimeoUrl(url){
  const VIMEO_REGEX = /^https?:\/\/(?:player\.)?vimeo.com\/(?:video\/)?(?:album\/[^\/]+\/video\/)?(?:channels\/[^\/]+\/)?(?:groups\/[^\/]+\/videos\/)?(?:ondemand\/[^\/]+\/)?([^\/]+)/ // eslint-disable-line
  if (typeof url === 'string' && url.match(VIMEO_REGEX))
    return `https://player.vimeo.com/video/${RegExp.$1}`
}

export function isVimeoUrl(url){
  return !!normalizeVimeoUrl(url)
}

export function removeAutoplay(url){
  url = new URL(`${url}`)
  for (const [key] of url.searchParams.entries())
    if (key.match(/autoplay/i))
      url.searchParams.delete(key)
  return url.toString()
}
