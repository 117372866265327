import { h, Fragment } from 'preact'

import useToggle from 'lib/useToggleHook'
import {
  ALICE_SISA_NOTICE,
  ALICE_SISA_SUMMARY,
  FULL_SISA_URL,
  DATAYOGI_ALICE_SISA_NOTICE,
} from 'lib/accountDataSpec'

import { useSISA, useSignSISA, useSISAEvents } from 'resources/SISAs'

import SingleColumn from 'components/SingleColumn'
import ContentBox from 'components/ContentBox'
import Link from 'components/Link'
import Loading from 'components/Loading'
import Header from 'components/Header'
import Navbar from 'components/Navbar'
import SisaText from 'components/SisaText'
import HelpButton from 'components/HelpButton'
import Timestamp from 'components/Timestamp'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationPageSubnav from 'components/OrganizationPageSubnav'
import LinkToOrganization from 'components/LinkToOrganization'
import WhatIsASISAModal from 'components/WhatIsASISAModal'
import { EventDetails } from 'components/SisaEventDescription'
import './index.sass'

export default function OrganizationSisaPage(props){
  const { organization, isMember } = props
  const sisa = useSISA(organization.apikey, { reload: true })
  return <SingleColumn className="OrganizationSisaPage">
    <div>
      <OrganizationPageSubnav {...{
        isMember,
        icon: 'data-tr',
        name: 'SISA',
      }}>
        <Button
          type="normal"
          value="All SISAs"
          href="/my-data/sisas"
        />
      </OrganizationPageSubnav>
      {
        sisa.sisaId ? <SISA {...{organization, sisa}}/> :
        sisa._loadingError ? <ErrorMessage {...{error: sisa._loadingError}}/> :
        sisa._notFoundAt ? <NoSISA {...{organization}}/> :
        <Loading type="block"/>
      }
    </div>
  </SingleColumn>
}
OrganizationSisaPage.pageTitle = 'SISA'


function SISA({ organization, sisa }){
  const [SISAModalOpen, openSISAModal, closeSISAModal] = useToggle()
  const signedByRightsHolderDate = sisa.acceptedSisa.createdAt

  const SISAText = <Link {...{
    type: 'link',
    onClick: openSISAModal,
  }}>Standard Information Sharing Agreement</Link>
  return <Fragment>
    <WhatIsASISAModal {...{open: SISAModalOpen, onClose: closeSISAModal}}/>
    <ContentBox padded>
      <p>
        <span>You signed a </span>
        {SISAText}
        <span> with </span>
        <LinkToOrganization {...{type: 'link', organization}}/>
        <span> on </span>
        <Timestamp time={signedByRightsHolderDate} format="date" />
      </p>
      <ShowMore {...{organization, sisa}} />
    </ContentBox>
    <SISAEvents {...{ organization, sisa }}/>
  </Fragment>
}

function ShowMore({ organization, sisa }){
  const [showingMore, , , toggle] = useToggle()
  const link = <p className="flex-horizontal-center">
    <Link type="link" onClick={toggle}>
      <i>show {showingMore ? 'less' : 'more'}</i>
    </Link>
  </p>
  return <Fragment>
    {showingMore && <MoreSisaDescription {...{organization, sisa}}/>}
    {link}
  </Fragment>
}

function SISAEvents({ organization }){
  return <Fragment>
    <ContentBox>
      <Navbar thin>
        <Header size="lg">SISA Events</Header>
        <Navbar.Grow/>
        <Button
          type="normal"
          value="Edit Shared Data"
          href={`/${organization.apikey}/my-data`}
        />
      </Navbar>
    </ContentBox>
    <SisaEvents {...{ organization, page: 0 }}/>
  </Fragment>
}

function SisaEvents({ organization }){
  const sisaEvents = useSISAEvents(organization.apikey, { reload: true })
  return (
    sisaEvents._loadingError ?
      <ErrorMessage error={sisaEvents._loadingError}/> :
      sisaEvents.events
        ? sisaEvents.events.length === 0
          ? <p className="flex-horizontal-center">
            <Header size="sm">no events yet</Header>
          </p>
          : sisaEvents.events.map(sisaEvent =>
            <SisaEvent {...{organization, sisaEvent}} />
          )
        : <Loading type="block"/>
  )
}

const EVENT_TYPE_DESCRIPTIONS = {
  dataEvent: `account data`,
  permissionEvent: `data permissions`,
}
function SisaEvent({ organization, sisaEvent }){
  return <ContentBox>
    <Navbar thin>
      <Header size="lg">
        <span>You changed your </span>
        <span>{EVENT_TYPE_DESCRIPTIONS[sisaEvent.eventType]}</span>
        <span> on </span>
        <Timestamp time={sisaEvent.created} date="date" />
      </Header>
      <Navbar.Grow/>
      <Button
        type="normal"
        value="view event"
        href={`/${organization.apikey}/sisa-events/${sisaEvent.id}`}
      />
    </Navbar>
    <div className="OrganizationSisaPage-details">
      <EventDetails event={sisaEvent.event} />
    </div>
  </ContentBox>
}


function MoreSisaDescription({ sisa }){
  return <Fragment>
    <p>
      <strong>Rights Holder (you) DID:</strong>
      <span> {sisa.acceptedSisa.rightsHolderDid} </span>
      <DIDHelpIcon/>
    </p>
    <p>
      <Link type="link" newWindow href={FULL_SISA_URL}>
        Full Standard Information Sharing Agreement text
      </Link>
    </p>
  </Fragment>
}

const DIDHelpIcon = () =>
  <HelpButton>
    <Header size="lg">What is a DID?</Header>
    <p>
      A DID is a <Link type="link" href="https://w3c-ccg.github.io/did-spec/" newWindow>Decentralized Identifier</Link> that
      JLINC uses to sign SISAS on your behalf.
    </p>
    <p>
      Decentralized Identifiers (DIDs) are a new type of identifier for verifiable, "self-sovereign" digital identity.
      DIDs are fully under the control of the DID subject, independent from any centralized registry, identity provider,
      or certificate authority. DIDs are URLs that relate a DID subject to means for trustable interactions with that
      subject. DIDs resolve to DID Documents — simple documents that describe how to use that specific DID. Each DID
      Document may contain at least three things: proof purposes, verification methods, and service endpoints. Proof
      purposes are combined with verification methods to provide mechanisms for proving things. For example, a DID
      Document can specify that a particular verification method, such as a cryptographic public key or pseudonymous
      biometric protocol, can be used to verify a proof that was created for the purpose of authentication. Service
      endpoints enable trusted interactions with the DID controller.
    </p>
  </HelpButton>

function NoSISA({ organization }){
  const organizationApikey = organization.apikey
  const [showingSisaSummary, showSisaSummary, hideSisaSummary] = useToggle(false)
  const signSISAAction = useSignSISA({ organizationApikey })

  return <ContentBox padded>
    <Header size="lg" underlined>
      Sign a Standard Information Sharing Agreement (SISA) with {organization.name}
    </Header>
    <ErrorMessage error={signSISAAction.error} />
    { showingSisaSummary
      ? <SISASummary {...{ organization, hideSisaSummary }}/>
      : <SisaText
        text={isDataYogi ? DATAYOGI_ALICE_SISA_NOTICE : ALICE_SISA_NOTICE}
        organization={organization}
        onSummaryClick={showSisaSummary}
      />
    }
    <ButtonRow>
      <Button {...{
        disabled: !!signSISAAction.acting,
        type: 'primary',
        value: signSISAAction.acting ? 'Signing SISA…' : 'Sign SISA',
        onClick: signSISAAction.act,
      }}/>
    </ButtonRow>
  </ContentBox>
}

function SISASummary({ organization, hideSisaSummary }){
  return <div>
    <SisaText text={ALICE_SISA_SUMMARY} organization={organization} />
    <br />
    <br />
    <Link type="link" onClick={hideSisaSummary}>
      Close SISA Summary
    </Link>
    <span> | </span>
    <Link type="link" href={FULL_SISA_URL} newWindow>
      View Full SISA
    </Link>
  </div>
}
