import { h } from 'preact'
import StyleishModal from 'components/StyleishModal'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import './index.sass'

const ChatMessageDeletionModal = ({
  isAdmin,
  isCurator,
  adminComment,
  setAdminComment,
  handleConfirmDelete,
  showDeletionModal,
  selectedReason,
  setSelectedReason,
  customReason,
  setCustomReason,
  setShowDeletionModal,
}) => {
  const deletionReasons = [
    "Spam or advertising",
    "Offensive content",
    "Harassment or bullying",
    "Off-topic",
    "Other (please specify)",
  ]

  if (!showDeletionModal) return null

  const handleSelectChange = (e) => {
    setSelectedReason(e.target.value)
    if (e.target.value !== "Other (please specify)") {
      setCustomReason("")
    }
  }

  return (
    <StyleishModal scrollInternally {...{
      className: 'ChatMessageDeletionModal',
      title: 'Delete Message?',
      open: showDeletionModal,
      onClose: () => setShowDeletionModal(false),
      padded: false,
      scrollInternally: true,
    }}>
      <div className="ChatMessageDeletionModal-form">
        <div className="modal-body">
          <select
            className="select-dropdown"
            value={selectedReason}
            onChange={handleSelectChange}
          >
            <option value="">Select a Reason</option>
            {deletionReasons.map(reason => (
              <option key={reason} value={reason}>{reason}</option>
            ))}
          </select>

          {selectedReason === "Other (please specify)" && (
            <textarea
              className="textarea"
              placeholder="Specify reason"
              value={customReason}
              onChange={(e) => setCustomReason(e.target.value)}
            />
          )}

          {(isAdmin || isCurator) && (
            <div className="admin-comment">
              <label htmlFor="adminComment">Admin Comment (Optional):</label>
              <textarea
                className="textarea"
                id="adminComment"
                placeholder="Add any additional comments"
                value={adminComment}
                onChange={(e) => setAdminComment(e.target.value)}
              />
            </div>
          )}
        </div>
        <ButtonRow>
          <Button
            type="normal"
            value="Cancel"
            onClick={() => setShowDeletionModal(false)}
          />
          <Button
            type="primary"
            value="Delete"
            onClick={handleConfirmDelete}
          />
        </ButtonRow>
      </div>
    </StyleishModal>
  )
}

export default ChatMessageDeletionModal
