import { h } from 'preact'

import ContentBox from 'components/ContentBox'
import './index.sass'

export default function OrganizationCalendarPage(){
  const url = 'https://calendar.google.com/calendar/embed?src=au66q7mh70uvucpm25ms08k7us%40group.calendar.google.com&ctz=America%2FLos_Angeles'
  return <div className="OrganizationCalendarPage">
    <ContentBox>
      <iframe {...{
        src: url,
        frameborder: "0",
        scrolling: "no"
      }}/>
    </ContentBox>
  </div>
}
