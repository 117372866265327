import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Header from 'components/Header'
import './index.sass'

export default function TabsHeaders({
  className,
  disabled,
  tabs,
  onTabClick,
  currentTabIndex,
  size = 'md',
  padded = false,
  fullWidth = false,
  ...props
}) {
  const headers = tabs.map((tab, index) => {
    if (typeof tab === 'string') tab = { title: tab }
    let headerClass = 'TabsHeaders-Header'
    if (index === currentTabIndex) headerClass += ' TabsHeaders-Header-Selected'
    return <Header
      key={index}
      disabled={disabled}
      href={tab.href}
      onClick={onTabClick && (() => { onTabClick(index) })}
      size={size}
      className={headerClass}
      centered
    >
      {tab.title}
    </Header>
  })

  props.className = classNames('TabsHeaders', { fullWidth, padded, className })
  return <div {...props}>{headers}</div>
}

TabsHeaders.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ).isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  onTabClick: PropTypes.func,
  size: PropTypes.oneOf(['md', 'sm']),
  padded: PropTypes.bool,
  fullWidth: PropTypes.bool,
}
