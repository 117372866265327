import { h } from 'preact'
import PropTypes from 'prop-types'

import Link from 'components/Link'

export default function LinkToOrganization({
  apikey,
  organizationApikey,
  organization,
  children,
  ...props
}){
  apikey =
    organizationApikey ||
    apikey ||
    (organization && organization.apikey)
  return <Link
    type="subtle"
    {...props}
    href={hrefToOrganization(apikey)}
  >
    {
      children ||
      (organization && organization.name) ||
      organizationApikey
    }
  </Link>
}

LinkToOrganization.propTypes = {
  children: PropTypes.node,
  organizationApikey: PropTypes.string,
  apikey: PropTypes.string,
  organization: PropTypes.shape({
    apikey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

export function hrefToOrganization(apikey){
  return `/${apikey}`
}
