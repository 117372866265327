// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Loading/knot.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner{display:inline-block;height:10px;width:10px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-size:cover;-webkit-animation:Spinner-spin 2s infinite linear;animation:Spinner-spin 2s infinite linear}@-webkit-keyframes Spinner-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./components/Spinner/index.sass"],"names":[],"mappings":"AAAA,SACE,oBAAA,CACA,WAAA,CACA,UAAA,CACA,wDAAA,CACA,0BAAA,CACA,qBAAA,CACA,iDAAA,CACA,yCAAA,CAEA,gCACE,GACE,8BAAA,CACA,sBAAA,CACF,KACE,gCAAA,CACA,wBAAA,CAAA","sourcesContent":[".Spinner\n  display: inline-block\n  height: 10px\n  width: 10px\n  background-image: url(../Loading/knot.svg)\n  background-position: center\n  background-size: cover\n  -webkit-animation: Spinner-spin 2s infinite linear\n  animation: Spinner-spin 2s infinite linear\n\n  @-webkit-keyframes Spinner-spin\n    0%\n      -webkit-transform: rotate(0deg)\n      transform: rotate(0deg)\n    100%\n      -webkit-transform: rotate(360deg)\n      transform: rotate(360deg)\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
