import { h } from "preact"
import "./index.sass"
import EndUserAvatar from "components/EndUserAvatar"
import LinkToPublicProfile from "components/LinkToPublicProfile"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { useEffect, useState } from "preact/hooks"

export default function ReactionsDetails({
  messageUid,
  reactionsDetail,
  handleClose,
  displayNameMap,
  publicProfiles,
  open,
  handleRemoveReaction,
}) {
  const [selectedEmoji, setSelectedEmoji] = useState(null)
  const EMOJI_THRESHOLD = 4
  const totalReactions = reactionsDetail.reduce(
    (total, reaction) => total + reaction.count,
    0
  )

  const onEmojiClick = (reaction) => () => {
    handleRemoveReaction(messageUid, reaction)
  }

  const filteredReactions = reactionsDetail.filter(
    (reaction) => !selectedEmoji || reaction.reaction === selectedEmoji
  )

  useEffect(() => {
    const closeOnOutsideClick = (event) => {
      if (open && !event.target.closest(".ReactionsModal")) {
        handleClose()
      }
    }

    document.addEventListener("click", closeOnOutsideClick)
    return () => {
      document.removeEventListener("click", closeOnOutsideClick)
    }
  }, [open, handleClose])

  const clearFilter = () => setSelectedEmoji(null)

  const [visibleReactions, setVisibleReactions] = useState([])
  console.log(visibleReactions)

  useEffect(() => {
    setVisibleReactions(reactionsDetail.slice(0, EMOJI_THRESHOLD))
  }, [reactionsDetail])

  return (
    <TransitionGroup component={null}>
      {open && (
        <CSSTransition
          in={open}
          timeout={300}
          classNames="ReactionsModal-animation"
          unmountOnExit
        >
          <div className="ReactionsModal">
            <div className="ReactionsModal-header">
              <div className="emoji-container">
                <span
                  className={`emoji ${!selectedEmoji ? "selected" : ""}`}
                  onClick={clearFilter}
                >
                  All
                  <span style={{
                    fontSize: "18px"
                  }}> {totalReactions}
                  </span>
                </span>
                {reactionsDetail.map((reaction, index) => (
                  <span
                    key={index}
                    className={`emoji ${
                      selectedEmoji === reaction.reaction ? "selected" : ""
                    }`}
                    onClick={() => setSelectedEmoji(reaction.reaction)}
                  >
                    {reaction.reaction}
                    <span style={{
                      fontSize: "18px"
                    }}> {reaction.count}</span>
                  </span>
                ))}
              </div>

            </div>

            <div className="ReactionsModal-content">

              {filteredReactions.map((reaction) => (
                <div
                  key={reaction.reaction}
                  className="ReactionsModal-reaction"
                >
                  <div className="ReactionsModal-users">
                    {reaction.users.map((userDid) => {
                      const userProfile = publicProfiles?.find(
                        (profile) => profile.did === userDid
                      )
                      if (!userProfile) return null

                      return (
                        <div key={userDid} className="ReactionsModal-user">
                          <LinkToPublicProfile
                            publicProfile={userProfile}
                            className="user-avatar"
                          >
                            <EndUserAvatar
                              publicProfile={userProfile}
                              size="sm"
                            />
                          </LinkToPublicProfile>
                          <span className="user-name">
                            {displayNameMap[userDid]}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                  <span
                    className="emoji"
                    onClick={onEmojiClick(reaction.reaction)}
                  >
                    {reaction.reaction}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
