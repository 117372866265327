import defer from 'lib/defer'
import { useEffect } from 'preact/hooks'
import { refToDOMNode } from 'lib/preactHelpers'
import { focusWithin } from 'lib/DOMHelpers'

export default function useAutoFocus(ref, args = []){
  useEffect(
    () => {
      if (!ref) return
      const focus = () => { useAutoFocus.focusRef(ref) }
      focus()
      defer(focus)
    },
    [ref, ...args]
  )
}
useAutoFocus.focusRef = ref => {
  const node = refToDOMNode(ref)
  if (node) focusWithin(node)
}
