import defer from 'lib/defer'
import { createLogger } from 'lib/logger'
import { createLocalStorageStore } from 'lib/stores'
import { onReturnToStaleApp } from 'lib/onReturnToStaleApp'

import { getLocation, setQuery } from './location'
import aServer from './aServer'
import oldAppState from './oldAppState'

const logger = createLogger('session', 'color: salmon')

const sessionStore = createLocalStorageStore('session', () => ({
  // keepMeLoggedIn: Boolean
  // loginVerificationRequired: ???
  // sessionId: String
  // userId: String
  // publicProfileDid: String
  // username: String
}))

export const {
  get: getSession,
  // set: setSession,
  useStore: useSession,
} = sessionStore

// ACTIONS

export function onAuthChange(callback){
  return sessionStore.sub((state, prevState) => {
    if (state.sessionId !== prevState.sessionId)
      callback(state.sessionId, state)
  })
}

export function setKeepMeLoggedIn(on){
  sessionStore.set({ keepMeLoggedIn: !!on })
}

function setSessionId(sessionId){
  logger.info('setSessionId', {sessionId})
  aServer.sessionId = sessionId
  oldAppState.setSessionId(sessionId)
}

export function setSession(session, replace){
  const { sessionId } = getSession()
  try{
    return session = sessionStore.set(session, replace)
  }finally{
    if (session.sessionId !== sessionId) setSessionId(session.sessionId)
    if (session.sessionId){
      sessionStore.set({ loginVerificationRequired: undefined })
    }
  }
}

export function resetSession(){
  setSession({}, true)
}

export async function verifySession(){
  if (getSession().sessionId)
    await aServer.getJSON('/verify_session')
  // this should throw an unauthorized error
  // which will trigger an aServer.on('invalidSession')
}

// HOOKS

export function useSessionId(){
  return useSession(s => [s.sessionId])[0]
}

// INIT
{
  const location = getLocation()
  if (location.query.session) {
    logger.info('IMPORTING SESSION FROM URL')
    const json = location.query.session
    setQuery({ session: null }, true)
    sessionStore.set(JSON.parse(json), true)
  }
}

logger.info(sessionStore.get())
defer(() => {
  const updateSessionId = () => {
    setSessionId(getSession().sessionId)
  }
  updateSessionId()
  onAuthChange(updateSessionId)
})

// DELETE old session
if ('jlinc-aportal-currentUser' in localStorage)
  delete window.localStorage['jlinc-aportal-currentUser']

aServer.on('invalidSession', () => {
  console.warn('logging out because of invalid session')
  resetSession()
})

onReturnToStaleApp(() => {
  // this.takeAction('newVersion.detect')
  logger.debug('returned from stale app, verifying session')
  verifySession()
})

// DEBUG

Object.assign(DEBUG, {
  getSession,
})
