import { h } from 'preact'
import PropTypes from 'prop-types'

import KNOT_PATHS from './knotPaths.json'
import './index.sass'

export default function FullPageKnot({className = '', children, ...props}){
  return <div {...props} className={`fullpage FullPageKnot ${className}`}>
    <AnimatedKnot />
    {children}
  </div>
}

FullPageKnot.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

function AnimatedKnot(){
  return <div className="FullPageKnot-knot">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.76 42.76">
        <g id="knot" fill="black" fillOpacity="0.1">
          {KNOT_PATHS.map(path => <path d={path}/>)}
        </g>
      </svg>
    </div>
  </div>
}
