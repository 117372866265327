import { h } from 'preact'
import PropTypes from 'prop-types'

import './index.sass'

export default function IconRowList({
  className = '', children, ...props
}){
  return <div
    className={`IconRowList ${className}`}
    {...props}
  >{children}</div>
}

IconRowList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
