import { h } from 'preact'
import { useState, useRef } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import useAutoFocus from 'lib/useAutoFocusHook'

import Form from 'components/Form'
import VerificationCodeInput from 'components/VerificationCodeInput'
import FatSubmitButton from 'components/FatSubmitButton'

export default function VerificationForm({
  className,
  onSubmit,
  submitting,
}){
  const [value, onInput] = useState('')
  const ref = useRef()
  useAutoFocus(ref)
  const disabled = !!submitting
  return <Form {...{
    className: classNames('VerificationForm', { className }),
    onSubmit(){
      if (VerificationCodeInput.validate(value)) onSubmit(value)
      else useAutoFocus.focusRef(ref)
    }
  }}>
    <Form.Row>
      <Form.Item>
        <VerificationCodeInput autoSubmit {...{
          ref,
          disabled,
          value,
          onInput,
        }}/>
      </Form.Item>
    </Form.Row>
    <FatSubmitButton {...{
      disabled,
      submitting,
      value: ['Verify', 'Verifying'],
    }}/>
  </Form>
}

VerificationForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}
