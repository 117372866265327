import { h } from 'preact'
import { useRef, useEffect, useMemo } from 'preact/hooks'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import debounce from 'lib/debounce'

import {
  getVerticalScrollParent,
  remainingScrollAreaShorterThanHeight,
  hasRoomForMore,
  addScrollEventListener,
  removeScrollEventListener,
} from 'lib/DOMHelpers'
import { refToDOMNode } from 'lib/preactHelpers'

import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import './index.sass'

const ANIMATION_DURATION = 300

export default function InfiniteScrollDown(props){
  const {
    component = 'div',
    className,
    name,
    loadOnMount = true,
    loadMore,
    loading,
    loadingError,
    fullyLoaded = false,
    children,
    emptyMessage = `There are no ${name} yet`,
    fullyLoadedMessage = `There are no more ${name}`,
    loaderSize = 'lg',
  } = props

  const rootRef = useRef()
  const scrollParentRef = useRef()
  const empty = children.length === 0

  const loadMoreRef = useRef()
  loadMoreRef.current = loadMore

  const lastChildRef = useRef()
  lastChildRef.current = children?.[children.length - 1]

  const loadMoreDebounced = useMemo(
    () => {
      return debounce(
        (/*source*/) => {
          // console.log('💰loading more', {source})
          // loadMore(loadMoreBeforeRef.current)
          if (!lastChildRef.current) return loadMoreRef.current()
          loadMoreRef.current(lastChildRef.current.props.sortBy)
        },
        1000,
        !loadOnMount,
      )
    },
    [],
  )

  useEffect(
    () => {
      if (loadingError || loading || fullyLoaded) return
      if (empty) return loadMoreDebounced('empty and not fully loaded')
      // wait for animation to complete before checking for extra room
      setTimeout(
        () => {
          if (scrollParentRef.current && hasRoomForMore(scrollParentRef.current))
            loadMoreDebounced('scrollParent has more room')
        },
        ANIMATION_DURATION
      )
    },
    [loadMoreDebounced, loadingError, loading, fullyLoaded, children.length, scrollParentRef.current]
  )

  useEffect(
    () => {
      if (fullyLoaded) return

      const updateVerticalScrollParent = () => {
        const root = refToDOMNode(rootRef)
        scrollParentRef.current = getVerticalScrollParent(root)
      }

      if (!scrollParentRef.current) updateVerticalScrollParent()
      const onResize = () => {
        updateVerticalScrollParent()
        if (hasRoomForMore(scrollParentRef.current)) loadMoreDebounced('onResize.hasRoomForMore')
      }
      const onScroll = () => {
        if (remainingScrollAreaShorterThanHeight(scrollParentRef.current)){
          loadMoreDebounced('onScroll')
        }
      }
      addScrollEventListener(scrollParentRef.current, onScroll)
      global.addEventListener('resize', onResize)
      return () => {
        removeScrollEventListener(scrollParentRef.current, onScroll)
        global.removeEventListener('resize', onResize)
      }
    },
    [fullyLoaded, loadMoreDebounced, scrollParentRef.current],
  )

  return h(component,
    {
      ref: rootRef,
      className: `InfiniteScrollDown ${className}`,
    },
    <TransitionGroup className="InfiniteScrollDown-entries">
      {children.map(child =>
        <CSSTransition
          key={child.key}
          timeout={ANIMATION_DURATION}
          classNames="InfiniteScrollDown-transition"
        >{child}</CSSTransition>
      )}
    </TransitionGroup>,
    <Footer {...{
      loading,
      loadingError,
      empty,
      fullyLoaded,
      emptyMessage,
      fullyLoadedMessage,
      loaderSize,
    }}/>
  )
}

InfiniteScrollDown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  loadMore: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
  loading: PropTypes.bool,
  loadingError: ErrorMessage.propTypes.error,
  fullyLoaded: PropTypes.bool,
  emptyMessage: PropTypes.node,
  fullyLoadedMessage: PropTypes.node,
  loaderSize: Loading.propTypes.size,
}

function Footer({
  loading,
  loadingError,
  empty,
  fullyLoaded,
  emptyMessage,
  fullyLoadedMessage,
  loaderSize,
}) {
  return <div className="InfiniteScrollDown-Footer">
    {loadingError
      ? <ErrorMessage error={loadingError} slideDown={false} />
      : fullyLoaded
        ? <div className="InfiniteScrollDown-FullyLoaded">
          { empty ? emptyMessage : fullyLoadedMessage }
        </div>
        : loading
          ? <Loading type="block" size={loaderSize} />
          : null
    }
  </div>
}
