import {h} from "preact"
import PropTypes from "prop-types"

import classNames from "lib/classNames"
import EndUserAvatar from "components/EndUserAvatar"
import "./index.sass"
import Icon from "components/Icon"

export default function EndUserDMAvatar({
  className,
  publicProfiles,
  size,
  bordered,
  chatImage,
}) {
  const length = publicProfiles.length
  if (chatImage && chatImage.startsWith('#')) {
    return (
      <div
        className={classNames('avatarImg', className)}
        style={{ backgroundColor: chatImage }}
      >
        <Icon type="chaty" />
      </div>
    )
  }

  if (chatImage && !chatImage.startsWith('#')) {
    return (
      <img
        src={chatImage}
        alt="Chat Image"
        className={classNames("avatarImg", className)}
        style={{padding:0}}
      />
    )
  }

  if (!chatImage && length === 1) {
    return (
      <EndUserAvatar
        {...{
          publicProfile: publicProfiles[0],
          size,
          bordered,
        }}
      />
    )
  }
  return (
    <div className={classNames("avatarImg", className)}  >
      <Icon type="chaty" />
    </div>
  )
}

EndUserDMAvatar.propTypes = {
  className: PropTypes.string,
  chatImage: PropTypes.string,
  publicProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: EndUserAvatar.propTypes.src,
    })
  ),
  size: EndUserAvatar.propTypes.size,
  bordered: EndUserAvatar.propTypes.bordered,
}
