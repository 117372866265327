import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Button from 'components/Button'

import './index.sass'

export default class Paginator extends Component {

  static propTypes = {
    className: PropTypes.string,
    currentPage: PropTypes.number.isRequired,
    pageToHref: PropTypes.func.isRequired,
    onLastPage: PropTypes.bool.isRequired,
    // lastPage: PropTypes.number,
  }

  render(){
    const {
      className = '',
      currentPage,
      pageToHref,
      onLastPage,
      // lastPage,
      ...props
    } = this.props

    const pageButton = (page) => (
      <Button
        key={page}
        type={page === currentPage ? "primary" : "normal"}
        href={pageToHref(page)}
        disabled={onLastPage && page > currentPage}
      >
        {page}
      </Button>
    )

    // Initialize pages array with the current page button
    const pages = [pageButton(currentPage)]

    // If not on the first page, add a button to go to the previous page
    if (currentPage > 1) {
      pages.unshift(pageButton(currentPage - 1))
    }

    // If not on the last page, add a button to go to the next page
    if (!onLastPage) {
      pages.push(pageButton(currentPage + 1))
    }

    return <div className={`Paginator ${className}`} {...props}>
      <Button
        className="Paginator-gotoStart"
        type="normal"
        href={pageToHref(1)}
        disabled={currentPage === 1}
      >
        {'<<'}
      </Button>
      <Button
        className="Paginator-goBack"
        type="normal"
        href={pageToHref(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {'<'}
      </Button>
      <div className="Paginator-pages">
        {pages}
      </div>
      <Button
        className="Paginator-goForward"
        type="normal"
        href={pageToHref(currentPage + 1)}
        disabled={onLastPage}
      >
        {'>'}
      </Button>
      {/* <Button
        className="Paginator-goToEnd"
        type="normal"
        href={pageToHref(lastPage)}
        disabled={!!onLastPage}
      >
        {'>>'}
      </Button> */}
    </div>
  }
}
