import { useEffect, useState } from 'preact/hooks'
import { io } from "socket.io-client"
import {
  getSession,
} from '../a-portal/resources/session'
import { useAppState } from './appState'


const socket = io(JLINC_A_API.replace("/api", ""), {
  autoConnect: false,
  auth: (cb) => {
    cb({
      token: getSession().sessionId
    })
  },
})

socket.on("connect_error", (err) => {
  console.log(err instanceof Error)
  console.log(err.message)
  console.log(err.data)
})


export { socket }

export function useSocket(){
  const [isConnected, setIsConnected] = useState(socket.connected)
  const {takeAction} = useAppState({}, 'useSocket')

  useEffect(() => {
    function onConnect() {
      setIsConnected(true)
    }

    function onDisconnect() {
      setIsConnected(false)
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on("new message", function() {
      takeAction('chat.pollingOnceForSynopsis')
    })

    socket.on("new reaction", function(data) {
      takeAction('chat.reflectNewReaction', data)
    })

    socket.on("edit message", function(data) {
      takeAction('chat.reflecteditedMessage', data.chatMessage)
    })

    socket.on("reply message", function(data) {
      takeAction('chat.loadMessages', data.chatMessage.channelUid)
    })

    socket.on("chatMessagesRead", function(data) {
      takeAction('chat.reflectReadMessage', data.chatMessage)
    })

    socket.on("chatMessageDeleted", function(data) {
      takeAction('chat.reflectDeletedMessage', data.chatMessage)
    })

    socket.on("chatEvents", function(data) {
      console.log("chatEvents", data.events)
      takeAction('chat.reflectChatEvents', data.events)
    })

    socket.on("chatMembers", function(data) {
      takeAction('chat.reflectChatMembers', data.events)
    })

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
    }
  }, [])

  return [isConnected]
}
