import mergeAccountData from 'jlinc-shared/mergeAccountData'
import diffAccountData from 'jlinc-shared/diffAccountData'

export default function(changes) {
  const { defaultAccountData, defaultAccountDataStagedChanges } = this.getState()

  if (defaultAccountDataStagedChanges)
    changes = mergeAccountData(defaultAccountDataStagedChanges, changes)

  const newDefaultAccountDataStagedChanges = diffAccountData(
    defaultAccountData,
    mergeAccountData(defaultAccountData, changes),
  )

  this.setState({
    defaultAccountDataStagedChanges: newDefaultAccountDataStagedChanges,
    updatingDefaultAccountDataError: undefined,
  })
}
