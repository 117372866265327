import { h } from 'preact'
import { useRef, useState, useCallback, useEffect } from 'preact/hooks'

import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { useMyPublicProfile } from 'lib/membershipAppStateHooks'

import { createPageAlert } from 'resources/pageAlerts'

import BannerImageInput from 'components/BannerImageInput'
import './index.sass'

export default function EditableEndUserBanner({className, ...props}){
  const {
    myPublicProfile,
    updateMyPublicProfile,
    updatingMyPublicProfile,
    errorUpdatingMyPublicProfile,
  } = useMyPublicProfile('EditableEndUserBanner')

  const defaultValue = myPublicProfile && myPublicProfile.banner

  const updatingRef = useRef(!!updatingMyPublicProfile)
  const [value, setValue] = useState(defaultValue)

  const onChange = useCallback(banner => {
    setValue(banner)
    updateMyPublicProfile({ banner })
    updatingRef.current = true
  }, [])

  useEffect(
    () => {
      if (updatingRef.current && !updatingMyPublicProfile){
        updatingRef.current = false
        if (errorUpdatingMyPublicProfile) {
          setValue(defaultValue)
          createPageAlert.error(`Failed to save new profile banner 🙁`)
        }else{
          createPageAlert.success(`New Profile Banner Saved 😀`)
        }
      }
    },
    [updatingMyPublicProfile, errorUpdatingMyPublicProfile]
  )

  const disabled = !myPublicProfile || !!updatingMyPublicProfile

  className = classNames('EditableEndUserBanner', { className })

  return <div className={className}>
    <BannerImageInput {...{
      ...props,
      disabled,
      defaultValue,
      value,
      onChange,
      unsaved: (
        myPublicProfile &&
        value &&
        value !== myPublicProfile.banner
      ),
    }}/>
  </div>
}

EditableEndUserBanner.propTypes = {
  className: PropTypes.string,
}
