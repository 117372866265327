import diffAccountData from 'jlinc-shared/diffAccountData'
import stripNonRequestedAccountData from 'jlinc-shared/stripNonRequestedAccountData'
import getRequestedDataForOrganization from 'jlinc-shared/getRequestedDataForOrganization'
import PNFO from 'jlinc-shared/PNFO'

import logger from 'lib/logger'

export default function(organizationApikey) {
  const importingDefaultsKey = `importatingDefaults:${organizationApikey}`
  const importDefaultAccountDataErrorKey = `importDefaultAccountDataError:${organizationApikey}`
  const organizationAccountDataStagedChangesKey = `organizationAccountDataStagedChanges:${organizationApikey}`
  logger.debug('importDefaultAccountData', {organizationApikey})

  const organizationAccountDataStagedChanges =
    this.getState()[`organizationAccountDataStagedChanges:${organizationApikey}`]

  if (organizationAccountDataStagedChanges) {
    logger.warn(
      `refusing to stage defaultAccountData into ` +
      `organizationAccountDataStagedChanges:${organizationApikey} ` +
      `because it already exists`
    )
    return
  }

  this.setState({
    [importingDefaultsKey]: true,
    [`importDefaultAccountDataError:${organizationApikey}`]: undefined,
    [`updatingOrganizationAccountDataError:${organizationApikey}`]: undefined,
  })

  return this.takeAction('loadDefaultAccountData')
    .then(() => {

      const {
        defaultAccountData,
        [`organization:${organizationApikey}`]: organization,
        [`organizationAccountData:${organizationApikey}`]: organizationAccountData,
      } = this.getState()

      const requestedData = getRequestedDataForOrganization(organization)
      logger.debug({ requestedData })
      logger.debug({ defaultAccountData })
      const strippedDefaultAccountData = stripNonRequestedAccountData(
        defaultAccountData,
        requestedData,
      )
      logger.debug({ strippedDefaultAccountData })
      let newOrganizationAccountDataStagedChanges = diffAccountData(
        organizationAccountData,
        strippedDefaultAccountData,
      )

      if (!newOrganizationAccountDataStagedChanges) {
        this.setState({
          [importDefaultAccountDataErrorKey]: (
            `Your defaults already match this ${PNFO.singular}'s preferences`
          )
        })
      }else{
        this.setState({
          [organizationAccountDataStagedChangesKey]: newOrganizationAccountDataStagedChanges,
        })
      }
    })
    .catch(error => {
      this.setState({
        [importDefaultAccountDataErrorKey]: error,
      })
    })
    .then(() => {
      this.setState({
        [importingDefaultsKey]: undefined,
      })
    })
}
