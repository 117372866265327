import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import classNames from 'lib/classNames'

import MyOrganizationsList from 'components/MyOrganizationsList'

export default function OrganizationsIAmMemberOfList({ className, ...props }){
  return <MyOrganizationsList {...{
    ...props,
    className: classNames('OrganizationsIAmMemberOfList', {className}),
    filter: o => o.membership || o.haveSISA,
    onEmpty: `You are not a member of any ${PNFO.plural} yet.`,
  }}/>
}
