import { useEffect } from 'preact/hooks'

import { createLogger } from 'lib/logger'
import { tx, createInMemoryStore } from 'lib/stores'

import aServer from './aServer'
import { setPageError } from './pageAlerts'

const logger = createLogger('webhooks', 'color: silver')

const state = createInMemoryStore()

// ACTIONS

const orgKey = apikey => `org:${apikey}`

export function get(organizationApikey){
  return state.get()[orgKey(organizationApikey)] || {}
}

export function set(organizationApikey, recordsById){
  state.set({ [orgKey(organizationApikey)]: recordsById })
}

export function add(record){
  let records = get(record.organizationApikey)
  records = { ...records, [record.id]: record }
  set(record.organizationApikey, records)
}

export function remove(record){
  let records = {...get(record.organizationApikey)}
  delete records[record.id]
  set(record.organizationApikey, records)
}

function byId(records){
  const byId = {}
  records.forEach(record => { byId[record.id] = record })
  return byId
}


const setCreateError = error =>
  setPageError('Webhook Creation Failed', error)

function create({ organizationApikey, url, secret }){
  setCreateError()
  logger.info('creating…', { organizationApikey, url })
  return tx(state, 'creating', async () => {
    try{
      const {
        organizationWebhook,
      } = await aServer.postJSON(
        `/organizations/${organizationApikey}/webhooks`,
        { url, secret },
      )
      logger.info('created', organizationWebhook)
      add(organizationWebhook)
      return organizationWebhook
    }catch(error){
      setCreateError(error)
      throw error
    }
  })
}

const setLoadError = error =>
  setPageError('Failed to load webhooks', error)

function load({ organizationApikey }){
  setLoadError()
  logger.info('loading…', { organizationApikey })
  return tx(state, 'loading', async () => {
    try{
      const {
        organizationWebhooks,
      } = await aServer.getJSON(
        `/organizations/${organizationApikey}/webhooks`,
      )
      logger.info('loaded', organizationWebhooks)
      set(organizationApikey, byId(organizationWebhooks))
    }catch(error){
      setLoadError(error)
      throw error
    }
  })
}


const setDestroyError = error =>
  setPageError('Failed to load webhooks', error)

function destroy({ organizationApikey, id }){
  setDestroyError()
  logger.info('destroying…', { organizationApikey, id })
  return tx(state, 'destroying', async () => {
    try{
      const {
        organizationWebhook,
      } = await aServer.postJSON(
        `/organizations/${organizationApikey}/webhooks/${id}/delete`,
      )
      remove(organizationWebhook)
    }catch(error){
      setDestroyError(error)
      throw error
    }
  })
}

// HOOKS

export function useOrganizationWebhookCreator(){
  return {
    ...state.useStore(['creating']),
    create
    // error
  }
}

export function useOrganizationWebhooks(organizationApikey){
  useEffect(
    () => { load({ organizationApikey }) },
    [organizationApikey]
  )
  return state.useStore(state =>
    state[orgKey(organizationApikey)]
  )
}

export function useOrganizationWebhookDetroyer(){
  return {
    ...state.useStore(['destroying']),
    destroy,
    // error
  }
}


// DEBUG

DEBUG.organizationWebhooks = state
