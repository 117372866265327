import { createLogger } from 'lib/logger'
import defer from 'lib/defer'
import { tx, createInMemoryStore } from 'lib/stores'
import useAsyncCall from 'lib/useAsyncCallHook'

import aServer from './aServer'
import oldAppState from './oldAppState'
import {socket} from 'lib/socketHooks'

import {
  getSession,
  setSession,
  useSession,
  setKeepMeLoggedIn,
  useSessionId,
  // setSessionId,
  onAuthChange,
} from './session'

import { setPageError } from './pageAlerts'
import { consumeCaptcha } from './captcha'

const logger = createLogger('auth', 'color: pink')

const state = createInMemoryStore({
  // signupFormValues: {},
  // loggingIn: false,
  // loggingInError: undefined,
  // verifying: false,
  // verifyingError: undefined,
  // loadingOverview: false,
  // loadingOverviewError: undefined,
})


// ACTIONS

export function getCurrentUser(){
  const { userId, publicProfileDid, username } = getSession()
  return { userId, publicProfileDid, username }
}

export function clearCurrentUser(){
  setSession({}, true)
}

export function setLogin(login){
  setSession({ login })
}

export function setUsername(username){
  setSession({ username })
}

export function isLoggedIn(){
  return !!getSession().sessionId
}

export function isRecentLoggedIn(){
  return (new Date()).getTime() - getSession().createdAt < 5 * 60 * 1000
}

export function assertLoggedIn(){
  if (!isLoggedIn()) throw new Error(`you must be logged in`)
}

export function assertNotLoggedIn(){
  if (isLoggedIn()) throw new Error(`you cannot be logged in`)
}

export async function getUsernameAvailability(username){
  logger.info(`checking username "${username}"`)
  if (typeof username !== 'string' || !username) return false
  const r = await aServer.getJSON(`/usernames/${username}/availability`)
  return r.available
}

export function setSignUpError(error){
  logger.error(error)
  setPageError('Signup Failed ', error)
}

export function signUp({
  captchaSolve,
  username,
  email,
  mobile,
  password,
  source,
  joinOrganization,
  organizationInviteToken,
}){
  logger.info('signing up', {
    username,
    email,
    mobile,
    // password,
    source,
    joinOrganization,
  })


  return tx(state, 'signingUp', async () => {
    setSignUpError()
    const { keepMeLoggedIn } = getSession()
    try{
      const captcha = consumeCaptcha()
      const newSession = await aServer.postJSON('/signup', {
        captchaEncValue: captcha.encValue,
        captchaSolve,
        username,
        email,
        mobile,
        password,
        keepMeLoggedIn,
        source,
        landingPage: sessionStorage.landingPage,
        referrer: sessionStorage.referrer,
        joinOrganization,
        // organizationApikey,
        // sisaInvite,
        membershipInviteToken: organizationInviteToken,
      })
      logger.info('signup successful', newSession)
      setSession(newSession)
      logger.info('signup successful', getSession())
      try {
        if(window.PasswordCredential){
          navigator.credentials.store(new window.PasswordCredential({
            id : email ? email : mobile,
            password,
            name:username
          }))
        }
      } catch (error) {
        console.error(error)
      }
    }catch(signUpError){
      if (`${signUpError}`.match(/a user with that (email|mobile) already exists/i)){
        return await loginAs({
          login: RegExp.$1 === 'email' ? email : mobile,
          password,
          joinOrganization
        }).catch(() => {
          setSignUpError(signUpError)
        })
      }
      setSignUpError(signUpError)
    }
  })
}
export function dismissSignupError(){
  state.set({ signingUpError: undefined })
}

export async function loginAs({
  login,
  password,
  joinOrganization,
  setRequire2FA,
  code,
}){
  setPageError('Login Failed')
  const { keepMeLoggedIn } = getSession()
  logger.info('logging in', {
    login, joinOrganization, keepMeLoggedIn
  })
  return tx(state, 'loggingIn', async () => {
    try{
      if(login === 'passkey'){
        const newSession = await aServer.postJSON('/webauthn/login_finish', {
          login,
          password,
          joinOrganization,
          keepMeLoggedIn,
        })
        setSession(newSession)
        logger.info('login successful', getSession())
      } else{
        const newSession = await aServer.postJSON('/login', {
          login,
          password,
          joinOrganization,
          keepMeLoggedIn,
          code,
        })
        setSession(newSession)
        logger.info('login successful', getSession())
      }
    }catch(loginError){
      if(loginError?.message === '2FA code is required'){
        setRequire2FA(true)
      } else {
        setPageError('Login Failed', loginError)
        throw loginError
      }
    }
  })
}

export async function autoLogin(){
  try {
    if(window.PasswordCredential){
      const c = await navigator.credentials.get({password:true, mediation: 'silent'})
      if(c && c.type === "password"){
        await loginAs({login: c.id, password:c.password})
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export async function logout(){
  logger.info('logging out')
  // TODO post to server
  clearCurrentUser()

  aServer.views.clear()

  // TEMP until we can wire things up better
  // we load things when logged out that need to be reloaded
  // after  login since the data we loaded has subtle changes
  // depending on if you are logged or not.
  //
  // Until we can fix this, delete such keys here at login.
  oldAppState.deleteKeys(
    /^(my|organization|membership|notification|preferences|externalIdentities|sisa)/,
  )
  /* END OF STUPID HACK */

  try {
    if(window.PasswordCredential){
      navigator.credentials.preventSilentAccess()
    }
  } catch (error) {
    console.error(error)
  }
}

export function loadOverview(){
  logger.info('loading overview')
  assertLoggedIn()
  return tx(state, 'loadingOverview', async () => {
    const overview = await aServer.getJSON('/overview')
    logger.info('overview loaded', overview)
    const {
      preferences,
      myPublicProfile,
      externalIdentities,
      notificationSettings,
    } = overview
    oldAppState.setState({
      preferences,
      externalIdentities,
      notificationSettings,
    })
    oldAppState.takeAction('publicProfiles.addPublicProfilesToAppState', [myPublicProfile])
    if (isDataYogi) oldAppState.takeAction('organization.load', 'DataYogi')
  })
}


// HOOKS

export function useLoggedIn(){
  return !!useSessionId()
}

export function useViewIfLoggedIn(viewId, options){
  return aServer.views.use(useLoggedIn() ? viewId : undefined, options)
}

export function useUsernameAvailability(username){
  const asyncCall = useAsyncCall(
    getUsernameAvailability,
    [username],
    {debounce: 1000},
  )
  return asyncCall.result
}

export function useLogin(){
  const { login, keepMeLoggedIn } = useSession()
  return {
    ...state.useStore(['loggingIn', 'loggingInError']),
    login,
    setLogin,
    keepMeLoggedIn,
    setKeepMeLoggedIn,
  }
}

export function useSignup(){
  return {
    ...useSession(['username', 'login']),
    ...state.useStore(['signingUp', 'signingUpError']),
    signUp,
    setSignUpError,
    setLogin,
    setUsername,
  }
}

export function useCurrentUser(){
  return useSession(s =>
    s.sessionId
      ? {
        userId: s.userId,
        publicProfileDid: s.publicProfileDid,
        username: s.username,
      }
      : undefined
  )
}

export function useMyPublicProfileDid(){
  return useSession(s => s.publicProfileDid)
}


// INIT


sessionStorage.landingPage = sessionStorage.landingPage || location.toString()
if (
  document.referrer &&
  !document.referrer.startsWith(BRANDING_SITE_URL) &&
  !document.referrer.startsWith(location.origin)
)
  sessionStorage.referrer = document.referrer


defer(() => {
  if (isLoggedIn()) {
    loadOverview()
    socket.connect()
  } else {
    // autoLogin()
  }
  onAuthChange(() => {
    if (isLoggedIn()) {
      loadOverview()
      socket.connect()
    } else{
      clearCurrentUser()
      socket.disconnect()
    }
  })
})


// DEBUG

Object.assign(DEBUG, {
  loginAs, logout, useSessionId, getCurrentUser,
  authState: state,
})
