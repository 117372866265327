import { h } from 'preact'
import { useMemo } from 'preact/hooks'
import PropTypes from 'prop-types'
import history from 'lib/history'

import classNames from 'lib/classNames'
import { isValidEmail } from 'lib/validators'
import { splitByUrls } from 'lib/urlHelpers'

import Link from 'components/Link'
import './index.sass'

export default function PlainText({
  className,
  text = '',
  maxUrlLength = 120,
  bigify,
  ...props
}){
  const options = { bigify }
  props.style = useMemo(
    () => PlainText.getStyle(text, options),
    [text],
  )
  const parts = useMemo(
    () => (
      splitByUrls(text).map(part =>
        typeof part === 'string'
          ? <span>{part}</span>
          : <PlainTextLink url={part.url} max={maxUrlLength}/>
      )
    ),
    [text, maxUrlLength],
  )
  props.className = classNames('PlainText', { className })
  return <div {...props}>{parts}</div>
}

PlainText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  maxUrlLength: PropTypes.number,
  bigify: PropTypes.bool,
}

PlainText.getStyle = (text, options = {}) => {
  const { bigify } = options
  if (typeof text !== 'string') return {}
  if (justEmojis(text)) return sizeToStyle(90)
  if (bigify && text.match(/^\S[^\n]*$/)) {
    let width = getTextWidth(text)
    let size = 56
    if (width > 60) size = size - ((width - 60) / 4)
    return sizeToStyle(Math.max(16, size))
  }
  return {}
}
const sizeToStyle = size => (
  { fontSize: `${size}px`, lineHeight: `${size}px` }
)

function PlainTextLink({url, max}){
  if (isValidEmail(url)) return <span>{url}</span>
  const href = /^(https?:)?\/\//.test(url) ? url : `//${url}`
  const externalLink = href && !history.hrefIsSameOrigin(href)
  return <Link newWindow type="link" href={externalLink ? `external?link=${href}` : href}>
    {max && url.length > max ? `${url.substr(0, max)}…` : url}
  </Link>
}

PlainText.Link = PlainTextLink

const justEmojis = text =>
  new RegExp(
    '^(' +
    '\\u00a9|' +
    '\\u00ae|' +
    '[\\u2000-\\u3300]|' +
    '\\ud83c[\\ud000-\\udfff]|' +
    '\\ud83d[\\ud000-\\udfff]|' +
    '\\ud83e[\\ud000-\\udfff]' +
    '){1,5}$',
    'gi'
  ).test(text)


const canvas2d = document.createElement("canvas").getContext("2d")
function getTextWidth(text){
  canvas2d.font = `400 16px proxima-nova, sans-serif`
  return canvas2d.measureText(text).width
}
