import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import humanizeNumber from 'lib/humanizeNumber'

import Button from 'components/Button'
import BounceArrow from 'components/BounceArrow'
import './index.sass'

export default function ElevateButton({
  className,
  disabled,
  elevated,
  count,
  onClick,
  ...props
}){
  return <Button {...{
    ...props,
    type: 'none',
    className: classNames('ElevateButton', { className, elevated }),
    disabled,
    onClick,
  }}>
    <BounceArrow up active={elevated}/>&nbsp;
    <span>{humanizeNumber(count)}</span>&nbsp;
    <span>Elevate</span>
  </Button>
}

ElevateButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  elevated: PropTypes.bool,
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func,
}
