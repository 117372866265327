import { h } from 'preact'
import PropTypes from 'prop-types'

import Card from 'components/Card'
import KNOT_PATHS from './knotPaths.json'
import './index.sass'

export default function FullPageCard({className = '', children, ...props}){
  return <div className={`fullpage FullPageCard ${className}`}>
    <AnimatedKnot />
    <Card {...props}>{children}</Card>
  </div>
}

FullPageCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

function AnimatedKnot(){
  return <div className="FullPageCard-knot">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.76 42.76">
        <g id="knot" fill="black" fillOpacity="0.1">
          {KNOT_PATHS.map(path => <path d={path}/>)}
        </g>
      </svg>
    </div>
  </div>
}
