import { Fragment, h } from 'preact'
import { useRef, useEffect, useState, useMemo, useCallback } from 'preact/hooks'
import moment from 'moment'
import classNames from 'lib/classNames'
import { publicProfileToPathname } from 'lib/publicProfiles'
import Link from 'components/Link'
import Icon from 'components/Icon'
import Subtext from 'components/Subtext'
import Spinner from 'components/Spinner'
import EndUserAvatar from 'components/EndUserAvatar'
import WYSIWYGContent from 'components/WYSIWYGContent'
import PlainText from 'components/PlainText'
import Timestamp from 'components/Timestamp'
import ChatUrlPreview from 'components/ChatUrlPreview'
import { FILE_ATTACHMENT_PREFIX } from './FILE_ATTACHMENT_PREFIX'
import FileAttachment from 'components/FileAttatchement'
import { UploadingFile } from './UploadingFile'
import { ChatMessageFailed } from './ChatMessageFailed'
import ChatMessageDeletionModal from 'components/ChatMessageDeletionModal'
import ReactionsDetails from 'components/ReactionsDetails'
import Picker from 'emoji-picker-react'
import { ThreadRepliesNumber } from './ThreadRepliesNumber'
import { useAppState } from 'lib/appState'
import Header from 'components/Header'
import './index.sass'
import Alert from 'components/Alert'
import Modal from 'components/Modal'
import MediaSlideshow from 'components/MediaSlideshow'


export function ChatMessage({
  uid,
  pending,
  error,
  message,
  timestamp,
  fromMe,
  unread,
  upload,
  publicProfile,
  lastInGroup,
  createdAt,
  handleEditMessage,
  urlPreview,
  setReplyingTo,
  handleStarMessage,
  handleUnStarMessage,
  isOneToOne,
  handleDeleteMessage,
  handleUndoDeleteClick,
  deletedAt,
  deletedByUserDid,
  isAdmin,
  isCurator,
  chatChannelType,
  deletedByRole,
  readAt,
  chatChannelUid,
  editedMessage,
  undeletedAt,
  undeleteDeniedAt,
  publicProfiles = [],
  myPublicProfile,
  recipientUserDid,
  addReactionToMessage,
  handleRemoveReaction,
  reactions,
  setForwarding,
  setForwardMessageUid,
  setForwardedMessageContent,
  forwarded_from_message_uid,
  editted_original_message,
  openThreadRepliesModal,
  replyCount,
  replies,
  isThread,
  sendToMainChat,
  replyToMessageUid,
  closeStarredMessagesModal,
  starredMessagesIds,
  Filecaption,
  group = [],
  onToggleGrouping
}) {


  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [messageReactions, setMessageReactions] = useState([])
  const [showReactionsDetail, setShowReactionsDetail] = useState(false)
  const [showCommonEmojis, setShowCommonEmojis] = useState(false)
  const [viewImage, setViewImage] = useState(false)
  const [slideImage, setSlideImage] = useState([])


  const isDeletedByAdmin =
    deletedAt && (deletedByRole === "admin" || deletedByRole === "curator")
  const isUndeleted = !!undeletedAt
  const isUndeleteDenied = !!undeleteDeniedAt

  const handleOpenModal = () => setShowReactionsDetail(true)

  const handleCloseModal = () => {
    setShowReactionsDetail(false)
  }
  const groupId = group.length ? group[0].groupId : null


  const toggleReactionsDetail = (event) => {
    event.stopPropagation()
    setShowReactionsDetail(!showReactionsDetail)
  }
  const { takeAction } = useAppState(
    {
      [
      `chat:channel:${chatChannelUid}:thread:open`
      ]: 'chatThread'
    },
    'ChatMessage'
  )

  const aggregatedReactions = useMemo(() => {
    const reactionCounts = {};
    (reactions || []).forEach((reaction) => {
      const reactionType = reaction?.reaction
      if (reactionType) {
        if (!reactionCounts[reactionType]) {
          reactionCounts[reactionType] = { count: 0, users: [] }
        }
        reactionCounts[reactionType].count += 1
        reactionCounts[reactionType].users.push(reaction.reactor_user_did)
      }
    })
    return Object.entries(reactionCounts).map(([reaction, details]) => ({
      reaction,
      count: details.count,
      users: details.users,
    })).sort((a, b) => b.count - a.count)
  }, [reactions])


  const topReactions = aggregatedReactions.slice(0, 3)
  const totalReactionsCount = aggregatedReactions.length
  const onEmojiSelect = (emojiObject) => {
    setShowEmojiPicker(false)
    const newReactions = [...messageReactions, emojiObject.emoji]
    console.log(newReactions, messageReactions, 'reacted')
    setMessageReactions(newReactions)
    addReactionToMessage(uid, emojiObject.emoji)
      .then(() => {})
      .catch((error) => {
        setMessageReactions(messageReactions)
        console.error("Failed to add reaction:", error)
      })
  }
  useEffect(() => {
    setMessageReactions(reactions)
  }, [reactions])


  const onReactionClick = (reaction) => async () => {
    const hasReacted = reactions?.some((r) => r.reaction === reaction)

    if (hasReacted) {
      await handleRemoveReaction(uid, reaction)
    } else {
      await addReactionToMessage(uid, reaction)
    }
  }

  const displayNameMap = useMemo(() => {
    return publicProfiles.reduce((map, profile) => {
      map[profile.did] = (profile.displayName || profile.username) || 'Unknown User'
      return map
    }, {})
  }, [publicProfiles])


  const renderReactionsSummary = () => {
    const renderIndividualReactions = () => {
      return topReactions.map((reaction, index) => {
        const currentUserReacted = reaction.users.includes(myPublicProfile.did)
        const userNames = reaction.users
          .map(userDid => userDid === myPublicProfile.did ? "you" : displayNameMap[userDid])
          .slice(0, 5)
        const additionalCount = reaction.users.length > 5 ? `, +${reaction.users.length - 5} more` : ''
        const tooltipMessage = `${userNames.join(', ')}${additionalCount} reacted with ${reaction.reaction}`
        const onClickFunction = onReactionClick(reaction.reaction)

        return (
          <div
            key={index}
            className={`emoji-reaction ${
              currentUserReacted
                ? "emoji-reaction-mine"
                : "emoji-reaction-others"
            }`}
            onClick={(event) => {
              event.stopPropagation()
              onClickFunction(event)
            }}
          >
            {reaction.reaction}
            <span className="tooltip">{tooltipMessage}</span>
            <span className="emoji-count">{reaction.count}</span>
          </div>
        )
      })
    }

    if (totalReactionsCount <= 3) {
      return (
        <div className="message-reactions" >
          {renderIndividualReactions()}
        </div>
      )
    } else {
      return (
        <div className="message-reactions">
          {renderIndividualReactions()}
          <div className="more-reactions" onClick={toggleReactionsDetail}>+{totalReactionsCount - 3}</div>
        </div>
      )
    }
  }

  const commonEmojis = ['❤️', '👍', '👎', '😂', '😲', '😔', 'createIcon']


  const toggleCommonEmojiPicker = () => {
    setShowCommonEmojis((prev) => !prev)
    if (showEmojiPicker) {
      setShowEmojiPicker(false)
    }
  }

  const onCommonEmojiSelect = (emoji) => {
    if (emoji === 'createIcon') {
      setShowCommonEmojis(false)
      setShowEmojiPicker(true)
    } else {
      addReactionToMessage(uid, emoji).catch(console.error)
      setShowCommonEmojis(false)
    }
  }

  const pickerPositionClass = fromMe ? "picker-emojis-fromMe" : "picker-emojis-fromOthers"


  const renderCommonEmojis = () => {
    const positionClass = fromMe ? "common-emojis-fromMe" : "common-emojis-fromOthers"
    return (
      <div ref={commonEmojiRef} className={`common-emojis
      ${positionClass}`}>
        {commonEmojis.map((emoji, index) => {
          if (emoji === 'createIcon') {
            return (
              <span key={index} onClick={() => onCommonEmojiSelect(emoji)} className="common-emoji">
                <Icon className="commonEmojiPlus" type="create" />

              </span>
            )
          } else {
            return (
              <span key={index} onClick={() => onCommonEmojiSelect(emoji)} className="common-emoji">
                {emoji}
              </span>
            )
          }
        })}
      </div>
    )
  }

  const now = new Date()
  const readTimestamp = new Date(readAt)
  const timeElapsed = now - readTimestamp

  const isReadByRecipient = !!recipientUserDid

  const [showDeletionModal, setShowDeletionModal] = useState(false)
  const [selectedReason, setSelectedReason] = useState("")
  const [customReason, setCustomReason] = useState("")
  const [adminComment, setAdminComment] = useState("")
  const isGeneralChat = chatChannelType === "general"

  const canDelete = (isAdmin || isCurator) && !fromMe && isGeneralChat

  const isDeleted = !!deletedAt
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)
  const className = classNames("OrganizationChatPage-ChatMessage", {
    fromMe,
    pending,
    failed: !!error,
    unread,
  })

  const handleDeleteClick = () => {
    if (canDelete) {
      setShowDeletionModal(true)
    } else {
      handleDeleteMessage(uid)
    }
    closeStarredMessagesModal()
  }
  const fileAttachment = (
    message &&
    message.startsWith(FILE_ATTACHMENT_PREFIX) &&
    JSON.parse(message.split(FILE_ATTACHMENT_PREFIX)[1])
  )


  const handleEditClick = () => {
    handleEditMessage(uid, message, isDeletedByAdmin, editedMessage)
    closeStarredMessagesModal()
  }

  const handleStarChatMessage = () => {
    handleStarMessage(uid)
    closeStarredMessagesModal()
  }

  const handleUnStarChatMessage = () => {
    handleUnStarMessage(uid)
    closeStarredMessagesModal()
  }

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const sortNonDeletedImagesFirst = useCallback((a, b) => {
    if (a.deletedAt && !b.deletedAt) {
      return 1
    }
    if (!a.deletedAt && b.deletedAt) {
      return -1
    }
    return 0
  }
  , [])


  const getImageMessageObject = fileAttachment && message.substring(message.indexOf('{'), message.lastIndexOf('}') + 1)
  const imageMessageObject = getImageMessageObject && JSON.parse(getImageMessageObject)
  const isImageMessage = imageMessageObject && imageMessageObject.type.includes('image')
  const isGroupedImage = useMemo(() => group && group.length > 1, [group])
  const isSingleAndNotGrouped = useMemo(() => group && group.length === 1 && group[0]?.grouped === false, [group])
  const renderAsSingleImage = isSingleAndNotGrouped || (!isGroupedImage && group && group.length === 1)

  const filteredImages = useMemo(() => group.filter(m => !m.deletedAt), [group])
  const displayImages = useMemo(
    () => group.sort(sortNonDeletedImagesFirst).slice(0, 4)
    , [group, sortNonDeletedImagesFirst]
  )
  const moreImagesCount = filteredImages.length - 4

  let jsonString
  let image
  if (isImageMessage) {
    jsonString = message?.substring(message.indexOf("{"), message.lastIndexOf("}") + 1)
  }


  try {
    image = jsonString ? JSON.parse(jsonString) : null
  } catch (error) {
    console.error("Failed to parse JSON:", error)
  }

  const handleImageClick = () => {
    setSlideImage([{ url:image.url, type: 'image' }])
    setViewImage(true)
  }

  const handleReplyClick = () => {
    setReplyingTo({
      message: isImageMessage ? imageMessageObject : message,
      uid: uid,
      username: fromMe ? 'You' : publicProfile.username,
    })
    closeStarredMessagesModal()
    setShowDropdown(false)
  }
  const handleForwardChat = () => {
    setForwarding(true)
    setForwardMessageUid(uid)
    setForwardedMessageContent(message)
  }
  const handleCopyMessageContent = () => {
    if (!navigator.clipboard) {
      console.error('Clipboard API is not available.')
      return
    }

    navigator.clipboard.writeText(message)
      .then(() => {
        <Alert type="success">Message copied to clipboard successfully</Alert>
      })
      .catch((err) => {
        console.error('Failed to copy message:', err)
      })
  }
  const handleConfirmDelete = () => {
    if (
      !selectedReason ||
      (selectedReason === "Other (please specify)" && !customReason.trim())
    ) {
      // eslint-disable-next-line no-alert
      alert("Please select a reason for deletion.")
      return
    }

    const deletionReason =
      selectedReason === "Other (please specify)"
        ? customReason
        : selectedReason
    console.log("Delete reason:", deletionReason)

    setShowDeletionModal(false)

    handleDeleteMessage(uid, {
      deletionReason:
        selectedReason === "Other (please specify)"
          ? customReason
          : selectedReason,
      AdminComment: adminComment,
    })
    setAdminComment("")
  }

  // from endpoint edit message if it is less than 60 minutes old
  const disableEdit = (message) => {
    const now = moment()
    const diffInMinutes = now.diff(message.createdAt, 'minutes')

    if (message.createdAt && diffInMinutes < 60) {
      return true
    }
    return false
  }

  const menuItems = [
    {
      label: "Send Reply to main chat",
      condition: isThread && !sendToMainChat && !isDeleted && fromMe,
      onClick: () => {
        takeAction('chat.sendReplyToMainChat', chatChannelUid, uid, true)
      },
    },
    {
      label: "Remove Reply from main chat",
      condition: !isThread && sendToMainChat && !isDeleted && fromMe,
      onClick: () => {
        takeAction('chat.sendReplyToMainChat', chatChannelUid, uid, false)
      },
    },
    {
      label: "Edit",
      condition: !isDeleted && !(isUndeleted || isUndeleteDenied) && fromMe && disableEdit({ fromMe, createdAt }),
      onClick: handleEditClick,
    },
    {
      label: "Reply",
      condition: !isDeleted && !isThread && !sendToMainChat,
      onClick: !isThread ? () => {
        takeAction('chat.openThread', chatChannelUid, uid)
        openThreadRepliesModal()
      } : handleReplyClick,
    },
    {
      label: "Forward",
      condition: !isDeleted,
      onClick: handleForwardChat,
    },
    {
      label: "Copy",
      condition: !isDeleted,
      onClick: handleCopyMessageContent,
    },
    {
      label: "Star",
      condition: !isDeleted && !starredMessagesIds?.has(uid),
      onClick: handleStarChatMessage
    },
    {
      label: "Remove Star",
      condition: starredMessagesIds?.has(uid),
      onClick: handleUnStarChatMessage,
    },
    {
      label: "Request Edit",
      condition: isDeletedByAdmin && !(isUndeleted || isUndeleteDenied) && fromMe,
      onClick: handleEditClick,
    },
    {
      label: "Delete",
      condition: !isDeleted && fromMe && (!readAt || timeElapsed < 3600000),
      onClick: () => handleDeleteMessage(uid),
    },
    {
      label: "Accept",
      condition: isDeleted && canDelete,
      onClick: () => handleUndoDeleteClick(chatChannelUid, uid, "approve"),
    },
    {
      label: "Deny",
      condition: isDeleted && canDelete,
      onClick: () => handleUndoDeleteClick(chatChannelUid, uid, "deny"),
    },
    {
      label: "Delete",
      condition: isDeleted && canDelete,
      onClick: handleDeleteClick,
    }
  ]

  const hasVisibleItems = menuItems.some(item => item.condition)
  const commonEmojiRef = useRef(null)
  const emojiPickerRef = useRef(null)


  useEffect(() => {
    function handleClickOutside(event) {
      if (commonEmojiRef.current && !commonEmojiRef.current.contains(event.target)) {
        setShowCommonEmojis(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false)
      }
    }

    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [showEmojiPicker])

  const dropdownClassName = classNames(
    'OrganizationChatPage-Bubble-dropdown',
    { 'from-me': fromMe, 'from-others': !fromMe }
  )

  const messageContent = editedMessage || message
  const isEdited = !!editedMessage

  const renderDeletedMessage = (
    <div className="OrganizationChatPage-Bubble">
      <div
        className="deleted-message-placeholder"
        style={{
          fontStyle: 'italic',
          fontSize: '14px'
        }}
      >
        Message was deleted by
        {deletedByRole === 'admin' || deletedByRole === 'curator'
          ? ` the ${deletedByRole}`
          : fromMe
            ? ' you'
            : ` ${publicProfiles.find(p => p.did === deletedByUserDid)?.username || ' unknown'}`
        }
      </div>
    </div>
  )

  const messageIsWYSIWYG = /^<(h\d|p|ul|figure)/.test(messageContent)

  return <div className={'OrganizationChatPage-message-container'}
    style={{ display: renderAsSingleImage || group.length  > 1 || !isImageMessage ? 'block' : 'none' }}>
    {forwarded_from_message_uid &&
     <p style={{
       color:'grey',
       textAlign:"right",
       margin:0
     }}>
       <Icon type="showInChat" /> Forwarded</p>}
    <div className={`OrganizationChatPage-Bubble-details-container ${fromMe ? 'fromMe' : ''}`}>

      {sendToMainChat && (
        <div className="OrganizationChatPage-message-repliedToAThread">
          <Icon type="markAsRead" size="sm" />
          {!isThread ? (
            <span>Replied to a thread</span>
          ) : (
            <span>Also Sent to Chat</span>
          )}
        </div>
      )}
      <div
        className={`OrganizationChatPage-Bubble-details${fromMe ? '-fromMe' : ''} ${lastInGroup ? 'lastInGroup' : ''}`}>
        {starredMessagesIds?.has(uid) && <span className="OrganizationChatPage-Bubble-starred-label">
          <Icon type="starred" size="sm" />
        </span>}
        {!fromMe && lastInGroup && !isOneToOne && (
          <Subtext className={'OrganizationChatPage-Bubble-details-displayName'}>
            {publicProfile?.displayName || publicProfile?.username}
          </Subtext>
        )}
        <Subtext className={'OrganizationChatPage-Bubble-details-time'}>
          <Timestamp time={createdAt} format="time" />
        </Subtext>
        <div className="OrganizationChatPage-ChatMessage-status">
          {fromMe && (
            pending ? (
              error ? (
                <ChatMessageFailed {...{ error, message, upload }} />
              ) : (
                <Spinner />
              )
            ) : (
              isReadByRecipient ? (
                <div className="OrganizationChatPage-Bubble-details-read">
                  <Icon size="sm" type="read" />
                </div>
              ) : (
                <div className="OrganizationChatPage-Bubble-details-read">
                  <Icon size="sm" type="messageSent" />
                </div>
              )
            )
          )}
        </div>
      </div>
    </div>
    <div {...{ className }} id={`message-${uid}`}>

      {!fromMe && (
        <Fragment>
          {lastInGroup && (
            <Link href={publicProfile && publicProfileToPathname(publicProfile)}>
              <EndUserAvatar
                {...{
                  publicProfile,
                  size: "sm",
                  style: { display: lastInGroup ? "block" : "none" },
                }}
              />
            </Link>
          )}
        </Fragment>
      )}
      {isDeleted ? (
        renderDeletedMessage
      ) : upload ? (
        <UploadingFile {...upload} />
      ) : isImageMessage ? (
        renderAsSingleImage ? (
          <div className="OrganizationChatPage-Bubble">
            <FileAttachment {...fileAttachment} handleClickImage={handleImageClick} />
            {viewImage && (
              <Modal onClose={() => setViewImage(false)} >
                <MediaSlideshow
                  slides={slideImage}
                  onClose={() => setViewImage(false)}
                />
              </Modal>
            )}
            <Header size="md">
              {Filecaption}
            </Header>
          </div>
        ) : isGroupedImage ? (
          <div className={`OrganizationChatPage-Bubble
          ${group.length === 3 ? 'OrganizationChatPage-grouped-three-images' :
          group.length > 1 ? 'OrganizationChatPage-grouped-images' : ''}`}>
            {displayImages.map((img, index) => (
              <FileAttachment key={index} imageGroup={img} handleClickImage={() => onToggleGrouping(groupId)} />
            ))}
            { moreImagesCount > 0 && (
              <div className="more-indicator" onClick={() => onToggleGrouping(groupId)}>+{moreImagesCount}</div>
            )}
            <Header size="md">
              {Filecaption}
            </Header>
          </div>
        ) : null
      ) : (
        <div className={`OrganizationChatPage-Bubble ${fromMe ? 'fromMe' : 'from-others'}`} title={timestamp}>
          {urlPreview && (
            <div className="RichMediaInput-urlPreview ">
              <ChatUrlPreview {...{
                ...urlPreview,
                canClosed: false,
                sendUrlPreview: true,
              }} />
            </div>
          )}
          {messageIsWYSIWYG ? (
            <WYSIWYGContent source={messageContent} />
          ) : (
            <PlainText text={messageContent} />
          )}
          {isEdited || editted_original_message && <span className="edited-label">edited</span>}
        </div>
      )}

      <div className={dropdownClassName} onClick={toggleDropdown} ref={dropdownRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-menu-vertical-dots"
        >
          <circle cx="12" cy="12" r="1" />
          <circle cx="12" cy="5" r="1" />
          <circle cx="12" cy="19" r="1" />
        </svg>
        {showDropdown && (
          <ul className="dropdown-menu">
            {hasVisibleItems ? (
              menuItems.map(
                (item, index) =>
                  item.condition && (
                    <li key={index}>
                      <span
                        onClick={item.onClick}
                        className="dropdown-option"
                      >
                        {item.label}
                      </span>
                    </li>
                  )
              )
            ) : (
              <li className="dropdown-option">
                <span
                  style={{
                    color: "grey",
                    fontStyle: "italic",
                    display: "block",
                  }}
                >
                No options available
                </span>
              </li>
            )}
          </ul>
        )}
        {showDeletionModal && (
          <ChatMessageDeletionModal
            handleConfirmDelete={handleConfirmDelete}
            onCancel={() => setShowDeletionModal(false)}
            selectedReason={selectedReason}
            customReason={customReason}
            setSelectedReason={setSelectedReason}
            setCustomReason={setCustomReason}
            showDeletionModal={showDeletionModal}
            setShowDeletionModal={setShowDeletionModal}
            isAdmin={isAdmin}
            isCurator={isCurator}
            adminComment={adminComment}
            setAdminComment={setAdminComment}
          />
        )}
      </div>

      {!isDeleted && (
        <div className="reaction-icon-hover" onClick={toggleCommonEmojiPicker}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-smile"
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 14s1.5 2 4 2 4-2 4-2" />
            <line x1="9" y1="9" x2="9.01" y2="9" />
            <line x1="15" y1="9" x2="15.01" y2="9" />
          </svg>
        </div>
      )}
      <div className="OrganizationChatPage-EmojiPicker">
        {showCommonEmojis && renderCommonEmojis()}
        {showEmojiPicker && (
          <div
            ref={emojiPickerRef}
            className={`picker-emojis ${pickerPositionClass}`}
          >
            <Picker onEmojiClick={onEmojiSelect} emojiStyle="native" />
          </div>
        )}
      </div>
    </div>

    {sendToMainChat && !isThread && (
      <div className={`OrganizationChatPage-Bubble-view-newer-replies ${fromMe ? 'fromMe' : ''}`}>
        <Header
          subtle
          centered
          className="OrganizationChatPage-createdBy"
          onClick={() => {
            takeAction('chat.openThread', chatChannelUid, replyToMessageUid)
            closeStarredMessagesModal()
            openThreadRepliesModal()
          }
          }
        >
          open in thread
        </Header>
      </div>
    )}
    <ThreadRepliesNumber {...{ replyCount, replies, fromMe, openThreadRepliesModal, chatChannelUid, uid }} />

    <div
      className={`OrganizationChatPage-Bubble-details${
        fromMe ? "-fromMe" : ""
      } ${lastInGroup ? "lastInGroup" : ""}`}
    >
      <div
        className="OrganizationChatPage-ChatMessage-chatdetails-lastgroup"
        style={{ display: "block" }}
      >
        <div className="OrganizationChatPage-EmojiPicker">
          {renderReactionsSummary()}
        </div>
      </div>
    </div>
    {totalReactionsCount > 3 && showReactionsDetail && (

      <ReactionsDetails
        messageUid={uid}
        reactionsDetail={aggregatedReactions}
        displayNameMap={displayNameMap}
        handleClose={handleCloseModal}
        publicProfiles={publicProfiles}
        open={handleOpenModal}
        handleRemoveReaction={handleRemoveReaction}
      />
    )}
  </div>
}
