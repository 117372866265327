import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import TextArea from 'components/TextArea'
import Switch from 'components/Switch'
import Tooltip from 'components/Tooltip'
import './index.sass'

export default class TextAreaWithSwitch extends Component {

  static propTypes = {
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    textAreaDisabled: PropTypes.bool.isRequired,
    textAreaUnsaved: PropTypes.bool.isRequired,
    switchDisabled: PropTypes.bool.isRequired,
    switchUnsaved: PropTypes.bool.isRequired,
    maxLines: PropTypes.number,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    onTextAreaChange: PropTypes.func,
    onTextAreaInput: PropTypes.func,
    onSwitchChange: PropTypes.func,
    onTextAreaClick: PropTypes.func,
    className: PropTypes.string,
    textAreaClassName: PropTypes.string,
    switchTooltip: PropTypes.bool.isRequired,
    tooltipText: PropTypes.string,
    autocomplete: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
    textAreaDisabled: false,
    textAreaUnsaved: false,
    switchDisabled: false,
    switchUnsaved: false,
    switchTooltip: false,
  }

  focus = () => {
    this.textArea.focus()
  }

  render(){
    const {
      disabled,
      textAreaDisabled,
      switchDisabled,
      textAreaUnsaved,
      switchUnsaved,
      value,
      placeholder,
      checked,
      onTextAreaChange,
      onTextAreaInput,
      onSwitchChange,
      onTextAreaClick,
      textAreaClassName,
      name,
      maxLines,
      switchTooltip,
      tooltipText,
      autocomplete,
      ...props
    } = this.props

    let className = `TextAreaWithSwitch ${this.props.className || ''} `
    let switchElement = <Switch
      disabled={switchDisabled || disabled}
      unsaved={switchUnsaved}
      checked={!!checked}
      onChange={onSwitchChange}
    />
    if (switchTooltip) {
      className += `TextInputWithSwitch-switchTooltip`
      switchElement = <Tooltip onClick={this.focus} text={tooltipText}>
        {switchElement}
      </Tooltip>
    }

    return <div className={className} {...props}>
      <TextArea
        ref={node => { this.textArea = node }}
        name={name}
        className={textAreaClassName}
        disabled={textAreaDisabled || disabled}
        unsaved={textAreaUnsaved}
        value={value}
        placeholder={placeholder}
        onChange={onTextAreaChange}
        onInput={onTextAreaInput}
        onClick={onTextAreaClick}
        maxLines={maxLines}
        autocomplete={autocomplete}
      />
      {switchElement}
    </div>
  }
}
