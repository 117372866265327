import { h, Fragment } from 'preact'
import { useState, useRef, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import useAutoFocus from 'lib/useAutoFocusHook'

import { usePasswordReset } from 'resources/password'

import Header from 'components/Header'
import Link from 'components/Link'
import Form from 'components/Form'
import FatSubmitButton from 'components/FatSubmitButton'
import VerificationForm from 'components/VerificationForm'
import APortalAuthForm from 'components/APortalAuthForm'
import Captcha from 'components/Captcha'

export default function APortalPasswordResetForm({
  className, onCancel, ...props
}){
  const passwordReset = usePasswordReset()
  const {
    cancelPasswordReset,
    passwordResetRequested,
    verifiedPasswordResetCode,
  } = passwordReset
  const cancel = useCallback(
    () => {
      cancelPasswordReset()
      if (onCancel) onCancel()
    },
    [cancelPasswordReset, onCancel]
  )
  return <div className={classNames('APortalPasswordResetForm', { className })}>
    <Header size="lg" centered underlined>Reset your password</Header>
    {h(
      verifiedPasswordResetCode ? ChangePasswordForm :
      passwordResetRequested ? VerifyPasswordResetCodeForm :
      RequestPasswordResetForm,
      {...props, ...passwordReset}
    )}
    <Form.ButtonRow centered>
      <Link onClick={cancel} type="subtle">cancel</Link>
    </Form.ButtonRow>
  </div>
}

APortalPasswordResetForm.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
}

function RequestPasswordResetForm({
  disabled,
  login,
  setLogin,
  requestPasswordReset,
  requestingPasswordReset,
}){
  const [captchaSolve, setCaptchaSolve] = useState('')
  const loginInputRef = useRef()
  useAutoFocus(loginInputRef)
  const submitting = !!requestingPasswordReset
  const submittable = !!(login && captchaSolve)
  return <Form {...{
    disabled,
    submitting,
    onSubmit(){
      if (submittable) requestPasswordReset({ login, captchaSolve })
    }
  }}>
    <APortalAuthForm.LoginInputRow {...{
      ref: loginInputRef,
      value: login,
      onInput: setLogin,
    }}/>
    <Form.Row>
      <Form.Item>
        <Captcha {...{
          value: captchaSolve,
          onChange: setCaptchaSolve,
          submitting,
        }}/>
      </Form.Item>
    </Form.Row>
    <FatSubmitButton {...{
      disabled: !submittable,
      submitting: requestingPasswordReset,
      value: ['Reset password', 'Reseting password'],
    }}/>
  </Form>
}

function VerifyPasswordResetCodeForm({
  passwordResetRequested: { login },
  verifyPasswordResetCode,
  verifyingPasswordResetCode,
}){
  return <Fragment>
    <p>{
      `If an account with ${login} exists; we've sent a verification` +
      ` code it. Please enter that code here to reset your password.`
    }</p>
    <VerificationForm {...{
      onSubmit(code){
        verifyPasswordResetCode({ login, code })
      },
      submitting: !!verifyingPasswordResetCode,
    }}/>
  </Fragment>
}

function ChangePasswordForm({
  changePassword,
  changingPassword,
}){

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const passwordInputRef = useRef()
  useAutoFocus(passwordInputRef)
  const disabled = !!changingPassword
  return <Form {...{
    disabled,
    onSubmit(){
      changePassword({ password, passwordConfirmation })
    }
  }}>
    <APortalAuthForm.Passwords {...{
      disabled,
      password,
      setPassword,
      passwordConfirmation,
      setPasswordConfirmation,
      showPassword,
      setShowPassword,
    }}/>
    <FatSubmitButton {...{
      submitting: changingPassword,
      value: ['Change password', 'Changing password'],
    }}/>
  </Form>
}


