import { h } from 'preact'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import classNames from 'lib/classNames'
import { usePortal } from 'lib/portal'

import Alert from 'components/Alert'
import './index.sass'

const ANIMATION_DURATION = 200

export default function PageAlerts({className, alerts, onDismiss}){
  return usePortal()(
    <TransitionGroup className={classNames('PageAlerts', { className })}>
      {alerts.map(alert =>
        <CSSTransition
          key={alert.id || alert.key}
          timeout={ANIMATION_DURATION}
          classNames="PageAlerts-transition"
        >
          <Alert {...{
            type: alert.type,
            title: alert.name,
            onDismiss(){ onDismiss(alert) },
          }}>{alert.message}</Alert>
        </CSSTransition>
      ).reverse()}
    </TransitionGroup>
  )
}

PageAlerts.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
