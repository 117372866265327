export function generateLinkToMap(...args){
  return (
    // `https://www.google.com/maps/search/` +
    `https://wego.here.com/search/` +
    encodeURIComponent(
      args.filter(x => typeof x === 'string').join(' ')
    )
  )
}

export function generateLinkGoogleMapsForOrganization(organization){
  return generateLinkToMap(
    [
      organization.address,
      organization.city,
      organization.state,
      organization.post_code,
      organization.country,
    ].filter(x => x).join(' ')
  )
}
