export default function sortBy(collection, iteratees = []){
  if (!Array.isArray(iteratees)) iteratees = [iteratees]
  iteratees = iteratees.map(iteratee =>
    typeof iteratee === 'string' ? (m => m[iteratee]) : iteratee
  )
  return Array.from(collection).sort((a, b) => {
    for (const iteratee of iteratees){
      const av = iteratee(a)
      const bv = iteratee(b)
      if (av === bv) continue
      const r = (
        typeof av === 'string' ? av.localeCompare(bv) :
        av - bv
      )
      if (r) return r
    }
    return 0
  })
}
