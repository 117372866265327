import { isLoggedIn, getCurrentUser } from '../resources/auth'
import api from '../api'
import { tx, loadEntity } from 'lib/actionHelpers'

const membershipsAppStateKeyFor = organizationApikey =>
  `organization:${organizationApikey}:memberships`

export async function loadMine(){
  if (this.getState()['myOrganizationMemberships:loaded']) return
  this.takeAction('organizationMemberships.reloadMine')
}

export async function reloadMine(){
  if (!isLoggedIn()) return
  const loadingKey = 'myOrganizationMemberships:loading'
  const errorKey = loadingKey + ':error'
  return await tx.call(this, loadingKey, errorKey, async () => {
    const organizationMemberships = await api.getOrganizationMemberships()
    this.setState({ 'myOrganizationMemberships:loaded': true })
    this.takeAction(
      'organizationMemberships.addMembershipsToAppState', organizationMemberships,
    )
  })
}

export async function loadSuggestedHubs(profileDids) {
  const loadingKey = 'suggestedHubs:loading'
  const errorKey = loadingKey + ':error'
  if (!isLoggedIn()) return

  return await tx.call(this, loadingKey, errorKey, async () => {
    const suggestedHubs = await api.getSuggestedHubs(profileDids)
    this.setState({ 'suggestedHubs:data': suggestedHubs.suggested })
  })
}


export async function create({
  organizationApikey,
  inviteToken,
  memberUserDid,
}){
  if (!organizationApikey) throw new Error(`organizationApikey is required`)

  const { creatingMembership } = this.getState()

  if (creatingMembership) {
    console.warn(`already creating membership for organization ${creatingMembership}`)
    return
  }
  this.setState({
    creatingMembership: {
      organizationApikey,
      inviteToken,
    },
    errorCreatingMembership: undefined,
  })

  try {
    const { organizationMembership, organizationFeedSubscription } = await api.createOrganizationMembership({
      organizationApikey, inviteToken, memberUserDid
    })
    this.takeAction('organizationMemberships.addMembershipsToAppState', [organizationMembership])
    if (organizationFeedSubscription) {
      this.takeAction(
        'feedSubscriptions.addOrganizationFeedSubscriptionsToAppState', [organizationFeedSubscription],
      )
    }
    if (inviteToken) {
      this.setState({
        [`organizationMembershipInvite:${inviteToken}`]: undefined,
        [`organizationMembershipInviteTo:${organizationApikey}`]: undefined,
      })
    }
    /*
      !! THIS SUCKS !!
      TODO remove this lame hack. This is a lame fix for a cache invalidation of organization memberships
    */
    this.takeAction('organizations.reload', [organizationMembership.organizationApikey])
  } catch(errorCreatingMembership) {
    this.setState({ errorCreatingMembership })
  } finally {
    this.setState({ creatingMembership: undefined })
  }
}

export async function update({ organizationMembership, changes }){
  if (!organizationMembership) throw new Error(`membership is required`)

  const updatingKey = `organizationMembership:${organizationMembership.uid}:updating`
  const errorUpdatingKey = `${updatingKey}:error`

  if (this.getState()[updatingKey]) return

  this.setState({
    [updatingKey]: true,
    [errorUpdatingKey]: undefined,
  })

  try {
    const result = await api.updateOrganizationMembership({
      organizationApikey: organizationMembership.organizationApikey,
      organiztionMembershipUid: organizationMembership.uid,
      changes,
    })
    this.takeAction('organizationMemberships.addMembershipsToAppState', [result.organizationMembership])
    if (result.notification) this.takeAction('notifications.addNotificationsToAppState', [result.notification])
    if (result.organizationFeedSubscription) {
      this.takeAction(
        'feedSubscriptions.addOrganizationFeedSubscriptionsToAppState', [result.organizationFeedSubscription],
      )
    }
    /*
      !! THIS SUCKS !!
      TODO remove this lame hack. This is a lame fix for a cache invalidation of organization memberships
    */
    this.takeAction('organizations.reload', [organizationMembership.organizationApikey])
  } catch(errorUpdatingOrganizationMembership) {
    this.setState({ [errorUpdatingKey]: errorUpdatingOrganizationMembership })
  } finally {
    this.setState({ [updatingKey]: undefined })
  }
}

export async function remove({ organizationMembership }){
  if (!organizationMembership) throw new Error(`organizationMembership is required`)

  const { removingMembership } = this.getState()

  if (removingMembership) return

  this.setState({
    removingMembership: organizationMembership,
    errorRemovingMembership: undefined,
  })

  const organizationApikey = organizationMembership.organizationApikey

  try {
    await api.deleteOrganizationMembership({
      organizationApikey, membershipUid: organizationMembership.uid
    })
    this.takeAction('organizationMemberships.removeMembershipsFromAppState', [organizationMembership])
    const organization = this.getState()[`organization:${organizationApikey}`]
    if (organization.is_private)
      this.takeAction('feedSubscriptions.removeForOrg', organizationApikey)
  } catch(errorRemovingMembership) {
    this.setState({ errorRemovingMembership })
  } finally {
    this.setState({ removingMembership: undefined })
  }
}

export async function loadFormerMemberships(organizationApikey, canEdit) {
  if (!organizationApikey) {
    console.error('Organization API key is required for fetching former memberships.')
    return
  } else if (!canEdit) {
    console.error('User does not have permission to fetch former memberships.')
    return
  }

  return loadEntity.call(this, {
    reload: true,
    entityKey: `organization:${organizationApikey}:formerMemberships`,
    request: async () =>  {
      try {
        const response = await api.getFormerMemberships(organizationApikey)
        return response
      } catch (error) {
        console.error('Error fetching former memberships for organization:', error)
      }
    }
  },
  )
}

function getOrganizationApikeysFromMemberships(memberships){
  const organizationApikeys = new Set()
  memberships.forEach(membership => {
    organizationApikeys.add(membership.organizationApikey)
    if (membership.memberOrganizationApikey) organizationApikeys.add(membership.memberOrganizationApikey)
  })
  return organizationApikeys
}

function alterMembershipsInAppState(memberships, addOrDelete){
  const currentUser = getCurrentUser()
  const myPublicProfileDid = currentUser && currentUser.publicProfileDid

  const appState = this.getState()

  const newState = {}
  if (myPublicProfileDid)
    newState.myOrganizationMemberships = new Set(appState.myOrganizationMemberships)
  const organizationApikeys = getOrganizationApikeysFromMemberships(memberships)

  Array.from(organizationApikeys).forEach(organizationApikey => {
    const membershipsKey = membershipsAppStateKeyFor(organizationApikey)
    newState[membershipsKey] = new Set(appState[membershipsKey])
  })

  memberships.forEach(membership => {
    newState[`membership:${membership.uid}`] = membership
    if (
      myPublicProfileDid &&
      newState.myOrganizationMemberships &&
      membership.memberUserDid === myPublicProfileDid
    ){
      newState.myOrganizationMemberships[addOrDelete](membership.uid)
    }
    if (membership.organizationApikey){
      newState[membershipsAppStateKeyFor(membership.organizationApikey)][addOrDelete](membership.uid)
    }
    if (membership.memberOrganizationApikey){
      newState[membershipsAppStateKeyFor(membership.memberOrganizationApikey)][addOrDelete](membership.uid)
    }
  })

  this.setState(newState)
}

export function addMembershipsToAppState(memberships){
  alterMembershipsInAppState.call(this, memberships, 'add')
}

export function removeMembershipsFromAppState(memberships){
  alterMembershipsInAppState.call(this, memberships, 'delete')
}


export async function loadMutedUsers(organizationDid) {
  if (!organizationDid) {
    console.error('Organization DID is required for fetching muted users.')
    return
  }

  return loadEntity.call(this, {
    reload: true,
    entityKey: 'mutedUsers',
    request: async () => {
      try {
        const response = await api.getMutedUsers(organizationDid)
        return response.mutedUsers
      } catch (error) {
        console.error('Error fetching muted users for organization:', error)
      }
    },
  })
}


export function alterMembershipRequestsInAppState(membershipRequests, addOrDelete){
  const appState = this.getState()
  const newState = {
    myPendingOrganizationMemberships: new Set(appState.myPendingOrganizationMemberships),
  }
  membershipRequests.forEach(membershipRequest => {
    newState[`membershipRequest:${membershipRequest.uid}`] = membershipRequest
    newState.myPendingOrganizationMemberships[addOrDelete](membershipRequest.uid)
  })
  this.setState(newState)
}

export function addMembershipRequestsToAppState(membershipRequests){
  alterMembershipRequestsInAppState.call(this, membershipRequests, 'add')
}
export function removeMembershipRequestsFromAppState(membershipRequests){
  alterMembershipRequestsInAppState.call(this, membershipRequests, 'delete')
}

export async function getForPublicProfile(publicProfileDid) {
  const membershipUidsKey = `user:${publicProfileDid}:memberships`
  const loadingKey = `${membershipUidsKey}:loading`
  const errorKey = `${loadingKey}:error`
  return await tx.call(this, loadingKey, errorKey, async () => {
    const {
      organizationMemberships,
      organizations,
    } = await api.getMembershipsForPublicProfile({ publicProfileDid })
    this.addToSet(membershipUidsKey, organizationMemberships.map(m => m.uid))
    this.takeAction('organizationMemberships.addMembershipsToAppState', organizationMemberships)
    this.takeAction('organization.addOrganizationsToAppState', organizations)
  })
}

export async function hideOrganizationMembership({ organizationApikey, memberUserDid }) {
  const updatingKey = `membership:${organizationApikey}:${memberUserDid}:hiding`
  const errorUpdatingKey = `${updatingKey}:error`

  if (this.getState()[updatingKey]) return

  this.setState({
    [updatingKey]: true,
    [errorUpdatingKey]: undefined,
  })

  try {
    await api.hideOrganizationMembership({ organizationApikey, memberUserDid })
    const hiddenStateKey = `membership:${organizationApikey}:${memberUserDid}:hidden`
    this.setState({ [hiddenStateKey]: true })
    await this.reloadMine()
  } catch (error) {
    this.setState({ [errorUpdatingKey]: error })
  } finally {
    this.setState({ [updatingKey]: undefined })
  }
}

export async function unhideOrganizationMembership({ organizationApikey, memberUserDid }) {
  const updatingKey = `membership:${organizationApikey}:${memberUserDid}:unhiding`
  const errorUpdatingKey = `${updatingKey}:error`

  if (this.getState()[updatingKey]) return

  this.setState({
    [updatingKey]: true,
    [errorUpdatingKey]: undefined,
  })

  try {
    await api.unhideOrganizationMembership({ organizationApikey, memberUserDid })
    const hiddenStateKey = `membership:${organizationApikey}:${memberUserDid}:hidden`
    this.setState({ [hiddenStateKey]: false })
    await this.reloadMine()
  } catch (error) {
    this.setState({ [errorUpdatingKey]: error })
  } finally {
    this.setState({ [updatingKey]: undefined })
  }
}

export async function updateMembershipsDisplayOrder({ organizationApikey, displayOrder, memberUserDid }) {
  const updatingKey = `organization:${organizationApikey}:membershipsByDisplayOrder:updating`
  const errorUpdatingKey = `${updatingKey}:error`

  if (this.getState()[updatingKey]) return
  this.setState({
    [updatingKey]: true,
    [errorUpdatingKey]: undefined,
  })

  try {
    await api.updateMembershipDisplayOrder({ organizationApikey, displayOrder, memberUserDid })
  } catch (errorUpdatingDisplayOrder) {
    this.setState({ [errorUpdatingKey]: errorUpdatingDisplayOrder })
  } finally {
    this.setState({ [updatingKey]: undefined })
  }
}
