import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { useAppState } from 'lib/appState'

export function useNotificationUids(componentName){
  const { notificationUids } = useAppState({'notifications:uids': 'notificationUids'}, componentName)
  return { notificationUids }
}

export function useNotifications(componentName) {
  const { notificationUids } = useNotificationUids(componentName)
  const notificationKeys = {}
  if (notificationUids) notificationUids.forEach(uid => {
    notificationKeys[`notification:${uid}`] = `notification:${uid}`
  })

  const {
    takeAction: _,
    appAction,
    loadingNotifications,
    loadingNotificationsError,
    lastPageOfNotifications,
    numberOfUnreadNotifications = 0,
    ...notificationsInAppStore
  } = useAppState(
    {
      ...notificationKeys,
      'notifications:loading': 'loadingNotifications',
      'notifications:loading:error': 'loadingNotificationsError',
      'notifications:lastPage': 'lastPageOfNotifications',
      'notifications:unread': 'numberOfUnreadNotifications',
    },
    componentName
  )

  const notifications = []
  if (notificationUids) notificationUids.forEach(uid => {
    const notification = notificationsInAppStore[`notification:${uid}`]
    if (!notification) {
      console.warn(`notification uid="${uid}" not found in appState`)
      return
    }
    notifications.push(notification)
  })

  const loadNotifications = appAction('notifications.load')
  const markNotificationAsSeen = appAction('notifications.markAsSeen')

  useEffect(
    () => {
      if (notificationUids === undefined && !loadingNotifications)
        loadNotifications()
    },
    []
  )

  return {
    loadingNotifications,
    loadingNotificationsError,
    lastPageOfNotifications,
    notificationUids,
    notifications,
    numberOfUnreadNotifications,
    loadNotifications,
    markNotificationAsSeen,
  }
}
