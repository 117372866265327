import { h } from 'preact'

import { useRedirect } from 'resources/location'

import Loading from 'components/Loading'

export default function OrganizationAnnouncementsPage(props){
  /*
   * This page is here to so the users coming to see
   * announcements will see that they need to login
   */
  useRedirect({ to: `/${props.organizationApikey}/forum?ancmt=1`, replace: true })
  return <Loading className="OrganizationAnnouncementsPage" type="fullPage" />
}
