import { h, Fragment } from 'preact'
import { memo, useEffect, useState } from 'preact/compat'
import PropTypes from 'prop-types'

import { useFeedPostDeletingReasons } from 'lib/feedPostHooks'

import Button from 'components/Button'
import FormBuilder from 'components/FormBuilder'
import Form from 'components/Form'
import './index.sass'
import { useAppState } from 'lib/appState'

export default function OrganizationForumFeedPostDeleteModal({
  className = '',
  post,
  ...props
}){
  return <div
    {...props}
    className={`OrganizationFeedPostDelete ${className}`}
  >
    <Contents {...{post}} />
  </div>
}

OrganizationForumFeedPostDeleteModal.propTypes = {
  className: PropTypes.string,
  feedPostUid: PropTypes.string.isRequired,
  post: PropTypes.object.isRequired,
}

const Contents = memo(({ post }) => {

  const {takeAction, deleting} = useAppState([], 'OrganizationForumFeedPostDeleteModal')

  const { reasons, loadingKey } = useFeedPostDeletingReasons('OrganizationForumFeedPostDeleteModal')

  const [formState, setFormState] = useState({ selectedReason: '', message: '', sendNotification: false })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(!!loadingKey)
  }, [loadingKey])

  const handleReasonChange = (e) => {
    setFormState({ ...formState, selectedReason: e.target.value })
  }

  const handleMessageChange = (e) => {
    setFormState({ ...formState, message: e.target.value })
  }

  const handleSubmit = () => {
    const { selectedReason, message, sendNotification } = formState
    takeAction('feedPosts.deletePost', { post, comment: message, reason: selectedReason, sendNotification })
  }

  const handleSendNotificationOrNot = (e) => {
    setFormState({ ...formState, sendNotification: e.target.value === 'true' })
  }

  return (
    <FormBuilder {...{
      onSubmit: handleSubmit,
      className: 'OrganizationForumFeedPostDeleteModal__form',
      render: form => (
        <Fragment>
          <Form.Row>
            {form.item({
              label: 'Delete Reason',
              isLoading: loadingKey,
              input: (
                <select
                  name="options"
                  onChange={handleReasonChange}
                  className="OrganizationForumFeedPostDeleteModal__select-field"
                  value={formState.selectedReason}
                >
                  {isLoading && loadingKey}
                  <option value="">Choose an Option</option>
                  {reasons && reasons.map((reason) => (
                    <option key={reason.id} value={reason.reason}>{reason.reason}</option>
                  ))}
                </select>
              ),
              required: true,
            })}
          </Form.Row>
          <Form.Row>
            <textarea
              name="message"
              className="OrganizationForumFeedPostDeleteModal__message-field"
              placeholder="Enter a message to the user (optional)"
              onChange={handleMessageChange}
              value={formState.message}
            />
          </Form.Row>
          <Form.Row>
            {form.item({
              label: 'Send Notification',
              input: (
                <select
                  name="sendNotification"
                  className="OrganizationForumFeedPostDeleteModal__select-field"
                  onChange={handleSendNotificationOrNot}
                  value={formState.sendNotification ? 'true' : 'false'}
                >
                  <option value="true">Send a notification to all users shared this post</option>
                  <option value="false">Do not send a notification</option>
                </select>
              ),
              required: true,
            })
            }
          </Form.Row>
          <Form.Row className={'OrganizationForumFeedPostDeleteModal__buttons'}>
            <Button
              type="primary"
              disabled={deleting}
              loading={deleting}
              onClick={handleSubmit}
            >
              Delete and Notify User
            </Button>
          </Form.Row>
        </Fragment>
      ),
    }} />
  )
})
