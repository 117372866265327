import { h, Fragment} from 'preact'
import { useState, useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import useToggle from 'lib/useToggleHook'
import useAutoFocus from 'lib/useAutoFocusHook'

import { useLogin, loginAs } from 'resources/auth'
import { useResettingPassword } from 'resources/password'

import Link from 'components/Link'
import Form from 'components/Form'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import TextInput from 'components/TextInput'
import Checkbox from 'components/Checkbox'
import APortalAuthForm from 'components/APortalAuthForm'
import LinkToOrganization from 'components/LinkToOrganization'
import APortalPasswordResetForm from 'components/APortalPasswordResetForm'
import { startAuthentication, browserSupportsWebAuthn, browserSupportsWebAuthnAutofill } from '@simplewebauthn/browser'


import './index.sass'
import api from '../../a-portal/api'

const webAuthnLogin = async (joinOrganization, autofill = false) => {
  const res = await api.generateAuthenticationOptions()
  try {
    const asseResp = await startAuthentication(res.loginOpts, autofill)
    const data = {
      login:"passkey",
      password:{asseResp:JSON.parse(JSON.stringify(asseResp)), sessionId:res.sessionId},
      joinOrganization,
    }
    loginAs(data)
  } catch (error) {
    console.log("error", error)
  }
}

export default function APortalLoginForm({ className, ...props }){
  const [
    resettingPassword,
    showResetPasswordForm,
    hideResetPasswordForm,
  ] = useResettingPassword()
  return <APortalAuthForm {...{
    className: classNames('APortalLoginForm', { className }),
  }}>
    {resettingPassword
      ? <APortalPasswordResetForm onCancel={hideResetPasswordForm} />
      : <LoginForm {...{
        ...props,
        showResetPasswordForm,
        hideResetPasswordForm,
      }}/>
    }
  </APortalAuthForm>
}

APortalLoginForm.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  destinationPath: PropTypes.string,
  organization: PropTypes.shape({
    apikey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

function LoginForm({
  showResetPasswordForm,
  disabled,
  organization,
  destinationPath,
  requireRecentLogin = false
}){
  const joinOrganization = organization && organization.apikey
  const loginInputRef = useRef()
  useAutoFocus(loginInputRef)
  const [showPassword, , , setShowPassword] = useToggle(false)
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [require2FA, setRequire2FA] = useState('')
  const [isBrowserSupportsWebAuthn, setIsBrowserSupportsWebAuthn] = useState(false)
  const {
    login,
    setLogin,
    keepMeLoggedIn,
    setKeepMeLoggedIn,
    loggingIn,
  } = useLogin()
  disabled = !!(loggingIn)


  useEffect(() => {
    const generateAuthenticationOptions = async () => {
      try {
        if (!browserSupportsWebAuthn()) return
        setIsBrowserSupportsWebAuthn(true)
        const isCMA = await browserSupportsWebAuthnAutofill()
        if (!isCMA) return
        await webAuthnLogin(joinOrganization, true)
      } catch (error) {
        console.error(error)
      }

    }
    generateAuthenticationOptions()

  }, [])

  return !require2FA ? <Form {...{
    disabled,
    onSubmit(){
      loginAs({
        login,
        password,
        joinOrganization,
        setRequire2FA,
      })
    },
  }}>
    <APortalAuthForm.Intro>
      {!requireRecentLogin && <APortalAuthForm.Logo {...{organization}}/> }
      {organization
        ? <p>{requireRecentLogin ? 'Login Again' : 'Login'} to join
          <LinkToOrganization {...{type: 'none', organization}}/></p>
        : <p>{requireRecentLogin ? 'Login Again' : 'Login'} to {APP_NAME}</p>
      }
    </APortalAuthForm.Intro>
    <APortalAuthForm.LoginInputRow {...{
      ref: loginInputRef,
      value: login,
      onInput: setLogin,
    }}/>
    <Form.Row className="APortalLoginForm-passwordRow">
      <Form.Item>
        <Form.Label>PASSWORD</Form.Label>
        <Link
          className="APortalLoginForm-ForgotPasswordLink"
          type="text"
          onClick={showResetPasswordForm}
        >forgot password?</Link>
        <TextInput {...{
          value: password,
          onInput: setPassword,
          disabled,
          type: showPassword ? 'text' : 'password',
          name: 'password',
          autocomplete: 'current-password webauthn',
          required: true,
          label: false,
          lpignore: false,
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Form.Item>
        <Checkbox {...{
          checked: showPassword,
          onChange: setShowPassword,
          disabled,
          label: 'Show password',
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Form.Item>
        <Checkbox {...{
          value: keepMeLoggedIn,
          onChange: setKeepMeLoggedIn,
          disabled,
          label: 'Keep me logged in',
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Button submit fat block {...{
        disabled,
        type: 'primary',
        value: (
          loggingIn
            ? <span><Spinner />&nbsp;Logging in…</span>
            : 'Login' + (organization ? ` and join ${organization.name}` : '')
        ),
      }}/>
    </Form.Row>
    {isBrowserSupportsWebAuthn && (
      <>
        <Form.Row>
          <div style={{textAlign:"center", width:"100%", padding:"10px 0 10px 0"}}>
          Or
          </div>
        </Form.Row>
        <Form.Row>
          <Button fat block {...{
            disabled,
            onClick:async () => {
              await webAuthnLogin(joinOrganization)
            },
            type: 'primary',
            value: (
              loggingIn
                ? <span><Spinner />&nbsp;Logging in…</span>
                : 'Sign in with a passkey'
            ),
          }}/>
        </Form.Row>
      </>
    )}
    {!requireRecentLogin && <APortalAuthForm.Footer>
      <span>New to {APP_NAME}? </span>
      <Link {...{
        type: 'text',
        // href: signupHref,
        pathname: '/signup',
        query: {r: destinationPath, o: joinOrganization}
      }}>Create an Account</Link>.
    </APortalAuthForm.Footer>}
  </Form> :  <Form {...{
    disabled,
    onSubmit(){
      loginAs({
        login,
        password,
        joinOrganization,
        setRequire2FA,
        code,
      })
    },
  }}>
    <APortalAuthForm.Intro>
      <APortalAuthForm.Logo {...{organization}}/>
      {organization
        ? <p>Login to join <LinkToOrganization {...{type: 'none', organization}}/></p>
        : <p>Login to {APP_NAME}</p>
      }
    </APortalAuthForm.Intro>
    <Form.Row className="APortalLoginForm-passwordRow">
      <Form.Item>
        <Form.Label>2-FACTOR PASSWORD</Form.Label>
        <TextInput {...{
          value: code,
          onInput: setCode,
          disabled,
          type: 'text',
          name: '2-factor password',
          autocomplete: 'one-time-code webauthn',
          required: true,
          label: false,
          lpignore: false,
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Button submit fat block {...{
        disabled,
        type: 'primary',
        value: (
          loggingIn
            ? <span><Spinner />&nbsp;Logging in…</span>
            : 'Login' + (organization ? ` and join ${organization.name}` : '')
        ),
      }}/>
    </Form.Row>
    {isBrowserSupportsWebAuthn && (
      <>
        <Form.Row>
          <div style={{textAlign:"center", width:"100%", padding:"10px 0 10px 0"}}>
          Or
          </div>
        </Form.Row>
        <Form.Row>
          <Button fat block {...{
            disabled,
            onClick:async () => {
              await webAuthnLogin(joinOrganization)
            },
            type: 'primary',
            value: (
              loggingIn
                ? <span><Spinner />&nbsp;Logging in…</span>
                : 'Sign in with a passkey'
            ),
          }}/>
        </Form.Row>
      </>
    )}
  </Form>
}
