import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

export default function Navbar({
  className, children, thin, ...props
}){
  return <div
    {...props}
    className={classNames('Navbar', {thin, className })}
  >{children}</div>
}

Navbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  thin: PropTypes.bool,
}

Navbar.Space = function({ size = 1 }){
  return <div className={`Navbar-Space Navbar-Space-${size}`} />
}

Navbar.Grow = function(){
  return <div className="Navbar-Grow" />
}

Navbar.Break = function(){
  return <div className="Navbar-Break" />
}
