import { h, Fragment} from 'preact'
import { useEffect } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import { useLocation, setQuery, replaceLocation } from 'resources/location'
import { useSession } from 'resources/session'
import { useLoggedIn, logout } from 'resources/auth'
import { useInviteToOrganization } from 'resources/inviteToOrganization'
import { useOrganization } from 'lib/membershipAppStateHooks'

import Loading from 'components/Loading'
import Button from 'components/Button'
import Form from 'components/Form'
import Card from 'components/Card'
import APortalLoginForm from 'components/APortalLoginForm'
import APortalSignupForm from 'components/APortalSignupForm'
import ExternalIdentityVerificationForm from 'components/ExternalIdentityVerificationForm'

import RedirectPage from '../RedirectPage'
import './index.sass'

export default function AuthPage({...props}) {
  const loggedIn = useLoggedIn()

  const { location } = props
  props.destinationPath = location.query.r
  if (isDataYogi && !props.destinationPath) props.destinationPath = `/${PNFO.plural}`
  if (loggedIn) return <RedirectPage to={props.destinationPath || '/'} />

  const path = location.pathname.toLowerCase().split('/')[1]
  return <div className="AuthPage">
    {
      path === 'login' ? <LoginOrSignup login {...props}/> :
      path === 'signup' ? <LoginOrSignup {...props}/> :
      path === 'logout' ? <Logout {...props}/> :
      path === 'join' ? <RedirectToSignup {...props}/> :
      // path === 'reset-password' ? <ResetPassword {...props}/> :
      null
    }
  </div>
}

function LoginOrSignup(props){
  props.organizationApikey = props.location.query.o
  if (isDataYogi) props.organizationApikey = `DataYogi`
  props.organizationInviteToken = props.location.query.i
  return h(
    props.organizationInviteToken ? FormsWithInvite :
    props.organizationApikey ? FormsWithOrg :
    Forms,
    props
  )
}

function FormsWithInvite(props){
  const {
    organizationInvite,
    organizationInviteInvalid,
  } = useInviteToOrganization(props.organizationInviteToken)

  useEffect(
    () => {
      if (organizationInviteInvalid) setQuery({ i: null })
      else if (
        organizationInvite &&
        organizationInvite.organizationApikey !== props.organizationApikey
      ) setQuery({ o: organizationInvite.organizationApikey })
    },
    [organizationInviteInvalid, organizationInvite]
  )

  return organizationInvite
    ? <FormsWithOrg {...{...props, organizationInvite}} />
    : <Loading type="block"/>
}

function FormsWithOrg(props){
  const {
    organizationNotFound,
    organization,
  } = useOrganization(props.organizationApikey, 'APortalSignupForm')

  const cannotJoinOrg = organizationNotFound || (
    organization &&
    organization.closed_memberships === true &&
    organization.users_can_request_membership === false &&
    !props.organizationInvite
  )

  useEffect(
    () => {
      if (cannotJoinOrg) setQuery({ o: null, i: null })
    },
    [cannotJoinOrg]
  )

  return organization
    ? <Forms {...{...props, organization}} />
    : <Loading type="block"/>
}

function Forms({ login, ...props }){
  const loginVerificationRequired = useSession(cu => cu.loginVerificationRequired)
  return <Card className="AuthPage-Forms">
    <Card.Body>
      {loginVerificationRequired
        ? <LoginVerificationForm {...loginVerificationRequired}/>
        : h(login ? APortalLoginForm : APortalSignupForm, props)
      }
    </Card.Body>
  </Card>
}

function LoginVerificationForm({ type, value }){
  return <Fragment>
    <ExternalIdentityVerificationForm {...{
      type, value, action: 'login'
    }}/>
    <Form.ButtonRow centered>
      <Button type="link" onClick={logout}>or logout</Button>
    </Form.ButtonRow>
  </Fragment>
}

function Logout(){
  return <div>logging out…</div>
}

function RedirectToSignup(){
  const location = useLocation('AuthPage')
  useEffect(
    () => { replaceLocation(location.withPathname('/signup')) },
    [location.toString()]
  )
  return <div>redirecting to /signup…</div>
}
