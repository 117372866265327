import { h, Fragment } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import { setPageTitle } from 'lib/Page'
import { useSearchAll } from 'lib/searchHooks'

import { publicProfileToPathname } from 'lib/publicProfiles'

import ContentBox from 'components/ContentBox'
import HeaderedContentBox from 'components/HeaderedContentBox'
import Header from 'components/Header'
import Navbar from 'components/Navbar'
import Icon from 'components/Icon'
import PageTab from 'components/PageTab'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import Paginator from 'components/Paginator'
import OrganizationList from 'components/OrganizationList'
import PeopleList from 'components/PeopleList'
import OrganizationFeedPost from 'components/OrganizationFeedPost'
import './index.sass'

export default function SearchPage({ location, params }){
  let page = location.query.page ? parseInt(location.query.page, 10) : 1
  if (page < 1) page = 1
  const searchQuery = params.searchQuery ? decodeURIComponent(params.searchQuery) : ''
  setPageTitle(`Search - ${searchQuery}`)

  let {
    searching,
    searchingError,
    searchResults,
  } = useSearchAll(searchQuery, page, 'SearchPage')

  const allOnLastPage = (
    searchResults?.organizations.length < searchResults?.pageSize &&
    searchResults?.publicProfiles.length < searchResults?.pageSize &&
    searchResults?.feedPosts.length < searchResults?.postsPageSize
  )

  const filters = [
    {
      name: 'All',
      href: `/search/${params.searchQuery}`,
      all: true,
      onLastPage: allOnLastPage,
    },
    {
      name: `${PNFO.plural}`,
      href: `/search/${PNFO.plural}/${params.searchQuery}`,
      organizations: true,
      onLastPage: searchResults?.organizations.length < searchResults?.pageSize,
    },
    {
      name: 'People',
      href: `/search/members/${params.searchQuery}`,
      people: true,
      onLastPage: searchResults?.publicProfiles.length < searchResults?.pageSize,
    },
    {
      name: 'Posts',
      href: `/search/posts/${params.searchQuery}`,
      posts: true,
      onLastPage: searchResults?.feedPosts.length < searchResults?.postsPageSize,
    },
  ]

  const filter = filters.find(filter =>
    location.pathname === filter.href
  )

  const pageToHref = page => `${location.pathname}?page=${page}`

  return <div className="SearchPage">
    <Nav {...{ filters, searchQuery, page }}/>
    {
      searching ? <Loading fullPage size="md"/> :
      searchingError ? <ErrorMessage error={searchingError} /> :
      searchResults ? <SearchResults {...{ searchQuery, filter, page, searchResults }}/> :
      null
    }
    { searchResults
      ? <Paginator {...{
        currentPage: page,
        pageToHref,
        onLastPage: filters.find(filterResult => filter.name === filterResult.name).onLastPage
      }}/>
      : null
    }
  </div>
}

function Nav({ filters, searchQuery }){
  return <ContentBox>
    <Navbar>
      <Icon type="search" size="lg" blue/>
      &nbsp;
      <Header size="lg">Results for: "{searchQuery}"</Header>
      <Navbar.Grow/>
      {filters.map(filter =>
        <PageTab {...{
          value: filter.name,
          href: `${filter.href}?page=${1}`,
        }}/>
      )}
    </Navbar>
  </ContentBox>
}

function SearchResults({ searchQuery, filter = {}, page, searchResults }){
  return <Fragment>
    { (filter.all || filter.organizations)
      ? <SearchOrganizationsResults {...{ searchQuery, page, searchResults }}/>
      : null
    }
    { (filter.all || filter.people)
      ? <SearchPeopleResults {...{ searchQuery, page, searchResults }}/>
      : null
    }
    { (filter.all || filter.posts)
      ? <SearchPostsResults {...{ searchQuery, page, searchResults }}/>
      : null
    }
  </Fragment>
}

function SearchOrganizationsResults({ searchQuery, page, searchResults }){
  const organizations = searchResults && searchResults.organizations

  return <HeaderedContentBox header={`${PNFO.plural}`}>
    <OrganizationList {...{
      organizations,
      onEmpty: `No ${page > 1 ? 'more ' : ''}${PNFO.plural} found for "${searchQuery}"`,
    }}/>
  </HeaderedContentBox>
}

function SearchPeopleResults({ searchQuery, page, searchResults }){
  const publicProfiles = (searchResults && searchResults.publicProfiles || [])
    .map(publicProfile => ({
      ...publicProfile,
      href: publicProfileToPathname(publicProfile),
    }))
  return <HeaderedContentBox header="People" padded>
    {<PeopleList {...{
      people: publicProfiles,
      onEmpty: `No ${page > 1 ? 'more ' : ''}people found for "${searchQuery}"`,
    }}/>}
  </HeaderedContentBox>
}

function SearchPostsResults({ searchQuery, page, searchResults }){
  const feedPosts = searchResults && searchResults.feedPosts || []
  const empty = feedPosts.length === 0
  return <Fragment>
    <HeaderedContentBox header="Feed Posts" padded={empty}>
      {empty &&
        <Header size="sm" centered italic>
          No {page > 1 ? 'more ' : ''} feed posts for "{searchQuery}"
        </Header>
      }
    </HeaderedContentBox>
    {feedPosts.map((post, index) =>
      <OrganizationFeedPost
        {...{
          key: post.uid,
          sortBy: index,
          post,
        }}
        withinFeed
      />
    )}
  </Fragment>
}
