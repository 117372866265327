import { Fragment, h } from 'preact'
import PropTypes from 'prop-types'
import StyleishModal from 'components/StyleishModal'
import classNames from 'lib/classNames'
import { useMyPublicProfile, usePublicProfiles } from 'lib/membershipAppStateHooks'
import { useEffect, useState } from 'preact/hooks'
import EndUserAvatar from 'components/EndUserAvatar'
import Timestamp from 'components/Timestamp'
import Subtext from 'components/Subtext'
import PlainText from 'components/PlainText'
import WYSIWYGContent from 'components/WYSIWYGContent'
import { navigateToMessage } from 'components/NotificationsList'
import { useSearchInChat } from 'lib/chatHooks'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import IconButton from 'components/IconButton'
import SearchInput from 'components/SearchInput'
import { useAppActions } from 'lib/appState'
import './index.sass'


export default function ChatSearchModal({
  open,
  chatChannel: chatChannelUid,
  closeChatSearchModal,
}){

  const [searchTerm, setSearchTerm] = useState('')

  const { chatSearchResults } = useSearchInChat(chatChannelUid, searchTerm, 'ChatSearchModal')

  const { takeAction } = useAppActions('ChatSearchModal')

  useEffect(() => {

    if (searchTerm) {
      takeAction('chat.searchInChat', chatChannelUid, searchTerm)
    } else {
      takeAction('chat.searchInChat', chatChannelUid, null)
    }
  }, [searchTerm])


  const publicProfileDids = chatSearchResults?.map(chatMessage => chatMessage?.creatorUserDid) || []

  const { publicProfiles } = usePublicProfiles([...publicProfileDids], 'ChatSearchModal')

  const { myPublicProfile } = useMyPublicProfile('ChatSearchModal')

  let modal

  if (open) modal = <CSSTransition
    timeout={300}
    classNames="ChatSearchModal-main"
    key={'ChatSearchModal'}
  >
    <Fragment>
      <div className={'ChatSearchModal-header'}>
        <IconButton
          {...{
            type: 'return',
            onClick: closeChatSearchModal,
            className: 'ChatSearchModal-header-returnButton',
          }}
        />
        <SearchInput {...{
          placeholder: 'Search…',
          className: 'SearchResultsMessagesList-SearchBar',
          interfaceHelp: {
            position: 'tl',
            inside: true,
            title: 'Search',
            content: `Search all starred messages`,
          },
          value: searchTerm,
          onChange: setSearchTerm,
        }}/>
      </div>
      <div className={'ChatSearchModal-container'}>
        <div className="SearchResultsMessagesList">
          {searchTerm && (
            <Fragment>
              {chatSearchResults?.map(chatMessage => {
                const fromMe = chatMessage?.creatorUserDid === myPublicProfile?.did
                return <ChatMessage {...{
                  uid: chatMessage?.uid,
                  message: chatMessage?.message,
                  createdAt: chatMessage?.createdAt,
                  publicProfile: publicProfiles.find(pp => pp.did === chatMessage?.creatorUserDid),
                  fromMe,
                  myPublicProfile,
                  isChatMessagesModalOpen: true,
                  publicProfiles,
                  searchTerm,
                }}/>
              })}
              {chatSearchResults?.length === 0 && (
                <div className="SearchResultsMessagesList-noResults">
                  {searchTerm && (
                    <div className="SearchResultsMessagesList-noResults-text">
                      No results found for "{searchTerm}"
                    </div>
                  )}
                </div>
              )}
            </Fragment>)}
        </div>
      </div>
    </Fragment>
  </CSSTransition>

  return <TransitionGroup component={"div"} className={"ChatSearchModal"}>{modal}</TransitionGroup>
}

ChatSearchModal.propTypes = {
  className: PropTypes.string,
  open: StyleishModal.propTypes.open,
  onClose: StyleishModal.propTypes.onClose,
  chatChannel: PropTypes.string.isRequired,
  closeChatSearchModal: PropTypes.func.isRequired,
  ResultsList: PropTypes.array.isRequired,
}


export function ChatMessage({
  uid,
  message,
  createdAt,
  publicProfile,
  fromMe,
  searchTerm
}) {

  const HighlightSearchResult = ({ text, searchTerm }) => {
    if (!searchTerm) {
      return <PlainText text={text} />
    }
    const regex = new RegExp(`(${searchTerm})`, 'gi')
    const parts = text.split(regex)
    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? <strong key={index}>{part}</strong> : part
        )}
      </span>
    )
  }

  const className = classNames(
    'ChatMessage',
    { 'from-me': fromMe, 'from-others': !fromMe },
  )

  const FILE_ATTACHMENT_PREFIX = 'jlinc:dm:attachment:'

  const fileAttachment = (
    message &&
    message.startsWith(FILE_ATTACHMENT_PREFIX) &&
    JSON.parse(message.split(FILE_ATTACHMENT_PREFIX)[1])
  )

  const handleShowInChat = () => {
    navigateToMessage(uid)
  }

  const messageIsWYSIWYG = /^<(h\d|p|ul|figure)/.test(message)

  return <Fragment>
    {!fileAttachment && (
      <div {...{ className }} onClick={handleShowInChat}>
        {!fromMe && (
          <EndUserAvatar
            {...{
              size: "md",
              publicProfile,
            }} />
        )}
        {!fromMe && (
          <Subtext className={'ChatMessage-Bubble-details-displayName'}>
            {publicProfile.displayName || publicProfile.username}
          </Subtext>
        )}
        {fromMe && (
          <Subtext className={'ChatMessage-Bubble-details-from-me'}>
          You:
          </Subtext>
        )}
        <div className={`ChatMessage-Bubble`}>
          {messageIsWYSIWYG ? (
            <WYSIWYGContent source={message} />
          ) : (
            <HighlightSearchResult text={message} searchTerm={searchTerm} />
          )}
          <Subtext className="ChatMessage-Bubble-time">
            <Timestamp time={createdAt} format="date" /> <br /> <Timestamp time={createdAt} format="time" />
          </Subtext>
        </div>
      </div>
    )}
  </Fragment>

}
