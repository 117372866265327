import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import {
  useChatChannel,
} from 'lib/chatHooks'
import Header from 'components/Header'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import ChatChannelAvatar from 'components/ChatChannelAvatar'
import { DMChatChannelHeader } from './DMChatChannelHeader'
import { ChatWindow } from './ChatWindow'
import { useMutedUsers } from 'lib/membershipAppStateHooks'
import { useAppActions } from 'lib/appState'
import Icon from 'components/Icon'
import './index.sass'


export function ChatChannel({
  organization,
  myPublicProfile,
  chatChannelUid,
  chatChannelSynopsis,
  messageUid,
  setForwarding,
  chatChip,
  openforwardWindow,
  setOpenforwardWindow,
  setSelectedChats,
  setChatChip,
  toggleChatSelection,
  ...props
}) {
  const {
    chatChannel,
    chatMessages,
    chatMessagesLoadingError,
    pendingChatMessages,
    createChatMessage,
    editChatMessage,
    replyToMessage,
    loadMessages,
    markChatMessagesAsRead,
    deleteChatMessage,
    undoDeleteChatMessage,
    starChatMessage,
    unstarChatMessage,
    starredChatMessages,
    deleteChatChannel,
    leaveChannel,
    createChatMessageReaction,
    removeChatMessageReaction,
  } = useChatChannel(chatChannelUid, messageUid, 'OrganizationChatPage')

  const { listmuted, mutedUsers } = useMutedUsers('OrganizationChatPage')
  const [isOtherMemberAdmin, setIsOtherMemberAdmin] = useState(false)


  useEffect(() => {
    if(organization?.did) {
      listmuted({ organizationDid: organization.did })
    }
  }, [listmuted, organization?.did])

  const { takeAction } = useAppActions(
    'OrganizationChatPage',
    chatChannelUid,
  )

  useEffect(() => {
    if (chatChannel) {
      takeAction('chat.loadStarredMessages', chatChannelUid)
    }
  }, [chatChannel])


  useEffect(
    () => { if (chatChannelSynopsis) loadMessages() },
    chatChannelSynopsis ? [chatChannelSynopsis.latestAt, chatChannelSynopsis.unread] : []
  )
  const [forwardMessageUid, setForwardMessageUid] = useState('')
  const [forwardedMessageContent, setForwardedMessageContent] = useState('')
  if (chatMessagesLoadingError) return <ErrorMessage error={chatMessagesLoadingError} />
  if (!chatChannel) return <Loading type="block" />

  if (chatMessagesLoadingError) {
    return <ErrorMessage error={chatMessagesLoadingError} />
  }

  if (!chatChannel) {
    return <Loading type="block" />
  }

  let isUserMutedForDM = false
  let isUserMutedForHubchat = false

  if (mutedUsers && Array.isArray(mutedUsers)) {
    isUserMutedForDM = mutedUsers.some(muted =>
      muted.user_did === myPublicProfile.did &&
      muted.organization_did === organization.did &&
      (muted.mute_type === 'ALL' || muted.mute_type === 'DM')
    )
    isUserMutedForHubchat = mutedUsers.some(muted =>
      muted.user_did === myPublicProfile.did &&
      muted.organization_did === organization.did &&
      (muted.mute_type === 'ALL' || muted.mute_type === 'hubchat')

    )
  }

  const isGeneral = chatChannel.type === 'general'

  const dmMute = chatChannel.type === 'dm' && isUserMutedForDM
  const generalMute = chatChannel.type === 'general' && isUserMutedForHubchat

  const isOneToOne = chatChannel?.memberUserDids?.length === 2

  const unread = chatChannelSynopsis?.unread || 0

  const addReactionToMessage = async (messageUid, emoji) => {
    try {
      await createChatMessageReaction(chatChannelUid, messageUid, emoji)
    } catch (error) {
      console.error(`Failed to add reaction to message ${messageUid}`, error)
    }
  }

  const handleRemoveReaction = async (messageUid, emoji) => {
    try {
      await removeChatMessageReaction(chatChannelUid, messageUid, emoji)

    } catch (error) {
      console.error(`Failed to remove reaction from message ${messageUid}`, error)
    }
  }

  return (
    <ChatWindow
      {...{
        organization,
        myPublicProfile,
        memberUserDids: chatChannel.memberUserDids,
        chatImage: chatChannel.imageUrl,
        chatname:chatChannel.name,
        uid: chatChannel.uid,
        loading: false,
        disabled: false,
        chatChannelUid,
        messageUid,
        chatChannel,
        setForwarding,
        setForwardMessageUid,
        setForwardedMessageContent,
        forwardedMessageContent,
        chatChip,
        forwardMessageUid,
        openforwardWindow,
        setOpenforwardWindow,
        setSelectedChats,
        setChatChip,
        toggleChatSelection,
        header: isGeneral ? (
          <Fragment>
            <ChatChannelAvatar size="sm" value={
              chatChannel.imageUrl.startsWith('#') ?
                <div className={'avatarImg'} style={{ backgroundColor: chatChannel.imageUrl }}>
                  <Icon type="chaty" />
                </div> :
                <img
                  src={chatChannel.imageUrl}
                  alt="Chat Image"
                  className={'avatarImg'}
                  style={{ padding: 0 }}
                />
            } />
            <Header size="lg">{`${chatChannel.organizationApikey} Chat`}</Header>
          </Fragment>
        ) : <DMChatChannelHeader {...{
          organization,
          myPublicProfile,
          chatname:chatChannel.name,
          memberUserDids: chatChannel.memberUserDids,
          onAdminStatusChange: setIsOtherMemberAdmin,
          chatImage: chatChannel.imageUrl,
        }} />,
        chatMessages,
        pendingChatMessages,
        markChatMessagesAsRead,
        createChatMessage,
        editChatMessage,
        replyToMessage,
        starChatMessage,
        unstarChatMessage,
        createdAt: isGeneral ? null : chatChannel.createdAt,
        creatorUserDid: isGeneral ? null : chatChannel.creatorUserDid,
        unread,
        starredChatMessages,
        isOneToOne,
        deleteChatMessage,
        undoDeleteChatMessage,
        addReactionToMessage,
        handleRemoveReaction,
        deleteChatChannel,
        leaveChannel,
        dmMute,
        generalMute,
        isOtherMemberAdmin,
        ...props,
      }}
    />
  )
}
