import PropTypes from 'prop-types'

export function addPostsToObject(postObject, postsArray){
  postsArray.forEach(post => {
    postObject[post.uid] = post
  })
}

export function filterOrganizationFeedPosts({
  feedPosts, organizationApikeys, type,
}){
  const matchingPosts = {}
  Object.values(feedPosts || {}).forEach(post => {
    if (
      ((type && post.type === type) || (!type && !post.type)) &&
      (organizationApikeys && organizationApikeys.includes(post.feedOrganizationApikey))
    ) matchingPosts[post.uid] = post
  })
  return matchingPosts
}

export function sortFeedPosts(posts) {
  return posts.sort((a, b) => a.createdAt - b.createdAt)
}

const MUTABLE_FEED_POST_KEYS = Object.freeze('title body mediaUrl mediaMimeType'.split(' '))

export function applyFeedPostChanges(post, existingChanges, newChanges){
  // setting the changes to undefined is how drop the current changes and close the edit form
  if (typeof newChanges === 'undefined') return undefined
  const changes = {...existingChanges, ...newChanges}
  for(const key in changes){
    if (key === 'error') continue
    if (
      !MUTABLE_FEED_POST_KEYS.includes(key) ||
      typeof changes[key] === 'undefined' ||
      changes[key] === post[key]
    ) delete changes[key]
  }
  return changes
}


export function postToPathname(post, feedUserPublicProfile){
  if (post.feedOrganizationApikey) return (
    `/${post.feedOrganizationApikey}` +
    `/${post.visibleTo === 0 ? 'forum' : 'published'}` +
    `/${post.uid}`
  )
  if (post.feedUserDid){
    if (!feedUserPublicProfile)
      console.warn(`postToPathname requires feedUserPublicProfile when post.feedUserDid is present`)
    const username = feedUserPublicProfile ? feedUserPublicProfile.username : post.feedUserDid
    return `/@${username}/post/${post.uid}`
  }
}

export function isValidVisibleToInt(value){
  return [0, 2, 3].includes(value)
}

export const feedPostPropType = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  feedPostContentUid: PropTypes.string.isRequired,
  posterUserDid: PropTypes.string,
  posterOrganizationApikey: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  feedUserDid: PropTypes.string,
  feedOrganizationApikey: PropTypes.string,
  initUid: PropTypes.string.isRequired,
  initCreatedAt: PropTypes.string.isRequired,
  initPosterUserDid: PropTypes.string.isRequired,
  initFeedOrganizationApikey: PropTypes.string.isRequired,
  initPosterOrganizationApikey: PropTypes.string,
  initFeedUserDid: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  parentUid: PropTypes.string,
  published: PropTypes.bool,
  hiddenAt: PropTypes.string,
  deletedAt: PropTypes.string,
  deletedByUserDid: PropTypes.string,
  edited: PropTypes.bool,
  lastPublishingUserDid: PropTypes.string,
  lastPublishingOrganizationApikey: PropTypes.string,
  lastPublishedAt: PropTypes.string,
})

export function applyVisibilityConstraints(newPost, visibilityConstraints){
  if (newPost.visibleTo < visibilityConstraints.visibleTo.min)
    newPost.visibleTo = visibilityConstraints.visibleTo.min
  else if (newPost.visibleTo > visibilityConstraints.visibleTo.max)
    newPost.visibleTo = visibilityConstraints.visibleTo.max

  if (newPost.maxVisibleTo < visibilityConstraints.maxVisibleTo.min)
    newPost.maxVisibleTo = visibilityConstraints.maxVisibleTo.min
  else if (newPost.maxVisibleTo > visibilityConstraints.maxVisibleTo.max)
    newPost.maxVisibleTo = visibilityConstraints.maxVisibleTo.max

  if (newPost.visibleTo > newPost.maxVisibleTo)
    newPost.visibleTo = newPost.maxVisibleTo
}

export function decorateFeedPost(post){
  const isDeleted = !!post.deletedAt
  const isHidden = !!post.hiddenAt
  const isPublished = post.visibleTo > 0
  const isPublishable = (
    !isDeleted &&
    !isHidden &&
    post.feedOrganizationApikey &&
    post.visibleTo === 0 &&
    post.maxVisibleTo > 0
  )
  const isEdited = post.contentCreatedAt > post.createdAt
  return {
    ...post,
    isDeleted,
    isHidden,
    isPublished,
    isPublishable,
    isEdited,
  }
}

export function isOrganizationForumFeedPost(post){
  return post && post.feedOrganizationApikey && post.visibleTo === 0
}

export const VISIBLE_TO_OPTIONS = Object.freeze([
  ['Members only'],
  ['Network only (coming soon)'],
  ['Tru only'],
  ['Public'],
])

export const PUBLISHABLE_TO_OPTIONS = Object.freeze([
  ['Cannot be published', 'Cannot be published'],
  ['Publishable to Networks', 'Publishable to Networks'],
  ['Publishable to Tru', 'Publishable to logged in users'],
  ['Publishable to public', 'Publishable to anyone on the internet'],
])
