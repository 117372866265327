import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import StyleishModal from 'components/StyleishModal'
import OrganizationFeedPostProvenance from 'components/OrganizationFeedPostProvenance'

export default function FeedPostProvenanceModal({
  className,
  feedPostUid,
  ...props
}){
  return <StyleishModal scrollInternally {...{
    title: PROVENANCE_CHAIN_NAME,
    ...props,
    className: classNames('FeedPostProvenanceModal', {className}),
  }}>
    <OrganizationFeedPostProvenance {...{feedPostUid}}/>
  </StyleishModal>
}

FeedPostProvenanceModal.propTypes = {
  className: PropTypes.string,
  feedPostUid: PropTypes.string.isRequired,
}
