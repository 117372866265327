import { h } from 'preact'

import classNames from 'lib/classNames'

import PageError from 'components/PageError'
import jlinx from './jlinx.svg'

export default function Unauthorized({ className, showImage = true, ...props }){
  return <PageError {...{
    header: 'Unauthorized',
    img: jlinx,
    showImage,
    subtext: 'You are not authorized to see this content',
    ...props,
    className: classNames('Unauthorized', { className }),
  }}/>
}
