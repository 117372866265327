import { EMPTY_WYSIWYG } from 'lib/WYSIWYG'
const PREFIX = `<figure class="jlinc:media" data-data="`
const SUFFIX = `"/>`

const is = html =>
  html &&
  typeof html === 'string' &&
  html.match(/^<figure class="jlinc:media" data-data="(.+)"\/>$/)


function parse(html){
  const matches = is(html)
  return matches ? JSON.parse(matches[1]) : false
}

function analyze(html){
  if (!html) return { isEmpty: true }
  const value = parse(html)
  const hasText = value.text && value.text.length > 0
  const hasFiles = value.files
  const hasUrlPreview = value.urlPreview
  const uploading = (
    hasFiles &&
    value.files.some(file => typeof file.uploading === 'number')
  )
  const failedUploads = hasFiles && value.files.some(file => file.error)
  const loadingPreview = hasUrlPreview && value.urlPreview.loading
  const isEmpty = !hasText && !hasFiles && !hasUrlPreview
  return {
    hasText,
    hasFiles,
    hasUrlPreview,
    uploading,
    failedUploads,
    loadingPreview,
    isEmpty,
  }
}

function toHTML(media){
  return `${PREFIX}${JSON.stringify(media)}${SUFFIX}`
}

function isEmpty(html){
  return html === PREFIX + '{}' + SUFFIX
}

function convertFromWYSIWYG(html = ''){
  const div = document.createElement('div')
  div.innerHTML = html
    .replace(/<br\/>/ig, '<br/>\n')
    .replace(/<\/p>/ig, '</p>\n\n')

  const files = Array.from(
    div.querySelectorAll(
      'figure[class="image"] img, ' +
      'figure[class="media"] oembed'
    )
  )
    .map(media => ({
      url: media.src,
      height: (
        'height' in media.dataset
          ? parseInt(media.dataset.height, 10)
          : media.naturalHeight
      ),
      width: (
        'width' in media.dataset
          ? parseInt(media.dataset.width, 10)
          : media.naturalWidth
      ),
    }))

  const text = div.textContent.trim()
  return toHTML({text, files})
}

function convertToWYSIWYG(media){
  media = parse(media)
  if (!media) return EMPTY_WYSIWYG
  let text = (media.text || '').split('\n\n')
    .map(line => `<p>${line}</p>`)
    .join('')
    .replace(/\n/g, '<br/>')

  if (media.files) media.files.forEach(file => {
    text += (
      `<figure class="image">` +
        `<img src="${file.url}" data-height="${file.height}" data-width="${file.width}"/>` +
      `</figure>`
    )
  })

  return text
}

export default {
  is,
  parse,
  analyze,
  toHTML,
  isEmpty,
  convertFromWYSIWYG,
  convertToWYSIWYG,
}
