import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import datayogiLogoUrl from './datayogi.png'
import truLogoUrl from './logo.svg'
import ImageWithLoader from 'components/ImageWithLoader'
import './index.sass'

const src = isDataYogi ? datayogiLogoUrl : truLogoUrl

export default function TruLogo({
  className,
  size = 'md',
  ...props
}) {
  return <ImageWithLoader {...{
    ...props,
    className: classNames('TruLogo', {[size]: 1, className}),
    src,
  }}/>
}

TruLogo.SIZES = Object.freeze(['sm', 'md', 'lg'])
TruLogo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(TruLogo.SIZES),
}
