import { isLoggedIn, getCurrentUser } from '../resources/auth'
import { createPageError } from '../resources/pageAlerts'
import api from '../api'
import { tx } from 'lib/actionHelpers'

export async function loadMine(){
  if (this.getState()['myOrganizationFeedSubscriptionUids:loaded']) return
  this.takeAction('feedSubscriptions.reloadMine')
}

export async function reloadMine(){
  if (!isLoggedIn()) return
  const loadingKey = 'myOrganizationFeedSubscriptionUids:loading'
  const errorKey = loadingKey + ':error'
  return await tx.call(this, loadingKey, errorKey, async () => {
    const { organizationFeedSubscriptions }
      = await api.getMyOrganizationFeedSubscriptions()
    this.setState({ 'myOrganizationFeedSubscriptionUids:loaded': true })
    this.takeAction(
      'feedSubscriptions.addOrganizationFeedSubscriptionsToAppState',
      organizationFeedSubscriptions,
    )
  })
}


export async function load(organizationApikey) {
  const feedKey = `organization:${organizationApikey}:feedSubscriptions`
  const loadingKey = `${feedKey}:loading`
  const loadedKey = `${feedKey}:loaded`
  const errorKey = `${loadingKey}:error`

  const {
    [loadingKey]: loading,
    [loadedKey]: loaded,
    [errorKey]: error,
  } = this.getState()
  if (loading || loaded || error) return

  this.setState({
    [loadingKey]: true,
    [errorKey]: undefined,
  })

  try {
    const { organizationFeedSubscriptions } = await api.getOrganizationFeedSubscriptions(organizationApikey)
    this.takeAction(
      'feedSubscriptions.addOrganizationFeedSubscriptionsToAppState', organizationFeedSubscriptions,
    )
    this.setState({ [loadedKey]: true })
  } catch(error) {
    this.setState({ [errorKey]: error })
  } finally {
    this.setState({ [loadingKey]: undefined })
  }
}

export async function subscribe({ organizationApikey, subscriberOrganizationApikey }) {
  const subscribingKey = `feedSubscriptions:subscribing`
  const errorKey = `${subscribingKey}:error`
  if (this.getState()[subscribingKey]) {
    console.warn(`already subscribing to ${organizationApikey}`)
    return
  }

  this.setState({
    [subscribingKey]: { organizationApikey, subscriberOrganizationApikey },
    [errorKey]: undefined,
  })

  try {
    const { organizationFeedSubscription } = await api.subscribeToOrganizationFeed({
      organizationApikey, subscriberOrganizationApikey,
    })
    this.takeAction(
      'feedSubscriptions.addOrganizationFeedSubscriptionsToAppState', [organizationFeedSubscription],
    )
  } catch(error) {
    this.setState({ [errorKey]: error })
    createPageError(`Failed to subscribe. Please try again later.`)
  } finally {
    this.setState({ [subscribingKey]: undefined })
  }
}

export async function unsubscribe({ organizationFeedSubscription }) {
  const unsubscribingKey = `feedSubscriptions:unsubscribing`
  const errorKey = `${unsubscribingKey}:error`
  if (this.getState()[unsubscribingKey]) {
    console.warn(`already unsubscribing from ${organizationFeedSubscription.organizationApikey}`)
    return
  }

  this.setState({
    [unsubscribingKey]: organizationFeedSubscription,
    [errorKey]: undefined,
  })

  try {
    await api.unsubscribeFromOrganizationFeed({
      organizationFeedSubscriptionUid: organizationFeedSubscription.uid,
      subscriberOrganizationApikey: organizationFeedSubscription.subscriberOrganizationApikey,
    })
    this.takeAction('feedSubscriptions.removeOrganizationFeedSubscriptionsFromAppState', [organizationFeedSubscription])
  } catch(error) {
    this.setState({ [errorKey]: error })
  } finally {
    this.setState({ [unsubscribingKey]: undefined })
  }
}

// removes the any feed subscription to this org for the current usert
export async function removeForOrg(organizationApikey){
  const currentUser = getCurrentUser()
  if (!currentUser) return
  const myPublicProfileDid = currentUser.publicProfileDid
  const appState = this.getState()
  const feedSubscriptions = appState[`organization:${organizationApikey}:feedSubscriptions`]
  if (!feedSubscriptions) return
  const sub = [...feedSubscriptions].find(uid => {
    const sub = appState[`organizationFeedSubscription:${uid}`]
    return sub && sub.subscriberUserDid === myPublicProfileDid
  })
  if (sub) this.takeAction('feedSubscriptions.removeOrganizationFeedSubscriptionsFromAppState', [sub])
}

export async function alterOrganizationFeedSubscriptionsInAppState(organizationFeedSubscriptions, addOrDelete) {
  const currentUser = getCurrentUser()
  const myPublicProfileDid = currentUser && currentUser.publicProfileDid
  const appState = this.getState()
  const newState = {}

  const organizationApikeys = new Set()
  const subscriberUserDids = new Set()

  organizationFeedSubscriptions.forEach(sub => {
    organizationApikeys.add(sub.organizationApikey)
    if (sub.subscriberOrganizationApikey)
      organizationApikeys.add(sub.subscriberOrganizationApikey)
    if (sub.subscriberUserDid)
      subscriberUserDids.add(sub.subscriberUserDid)
  })

  // create new sets so app state keys change
  organizationApikeys.forEach(organizationApikey => {
    const subscriptionsKey = `organization:${organizationApikey}:feedSubscriptions`
    newState[subscriptionsKey] = new Set(appState[subscriptionsKey])
  })
  if (myPublicProfileDid && subscriberUserDids.has(myPublicProfileDid)){
    newState.myOrganizationFeedSubscriptionUids = new Set(appState.myOrganizationFeedSubscriptionUids)
  }

  // add or remove from all the new sets
  organizationFeedSubscriptions.forEach(sub => {
    const {
      uid,
      organizationApikey,
      subscriberOrganizationApikey,
      subscriberUserDid,
    } = sub

    newState[`organizationFeedSubscription:${uid}`] = (
      addOrDelete === 'add' ? sub : undefined
    )
    newState[`organization:${organizationApikey}:feedSubscriptions`][addOrDelete](uid)
    if (subscriberOrganizationApikey) {
      newState[`organization:${subscriberOrganizationApikey}:feedSubscriptions`][addOrDelete](uid)
    }else if (
      myPublicProfileDid &&
      subscriberUserDid &&
      subscriberUserDid === myPublicProfileDid
    ) {
      newState.myOrganizationFeedSubscriptionUids[addOrDelete](uid)
    }
  })
  this.setState(newState)
}

export function addOrganizationFeedSubscriptionsToAppState(organizationFeedSubscriptions) {
  alterOrganizationFeedSubscriptionsInAppState.call(this, organizationFeedSubscriptions, 'add')
}

export function removeOrganizationFeedSubscriptionsFromAppState(organizationFeedSubscriptions) {
  alterOrganizationFeedSubscriptionsInAppState.call(this, organizationFeedSubscriptions, 'delete')
}
