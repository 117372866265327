import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'
import { useMemo, useCallback } from 'preact/hooks'

import { useMyPublicProfileDid } from 'resources/auth'
import {
  useOrganizationsMemberships,
  useCreateOrganizationMembership,
  useUpdateOrganizationMembership,
} from 'lib/membershipAppStateHooks'

import StyleishModal from 'components/StyleishModal'
import OrganizationList from 'components/OrganizationList'
import Button from 'components/Button'

export default function InviteUserToOrganizationsModal({
  className = '',
  open,
  onClose,
  publicProfile,
  organizationsIAdmin,
}) {
  return <StyleishModal {...{
    className: `InviteUserToOrganizationsModal ${className}`,
    scrollInternally: true,
    open,
    onClose,
    title: `Invite ${publicProfile.displayName} to join one of your ${PNFO.plural}`,
    padded: false,
  }} >
    <Content {...{ organizationsIAdmin, publicProfile }}/>
  </StyleishModal>
}

const Content = function({ organizationsIAdmin, publicProfile }){
  const {
    creatingOrganizationMembership,
    errorCreatingOrganizationMembership,
    createOrganizationMembership,
  } = useCreateOrganizationMembership('InviteUserToOrganizationsModal')
  const myPublicProfileDid = useMyPublicProfileDid()

  const { organizationsMemberships } = useOrganizationsMemberships(
    organizationsIAdmin.map(o => o.apikey),
    'InviteUserToOrganizationsModal',
  )

  const organizations = useMemo(
    () => {
      return organizationsIAdmin
        .filter(organizationIAdmin =>
          !organizationsMemberships.find(m =>
            m.organizationApikey === organizationIAdmin.apikey &&
            m.memberUserDid === publicProfile.did &&
            (m.rejectedAt || m.acceptedAt)
          )
        )
        .map(organizationIAdmin => {
          const organizationApikey = organizationIAdmin.apikey
          const organizationMembership = organizationsMemberships.find(organizationMembership =>
            organizationMembership.organizationApikey === organizationIAdmin.apikey &&
            organizationMembership.memberUserDid === publicProfile.did
          )


          return {
            ...organizationIAdmin,
            buttons: <InviteUserToOrganizationButton {...{
              myPublicProfileDid,
              publicProfile,
              createOrganizationMembership,
              organizationMembership,
              disabled: creatingOrganizationMembership,
              organizationApikey,
            }}/>,
          }
        })
    },
    [organizationsIAdmin, organizationsMemberships]
  )

  return <OrganizationList
    error={errorCreatingOrganizationMembership}
    organizations={organizations}
  />
}

function InviteUserToOrganizationButton({
  disabled, organizationMembership, myPublicProfileDid, publicProfile, organizationApikey, createOrganizationMembership
}) {
  const {
    updateOrganizationMembership,
    updatingOrganizationMembership,
  } = useUpdateOrganizationMembership(organizationMembership || {}, 'OrganizationMembershipButton')

  const invited = organizationMembership && organizationMembership.createdByUserDid === myPublicProfileDid
  const hasRequestedToJoin = organizationMembership && organizationMembership.createdByUserDid === publicProfile.did

  const onClick = useCallback(() => {
    if (hasRequestedToJoin) updateOrganizationMembership({ accept: true })
    else createOrganizationMembership({ organizationApikey, memberUserDid: publicProfile.did })
  }, [organizationApikey, publicProfile.did])

  return <Button {...{
    type: 'normal',
    value: invited ? 'Invited!' : 'Invite',
    disabled: disabled || invited || updatingOrganizationMembership,
    onClick,
  }}/>
}
