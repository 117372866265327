import 'lib/debug'
import 'lib/serviceworker'
import 'lib/browserNotifications'
import { onPWAChange } from 'lib/PWAHelpers'
import renderApp from 'lib/renderApp'
import App from './App'

const windowClassList = () =>
  global.document.documentElement.classList

if (isDataYogi) windowClassList().add('isDataYogi')
onPWAChange(isPWA => {
  windowClassList()[isPWA ? 'add' : 'remove']('isPWA')
})

renderApp(App)
