import { useRef, useEffect, useLayoutEffect, useCallback } from 'preact/hooks'
import { createLogger } from 'lib/logger'
import history from 'lib/history'
import {
  toHref,
  Location,
  getLocation,
  locationToHref,
  set,
  replace,
  setQuery,
} from 'lib/location'
import useForceUpdate from 'lib/useForceUpdate'

const logger = createLogger('location', 'color: yellow')

export { toHref, Location, setQuery, getLocation }

export function setLocation(location, _replace){
  logger.debug(`${_replace ? 'replace' : 'set'}: ${locationToHref(location)}`)
  return (_replace ? replace : set)(location)
}

export function replaceLocation(location){
  return setLocation(location, true)
}

export function setLocationQuery(query, replace){
  return setLocation(getLocation().withQuery(query, replace))
}

export function onLocationChange(_onChange){
  const onChange = () => _onChange(getLocation())
  history.subscribe(onChange)
  return () => {
    history.unsubscribe(onChange)
  }
}

// HOOKS

export function useOnLocationChange(onChange, args = []){
  const ref = useRef()
  ref.location = getLocation()
  useLayoutEffect(
    () => onLocationChange(newLocation => {
      if (locationToHref(newLocation) !== locationToHref(ref.location)){
        ref.location = newLocation
        if (onChange) onChange(newLocation)
      }
    }),
    [onChange, ...args],
  )
  return ref.location
}

export function useLocation(){
  const forceUpdate = useForceUpdate()
  return useOnLocationChange(forceUpdate)
}

export function useSetQuery(query, replace){
  return useCallback(
    value => setQuery({ [query]: value }, undefined, replace),
    [query, replace],
  )
}

export function useLocationQueryState(options) {
  const { query, defaultValue, replace } = options
  const location = useLocation()
  const value = location.query[query] || defaultValue
  const setQuery = useSetQuery(query, replace)
  return [value, setQuery]
}

export function useRedirect(options){
  const {to: location, replace} = options
  const condition = ('if' in options) ? !!options['if'] : true
  useEffect(
    () => {
      if (condition === true) setLocation(location, replace)
    },
    [location, condition, replace]
  )
}


// INIT

history.subscribe(() => {
  logger.info('CHANGE', getLocation())
})

// DEBUG

Object.assign(DEBUG, {
  getLocation,
  setLocation,
  replaceLocation,
})
