import { h } from 'preact'
import classNames from 'lib/classNames'

import ContextMenu from 'components/ContextMenu'
import APortalLoginForm from 'components/APortalLoginForm'
import './index.sass'

export default function EndUserLoginDropdown({className, location, ...props}){
  return <ContextMenu {...{
    rightAligned: true,
    closeOnWindowBlur: false,
    ...props,
    className: classNames('EndUserLoginDropdown', { className }),
  }}>
    <APortalLoginForm {...{ signupLinkHref: location.toSignup() }}/>
  </ContextMenu>
}
