import api from '../api'

export function requestReset({ login }) {
  this.setState({
    passwordResetRequest: {login, pending: true}
  })

  api.requestPasswordReset({ login })
    .then(
      ({ mobilePhoneVerificationRequired, emailVerificationRequired }) => {
        this.setState({
          passwordResetRequest: {
            login,
            sent: true,
            mobilePhoneVerificationRequired,
            emailVerificationRequired,
          },
        })
      },
      error => {
        this.setState({
          passwordResetRequest: {login, error},
        })
      }
    )
}


export function deleteResetRequest(){
  this.setState({
    passwordResetRequest: undefined,
  })
}


export function verifyResetToken(resetPasswordToken) {
  if (this.getState().verifyingResetPasswordToken) return
  this.setState({ verifyingResetPasswordToken: true })

  return api.verifyResetPasswordToken({ resetPasswordToken }).then(
    () => {
      this.setState({
        verifyingResetPasswordToken: undefined,
        verifiedResetPasswordToken: resetPasswordToken,
      })
    },
    verifyingResetPasswordTokenError => {
      if (`${verifyingResetPasswordTokenError}`.includes('invalid resetPasswordToken'))
        verifyingResetPasswordTokenError = 'invalid'
      this.setState({
        verifyingResetPasswordToken: undefined,
        verifyingResetPasswordTokenError,
      })
    }
  )
}


export function reset({ resetPasswordToken, password, passwordConfirmation }) {
  this.setState({
    passwordResetRequest: {pending: true},
  })

  api.resetPassword({ resetPasswordToken, password, passwordConfirmation }).then(
    () => {
      this.setState({
        passwordResetRequest: {success: true},
      })
    },
    error => {
      this.setState({
        passwordResetRequest: {error},
      })
    }
  )
}
