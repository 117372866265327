import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import history from 'lib/history'

import Loading from 'components/Loading'
import Link from 'components/Link'
import ErrorMessage from 'components/ErrorMessage'
import './index.sass'

export default function FeedPostURLPreview({
  className,
  loading,
  invalidUrl,
  url,
  type,
  title,
  description,
  image,
  text,
}){
  const hostname = urlToHostname(url)

  const externalLink = url && !history.hrefIsSameOrigin(url)
  const link = children =>
    <Link newWindow type="link" href={externalLink ? `external?link=${url}` : url}>{children}</Link>

  const linkImage = src =>
    link(<img {...{src}}/>)

  let error
  if (invalidUrl) error = `Failed to load preview for "${url}"`

  const media = (
    loading ? <Loading type="block" /> :
    error ? <ErrorMessage error={error} dismissable={false}/> :
    type === 'text' ? <pre>{text}</pre> :
    type === 'image' ? linkImage(url) :
    // type === 'audio' ?
    // type === 'video' ?
    (type === 'html' && image) ? linkImage(image) :
    undefined
  )


  return <div className={classNames('FeedPostURLPreview', { className, loading })} >
    {media && <div className="FeedPostURLPreview-media">{media}</div>}
    <div className="FeedPostURLPreview-details">
      {hostname &&
        <Link
          className="FeedPostURLPreview-hostname"
          href={`https://${hostname}`}
          newWindow
        >{hostname.toUpperCase()}</Link>
      }
      <Link href={url} newWindow className="FeedPostURLPreview-title">{title || url}</Link>
      {description && <div className="FeedPostURLPreview-description">{description}</div>}
    </div>
  </div>
}

FeedPostURLPreview.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  invalidUrl: PropTypes.bool,
  url: PropTypes.string,
  hostname: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  text: PropTypes.string,
}

function urlToHostname(url){
  try{ return (new URL(url)).hostname }catch(e){ /**/ }
}
