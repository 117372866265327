import api from '../api'

export default function() {
  this.setState({
    errorLoadingDefaultAccountData: undefined,
    loadingDefaultAccountData: true,
  })

  return api.getDefaultAccountdata().then(
    defaultAccountData => {
      this.setState({
        defaultAccountData,
        loadingDefaultAccountData: undefined,
      })
    },
    errorLoadingDefaultAccountData => {
      this.setState({
        errorLoadingDefaultAccountData,
        loadingDefaultAccountData: undefined,
      })
    }
  )
}
