import { h } from 'preact'
import { useState, useRef, useEffect, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { smartCloneElement } from 'lib/preactHelpers'

import Button from 'components/Button'

export default function ActionButton({
  className,
  action,
  disabled,
  children,
  value,
  busyValue,
  onError,
  onSuccess,
  confirmation,
  ...props
}){
  const [busy, setBusy] = useState()
  if (busy) disabled = true

  const callbacks = useRef()
  useEffect(
    () => { Object.assign(callbacks, { onError, onSuccess }) },
    []
  )

  const onclick = useCallback(
    () => {
      if (disabled) return
      setBusy(true)
      if (confirmation && !confirm(confirmation)) return // eslint-disable-line
      action()
        .then(
          result => { if (callbacks.onSuccess) callbacks.onSuccess(result) },
          error => { if (callbacks.onError) callbacks.onError(error) },
        )
        .then(() => { setBusy(false) })
    },
    [action, disabled, confirmation]
  )

  props = {
    ...props,
    className: classNames('ActionButton', { className }),
    disabled,
    onclick,
    value: busy ? busyValue : value,
    children: busy ? busyValue : value,
  }
  return children
    ? smartCloneElement(children, props)
    : <Button {...props}/>
}

ActionButton.propTypes = {
  className: PropTypes.string,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.node,
  busyValue: PropTypes.node,
  confirmation: PropTypes.string,
  onError: PropTypes.bool,
  onSuccess: PropTypes.bool,
}
