import { h } from 'preact'
import { useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

export default function Spotlight({className, ...props}){
  const rootRef = useRef()

  useEffect(
    () => {
      const spotlight = rootRef.current
      const removeAnimatedClassName = () => {
        spotlight.classList.remove('Spotlight-animated')
      }
      spotlight.addEventListener('transitionend', removeAnimatedClassName)
      return () => {
        spotlight.removeEventListener('transitionend', removeAnimatedClassName)
      }
    },
    []
  )

  return <div
    {...props}
    ref={rootRef}
    className={classNames('Spotlight', { className })}
  >
    <div className="Spotlight-top" />
    <div className="Spotlight-left" />
    <div className="Spotlight-bottom" />
    <div className="Spotlight-right" />
    <div className="Spotlight-spot" />
  </div>
}

Spotlight.propTypes = {
  className: PropTypes.string,
  target: PropTypes.node,
}

Spotlight.pointAt = function({
  spotlight,
  target,
  targetRect,
  padding = 20,
  offsetY = 0,
  animate = false,
  minHeight = 68,
  minWidth = 68,
  circular = true,
}){
  if (!targetRect && target)
    targetRect = target.getBoundingClientRect()

  if (!targetRect){
    positionSpot({
      spotlight,
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      animate: false,
    })
    return
  }

  let height, width
  if (circular){
    let size = Math.max(targetRect.height, targetRect.width) + padding
    height = size
    width = size
  }else{
    height = targetRect.height + padding
    width = targetRect.width + padding
  }

  if (height < minHeight) height = minHeight
  if (width < minWidth) width = minWidth

  const x = targetRect.left + (targetRect.width / 2)
  const y = targetRect.top + (targetRect.height / 2) + offsetY
  positionSpot({
    spotlight,
    top: y - (height / 2),
    left: x - (width / 2),
    height,
    width,
    animate,
    circular,
  })
}


function positionSpot({
  spotlight, top, left, height, width, animate, circular,
}){

  spotlight.classList[animate ? 'add' : 'remove']('Spotlight-animated')
  spotlight.classList[!circular ? 'add' : 'remove']('Spotlight-square')
  spotlight.style.setProperty('--spot-top', `${top}px`)
  spotlight.style.setProperty('--spot-left', `${left}px`)
  spotlight.style.setProperty('--spot-height', `${height}px`)
  spotlight.style.setProperty('--spot-width', `${width}px`)
}
