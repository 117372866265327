import logger from 'lib/logger'

const diffAccountData = require('jlinc-shared/diffAccountData')

export default function({organizationApikey, organizationName}) {
  logger.debug('stageOrganizationAccountDataForDefaultAccountData', {organizationApikey, organizationName})
  this.setState({
    updatingDefaultAccountDataError: undefined,
    stagingOrganizationAccountDataForDefaultAccountData: true,
  })

  return Promise.all([
    this.takeAction('loadDefaultAccountData'),
    this.takeAction('loadOrganizationAccountData', organizationApikey),
  ]).then(() => {
    const {
      defaultAccountData,
      [`organizationAccountData:${organizationApikey}`]: organizationAccountData,
    } = this.getState()

    const changes = diffAccountData(defaultAccountData, organizationAccountData)
    if (changes) {
      this.setState({
        defaultAccountDataStagedChanges: changes,
      })
    }else{
      const organization = this.getState()[`organization:${organizationApikey}`]
      const organizationName = organization ? organization.name : organizationApikey
      const error = new Error(
        `${organizationName}'s preferences already match your defaults`
      )
      this.setState({
        updatingDefaultAccountDataError: error,
      })
    }
    this.setState({
      stagingOrganizationAccountDataForDefaultAccountData: undefined,
    })
  })
}
