import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import ImageWithLoader from 'components/ImageWithLoader'
import { singleLetterIcon } from 'lib/imageHelpers'

import './index.sass'

export default function EndUserAvatar({
  className,
  size,
  bordered,
  publicProfile,
  ...props
}){

  const src = publicProfile && (
    publicProfile.avatar ||
    singleLetterIcon(publicProfile.username || '??')
  )
  return <ImageWithLoader {...{
    ...props,
    className: classNames('EndUserAvatar', {
      [size]: 1, className, bordered,
    }),
    src,
  }}/>
}

EndUserAvatar.SIZES = Object.freeze(['tn', 'sm', 'md', 'lg'])

EndUserAvatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(EndUserAvatar.SIZES).isRequired,
  publicProfile: PropTypes.shape({
    username: PropTypes.string,
    avatar: PropTypes.string,
  }),
  bordered: PropTypes.bool,
}

EndUserAvatar.defaultProps = {
  size: 'lg',
  bordered: false,
}
