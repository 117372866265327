import { useCallback, useRef, useEffect } from 'preact/hooks'
import { useAppState, useAppActions } from 'lib/appState'
import { useRefState } from 'lib/stateHooks'

const SEACH_RESULTS_TTL = 3000

export function useSearchAll(searchQuery, page, componentName){
  searchQuery = searchQuery.trim().toLowerCase().replace(/\s+/, ' ')
  const searchKey = `search:${searchQuery}:${page}`
  let {
    takeAction,
    searching,
    searchingError,
    searchResults,
  } = useAppState(
    {
      [`${searchKey}:searching`]: 'searching',
      [`${searchKey}:error`]: 'searchingError',
      [`${searchKey}:results`]: 'searchResults',
    },
    componentName,
  )

  if (searchResults && searchResultsExpired(searchResults))
    searchResults = undefined

  useEffect(
    () => {
      if (searching || searchingError || searchResults) return
      takeAction('search.all', searchQuery, page)
    },
    [searchQuery, page, searching, searchingError, searchResults]
  )

  return {
    searching,
    searchingError,
    searchResults,
  }
}

function searchResultsExpired(searchResults){
  return (Date.now() - searchResults.searchedAt) > SEACH_RESULTS_TTL
}

export function useSearchOrganizations(searchQuery, componentName){
  const { takeAction } = useAppActions(componentName)

  if (typeof searchQuery !== 'string') searchQuery = ''

  const [state, setState] = useRefState(
    {
      searchQuery,
      validQuery: typeof searchQuery === 'string' && searchQuery.length > 0,
      page: 0,
      loading: false,
      error: undefined,
      organizations: undefined,
      lastPage: false,
    },
    [searchQuery]
  )

  const loadMore = useCallback(
    () => {
      if (!state.validQuery) return
      if (state.loading || state.lastPage){
        console.warn('loadMore called when',
          state.loading ? 'loading' : 'last page'
        )
        return
      }
      setState({ loading: true })
      const { searchQuery, page } = state
      takeAction('search.organizations', searchQuery, page).then(
        batch => {
          if (state.searchQuery !== searchQuery || state.page !== page){
            console.warn('throwing away organization search results for', { searchQuery, page })
            return
          }
          setState({
            loading: false,
            organizations: (
              state.page === 0
                ? batch.organizations
                : [...state.organizations, ...batch.organizations]
            ),
            page: state.page + 1,
            lastPage: !!batch.lastPage,
          })
        },
        error => {
          setState({
            loading: false,
            error,
          })
        },
      )
    },
    [state],
  )

  const debounceInitLoad = useRef()
  useEffect(
    () => {
      clearTimeout(debounceInitLoad.timeout)
      debounceInitLoad.timeout = setTimeout(loadMore, 300)
    },
    [searchQuery]
  )

  return {...state, loadMore}
}
