import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { VISIBLE_TO_OPTIONS } from 'lib/feedPostHelpers'

import Dropdown from 'components/Dropdown'

export default function FeedPostVisibilityDropdown({
  disabled,
  className,
  min = 0,
  max = VISIBLE_TO_OPTIONS.length - 1,
  ...props
}){
  const options = {}
  VISIBLE_TO_OPTIONS.forEach(([label], value) => {
    if (label && value >= min && value <= max) options[label] = value
  })
  return <Dropdown {...{
    ...props,
    className: classNames('FeedPostVisibilityDropdown', { className }),
    options,
    disabled: disabled || Object.keys(options).length < 2,
  }}/>
}

FeedPostVisibilityDropdown.propTypes = {
  ...Dropdown.propTypes,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  min: PropTypes.oneOf([0, 2, 3]),
  max: PropTypes.oneOf([0, 2, 3]),
}
