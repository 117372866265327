const mediaQuery = window.matchMedia('(display-mode: standalone)')

export function isPWA(){ return !!mediaQuery.matches }

export function onPWAChange(handler){
  const onChange = () => { handler(isPWA()) }
  mediaQuery.addListener(onChange)
  return () => {
    mediaQuery.removeListener(onChange)
  }
}
