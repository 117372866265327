import 'style/index.sass'

import { h } from 'preact'
import { useErrorBoundary } from 'preact/hooks'

import { useLocation } from 'resources/location'
import { useCurrentUser } from 'resources/auth'
import { useRedirectingToBrandingSite } from 'resources/brandingSite'
import { usePageAlerts, dismissPageAlert } from 'resources/pageAlerts'
import { AppStateProvider, useAppState } from 'lib/appState'
import { Portal } from 'lib/portal'
import { useSessionStorage } from 'lib/storageHooks'

import appState from './state'
import Layout from './Layout'
import router from './router'
import NotFoundPage from './pages/NotFoundPage'
import RedirectPage from './pages/RedirectPage'
import WelcomePage from './pages/WelcomePage'
import DailyDigestAnnouncementPage from './pages/DailyDigestAnnouncementPage'

import APortalTheme from 'components/APortalTheme'
import Loading from 'components/Loading'
import FullPageKnot from 'components/FullPageKnot'
import AppError from 'components/AppError'
import PageAlerts from 'components/PageAlerts'
import HelpAndFeedback from 'components/HelpAndFeedback'
import APortalInterfaceWalkthrough from 'components/APortalInterfaceWalkthrough'
import NewAppVersionDetectedPrompt from 'components/NewAppVersionDetectedPrompt'
import { useSocket } from 'lib/socketHooks'

export default function App(){
  return <AppStateProvider {...{appState}}>
    <APortalTheme>
      <Portal/>
      <AppAlerts/>
      <APortalInterfaceWalkthrough />
      <NewAppVersionDetectedPrompt />
      <AppRoot />
      <HelpAndFeedback />
    </APortalTheme>
  </AppStateProvider>
}

function AppAlerts(){
  const alerts = usePageAlerts()
  const onDismiss = dismissPageAlert
  return <PageAlerts {...{ alerts, onDismiss }}/>
}

function AppRoot() {
  const [error] = useErrorBoundary()
  const [isConnected] = useSocket()
  console.log('isConnected', isConnected)
  const location = useLocation('AppRoot')
  const currentUser = useCurrentUser() // TODO change to isLoggedIn
  const { preferences, externalIdentities, notificationSettings }
    = useAppState(['preferences', 'externalIdentities', 'notificationSettings'], 'AppRoot')
  const [justSignedUp, setJustSignedUp] = useSessionStorage('justSignedUp')
  const loggedIn = !!currentUser
  const { loadingOverview, loadingOverviewError } = currentUser || {}
  const redirectingToBrandingSite = useRedirectingToBrandingSite()

  if (error || loadingOverviewError) return <FullPageKnot>
    <AppError error={error || loadingOverviewError}/>
  </FullPageKnot>

  if (redirectingToBrandingSite)
    return <Loading type="fullScreen" />

  if (currentUser){
    if (justSignedUp) return <WelcomePage {...{setJustSignedUp}}/>
    if (!preferences || !externalIdentities || !notificationSettings)
      return <Loading type="fullScreen" />
    const hasNoEmail = !(externalIdentities && Object.values(externalIdentities).find(ei => ei.type === 'email'))
    if (
      (hasNoEmail || !notificationSettings.recieveDailyDigestEmail) &&
      !preferences.has_seen_daily_digest_email_announcement
    ) return <DailyDigestAnnouncementPage/>
  }

  const props = { location, loggedIn, currentUser, params: {} }
  let layout = true
  let Component = NotFoundPage
  const route = router.resolve(location.pathname)
  if (route) {
    const {
      noLayout,
      requireNotLoggedIn,
      requireLoggedIn,
      ...params
    } = route.params
    if (noLayout) layout = false
    if (requireNotLoggedIn && loggedIn){
      Component = RedirectPage
      props.to = location.query.r || `/`
    }else if (requireLoggedIn && loggedIn && loadingOverview){
      return <Loading type="fullScreen" />
    }else if (requireLoggedIn && !loggedIn){
      Component = RedirectPage
      props.to = location.toLogin()
    }else{
      Component = route.Component
      props.params = params
    }
  }
  const page = <Component {...props}/>
  return layout ? <Layout {...props}>{page}</Layout> : page
}
