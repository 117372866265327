import { useEffect } from 'preact/hooks'
import { refToDOMNode } from 'lib/preactHelpers'

export function useScrollIntoView(
  { ref, behavior = 'smooth', block = 'start', delay = 10 },
  args = []
){
  useEffect(
    () => {
      setTimeout(
        () => {
          const node = refToDOMNode(ref)
          if (node) node.scrollIntoView({ behavior, block })
        },
        delay
      )
    },
    [ref, ...args]
  )
}
