import { h } from 'preact'
import { useRef, useState, useEffect} from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

export default function NotificationBadge({ className, total, topRight, ...props }){
  const prevTotalRef = useRef()
  const [fadingOut, setFadingOut] = useState(false)
  total = normalizeTotal(total || 0)
  const zero = total === 0
  const prevTotal = prevTotalRef.current
  prevTotalRef.current = total
  const shouldFadeOut = zero && prevTotal > 0

  useEffect(
    () => {
      if (shouldFadeOut){
        const timeout = setTimeout(stopFadingOut, 200)
        function stopFadingOut(){
          clearTimeout(timeout)
          setFadingOut(false)
        }
        setFadingOut(prevTotal)
        return stopFadingOut
      }
    },
    [total]
  )

  if (shouldFadeOut) total = prevTotal
  else if (fadingOut) total = fadingOut

  return <div
    {...props}
    className={classNames('NotificationBadge', { className, zero, topRight })}
  >{total > 9 ? '9+' : total}</div>
}

NotificationBadge.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number.isRequired,
  topRight: PropTypes.bool,
}

NotificationBadge.defaultProps = {
  total: 0,
  topRight: false,
}

const normalizeTotal = total =>
  (typeof total !== 'number' || total < 0) ? 0 : total
