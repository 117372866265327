import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Button from 'components/Button'
import './index.sass'

export default function FileButton({
  className = '',
  children,
  disabled,
  tabIndex,
  value,
  title,
  accept,
  capture,
  multiple,
  onFiles,
  ...props
}){
  const onChange = useCallback(
    event => {
      const files = [...event.target.files]
      event.target.value = ''
      onFiles(files)
    },
    [onFiles]
  )
  return <Button {...{
    tabIndex: -1,
    className: classNames('FileButton', {className}),
    disabled,
    ...props,
  }}>
    { value ? value : children }
    <input {...{
      tabIndex,
      className: 'FileButton-input',
      type: 'file',
      disabled,
      accept,
      capture,
      multiple,
      onChange,
      title,
    }}/>
  </Button>
}

FileButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.string,
  tabIndex: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  accept: PropTypes.string,
  capture: PropTypes.string,
  multiple: PropTypes.string,
  onFiles: PropTypes.func.isRequired,
}

FileButton.defaultProps = {
  accept: '*',
  multiple: true,
}
