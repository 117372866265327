import { h } from 'preact'

import { setPageTitle } from 'lib/Page'

import PageNotFound from 'components/PageNotFound'

export default function NotFoundPage(){
  setPageTitle('Not Found')
  return <div className="NotFoundPage">
    <PageNotFound />
  </div>
}
