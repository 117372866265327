import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Link from 'components/Link'
import './index.sass'

export default function StepDots({
  className,
  dots,
  ...props
}){
  return <div {...props} className={classNames('StepDots', { className })}>
    {dots.map(({selected, ...link}, index) =>
      <Link
        key={link.key || `index-${index}`}
        className={classNames('StepDots-dot', { selected })}
        tabIndex={-1}
        {...link}
        type="none"
      ><div/></Link>
    )}
  </div>
}

StepDots.propTypes = {
  className: PropTypes.string,
  dots: PropTypes.arrayOf(
    PropTypes.shape({
      ...Link.propTypes,
      selected: PropTypes.bool,
    })
  ).isRequired,
}
