import { h } from 'preact'
import PropTypes from 'prop-types'

import { useLocation } from 'resources/location'
import classNames from 'lib/classNames'
import pathnameMatcher from 'lib/pathnameMatcher'

import TabsHeaders from 'components/TabsHeaders'

export default function PageTabs({ className, disabled, fullWidth, tabs, size, padded }){
  const location = useLocation()

  const currentTabIndex = tabs.findIndex(tab =>
    tab.route
      ? pathnameMatcher.match(location.pathname, tab.route)
      : location.pathname === tab.href
  )
  const currentTab = currentTabIndex !== -1 && tabs[currentTabIndex]
  return <div className={classNames('PageTabs', { className })}>
    <TabsHeaders {...{ disabled, fullWidth, tabs, currentTabIndex, size, padded }}/>
    {currentTab && currentTab.render()}
  </div>
}

PageTabs.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: TabsHeaders.propTypes.fullWidth,
  tabs: TabsHeaders.propTypes.tabs,
  size: TabsHeaders.propTypes.size,
  padded: TabsHeaders.propTypes.padded,
}

PageTabs.defaultProps = {
  fullWidth: true,
  size: 'md',
}
