import { h } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import { useAppState } from 'lib/appState'
import {
  useMyOrganizationMembership,
  useMyPendingOrganizationMemberships,
  useCreateOrganizationMembership,
  useUpdateOrganizationMembership,
  useOrganizationMembershipInviteFor,
} from 'lib/membershipAppStateHooks'
import { useLocation } from 'resources/location'
import { useCurrentUser } from 'resources/auth'

import Button from 'components/Button'
import InterfaceHelp from 'components/InterfaceHelp'
import { createPageAlert } from 'resources/pageAlerts'

export default function OrganizationMembershipButton({
  organization, disabled, hideIfMember, onJoin, includeOrgName
}) {
  const organizationApikey = organization.apikey
  const {
    createOrganizationMembership,
    creatingOrganizationMembership,
  } = useCreateOrganizationMembership(
    { organizationApikey},
    'OrganizationMembershipButton',
  )
  const location = useLocation()
  const currentUser = useCurrentUser()
  const { organizationMembershipInvite } = useOrganizationMembershipInviteFor(
    organizationApikey,
    'OrganizationMembershipButton'
  )
  const inviteToken = organizationMembershipInvite && organizationMembershipInvite.token

  const { takeAction } = useAppState(undefined, 'OrganizationMembershipButton')
  const { organizationMembership, isAdmin, isMember } =
    useMyOrganizationMembership(organizationApikey, 'OrganizationMembershipButton')
  const {
    updateOrganizationMembership,
    updatingOrganizationMembership,
  } = useUpdateOrganizationMembership(organizationMembership || {}, 'OrganizationMembershipButton')

  const { myPendingOrganizationMemberships } = useMyPendingOrganizationMemberships('OrganizationMembershipButton')
  const pendingMembership = myPendingOrganizationMemberships.find(pendingMembership =>
    pendingMembership.organizationApikey === organizationApikey
  )

  if (
    isAdmin ||
    (
      !isMember &&
      organization.closed_memberships &&
      !organization.users_can_request_membership &&
      !pendingMembership &&
      !inviteToken
    ) ||
    (hideIfMember && isMember)
  ) return null

  const requestedToJoin = pendingMembership &&
  pendingMembership.memberUserDid === pendingMembership.createdByUserDid
  const wasInvited = pendingMembership &&
  pendingMembership.memberUserDid !== pendingMembership.createdByUserDid


  let [buttonValue, helpContent] = (
    isMember ? [`Leave`, help.leave] :
    requestedToJoin ? [`Requested to join`, help.requestedToJoin] :
    (organization.closed_memberships && !wasInvited && !inviteToken) ? [`Request to join`, help.requestToJoin] :
    [`Join`, help.join]
  )
  if (includeOrgName) {
    let name = organization.name
    if (name.length > 20) name = name.substr(0, 20) + '…'
    buttonValue += ` ${name}`
  }

  let href, onClick
  if (currentUser){
    onClick = () => {
      if (organizationMembership && !requestedToJoin && !wasInvited){
        const confirmation = help.leaveConfirmation(organization)
        if (!confirm(confirmation)) return // eslint-disable-line
        takeAction('organizationMemberships.remove', { organizationMembership })
      }else if (requestedToJoin && !inviteToken){
        // do nothing
      }else if (wasInvited){
        updateOrganizationMembership({ accept: true })
          .then(() => { if (onJoin) onJoin() })
      }else{
        createOrganizationMembership({ organizationApikey, inviteToken })
          .then(() => {
            if (onJoin) onJoin()
            createPageAlert({
              type: 'success',
              message: `You have requested to join ${organization.name}.`,
            })
          })
      }
    }
  }else{
    href = location.withQuery({ s: 1 }).toSignup().withQuery({ o: organizationApikey })
  }

  return <InterfaceHelp {...{
    title: `${PNFO.singular} Membership`,
    content: helpContent,
  }}>
    <Button {...{
      className: 'OrganizationMembershipButton',
      type: organizationMembership || pendingMembership ? 'normal' : 'primary',
      disabled: (
        disabled ||
        requestedToJoin ||
        creatingOrganizationMembership ||
        updatingOrganizationMembership
      ),
      value: buttonValue,
      onClick,
      href,
    }}/>
  </InterfaceHelp>
}

OrganizationMembershipButton.propTypes = {
  organization: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  hideIfMember: PropTypes.bool,
  includeOrgName: PropTypes.bool,
  onJoin: PropTypes.func,
  inviteToken: PropTypes.string,
}

const help = {
  leave: (
    `This rescinds your membership to this ${PNFO.singular}.`
  ),
  requestedToJoin: (
    `Your have requested to join this ${PNFO.singular}. Please wait ` +
    `for a ${PNFO.singular} admin to approve your request`
  ),
  requestToJoin: `This ${PNFO.singular} approves each member. Click this button to request to join.`,
  join: `Click this button to join this ${PNFO.singular}`,
}
help.leaveConfirmation = organization => (
  `Are you sure you want to leave ${organization.name}?\n\n` +
  help.leave
)
