import { h, Fragment } from 'preact'
import { useRef, useMemo } from 'preact/hooks'
import PropTypes from 'prop-types'

import isMobile from 'lib/isMobile'
import classNames from 'lib/classNames'
import useToggle from 'lib/useToggleHook'
import { countUnreadMessages, useChatSynopsis } from 'lib/chatHooks'

import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import StyleishModal from 'components/StyleishModal'
import NotificationBadge from 'components/NotificationBadge'
import ContextMenu from 'components/ContextMenu'
import Header from 'components/Header'
import ChatNotificationsList from 'components/ChatNotificationsList'
import './index.sass'
import Button from 'components/Button'

export default function ChatNotificationsButton({ className, ...props }){
  const { chatSynopsis: synopsis = [] } = useChatSynopsis('ChatNotificationsButton')

  const buttonRef = useRef()
  const [isOpen, open, close] = useToggle(false)

  return <Fragment>
    <ChatButton {...{
      ...props,
      className,
      ref: buttonRef,
      synopsis,
      ...(isMobile ? { href: '/chat' } : { onClick: open }),
    }}/>
    {h(isMobile ? Modal : Menu, {
      open: isOpen,
      openChats: open,
      onClose: close,
      buttonRef,
      synopsis,
    })}
  </Fragment>
}

ChatNotificationsButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  children: IconButton.propTypes.children,
}

function ChatButton({
  className,
  synopsis,
  type = 'messages',
  size = 'lg',
  ...props
}){
  const totalUnread = useMemo(
    () => countUnreadMessages(synopsis),
    [synopsis]
  )
  return <IconButton {...{
    className: classNames('ChatNotificationsButton', { className }),
    ...props,
  }}>
    <Icon {...{type, size}} />
    <NotificationBadge topRight total={totalUnread}/>
  </IconButton>
}

function Modal({synopsis, open, onClose}){
  return <StyleishModal scrollInternally {...{
    className: 'ChatNotificationsButton-Modal',
    title: 'Chat Messages',
    open,
    onClose,
    padded: false,
  }}>
    <List {...{synopsis, onClose}}/>
  </StyleishModal>
}

function Menu({synopsis, open, onClose, buttonRef}){
  return <ContextMenu {...{
    className: 'ChatNotificationsButton-Menu',
    open,
    onClose,
    anchorRef: buttonRef,
    rightAligned: true,
  }}>
    <Header size="md" underlined className="ChatNotificationsButton-Menu-header">
      <span>Chat Notifications</span>
      {!isMobile && <Button {...{
        type: 'primary',
        size: 'sm',
        onClick: () => { window.location.href = '/chat' },
      }}>
        See All Chats
      </Button>}
    </Header>
    <List {...{synopsis, onClose}}/>
  </ContextMenu>
}

const List = ({synopsis, onClose}) =>
  <ChatNotificationsList {...{
    className: 'ChatNotificationsButton-list',
    synopsis,
    onMemberClick: onClose,
  }}/>
