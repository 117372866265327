import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import richMedia from 'lib/richMedia'

import PlainText from 'components/PlainText'
import MediaSlideshow from 'components/MediaSlideshow'
import FeedPostURLPreview from 'components/FeedPostURLPreview'
import './index.sass'

export default function RichMedia({ className, body, bigify, constrainHeight }){
  const { text, files, urlPreview } = richMedia.parse(body)
  const withFiles = !!files
  return <div className={classNames('RichMedia', { className, constrainHeight, withFiles })}>
    <div className="RichMedia-box">
      {text && <PlainText bigify={bigify} text={text}/>}
      {urlPreview && <FeedPostURLPreview {...urlPreview}/>}
    </div>
    {files && <MediaSlideshow slides={files}/>}
  </div>
}

RichMedia.propTypes = {
  className: PropTypes.string,
  body: PropTypes.string,
  bigify: PropTypes.bool,
  constrainHeight: PropTypes.bool,
}

RichMedia.is = body =>
  typeof body === 'string' &&
  body.match(/^<figure class="jlinc:media" data-data="(.+)"\/>$/)
