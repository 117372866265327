import api from '../api'

export async function load() {
  if (this.getState().gettingAuthenticatorApp) return

  this.setState({
    gettingAuthenticatorApp: true,
    gettingAuthenticatorAppError: undefined,
    addingAuthenticatorAppError: undefined,
    deletingAuthenticatorAppError: undefined,
    verifyingAuthenticatorAppError:undefined
  })

  try {
    const { authenticatorApp } = await api.getAuthenticatorApp()

    this.setState({ authenticatorApp })
  } catch (gettingAuthenticatorAppError) {
    this.setState({ gettingAuthenticatorAppError })
  } finally {
    this.setState({ gettingAuthenticatorApp: undefined })
  }
}

export async function add() {
  if (this.getState().addingAuthenticatorApp) return

  this.setState({
    addingAuthenticatorApp: true,
    gettingAuthenticatorAppError: undefined,
    addingAuthenticatorAppError: undefined,
    deletingAuthenticatorAppError: undefined,
    verifyingAuthenticatorAppError:undefined
  })

  try {
    const { qrCodeUrl, email, secret } = await api.addAuthenticatorApp()
    this.setState({
      authenticatorAppQrCodeUrl: qrCodeUrl,
      authenticatorAppSecret: secret, authenticatorAppEmail: email
    })

  } catch (addingAuthenticatorAppError) {
    this.setState({ addingAuthenticatorAppError })
  } finally {
    this.setState({ addingAuthenticatorApp: undefined })
  }
}


export async function verify({code}) {
  if (this.getState().verifyingAuthenticatorApp) return

  this.setState({
    verifyingAuthenticatorApp: true,
    gettingAuthenticatorAppError: undefined,
    addingAuthenticatorAppError: undefined,
    deletingAuthenticatorAppError: undefined,
    verifyingAuthenticatorAppError:undefined
  })

  try {
    const {authenticatorApp} = await api.verifyAuthenticatorApp({code})
    this.setState({ authenticatorApp })
  } catch (verifyingAuthenticatorAppError) {
    this.setState({ verifyingAuthenticatorAppError })
  } finally {
    this.setState({ verifyingAuthenticatorApp: undefined })
  }
}


async function _delete() {
  if (this.getState().deletingAuthenticatorApp) return

  this.setState({
    deletingAuthenticatorApp: true,
    gettingAuthenticatorAppError: undefined,
    addingAuthenticatorAppError: undefined,
    deletingAuthenticatorAppError: undefined,
    verifyingAuthenticatorAppError:undefined
  })

  try {
    await api.deleteAuthenticatorApp()
    this.setState({ authenticatorApp: null })
  } catch (deletingAuthenticatorAppError) {
    this.setState({ deletingAuthenticatorAppError })
  } finally {
    this.setState({ deletingAuthenticatorApp: undefined })
  }
}

export { _delete as delete }

