import { h } from 'preact'

import Link from 'components/Link'

export default function LinkToEmailSupport({ children, ...props }){
  const href = LinkToEmailSupport.href
  return <Link {...{...props, href}}>
    {children || `${LinkToEmailSupport.email}`}
  </Link>
}

LinkToEmailSupport.email = `support@${location.hostname.split('.').slice(-2).join('.')}`
LinkToEmailSupport.href = `mailto:${LinkToEmailSupport.email}`
