import { h, Fragment } from 'preact'
import { useMemo, useCallback } from 'preact/hooks'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'


import classNames from 'lib/classNames'
import { useMyPublicProfileDid } from 'resources/auth'
import { createPageAlert } from 'resources/pageAlerts'
import { useAppState } from 'lib/appState'
import {
  decorateFeedPost,
  feedPostPropType,
  postToPathname,
} from 'lib/feedPostHelpers'
import {
  useMyOrganizationMemberships,
  usePublicProfile,
  useOrganization,
} from 'lib/membershipAppStateHooks'
import { isValidUrl } from 'lib/validators'
import useToggle from 'lib/useToggleHook'
import humanizeNumber from 'lib/humanizeNumber'
import { copyToClipboard } from 'lib/clipboard'
import { useLocalStorage } from 'lib/storageHooks'
import interfaceHelpCopy from 'components/APortalInterfaceWalkthrough/copy'

import FeedContentBox from 'components/FeedContentBox'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Alert from 'components/Alert'
import TimeAgo from 'components/TimeAgo'
import Header from 'components/Header'
import FeedPostForm from 'components/FeedPostForm'
import OrganizationFeedPostMedia from 'components/OrganizationFeedPostMedia'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import LinkToOrganization from 'components/LinkToOrganization'
import ElevateButton from 'components/ElevateButton'
import RichMedia from 'components/RichMedia'
import WYSIWYGContent from 'components/WYSIWYGContent'
import ButtonRow from 'components/ButtonRow'
import OrganizationFeedPostAuthorIcon from 'components/OrganizationFeedPostAuthorIcon'
import FeedPostRepostModal from 'components/FeedPostRepostModal'
import FeedPostProvenanceModal from 'components/FeedPostProvenanceModal'
import FeedPostDeleteModal from 'components/FeedPostDeleteModal'
import OrganizationForumFeedPostPublishModal from 'components/OrganizationForumFeedPostPublishModal'
import PlainText from 'components/PlainText'
import InterfaceHelp from 'components/InterfaceHelp'
import './index.sass'

const OrganizationFeedPost = memo(function({
  className,
  disabled,
  post,
  withinFeed,
  feed,
  posts,
}) {
  post = useMemo(() => decorateFeedPost(post), [post])
  const myPublicProfileDid = useMyPublicProfileDid()
  const { myOrganizationMemberships } =
    useMyOrganizationMemberships('OrganizationFeedPost')

  const {
    takeAction,
    deleting,
    deletingError,
    publishing,
    publishingError,
    voting,
    votingError,
    hidingFromMe,
    hidingFromMeError,
    hiding,
    hidingError,
    pinning,
    pinningError,
    pinned,
    unpinning,
    unpinningError,
    unpinned,
    isPinned,

  } = useAppState(
    {
      [`organizationFeedPost:${post.uid}:deleting`]: 'deleting',
      [`organizationFeedPost:${post.uid}:deleting:error`]: 'deletingError',
      [`organizationFeedPost:${post.uid}:publishing`]: 'publishing',
      [`organizationFeedPost:${post.uid}:publishing:error`]: 'publishingError',
      [`organizationFeedPost:${post.uid}:voting`]: 'voting',
      [`organizationFeedPost:${post.uid}:voting:error`]: 'votingError',
      [`organizationFeedPost:${post.uid}:hidingFromMe`]: 'hidingFromMe',
      [`organizationFeedPost:${post.uid}:hidingFromMe:error`]: 'hidingFromMeError',
      [`organizationFeedPost:${post.uid}:hiding`]: 'hiding',
      [`organizationFeedPost:${post.uid}:hiding:error`]: 'hidingError',
      [`organizationFeedPost:${post.uid}:pinning`]: 'pinning',
      [`organizationFeedPost:${post.uid}:pinning:error`]: 'pinningError',
      [`organizationFeedPost:${post.uid}:unpinning`]: 'unpinning',
      [`organizationFeedPost:${post.uid}:unpinning:error`]: 'unpinningError',
    },
    'OrganizationFeedPost',
    ['isTrue']
  )
  const [showingRepostingModal, showRepostingModal, hideRepostingModal] = useToggle(false)
  const [showingProvenanceModal, showProvenanceModal, hideProvenanceModal] = useToggle(false)
  const [showingDeleteModal, showDeleteModal, hideDeleteModal] = useToggle(false)
  const [showingPublishModal, showPublishModal, hidePublishModal] = useToggle(false)
  const [stagedChanges, setStagedChanges]
    = useLocalStorage(`organizationFeedPost:${post.uid}:changes`)
  console.log(isPinned)
  const editing = !!stagedChanges
  const startEditing = () => { setStagedChanges({}) }
  const stopEditing = () => { setStagedChanges() }

  const { publicProfile: feedUserPublicProfile } = usePublicProfile(post.feedUserDid, 'OrganizationFeedPost')

  const postPathname = postToPathname(post, feedUserPublicProfile)
  const organizationsICouldRepostTo = []
  myOrganizationMemberships.forEach(membership => {
    if (membership.organizationApikey && membership.organizationApikey !== post.feedOrganizationApikey)
      organizationsICouldRepostTo.push(membership.organizationApikey)
  })

  const membership = myOrganizationMemberships.find(membership =>
    membership.organizationApikey === post.feedOrganizationApikey
  )

  post.mine = post.posterUserDid === myPublicProfileDid

  const currentUser = {}
  if (myPublicProfileDid) {
    currentUser.loggedIn = true
    currentUser.did = myPublicProfileDid
    currentUser.isMember = !!membership && !!membership.acceptedAt
    currentUser.isAdmin = membership && membership.admin
    currentUser.isCurator = currentUser.isAdmin || (membership && membership.curator)
    currentUser.canPublish = currentUser.isAdmin || currentUser.isCurator
    currentUser.canEdit = (
      !post.isDeleted &&
      post.uid === post.initUid &&
      post.posterUserDid === currentUser.did
    )
  }

  disabled = !!(
    disabled || publishing || deleting || voting || hidingFromMe || post.isDeleted || hiding || pinning || unpinning
  )
  const error = deletingError || publishingError || votingError || hidingFromMeError || hidingError
    || pinningError || unpinningError

  const { action, actor, actorIsOrg, showInitPoster } = getPostInfo({ post })

  const hidePost = useMemo(
    () => {
      if (post.isPublished && post.feedOrganizationApikey && !post.hidden && !post.isDeleted)
        return () => {
          if (confirm( // eslint-disable-line
            `Are you sure you want to hide this post? This action cannot be undone.`
          )) takeAction('feedPosts.hide', { post })
        }
    },
    [post],
  )

  className = classNames('OrganizationFeedPost', {
    className, published: post.isPublished, pinned, unpinned
  })

  const sharePostButton = <InterfaceHelp offset={2} {...interfaceHelpCopy.shareFeedPost}>
    <Button
      disabled={disabled}
      className={(
        'OrganizationFeedPost-buttonRow-button' +
        ' OrganizationFeedPost-shareButton' // used in the src/components/APortalInterfaceWalkthrough/steps.js
      )}
      value={<Fragment><Icon type="shareplane" /><span>&nbsp;Share</span></Fragment>}
      onClick={() => {
        const coppied = copyToClipboard(`${window.location.origin}${postPathname}`)
        if (coppied) createPageAlert({
          type: 'success',
          message: 'Link copied to clipboard',
        })
      }}
    />
  </InterfaceHelp>

  if (currentUser.canEdit && editing) return <FeedPostForm {...{
    className,
    feedPost: post,
    minMaxVisibleTo: post.maxVisibleTo,
    maxMaxVisibleTo: post.maxVisibleTo,
    canPostAsOrganization: post.posterOrganizationApikey,
    value: stagedChanges,
    onChange: setStagedChanges,
    onSuccess: stopEditing,
    onCancel: stopEditing,
  }} />

  const isRichMedia = RichMedia.is(post.body)


  return <FeedContentBox {...{
    "data-feed-post-uid": post.uid,
    className,
    disabled,
    error,
    icon: (
      !post.isPublished &&
      <OrganizationFeedPostAuthorIcon {...{
        organizationApikey: actorIsOrg ? actor : undefined,
        publicProfileDid: actorIsOrg ? undefined : actor,
        bordered: actorIsOrg,
      }} />
    ),
    title: <Title {...{
      disabled,
      post,
      postPathname,
      action,
      actor,
      actorIsOrg,
    }} />,
    actions: getActions({
      withinFeed,
      takeAction,
      currentUser,
      post,
      postPathname,
      showRepostingModal,
      showProvenanceModal,
      showDeleteModal,
      showPublishModal,
      startEditing,
      hidePost,
      organizationsICouldRepostTo,
      feed,
      posts,
    }),
    modals: <Fragment>
      <FeedPostRepostModal {...{
        post,
        open: showingRepostingModal,
        onClose: hideRepostingModal,
      }} />
      <FeedPostProvenanceModal {...{
        feedPostUid: post.uid,
        open: showingProvenanceModal,
        onClose: hideProvenanceModal,
      }} />
      <FeedPostDeleteModal {...{
        post: post,
        open: showingDeleteModal,
        onClose: hideDeleteModal,
      }}/>
      <OrganizationForumFeedPostPublishModal {...{
        post,
        open: showingPublishModal,
        onClose: hidePublishModal,
      }} />
    </Fragment>,
    truncateBody: (withinFeed && !isRichMedia),
    body: <Body {...{ post, isRichMedia, withinFeed }} />,
    bottom: post.isPublished
      ? <PublishedBottom {...{
        disabled,
        post,
        showInitPoster,
        organizationApikey: post.feedOrganizationApikey,
        showProvenanceModal,
        sharePostButton,
        showRepostingModal,
        currentUser,
        startEditing,
      }} />
      : <ForumBottom {...{
        disabled,
        currentUser,
        post,
        takeAction,
        withinFeed,
        postPathname,
        voting,
        showPublishModal,
        sharePostButton,
        startEditing,
        showProvenanceModal,
        pinning,
      }} />
  }} />
})

OrganizationFeedPost.propTypes = {
  disabled: PropTypes.bool,
  post: feedPostPropType.isRequired,
  withinFeed: PropTypes.bool,
}

export default OrganizationFeedPost

const Title = memo(({ disabled, post, postPathname, action, actor, actorIsOrg }) => {
  return <Fragment>
    {post.title &&
      <Header size="lg" className="OrganizationFeedPost-title">
        <LinkToPost {...{ disabled, title: post.title, postPathname }}>
          {post.title}
        </LinkToPost>
      </Header>
    }
    <Header size="sm" className="OrganizationFeedPost-poster">
      {post.isDeleted
        ? '[deleted]'
        : actorIsOrg
          ? <OrgName {...{ actor }} />
          : <UserName {...{ actor }} />}
      <span className="OrganizationFeedPost-poster-action">&nbsp;{action}&nbsp;</span>
      <LinkToPost {...{
        disabled,
        title: post.createdAt,
        postPathname,
        type: 'none',
        className: 'OrganizationFeedPost-postedAgo',
      }}>
        <TimeAgo time={post.createdAt} />
      </LinkToPost>
      {post.isEdited && <span> and edited <TimeAgo time={post.contentCreatedAt} /></span>}
    </Header>
    {
      post.isPublished || <MaxVisibleTo {...post} />
    }
  </Fragment>
})


const VisibleToInterfaceHelp = ({ labels }) =>
  <div>
    <Header size="lg">Feed posts marked with…</Header>
    <p><b>{labels[0]}</b> - Can never leave this {PNFO.singular}</p>
    <p><b>{labels[2]}</b> - Can only every be shared to logged in {APP_NAME} users</p>
    <p><b>{labels[3]}</b> - Can be shared anywhere</p>
  </div>

const HUMANIZED_VISIBLE_TO = [
  `Visible to ${PNFO.singular} members`,
  'Visible to selected networks',
  `Visible to logged in ${APP_NAME} users`,
  'Visible to anyone on the internet',
]
const VisibleTo = memo(({ visibleTo }) =>
  <Header size="sm" className="OrganizationFeedPost-visibleTo">
    <InterfaceHelp p="inline" {...{
      title: `Visibility of this Feed Post`,
      content: <VisibleToInterfaceHelp labels={HUMANIZED_VISIBLE_TO} />
    }}>
      <i>{HUMANIZED_VISIBLE_TO[visibleTo]}</i>
    </InterfaceHelp>
  </Header>
)

const HUMANIZED_MAX_VISIBLE_TO = [
  'Cannot be published',
  'Can be published to networks',
  `Can be published to logged in ${APP_NAME} users`,
  'Can be published to anyone on the internet',
]
const MaxVisibleTo = memo(({ maxVisibleTo }) =>
  <Header size="sm" className="OrganizationFeedPost-visibleTo">
    <InterfaceHelp p="inline" {...{
      title: `Max Visibility of this Feed Post`,
      content: <VisibleToInterfaceHelp labels={HUMANIZED_MAX_VISIBLE_TO} />
    }}>
      <i>{HUMANIZED_MAX_VISIBLE_TO[maxVisibleTo]}</i>
    </InterfaceHelp>
  </Header>
)

const LinkToPost = memo(({ className = '', postPathname, children, ...props }) => {
  return <Link {...{
    href: postPathname,
    className: `OrganizationFeedPost-LinkToPost ${className}`,
    ...props,
  }}>
    {children}
  </Link>
})

const Body = memo(({ post, isRichMedia, withinFeed }) => {
  if (post.isDeleted) return <DeletedAlert post={post} />
  if (post.mediaUrl)
    return <OrganizationFeedPostMedia
      mediaUrl={post.mediaUrl}
      mediaMimeType={post.mediaMimeType}
    />
  if (post.body)
    return <OrganizationFeedPostBody {...{ post, isRichMedia, withinFeed }} />
})

const PublishedBottom = memo(({
  post,
  disabled,
  showInitPoster,
  // showProvenanceModal, //! related to truLine
  sharePostButton,
  showRepostingModal,
  currentUser,
  startEditing,
}) => {
  return <div className="OrganizationFeedPost-PublishedBottom">
    <ButtonRow className="OrganizationFeedPost-buttonRow">
      {sharePostButton}
      {/* //! remove truLine from published as it is hard to identify from it that the post is deleted from forum post or not  */}
      {/* <InterfaceHelp offset={2} content={
        `The ${APP_NAME}Line shows where content came from, where it was posted and republished, and by whom`
      }>
        <Button
          type="none"
          className={(
            'OrganizationFeedPost-buttonRow-button' +
            ' OrganizationFeedPost-truLine' // used in the src/components/APortalInterfaceWalkthrough/steps.js
          )}
          onClick={showProvenanceModal}
          disabled={disabled}
        >
          <Icon type="truline" />
          <span>&nbsp;TruLine</span>
        </Button>
      </InterfaceHelp> */}
      <InterfaceHelp offset={2} content={`Repost this post to a forum or your public profile`}>
        <Button
          type="none"
          className={(
            'OrganizationFeedPost-buttonRow-button' +
            ' OrganizationFeedPost-repost' // used in the src/components/APortalInterfaceWalkthrough/steps.js
          )}
          onClick={showRepostingModal}
          disabled={disabled}
        >
          <Icon type="share" />
          <span>&nbsp;Repost</span>
        </Button>
      </InterfaceHelp>
      {currentUser.canEdit && post.feedUserDid === currentUser.did &&
        <InterfaceHelp offset={2} {...interfaceHelpCopy.editForumFeedPost}>
          <Button
            disabled={disabled || post.isDeleted}
            type="none"
            className="OrganizationFeedPost-buttonRow-button"
            onClick={startEditing}
          >
            <Icon type="edit"/>
            <span>&nbsp;Edit</span>
          </Button>
        </InterfaceHelp>
      }
    </ButtonRow>
    <div className="OrganizationFeedPost-publishedDetails">
      {showInitPoster &&
        <Header size="sm" className="OrganizationFeedPost-initPoster">
          <LinkToOriginalPost {...{ post, disabled }} />
          &nbsp;by&nbsp;
          {post.initPosterOrganizationApikey
            ? <OrgName {...{ actor: post.initPosterOrganizationApikey }} />
            : <UserName {...{ actor: post.initPosterUserDid }} />
          }
          &nbsp;
          {post.feedOrganizationApikey !== post.initFeedOrganizationApikey &&
            post.initPosterOrganizationApikey !== post.initFeedOrganizationApikey &&
            <span>to&nbsp;<OrgName {...{ actor: post.initFeedOrganizationApikey }} />&nbsp;</span>
          }
          <TimeAgo time={post.initCreatedAt} />
        </Header>
      }
      <VisibleTo {...post} />
    </div>
  </div>
})

const ForumBottom = memo(({
  disabled,
  currentUser,
  post,
  takeAction,
  withinFeed,
  postPathname,
  voting,
  showPublishModal,
  sharePostButton,
  startEditing,
}) => {
  const cantAct = disabled || !post || !currentUser.isMember
  const elevated = post.myVote === 1
  return <div className="OrganizationFeedPost-ForumBottom">
    <ButtonRow className="OrganizationFeedPost-buttonRow">
      <InterfaceHelp offset={2} {...interfaceHelpCopy.upvoteForumFeedPost}>
        <ElevateButton {...{
          className: 'OrganizationFeedPost-buttonRow-button',
          disabled: cantAct || voting,
          elevated,
          count: post.upvoteCount,
          onClick: useCallback(
            () => {
              if (cantAct || voting) return
              takeAction('feedPosts.voteOnPost', {
                feedPostUid: post.uid,
                vote: elevated ? 0 : 1 // toggle
              })
            },
            [takeAction, post, voting, cantAct, elevated]
          ),
        }} />
      </InterfaceHelp>
      <InterfaceHelp offset={2} {...interfaceHelpCopy.viewFeedPostComments}>
        <CommentCount {...{
          disabled: (disabled || !currentUser),
          post, withinFeed, postPathname
        }} />
      </InterfaceHelp>
      {!post.isDeleted && sharePostButton}
      {currentUser.canPublish &&
        <InterfaceHelp offset={2} {...interfaceHelpCopy.publishForumFeedPost}>
          <Button
            disabled={disabled || post.isDeleted || post.isPublished || !post.isPublishable}
            type="none"
            className="OrganizationFeedPost-buttonRow-button"
            onClick={showPublishModal}
          >
            <Icon type="public-feed" />
            <span>&nbsp;Publish</span>
          </Button>
        </InterfaceHelp>
      }
      {currentUser.canEdit &&
        <InterfaceHelp offset={2} {...interfaceHelpCopy.editForumFeedPost}>
          <Button
            disabled={disabled}
            type="none"
            className="OrganizationFeedPost-buttonRow-button"
            onClick={startEditing}
          >
            <Icon type="edit" />
            <span>&nbsp;Edit</span>
          </Button>
        </InterfaceHelp>
      }
    </ButtonRow>
  </div>
})

function CommentCount({ disabled, post, withinFeed, postPathname }) {
  return <Link
    disabled={disabled}
    className="OrganizationFeedPost-buttonRow-button"
    href={withinFeed ? postPathname : undefined}
  >
    <Icon type="singlecomment" />
    <span>&nbsp;{humanizeNumber(post.commentCount)}</span>
    <span>&nbsp;Comments</span>
  </Link>
}

function getPostInfo({ post }) {
  let action // published || reposted || posted
  let actor // organizationApikey || publicProfileDid
  let actorIsOrg
  let showInitPoster = true

  // if consumed
  if (!post.posterUserDid && !post.posterOrganizationApikey && post.lastPublishingOrganizationApikey) {
    action = 'published'
    actor = post.lastPublishingOrganizationApikey
    actorIsOrg = true
    // if published
  } else if (post.feedOrganizationApikey && post.isPublished) {
    action = 'published'
    actor = post.posterOrganizationApikey
    actorIsOrg = true
  } else if (post.feedUserDid) {
    action = 'published'
    actor = post.posterUserDid
    actorIsOrg = false
    // if reposted
  } else if (post.parentUid && post.posterUserDid) {
    action = 'reposted'
    actor = post.posterOrganizationApikey || post.posterUserDid
    actorIsOrg = !!post.posterOrganizationApikey
    // else posted
  } else {
    action = 'posted'
    actor = post.posterOrganizationApikey || post.posterUserDid
    actorIsOrg = !!post.posterOrganizationApikey
    showInitPoster = false
  }
  return { action, actor, actorIsOrg, showInitPoster }
}

function OrgName({ actor }) {
  const { organization = {} } =
    useOrganization(actor, 'OrganizationFeedPost.OrgName')
  return <LinkToOrganization type="text" organizationApikey={actor}>
    {organization.name}
  </LinkToOrganization>
}

function UserName({ actor }) {
  const { publicProfile } =
    usePublicProfile(actor, 'OrganizationFeedPost.UserName')
  if (!publicProfile) return <span>@unknown</span>
  return <LinkToPublicProfile type="text" publicProfile={publicProfile} />
}

function LinkToOriginalPost({ post, disabled }) {
  const { publicProfile } =
    usePublicProfile(post.initFeedUserDid, 'OrganizationFeedPost.LinkToOriginalPost')
  const href = post.initFeedOrganizationApikey
    ? `/${post.initFeedOrganizationApikey}/forum/${post.initUid}`
    : publicProfile
      ? `/@${publicProfile.username}/post/${post.initUid}`
      : undefined
  return <Link {...{ type: 'none', disabled, href }}>originally posted</Link>
}

function getActions({
  withinFeed,
  takeAction,
  currentUser,
  post,
  postPathname,
  showProvenanceModal,
  showPublishModal,
  showDeleteModal,
  startEditing,
  hidePost,
  feed,
}) {
  const options = []
  console.log(withinFeed)
  if (currentUser.loggedIn && !post.deletedAt) {
    options.push({
      value: PROVENANCE_CHAIN_NAME,
      onSelect: showProvenanceModal,
    })
  }

  if (withinFeed) {
    options.push({
      value: 'Go to post',
      href: postPathname,
    })
  }

  if (
    !post.hidden &&
    !post.mine &&
    currentUser.loggedIn &&
    !(post.isPublished && currentUser.canPublish)
  ) {
    options.push({
      value: 'Hide from me',
      onSelect: () => {
        if (confirm( // eslint-disable-line
          `Are you sure you want to hide this post?`
        )) takeAction('feedPosts.hideFromMe', { post })
      },
    })
  }

  if (currentUser.canPublish && post.isPublishable) {
    options.push({
      value: 'Publish',
      onSelect: showPublishModal,
    })
  }

  if (currentUser.canEdit && (post.isPublishable || post.feedUserDid === currentUser.did)){
    options.push({
      value: 'Edit',
      onSelect: startEditing,
    })
  }

  if (
    !post.isDeleted &&
    !post.hidden &&
    post.isPublished
  ) {

    if (currentUser.canPublish && feed !== 'homepage:feed') {
      options.push({
        value: 'Hide',
        onSelect: hidePost,
      })
    }
  }

  if (
    !post.isDeleted &&
    (post.mine || currentUser.isAdmin || currentUser.isCurator)
  ) {
    options.push({
      value: 'Delete',
      onSelect:
      () => {
        if (post.mine && currentUser.isAdmin || post.mine) {
          if (confirm( // eslint-disable-line
            `Are you sure you want to delete this post?`
          )) takeAction('feedPosts.deletePost', { post })
        } else {
          showDeleteModal()
        }
      },
    })
  }

  if (currentUser.isCurator && !post.isDeleted) {
    if (post.pinned) {
      options.push({
        icon: 'pin',
        value: 'Unpin Post',
        onSelect: function() {
          if (confirm( // eslint-disable-line
            `Are you sure you want to unpin this post?`
          )) takeAction('feedPosts.unpinPost', { post })

          createPageAlert({
            type: 'info',
            message: 'This post has been unpinned.',
          })
        },
      })
    } else {
      options.push({
        value: 'Pin Post',
        onSelect: function() {
          if (confirm( // eslint-disable-line
            `Are you sure you want to pin this post?`
          )) takeAction('feedPosts.pinPost', { post })

          createPageAlert({
            type: 'success',
            message: 'Post pinned successfully!',
          })
        },
      })
    }
  }

  return options
}

const OrganizationFeedPostBody = memo(({ post, isRichMedia, withinFeed }) => {
  if (isRichMedia) return <RichMedia body={post.body} bigify constrainHeight={withinFeed} />

  const type = (
    (post.body && isValidUrl(post.body)) ? 'link' :
    post.mediaUrl ? 'media' : 'text'
  )

  if (type === 'link') {
    return <div className="OrganizationFeedPost-linkContent">
      <Header size="md">
        <OrganizationFeedPostBodyLink url={post.body} />
      </Header>
    </div>
  }

  if (post.body.startsWith('<'))
    return <WYSIWYGContent source={post.body} />

  return <PlainText text={post.body} />
})

function OrganizationFeedPostBodyLink(props) {
  return <PlainText.Link {...props} />
}

function DeletedAlert({ post }) {
  return <Alert type="warning" className="OrganizationFeedPost-deleted">
    This post was deleted
    {!post.isPublished &&
      (post.deletedByPoster ? ' by the user who created it' : ' by an administrator')
    }
  </Alert>
}

